import { useEffect, net } from 'react';
import MainPage from './Sites/MainPage';
//import styles from './Styles/styles.module.css';
import {ScreenDataContext, ScreenDataContextProvider} from './API/ScreenDataContext'
import {BaseStatContext, BaseStatContextProvider} from './API/BaseStatContext'
import {AttributeContext, AttributeContextProvider} from './API/AttributeContext'
import {SkillContext, SkillContextProvider} from './API/SkillContext'
import {ToolDataContext, ToolDataContextProvider} from './API/ToolDataContext'
import {TextDataContext, TextDataContextProvider} from './API/TextDataContext'
import {EquipmentContext, EquipmentContextProvider} from './API/EquipmentContext'
import {MagicContext, MagicContextProvider} from './API/MagicContext'
import {CampaignContext, CampaignContextProvider} from './API/CampaignContext'
import {UserDataContext, UserDataContextProvider} from './API/UserDataContext'
import {MessageBoxContext, MessageBoxContextProvider} from './API/MessageBoxContext'
import {RestUserContext, RestUserContextProvider} from './ServerConnection/RestUserContext'
import {RemoteConnectContext, RemoteConnectContextProvider} from './ServerConnection/RemoteConnectContext'

import {APIContext, APIContextProvider} from './API/API'

function App() {

  return (
    <MessageBoxContextProvider>
      <ToolDataContextProvider>
        <UserDataContextProvider>
          <ScreenDataContextProvider>
            <TextDataContextProvider>
              <BaseStatContextProvider>
                <AttributeContextProvider>
                  <SkillContextProvider>
                    <EquipmentContextProvider>
                      <MagicContextProvider>
                        <CampaignContextProvider>
                          <APIContextProvider>
                            <RestUserContextProvider>
                              <RemoteConnectContextProvider>
                                <div className="App" style={{height: '100vh', background: 'lightgrey'}}>
                                  <MainPage />
                                </div>
                              </RemoteConnectContextProvider>
                            </RestUserContextProvider>
                          </APIContextProvider>
                        </CampaignContextProvider>
                      </MagicContextProvider>
                    </EquipmentContextProvider>
                  </SkillContextProvider>
                </AttributeContextProvider>
              </BaseStatContextProvider>
            </TextDataContextProvider>
          </ScreenDataContextProvider>
        </UserDataContextProvider>
      </ToolDataContextProvider>
    </MessageBoxContextProvider>
  );
}

export default App;
