import { useState, createContext} from 'react'
import {
    BACKEND_get_tool_data, 
    BACKEND_set_tool_data,
    BACKEND_get_tool_settings,
    BACKEND_set_tool_settings,
    BACKEND_get_dice_log,
    BACKEND_set_dice_log,
    BACKEND_set_dice_log_value,
    BACKEND_get_language_list
} from '../Backend/Parameters/ToolData'
import {
    BACKEND_get_user,
    BACKEND_set_user
} from '../Backend/Parameters/UserData'

const ToolDataContext = createContext();

const ToolDataContextProvider = ({children}) => {

    const [toolData, setToolData] = useState({...BACKEND_get_tool_data()});
    const [toolSettings, setToolSettings] = useState({...BACKEND_get_tool_settings()});
    const [diceLog, setDiceLog] = useState({...BACKEND_get_dice_log()});
    const [diceLogVisible, setDiceLogVisible] = useState(false);
    //const [userMode, setUserMode] = useState('undefined');

    const API_get_tool_data = () => {return toolData;}
    const API_set_tool_data = () => {setToolData({...BACKEND_get_tool_data()});}

    const API_get_tool_settings = () => {return toolSettings;}
    const API_set_tool_settings = () => {setToolSettings({...BACKEND_get_tool_settings()});}

    const API_get_dice_log = () => {return diceLog;}
    const API_set_dice_log = () => {setDiceLog({...BACKEND_get_dice_log()});}

    const API_get_dice_log_visibility = () => {return diceLogVisible;}
    const API_set_dice_log_visibility = (_val) => {setDiceLogVisible(_val);}

    /*const API_get_user_mode = () => {return userMode;}
    const API_set_user_mode = () => {setUserMode({...BACKEND_get_user().user_mode});}*/

    return(
        <ToolDataContext.Provider value={{
            API_get_tool_data,
            API_set_tool_data,
            API_get_tool_settings,
            API_set_tool_settings,
            API_get_dice_log,
            API_set_dice_log,
            API_get_dice_log_visibility,
            API_set_dice_log_visibility,
            //API_get_user_mode,
            //API_set_user_mode
        }}>
            {children}
        </ToolDataContext.Provider>
    )
}

export {ToolDataContext, ToolDataContextProvider}