import { useState, useContext } from 'react'
import { 
   BACKEND_set_attribute_groups,
   BACKEND_get_attribute_groups,
   BACKEND_set_attributes,
   BACKEND_get_attributes,
   BACKEND_set_skills,
   BACKEND_get_skills,
   BACKEND_set_melee_fighting_types,
   BACKEND_get_melee_fighting_types,
   BACKEND_set_ranged_fighting_types,
   BACKEND_get_ranged_fighting_types,
   //BACKEND_get_hand_types,
   //BACKEND_set_hand_types,
   BACKEND_get_lead_attribute_tags,
   BACKEND_set_lead_attribute_tags,
   BACKEND_set_money_list,
   BACKEND_get_money_list,
   BACKEND_set_magic_element_types,
   BACKEND_get_magic_element_types,
   BACKEND_set_magic_circle_types,
   BACKEND_get_magic_circle_types
   //BACKEND_get_lead_attribute_matching
} from '../Parameters/CharacterParameters'

import { 
   BACKEND_get_tool_data, 
   BACKEND_set_tool_data,
   BACKEND_get_dice_log,
   BACKEND_set_dice_log,
   BACKEND_set_dice_log_value,
   BACKEND_get_language_list
} from '../Parameters/ToolData'

import {
   BACKEND_get_base_stat_labels, 
   BACKEND_set_base_stat_labels, 
   BACKEND_get_base_stat_labels_translations,
   BACKEND_get_std_labels, 
   BACKEND_set_std_labels, 
   BACKEND_get_std_header_translations,
   BACKEND_get_std_labels_translations,
   BACKEND_get_std_header_labels,
   BACKEND_set_std_header_labels,
   BACKEND_set_stat_overview_labels,
   BACKEND_get_stat_overview_labels,
   BACKEND_get_stat_overview_translations,
   BACKEND_set_money_header_labels,
   BACKEND_get_money_header_labels,
   BACKEND_get_money_header_translations,
   BACKEND_get_money_list_translations,
   BACKEND_set_message_box_content_labels,
   BACKEND_get_message_box_content_labels,
   BACKEND_get_message_box_content_translations,
   BACKEND_get_tab_view_labels,  
   BACKEND_set_tab_view_labels,  
   BACKEND_get_tab_view_translations,
   BACKEND_get_character_stats_labels, 
   BACKEND_set_character_stats_labels, 
   BACKEND_get_character_stats_translations,
   BACKEND_get_attribute_header_labels, 
   BACKEND_set_attribute_header_labels,
   BACKEND_get_attribute_header_translations,
   BACKEND_set_equipment_header_labels,
   BACKEND_get_equipment_header_labels,
   BACKEND_get_equipment_header_translations,
   BACKEND_get_melee_fighting_type_translations,
   BACKEND_get_ranged_fighting_type_translations,
   //BACKEND_get_hand_type_translations,
   BACKEND_get_attribute_group_translations,
   BACKEND_get_attribute_translations,
   BACKEND_get_skill_translations,
   BACKEND_get_dice_log_translations,
   BACKEND_get_magic_circle_type_translations,
   BACKEND_get_user_labels,
   BACKEND_set_user_labels,
   BACKEND_get_user_label_translations
} from './TextData'

import {
   BACKEND_get_attribute_by_id
} from '../BackendRouter'

import {
   API_get_skill_meanings,
   API_set_skill_meanings,
   API_get_check_difficulties,
   API_set_check_difficulties,
   API_get_check_qualities,
   API_set_check_qualities,
   API_get_skill_meanings_translations,
   API_get_check_difficulty_translations,
   API_get_check_quality_translations
} from '../../API/Parameters/RuleParameters'

const Translate = (_language_to) => {

   let _tool_data = BACKEND_get_tool_data();

   switch_language(_language_to);
   
   _tool_data.language = _language_to;
   BACKEND_set_tool_data(_tool_data);
}

const get_skills_from_group = (_group_id) => {
   let _skills = BACKEND_get_skills();
   for(let key in _skills){
      if(_skills[key].skill_group_id == _group_id){
         return _skills[key].skills;
      }
   }
   return null;
}

const switch_language = (_language) => {

   switch_language_list_no_ID(BACKEND_get_base_stat_labels(), BACKEND_get_base_stat_labels_translations(), BACKEND_set_base_stat_labels, _language);
   switch_language_list_no_ID(BACKEND_get_std_labels(), BACKEND_get_std_labels_translations(), BACKEND_set_std_labels, _language);
   switch_language_list_no_ID(BACKEND_get_std_header_labels(), BACKEND_get_std_header_translations(), BACKEND_set_std_header_labels, _language);
   switch_language_list_no_ID(BACKEND_get_tab_view_labels(), BACKEND_get_tab_view_translations(), BACKEND_set_tab_view_labels, _language);
   switch_language_list_no_ID(BACKEND_get_character_stats_labels(), BACKEND_get_character_stats_translations(), BACKEND_set_character_stats_labels, _language);
   switch_language_list_no_ID(BACKEND_get_attribute_header_labels(), BACKEND_get_attribute_header_translations(), BACKEND_set_attribute_header_labels, _language);
   switch_language_list_no_ID(BACKEND_get_equipment_header_labels(), BACKEND_get_equipment_header_translations(), BACKEND_set_equipment_header_labels, _language);
   switch_language_list_no_ID(BACKEND_get_dice_log(), BACKEND_get_dice_log_translations(), BACKEND_set_dice_log, _language);
   switch_language_list_no_ID(BACKEND_get_stat_overview_labels(), BACKEND_get_stat_overview_translations(), BACKEND_set_stat_overview_labels, _language);
   switch_language_list_no_ID(BACKEND_get_money_header_labels(), BACKEND_get_money_header_translations(), BACKEND_set_money_header_labels, _language);
   switch_language_list_no_ID(BACKEND_get_message_box_content_labels(), BACKEND_get_message_box_content_translations(), BACKEND_set_message_box_content_labels, _language);
   switch_language_list_no_ID(BACKEND_get_user_labels(), BACKEND_get_user_label_translations(), BACKEND_set_user_labels, _language);
   switch_language_list_ID(BACKEND_get_attribute_groups(), BACKEND_get_attribute_group_translations(), BACKEND_set_attribute_groups, _language);
   switch_language_list_ID(BACKEND_get_melee_fighting_types(), BACKEND_get_melee_fighting_type_translations(), BACKEND_set_melee_fighting_types, _language);
   switch_language_list_ID(BACKEND_get_ranged_fighting_types(), BACKEND_get_ranged_fighting_type_translations(), BACKEND_set_ranged_fighting_types, _language);
   //switch_language_list_ID(BACKEND_get_hand_types(), BACKEND_get_hand_type_translations(), BACKEND_set_hand_types, _language);
   switch_language_list_ID(API_get_skill_meanings(), API_get_skill_meanings_translations(), API_set_skill_meanings, _language);
   switch_language_list_ID(API_get_check_difficulties(), API_get_check_difficulty_translations(), API_set_check_difficulties, _language);
   switch_language_list_ID(API_get_check_qualities(), API_get_check_quality_translations(), API_set_check_qualities, _language);
   switch_language_list_ID_2_params(BACKEND_get_money_list(), BACKEND_get_money_list_translations(), BACKEND_set_money_list, _language);
   switch_language_list_ID_2_params(BACKEND_get_attributes(), BACKEND_get_attribute_translations(), BACKEND_set_attributes, _language);
   switch_skill_language(_language);
   switch_language_list_ID_from_existing_list_2_params(BACKEND_get_lead_attribute_tags(), BACKEND_get_attributes(), BACKEND_set_lead_attribute_tags, _language, 'display_name', 'abbreviation', 'attribute_id', 'id');
   switch_language_list_ID_from_existing_list_2_params(BACKEND_get_magic_element_types(), get_skills_from_group(18), BACKEND_set_magic_element_types, _language, 'display_name', 'display_name', 'id', 'id');
   switch_language_list_ID(BACKEND_get_magic_circle_types(), BACKEND_get_magic_circle_type_translations(), BACKEND_set_magic_circle_types, _language);
   
   //switch_attribute_groups_language(_language);
   //switch_money_list_language(_language);
   //switch_attributes_language(_language);
   //switch_melee_weapon_type_language(_language);
   //switch_ranged_weapon_type_language(_language);
   //switch_lead_attribute_tag_language(_language);
   //switch_magic_element_type_language(_language);
   //switch_magic_circle_type_language(_language);

   //BACKEND_set_attribute_groups(_attribute_groups);
}

const switch_language_list_no_ID = (_label_list, _translation_list, _setter, _language) =>
{
   let _local_list = {..._label_list};
   let _local_translation_list = {..._translation_list};
   for(var key in _local_translation_list){
      _local_list[key] = _local_translation_list[key][_language];
   }
   _setter(_local_list);
}

const switch_language_list_ID = (_label_list, _translation_list, _setter, _language, _parameter_tag = 'display_name', _id_tag = 'id', _log = false) =>
{
   let _local_list = {..._label_list};
   let _local_translation_list = {..._translation_list};
   for(var key1 in _label_list){
       for(var key2 in _local_translation_list){
           if(_local_list[key1][_id_tag] == _local_translation_list[key2][_id_tag])
           {
             _local_list[key1][_parameter_tag] = _local_translation_list[key2][_language];
           }
       }
   }
   _setter(_local_list);
}

const switch_language_list_ID_2_params = (_label_list, _translation_list, _setter, _language, _parameter_tag_1 = 'display_name', _parameter_tag_2 = 'abbreviation', _id_tag = 'id') =>
{
   let _local_list = {..._label_list};
   let _local_translation_list = {..._translation_list};
   for(var key1 in _local_list){
       for(var key2 in _local_translation_list){
           if(_local_list[key1][_id_tag] == _local_translation_list[key2][_id_tag])
           {
            _local_list[key1][_parameter_tag_1] = _local_translation_list[key2][_language][_parameter_tag_1];
            _local_list[key1][_parameter_tag_2] = _local_translation_list[key2][_language][_parameter_tag_2];
           }
       }
   };
   _setter(_local_list);
}

const switch_language_list_ID_from_existing_list = (_label_list, _other_list, _setter, _language, _label_parameter_tag = 'display_name', _label_id_tag = 'id', _other_id_tag = 'id') =>
{
   let _local_list = {..._label_list};
   let _local_other_list = {..._other_list};
   for(let key1 in _local_list)
   {
      for(let key2 in _local_other_list)
      {
         if(_local_list[key1][_label_id_tag] == _local_other_list[key2][_other_id_tag])
         {
            _local_list[key1][_label_parameter_tag] = _local_other_list[key2];
         }
      }
   }
   _setter(_local_list);
}

const switch_language_list_ID_from_existing_list_2_params = (_label_list, _other_list, _setter, _language, _label_parameter_tag = 'display_name', _other_parameter_tag = 'abbreviation', _label_id_tag = 'id', _other_id_tag = 'id') =>
{
   let _local_list = {..._label_list};
   let _local_other_list = {..._other_list};
   for(let key1 in _local_list)
   {
      for(let key2 in _local_other_list)
      {
         if(_local_list[key1][_label_id_tag] == _local_other_list[key2][_other_id_tag])
         {
            _local_list[key1][_label_parameter_tag] = _local_other_list[key2][_other_parameter_tag];
         }
      }
   }
   _setter(_local_list);
}

/*const switch_melee_weapon_type_language = (_language) => {
   let _melee_weapon_type =   BACKEND_get_melee_weapon_types();
   let _melee_weapon_type_translations = BACKEND_get_melee_weapon_type_translations();
   for(var key1 in _melee_weapon_type){
       for(var key2 in _melee_weapon_type_translations){
           if(_melee_weapon_type[key1].id == _melee_weapon_type_translations[key2].id)
           {
               _melee_weapon_type[key1].display_name = _melee_weapon_type_translations[key2][_language];
           }
       }
   }
   BACKEND_set_melee_weapon_types(_melee_weapon_type);
}*/

/*const switch_ranged_weapon_type_language = (_language) => {
   let _ranged_weapon_type = BACKEND_get_ranged_weapon_types();
   let _ranged_weapon_type_translations = BACKEND_get_ranged_weapon_type_translations();
   for(var key1 in _ranged_weapon_type){
       for(var key2 in _ranged_weapon_type_translations){
           if(_ranged_weapon_type[key1].id == _ranged_weapon_type_translations[key2].id)
           {
            _ranged_weapon_type[key1].display_name = _ranged_weapon_type_translations[key2][_language];
           }
       }
   }
   BACKEND_set_ranged_weapon_types(_ranged_weapon_type);
}*/

/*const switch_magic_element_type_language = (_language) => {
   let _element_type = BACKEND_get_magic_element_types();
   let _element_type_translation = BACKEND_get_magic_element_type_translations();
   for(var key1 in _ranged_weapon_type){
       for(var key2 in _ranged_weapon_type_translations){
           if(_ranged_weapon_type[key1].id == _ranged_weapon_type_translations[key2].id)
           {
            _ranged_weapon_type[key1].display_name = _ranged_weapon_type_translations[key2][_language];
           }
       }
   }
   BACKEND_set_ranged_weapon_types(_ranged_weapon_type);
}*/

/*const switch_attribute_groups_language = (_language) => {
   let _attribute_groups = BACKEND_get_attribute_groups();
   var _attribute_group_translations = BACKEND_get_attribute_group_translations();
   for(var key1 in _attribute_groups){
       for(var key2 in _attribute_group_translations){
           if(_attribute_groups[key1].id == _attribute_group_translations[key2].id)
           {
               _attribute_groups[key1].display_name = _attribute_group_translations[key2][_language];
           }
       }
   }
   BACKEND_set_attribute_groups(_attribute_groups);
}*/

/*const switch_money_list_language = (_language) => {
   let _money_list = BACKEND_get_money_list();
   var _money_list_translations = BACKEND_get_money_list_translations();
   for(var key1 in _money_list){
       for(var key2 in _money_list_translations){
           if(_money_list[key1].id == _money_list_translations[key2].id)
           {
            _money_list[key1].display_name = _money_list_translations[key2][_language].display_name;
            _money_list[key1].abbreviation = _money_list_translations[key2][_language].abbreviation;
           }
       }
   };
   BACKEND_set_money_list(_money_list);
}*/

/*const switch_attributes_language = (_language) => {
   let _attributes = BACKEND_get_attributes();
   var _attribute_translations = BACKEND_get_attribute_translations();
   for(var key1 in _attributes){
       for(var key2 in _attribute_translations){
           if(_attributes[key1].id == _attribute_translations[key2].id)
           {
               _attributes[key1].display_name = _attribute_translations[key2][_language].display_name;
               _attributes[key1].abbreviation = _attribute_translations[key2][_language].abbreviation;
           }
       }
   };
   BACKEND_set_attributes(_attributes);
}*/

/*const switch_lead_attribute_tag_language = (_language) => {
   let _lead_attribute_tags = BACKEND_get_lead_attribute_tags();
   let _attributes = BACKEND_get_attributes();

   for(let key1 in _lead_attribute_tags)
   {
      for(let key2 in _attributes)
      {
         if(_lead_attribute_tags[key1].attribute_id == _attributes[key2].id)
         {
            _lead_attribute_tags[key1].display_name = _attributes[key2].abbreviation;
         }
      }
   }
   BACKEND_set_lead_attribute_tags(_lead_attribute_tags);
}*/

const switch_skill_language = (_language) => {
   let _skills = BACKEND_get_skills();
   var _skill_translations = BACKEND_get_skill_translations();

   
   for(var key1 in _skills){
      let _attribute = BACKEND_get_attribute_by_id(_skills[key1].skill_group_id);
      _skills[key1].display_name = _attribute.display_name;
   }

   for(var key1 in _skills){
      for(var key2 in _skill_translations){
         if(_skills[key1].skill_group_id == _skill_translations[key2].skill_group_id)
         {
            for(var key3 in _skills[key1].skills){
               for(var key4 in _skill_translations[key2].skill_translations){
                  if(_skills[key1].skills[key3].id == _skill_translations[key2].skill_translations[key4].id)
                  {
                     _skills[key1].skills[key3].display_name = _skill_translations[key2].skill_translations[key4][_language];
                  }
               }
            }
         }
      }
   };

   BACKEND_set_skills(_skills);
}

  export {Translate}