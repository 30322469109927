import { createRef, useContext } from 'react'
import {MessageBoxContext} from '../../API/MessageBoxContext'
import btn_style from '../../StylesStylish/btn.module.css'
import container_style from '../../StylesStylish/container.module.css'


const YesNoMessageBox = (props) => {
    const {
        API_get_yes_no_message_box_visibility,
        API_get_message_box_content,
        API_get_confirm_function,
        API_open_yes_no_message_box,
        API_close_yes_no_message_box
    } = useContext(MessageBoxContext);

    const yesHandler = () => {
        let _function = API_get_confirm_function();
        _function();
        API_close_yes_no_message_box();
    }

    const noHandler = () => {
        API_close_yes_no_message_box();
    }

    return ( 
        <div className={container_style.message_box_container}>
            <div className={container_style.message_box}>
                <div>
                    <label style={{paddingTop: 15, display: 'flex', textAlign: 'center'}}>{API_get_message_box_content()}</label>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', marginTop: 15}}>
                    <button className={btn_style.std_button} style={{display: 'inline-block', color: 'white', marginRight: 15, width: 100, height: 30}} onClick={yesHandler}>Yes</button>
                    <button className={btn_style.std_button} style={{color: 'white', width: 100, height: 30}} onClick={noHandler}>No</button>
                </div>
            </div>
        </div>
     );
}
 
export default YesNoMessageBox;