import { useState, useContext } from 'react'
import {SkillContext, SkillContextProvider} from '../API/SkillContext'
import {AttributeContext, AttributeContextProvider} from '../API/AttributeContext'
import {TextDataContext, TextDataContextProvider} from '../API/TextDataContext'
import {BaseStatContext, BaseStatContextProvider} from '../API/BaseStatContext'
import {APIContext, APIContextProvider} from '../API/API'
import {SkillTablePage} from './SubPages/SkillTablePage'
import {SkillGroupHeaderPage} from './SubPages/SkillGroupHeaderPage'
import {FreePointsTablePage} from './SubPages/FreePointsTablePage'
import {IncreaseSelectorPage} from './SubPages/IncreaseSelectorPage'
import {MagicSpellTablePage} from './SubPages/MagicSpellTablePage'
import logo from '../Rss/Images/Zirkel.png';
import btn from '../Styles/btn.module.css'
import table from '../Styles/table.module.css'
import field from '../Styles/field.module.css'
import container from '../Styles/container.module.css'

const MagicPage = () => {

    const {
        API_get_attributes,
        API_set_attributes,
        API_set_comments,
        API_get_comments,
        API_set_character_stats,
        API_get_character_stats,
        //API_set_secondary_attributes,
        //API_get_secondary_attributes,
        API_get_attribute_group_by_id,
        API_get_attribute_by_id
    } = useContext(AttributeContext);

    const {
        API_get_attribute_group_ref,
        API_get_attribute_ref
    } = useContext(APIContext);
    

    return ( 
        <div style={{height: '100%', background: 'lightgray'}}>
            <div style={{display: 'inline-block'}}>
                <div className={container.skill_table_container}>
                    <div style={{margin: '0px 0px 12px 0px'}}><SkillGroupHeaderPage id={6} internal_groups={[18, 19]}></SkillGroupHeaderPage></div>
                    <div style={{margin: '0px 0px 12px 0px', display: 'flex', verticalAlign: 'top'}}>
                        <div style={{display: 'inline-block', marginRight: 15}}>
                            <div style={{margin: '0px 0px 13px 12px'}}><SkillTablePage skill_group_id={19}></SkillTablePage></div>
                                { API_get_attribute_group_by_id(API_get_attribute_group_ref().magic).focus >= 0 && 
                                    <div className={container.image_container} style={{margin: '0px 0px 0px 12px'}}>
                                        <img src={logo} alt="Logo" />
                                    </div>
                                }
                        </div>
                        <div style={{display: 'inline-block'}}><SkillTablePage skill_group_id={18}></SkillTablePage></div>
                    </div>
                </div>
                <div style={{marginLeft: 15, marginBottom: 15}}>
                    { API_get_attribute_group_by_id(API_get_attribute_group_ref().magic).focus >= 0 && 
                        <MagicSpellTablePage></MagicSpellTablePage>
                    }
                </div>
            </div>
        </div>
     );
}
 
export default MagicPage;