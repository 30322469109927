import { useState, createContext} from 'react'
import {
    BACKEND_get_skills,
    BACKEND_get_free_skill_points,
    BACKEND_get_feat_lv_2,
    BACKEND_set_feat_lv_2,
    BACKEND_get_feat_lv_3,
    BACKEND_set_feat_lv_3,
} from '../Backend/Parameters/CharacterParameters'

import{
    GetElementById
} from './Functions/GeneralFunctions'

const SkillContext = createContext();

const SkillContextProvider = ({children}) => {

    const [skills, setSkills] = useState({...BACKEND_get_skills()});
    const [editSkillVisibility, setEditSkillVisibility] = useState(false);
    const [increaseSelector, setIncreaseSelector] = useState('increase');
    const [freeSkillPoints, setFreeSkillPoints] = useState({...BACKEND_get_free_skill_points()});
    const [featsLv2, setFeatsLv2] = useState({...BACKEND_get_feat_lv_2()});
    const [featsLv3, setFeatsLv3] = useState({...BACKEND_get_feat_lv_3()});
    const [skillGroupVisibility, setSkillGroupVisibility] = useState(new Array(Object.keys({...BACKEND_get_skills()}).length).fill(true));

    const API_set_skills = () => {setSkills({...BACKEND_get_skills()});}
    const API_get_skills = () => {return skills;}
    const API_get_edit_skill_visibility = () => {return editSkillVisibility}
    const API_set_edit_skill_visibility = (_visiblilty) => {setEditSkillVisibility(_visiblilty);}
    const API_get_increase_selector = () => {return increaseSelector}
    const API_set_increase_selector = (_value) => {setIncreaseSelector(_value);}
    const API_get_free_skill_points = () => {return freeSkillPoints}
    const API_set_free_skill_points = () => {setFreeSkillPoints({...BACKEND_get_free_skill_points()});}
    const API_get_feat_lv_2 = () => {return featsLv2;}
    const API_set_feat_lv_2 = () => {setFeatsLv2({...BACKEND_get_feat_lv_2()});}
    const API_get_feat_lv_3 = () => {return featsLv3}
    const API_set_feat_lv_3 = () => {setFeatsLv3({...BACKEND_get_feat_lv_3()});}

    const API_get_skill_group_visibility = (_index) => {
        if(skillGroupVisibility == null)
            return false;

        if(_index <0 || _index >= Object.keys(skillGroupVisibility).length)
            return false;

        return skillGroupVisibility[_index];
    }

    /*const API_set_skill_group_visibility = (_index_list) => {
        let _tempArray = {...skillGroupVisibility};
        if(_tempArray == null)
            return;

        if(_index <0 || _index >= Object.keys(_tempArray).length)
            return;

        for(let key in _index_list)
        {
            _tempArray[_index_list[key]] = !_tempArray[_index_list[key]];
        }
        setSkillGroupVisibility(_tempArray);
    }*/

    const API_toggle_skill_group_visibility = (_index_list) => {
        let _tempArray = {...skillGroupVisibility};
        if(_tempArray == null || _index_list == null)
            return;


        for(let key in _index_list)
        {
            if(_index_list[key] >= 0 && _index_list[key] < Object.keys(_tempArray).length)
            {
                _tempArray[_index_list[key]] = !_tempArray[_index_list[key]];
            }
        }
        setSkillGroupVisibility(_tempArray);
    }

    const API_get_skill_by_id = (_akttribute_id, _skill_id) => {
        for(let key1 in skills){
            if(skills[key1].skill_group_id == _akttribute_id)
            {
                for(let key2 in skills[key1].skills){
                    if(skills[key1].skills[key2].id == _skill_id)
                    {
                        return skills[key1].skills[key2];
                    }
                }
            }
        }
        return null;
    }

    const API_get_feat_by_id = (_id, _level) => {
        if(_level == 2){
            GetElementById(_id, featsLv2);
        }
        else if(_level == 3){
            GetElementById(_id, featsLv3);
        }
    }

    return(
        <SkillContext.Provider value={{
            API_get_skill_group_visibility,
            //API_set_skill_group_visibility,
            API_toggle_skill_group_visibility,
            API_set_skills,
            API_get_skills,
            API_get_edit_skill_visibility,
            API_set_edit_skill_visibility,
            API_get_increase_selector,
            API_set_increase_selector,
            API_get_free_skill_points,
            API_set_free_skill_points,
            API_get_feat_lv_2,
            API_set_feat_lv_2,
            API_get_feat_lv_3,
            API_set_feat_lv_3,
            API_get_skill_by_id,
            API_get_feat_by_id
        }}>
            {children}
        </SkillContext.Provider>
    )
}

export {SkillContext, SkillContextProvider}