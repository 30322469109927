import { useState, createContext} from 'react'
import {
    BACKEND_get_user,
    BACKEND_set_user,
    BACKEND_set_character_id,
    BACKEND_get_character_id
} from '../Backend/Parameters/UserData'
import{
    SetTimedMessage
} from './Functions/GeneralFunctions'

const UserDataContext = createContext();

const UserDataContextProvider = ({children}) => {

    //var timer = null;

    const [styleLogin, setStyleLogin] = useState(false); //Später entfernen wenn es nur noch einen Style gibt
    const [user, setUser] = useState({...BACKEND_get_user()});
    const [characterID, setCharacterID] = useState(BACKEND_get_character_id());
    const [remoteMessage, setRemoteMessage] = useState('');
    //const [userMode, setUserMode] = useState('undefined');

    const API_get_style_login = () => {return styleLogin;}
    const API_set_style_login = (_style_login) => {setStyleLogin(_style_login);}

    const API_get_user = () => {return user;}
    const API_set_user = () => {setUser({...BACKEND_get_user()});}

    const API_get_character_id = () => {return characterID;}
    const API_set_character_id = () => {setCharacterID(BACKEND_get_character_id());}

    const API_get_remote_connect_message = () => {return remoteMessage;}
    const API_set_remote_connect_message = (_message, _timer = 3000) => {
        SetTimedMessage(_message, setRemoteMessage, _timer)
        /*setRemoteMessage(_message);
        
        if(_timer > 0){
            const changeValue = () => {
                // Löschen Sie den vorherigen Timeout, falls vorhanden
                if(timer) {
                    //console.log('clearTimeout');
                    clearTimeout(timer);
                }
                else{
                    //console.log('clearTimeout');
                }
                // Erstellen Sie einen neuen Timeout und speichern Sie ihn in der Variablen
                timer = setTimeout(() => {
                    //timer = null
                    setRemoteMessage('');
                }, _timer);
            };
            changeValue();
        }*/

    }

    return(
        <UserDataContext.Provider value={{
            API_get_style_login, 
            API_set_style_login,
            API_get_user, 
            API_set_user,
            API_get_character_id,
            API_set_character_id,
            API_get_remote_connect_message,
            API_set_remote_connect_message
            //API_get_user_mode,
            //API_set_user_mode
        }}>
            {children}
        </UserDataContext.Provider>
    )
}

export {UserDataContext, UserDataContextProvider}