import { useState, useContext, useEffect } from 'react'
import characterPortrait from '../images/Tamos.png';
import CampaignSheetHeader from './SubPagesCampaignSheet/CampaignSheetHeader'
/*const logo = require('./logo.jpeg'); // with require

then plug it in...

<img src={logo} />*/


const CampaignSheetStylish = () => {
    const marginBottom = 8;
    return ( 
        <div style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{marginRight: 10, marginTop: 5}}>
                <img src={characterPortrait} alt="Bild" style={{ float: 'left', width: '400px', border: '2px solid black' }} />
            </div>
            <div style={{}}>
                <CampaignSheetHeader />
            </div>
        </div>
    );
}

export default CampaignSheetStylish;