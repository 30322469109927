import { useState, useContext, useEffect } from 'react'
import Navbar from './Navbar';
//import DownloadHandler from '../Components/DownloadHandler';
import {ToolDataContext,ToolDataContextProvider} from '../API/ToolDataContext'
import {ScreenDataContext,ScreenDataContextProvider} from '../API/ScreenDataContext'
import ManageCombatPage from '../Components/ManageCombatPage';
import GeneralPage from '../Components/GeneralPage';
import SkillPage from '../Components/SkillPage';
import EquipmentPage from '../Components/EquipmentPage';
import MagicPage from '../Components/MagicPage';
import FeatPage from '../Components/FeatPage';
import BaseStatsPage from '../Components/BaseStatsPage';
import RulesPage from '../Components/RulesPage';
import RemoteUserPage from '../Components/RemoteUserPage';
import btn from '../Styles/btn.module.css'
import container from '../Styles/container.module.css'

const GameMasterMainPage = () => {
    const {
        API_get_tool_data,
        API_set_tool_data,
        API_get_user_mode
    } = useContext(ToolDataContext);

    const {
        API_get_active_screen,
        API_set_active_screen
    } = useContext(ScreenDataContext);

    return ( 
        <div>
            <div className={container.base_container} style={{position: 'fixed', zIndex: 9, width: '100%', minWidth: '100%', height: '126px', minHeight: '126px', maxHeight: '126px', background: 'gray'}}>
                <Navbar game_mode={'game_master'}></Navbar>
            </div>
            <div style={{background: 'transparent', paddingTop: '146px'}}>
                <BaseStatsPage></BaseStatsPage>  
            </div>
            <div style={{background: 'transparent'}}>
                
            { API_get_active_screen() == 'general' &&
                    <GeneralPage />
                }
                { API_get_active_screen() == 'skills' &&
                    <SkillPage />
                }
                { API_get_active_screen() == 'equipment' &&
                    <EquipmentPage />
                }
                { API_get_active_screen() == 'magic' &&
                    <MagicPage />
                }
                { API_get_active_screen() == 'feat' &&
                    <FeatPage />
                }
                { API_get_active_screen() == 'combat' &&
                    <ManageCombatPage />
                }
                { API_get_active_screen() == 'rules' &&
                    <RulesPage />
                }
                { API_get_active_screen() == 'remote_user' &&
                    <RemoteUserPage />
                }
            </div>
        </div>
    );
}

export {GameMasterMainPage};