import { createRef, useContext, useState } from 'react'
import {RemoteConnectContext} from '../../../ServerConnection/RemoteConnectContext'
import btn_style from '../../../StylesStylish/btn.module.css'
import diceIcon from '../../../images/icons/dice.png';
import { Tooltip } from 'react-tooltip'


const DiceButton = (props) => {
    const { width = '72px', height = '32px', roll = 'd6', roll_label = 'd6'} = props;

    const {
        BACKEND_send_dice_roll,
        BACKEND_open_foundry_connector_window
    } = useContext(RemoteConnectContext);
    
    function handleRoll() {
        BACKEND_send_dice_roll(roll);
    }

    return ( 
        <div>
            <button className={btn_style.std_button} onClick={handleRoll} style={{width: width, minWidth: width, height: height}}>
                <img src={diceIcon} alt="Bild" style={{ float: 'left', width: '18px', marginRight: 5 }} /> <p>{roll_label}</p>
            </button>
        </div>
     );
}
 
export default DiceButton;