import { useState, useContext } from 'react'
import {APIContext, APIContextProvider} from '../../API/API'
import {AttributeContext, AttributeContextProvider} from '../../API/AttributeContext'
import {TextDataContext, TextDataContextProvider} from '../../API/TextDataContext'
import btn from '../../Styles/btn.module.css'
import table from '../../Styles/table.module.css'
import field from '../../Styles/field.module.css'
import container from '../../Styles/container.module.css'

const CommentPage = () => {
    const {
        API_full_GUI_update,
        API_increment_level,
        API_update_base_stat,
        API_increment_attribute,
        API_increment_skill,
        API_increment_other_skill_bonus,
        API_increment_ancestry_bonus,
        API_increment_other_bonus,
        API_increment_focus,
        API_increment_kho_tal,
        API_update_comments,
        API_get_dice_list_string,
        API_roll_dice,
        API_modify_character_stat_bonus,
        //API_modify_secondary_attribute_bonus,
        API_change_hitpoints,
        API_increment_free_attribute_points_bonus,
        API_increment_free_focus_points_bonus,
        API_get_skill_attribute_mod
    } = useContext(APIContext);

    const {
        API_get_attributes,
        API_get_comments,
        API_get_character_stats,
        //API_get_secondary_attributes,
        API_get_attribute_by_id
    } = useContext(AttributeContext);

    const {
        API_get_base_stat_labels, 
        API_set_base_stat_labels,
        API_get_std_labels,
        API_set_std_labels,
        API_get_std_header_labels,
        API_set_std_header_labels,
        API_get_tab_view_labels,
        API_set_tab_view_labels,
        API_get_character_stats_labels,
        API_set_character_stats_labels,
        API_get_table_header_labels,
        API_set_table_header_labels
    } = useContext(TextDataContext);

    const updateComments = (_text) => {
        API_update_comments(_text);
    }

    return ( 
        <div>
            <div style={{alignItems: 'left', position: 'relative'}}>
                <div style={{display: 'inline-block'}}>
                    <p style={{fontWeight: 'bold', fontSize: '20px', marginBottom: '5px'}}>{API_get_character_stats_labels().comments}</p>
                    <textarea
                        rows={25} 
                        cols={60}
                        placeholder={API_get_character_stats_labels().add_comments}
                        onChange={(e) => updateComments(e.target.value)}
                        value={API_get_comments()}
                    />
                </div>
            </div>
        </div>
    );
}
 
export {
    CommentPage
}