import { useState, useContext } from 'react'
import {APIContext, APIContextProvider} from '../../API/API'
import {SkillContext, SkillContextProvider} from '../../API/SkillContext'
import {TextDataContext, TextDataContextProvider} from '../../API/TextDataContext'
import { 
    ParseToFloat, 
    ParseToInteger,
    GetElementById,
    GetListParameterByID,
    SetListParameterByID
} from '../../API/Functions/GeneralFunctions'
import btn from '../../Styles/btn.module.css'
import table from '../../Styles/table.module.css'
import field from '../../Styles/field.module.css'
import container from '../../Styles/container.module.css'

const FeatTablePage = (props) => {
    const {
        API_full_GUI_update,
        API_increment_level,
        API_update_base_stat,
        API_increment_attribute,
        API_increment_skill,
        API_increment_other_skill_bonus,
        API_increment_ancestry_bonus,
        API_increment_other_bonus,
        API_increment_focus,
        API_increment_kho_tal,
        API_update_comments,
        API_get_dice_list_string,
        API_roll_dice,
        API_modify_character_stat_bonus,
        //API_modify_secondary_attribute_bonus,
        API_change_hitpoints,
        API_increment_free_attribute_points_bonus,
        API_increment_free_focus_points_bonus,
        API_get_skill_attribute_mod,
        API_modify_feat_name,
        API_modify_feat_description,
        API_add_new_feat_element,
        API_remove_feat_element,
        API_move_feat_element
    } = useContext(APIContext);

    const {
        API_get_feat_lv_2,
        API_set_feat_lv_2,
        API_get_feat_lv_3,
        API_set_feat_lv_3,
        API_get_skill_by_id,
        API_get_feat_by_id
    } = useContext(SkillContext);

    const {
        API_get_base_stat_labels, 
        API_set_base_stat_labels,
        API_get_std_labels,
        API_set_std_labels,
        API_get_std_header_labels,
        API_set_std_header_labels,
        API_get_stat_overview_labels,
        API_set_stat_overview_labels,
        API_get_money_header_labels,
        API_set_money_header_labels,
        API_get_tab_view_labels,
        API_set_tab_view_labels,
        API_get_character_stats_labels,
        API_set_character_stats_labels,
        API_get_table_header_labels,
        API_set_table_header_labels,
        API_get_equipment_header_labels,
        API_set_equipment_header_labels
    } = useContext(TextDataContext);

    const [editList, setEditList] = useState(null);
    const [editName, setEditName] = useState(null);
    const [editDescription, setEditDescription] = useState(null);

    const clickEditElementHandler = (_value) => {
        let _list = getFeatList();
        let _local_edit_list = [];
        let _local_edit_name = [];
        let _local_edit_description = [];

        for(let key in _list){
            _local_edit_list.push({id: _list[key].id, value: false});
            _local_edit_name.push({id: _list[key].id, value: _list[key].display_name});
            _local_edit_description.push({id: _list[key].id, value: _list[key].description});
        }

        if(editList == null)
        {
            for(let key in _local_edit_list){
                if(_local_edit_list[key].id == _value.id)
                    _local_edit_list[key].value = true;
            }

            setEditName(_local_edit_name);
            setEditDescription(_local_edit_description);
            setEditList(_local_edit_list);

            return;
        }


        for(let key1 in _local_edit_list){
            for(let key2 in editList){
                if(_local_edit_list[key1].id == editList[key2].id){
                    _local_edit_list[key1].value = editList[key2].value;
                }
            }
        }

        for(let key in _local_edit_list){
            if(_local_edit_list[key].id == _value.id)
            _local_edit_list[key].value = !_local_edit_list[key].value;
        }
            
        setEditName(_local_edit_name);
        setEditDescription(_local_edit_description);
        setEditList(_local_edit_list);
    }

    const getValueByID = (_id, _list, _type) => {
        return GetListParameterByID(_id, _list, _type);
    }

    const setValueByID = (_id, _list, _setter, _newValue, _type) => {
        SetListParameterByID(_id, _list, _setter, _newValue, _type);
    }

    const modifyName = (_id, _newValue) => {
        API_modify_feat_name(_id, _newValue, props.level);
    }

    const modifyDescription = (_id, _newValue) => {
        API_modify_feat_description(_id, _newValue, props.level);
    }
    
    const addNewElement = () => {
        API_add_new_feat_element(props.level);
    }

    const removeElement = (_element) => {
        API_remove_feat_element(_element, props.level);
    }

    const moveElement = (_element, _direction) => {
        API_move_feat_element(_element, _direction, props.level);
    }

    const getFeatList = () => {
        if(props.level == 2)
        {
            return API_get_feat_lv_2();
        }
        else if(props.level == 3)
        {
            return API_get_feat_lv_3();
        }
        return null;
    }

    return ( 
        <div>
            <table className={table.table_editable} style={{background: 'transparent'}}>
                <tr>
                    <td className={table.cell_header} style={{width: 400, minWidth: 400, textAlign: 'left', paddingLeft: 5}}>{API_get_std_header_labels().level} {props.level} {API_get_std_header_labels().feat}</td>
                    <td className={table.cell_header} style={{width: 915, minWidth: 915, textAlign: 'left', paddingLeft: 5}}>{API_get_std_header_labels().description}</td>
                    <td className={table.cell_header} style={{width: 120, minWidth: 120}} colspan={5}></td>
                </tr>
                {Object.entries(getFeatList()).map(([key, value], index) => (
                    <tr>
                        <td style={{textAlign: 'left'}}>
                            {(!getValueByID(value.id, editList, 'bool')) && 
                                <p style={{paddingLeft: 5}}>{value.display_name}</p>
                            }
                            {(getValueByID(value.id, editList, 'bool')) && 
                                <input className={field.input_field_text} style={{width: 385}} value={getValueByID(value.id, editName, 'text')} onChange={(e) => setValueByID(value.id, editName, setEditName, e.target.value, 'text')} onBlur={(e) => modifyName(value.id, e.target.value)} />
                            }
                        </td>
                        <td style={{textAlign: 'left'}}>
                            {(!getValueByID(value.id, editList, 'bool')) && 
                                <p style={{paddingLeft: 5}}>{value.description}</p>
                            }
                            {(getValueByID(value.id, editList, 'bool')) && 
                                <input className={field.input_field_text} style={{width: 900}} value={getValueByID(value.id, editDescription, 'text')} onChange={(e) => setValueByID(value.id, editDescription, setEditDescription, e.target.value, 'text')} onBlur={(e) => modifyDescription(value.id, e.target.value)} />
                            }
                        </td>
                        <td style={{width: 24, minWidth: 24, borderRight: '0px solid black'}}>
                            <button className={btn.button_icon} onClick={() => clickEditElementHandler(value)}>E</button>
                        </td>
                        <td style={{width: 24, minWidth: 24, borderLeft: '0px solid black', borderRight: '0px solid black'}}>
                            <button className={btn.button_icon} onClick={() => moveElement(value, 'up')}>+</button>
                        </td>
                        <td style={{width: 24, minWidth: 24, borderLeft: '0px solid black', borderRight: '0px solid black'}}>
                            <button className={btn.button_icon} onClick={() => moveElement(value, 'down')}>-</button>
                        </td>
                        <td style={{width: 24, minWidth: 24, borderLeft: '0px solid black', borderRight: '0px solid black'}}>
                        </td>
                        <td style={{width: 24, minWidth: 24, borderLeft: '0px solid black'}}>
                            <button className={btn.button_icon} onClick={() => removeElement(value)}>x</button>
                        </td>
                    </tr>
                ))}
                <tr>
                    <td style={{textAlign: 'right', paddingLeft: 5}}>
                        <button className={btn.button_icon} onClick={() => addNewElement()}>+</button>
                    </td>
                    <td></td>
                    <td colspan={5}></td>
                </tr>
            </table>
        </div>
    );
}

export {
    FeatTablePage
}