//Clone object in order to prevent unwanted references
const CloneObj = (obj, deep = false) => {
   if (obj === null || typeof obj !== 'object') {
       return obj; // Wenn es sich nicht um ein Objekt oder Array handelt, gib es unverändert zurück
   }

   // Wenn es sich um ein Array handelt, kopiere die Elemente im Array
   if (obj instanceof Array) {
       return obj.map(item => CloneObj(item, true));
   }

   // Wenn es sich um ein Objekt handelt, kopiere die Eigenschaften
   const result = {};
   for (const key in obj) {
       if (deep && obj[key] instanceof Object) {
           result[key] = CloneObj(obj[key], true);
       } else {
           result[key] = obj[key];
       }
   }
   return result;
};

//Only take values if exists in refList
function AdjustObjToRef(_inputList, _refList) {
   if(_refList == null)
      return _inputList;

   let _result = CloneObj(_refList, true);

   for (const parameterName in _inputList) {
       if (_refList.hasOwnProperty(parameterName)) {
           const _inputValue = _inputList[parameterName];
           const _refValue = _refList[parameterName];

           if (Array.isArray(_inputValue) && Array.isArray(_refValue)) {
               // Wenn beide Werte Arrays sind, vergleiche ihre Elemente rekursiv
               _result[parameterName] = AdjustListToRef(_inputValue, _refValue);
           } else if (typeof _inputValue === 'object' && typeof _refValue === 'object') {
               // Wenn beide Werte Objekte sind, vergleiche ihre Eigenschaften rekursiv
               _result[parameterName] = AdjustObjToRef(_inputValue, _refValue);
           } else {
               // Ansonsten nimm den Wert aus der Inputliste
               _result[parameterName] = _inputValue;
           }
       }
   }

   return _result;
}

//used in CompareObject
function AdjustListToRef(_inputList, _refList) {
   if(_refList == null)
      return _inputList;

   let _result = [];

   for (let i = 0; i < _inputList.length; i++) {
       if (i < _refList.length) {
           const _inputValue = _inputList[i];
           const _refValue = _refList[i];

           if (Array.isArray(_inputValue) && Array.isArray(_refValue)) {
               // Wenn beide Werte Arrays sind, vergleiche ihre Elemente rekursiv
               _result.push(AdjustListToRef(_inputValue, _refValue));
           } else if (typeof _inputValue === 'object' && typeof _refValue === 'object') {
               // Wenn beide Werte Objekte sind, vergleiche ihre Eigenschaften rekursiv
               _result.push(AdjustObjToRef(_inputValue, _refValue));
           } else {
               // Ansonsten nimm den Wert aus der Inputliste
               _result.push(_inputValue);
           }
       }
   }

   return _result;
}

const IsWhitespace = (_str) => {
   return !_str.trim().length;
}

const IsNullOrEmpty = (_str) => {
   if(_str == null)
      return true;

   if(_str == '' || IsWhitespace(_str))
      return true;

   return false;
}

const ConvertStringToFileNameFormat = (_input) => {
   if(_input == null)
      return '_';
   
   if(IsWhitespace(_input) ||  _input == '')
      return '_';

   let _output = _input.toLowerCase();
   _output = _output.replace(/ /g, "_");
   _output = _output.replace(/[^0-9a-zA-Z]/g, "_");

    return _output;
}

   /*const CloneObj = (obj, deep=false) => {
      var result = {};
      for(var key in obj){
         if(deep && obj[key] instanceof Object){
            if(obj[key] instanceof Array){
               result[key] = [];
               obj[key].forEach(function(item){
                  if(item instanceof Object){
                     result[key].push(CloneObj(item, true));
                  } else {
                     result[key].push(item);
                  }
               });
            } else {
            result[key] = CloneObj(obj[key]);
            }
         } else {
            result[key] = obj[key];
         }
      }
      return result
   }*/

const CheckIfKeyIsContained = (_key, _list) => {
   for(let key in _list){
      if(key == _key)
         return true;
   }
   return false;
}

const GetElementById = (_id, _list, _id_tag = 'id') =>
{
   for(var key in _list)
   {
      if (typeof _list[key] !== 'undefined') {
         if(_id == _list[key][_id_tag])
         {
            return _list[key];
         }
      }
   }
   return null;
}

const ParseToInteger = (_input) => {
   var _output = parseInt(_input);
   if(isNaN(_output))
   {
      _output = 0;
   }
   return _output;
}
   
const ParseToFloat = (_input, _decimal = 2) => {
   return FixDecimalPlaces(_input, _decimal);
}

const TryParse = (_input, _type) => {
   let _out = null;
   if(_type == 'integer'){
      _out = parseInt(_input);
   }
   else if(_type == 'float'){
      _out = parseFloat(_input);
   }

   return !isNaN(_out);
}

const ParseToBool = (_input) => {
      var _output = parseInt(_input);
      if(isNaN(_output))
      {
         return false;
      }
      if(_output == 1)
      {
         return true;
      }
      else
      {
         return false;
      }
}

const FixDecimalPlaces = (_input, _decimal = 2) => {
   var _output = parseFloat(_input);
   if(isNaN(_output))
   {
      _output = 0.0;
   }

   return parseFloat(_output.toFixed(_decimal));
}

   const RollSingleDice = (_diceVal) => {
      var _result = _diceVal*Math.random();
      _result = Math.round(_result + 0.5); 
      return _result;
   }

   const ParseValueByType = (_val, _type) => {
      let _new_val = _val;
      if(_type == 'integer')
      {
         _new_val = ParseToInteger(_val);
      }
      else if(_type == 'float')
      {
         _new_val = ParseToFloat(_val);
      }
      else if(_type == 'text')
      {
         _new_val = '' + _val;
      }
      else if(_type == 'bool')
      {
         _new_val = ParseToBool(_val);
      }
      return _new_val;
   }

   const GetListParameterByID = (_id, _list, _type, _id_tag = 'id', _parameter_tag = 'value') => {
      if(_list == null)
         return ParseValueByType(0, _type);
      
      for(let key in _list){
          if(_list[key][_id_tag] == _id){
              return _list[key][_parameter_tag];
          }
      }
  }

  const SetListParameterByID = (_id, _list, _setter, _new_value, _type, _id_tag = 'id', _parameter_tag = 'value') => {
      let _local_list = _list;

      if(_list == null)
         return;

      let _parsed_val = ParseValueByType(_new_value, _type);

      for(let key in _local_list){
          if(_local_list[key][_id_tag] == _id){
              _local_list[key][_parameter_tag] = _parsed_val;
          }
      }
      _setter({..._local_list});
  }

   const AddElementToList = (_list, _template, _setter, _id_tag = 'id') => {
      let _local_list = {..._list}
      let _local_template = {..._template}
      //let _length = Object.keys(_local_list).length;
      let _new_id = 0;

      for(let key in _local_list){
         if(_local_list[key][_id_tag] > _new_id)
         {
            _new_id = _local_list[key][_id_tag];
         }
      }
      _new_id += 1;

      _local_template.id = _new_id;
      _local_list[_new_id] = _local_template;
      
      _setter(_local_list);
   }

   const RemoveElementFromList = (_id, _list, _setter, _id_tag = 'id') => {
      let _local_list = {..._list};
  
      for(let key in _local_list){
         if(_local_list[key][_id_tag] == _id)
         {
            delete _local_list[key];
         }
      }
  
      _setter(_local_list);
   }

   function MoveElementInList (_id, _list, _setter, _direction, _id_tag = 'id', _fix_first_element = false) {
      //let _weapon_list = getWeapons(_type);
      let _local_list = {..._list};

      let _key_before = null;
      let _key_middle = null;
      let _key_after = null;
  
      let _tempValue_1 = null;
      let _tempValue_2 = null;
  
      let _run_before = true;
      let _run_after = false;
  
      let key_1 = null;
      let key_2 = null;
  
      for(let key in _local_list){
          if(_run_after)
          {
              _key_after = key;
              _run_after = false;
          }
  
          if(_local_list[key][_id_tag] == _id)
          {
              _key_middle = key;
              _run_before = false;
              _run_after = true;
          }
  
          if(_run_before)
              _key_before = key;
      }
  
      if(_direction == 'up')
      {
          if(_key_before == null || _key_middle == null)
              return
  
          key_1 = _key_before;
          key_2 = _key_middle;

          if(_fix_first_element)
          {
              if(_local_list[key_1][_id_tag] == 0)
                  return;
          }
      }
      else if(_direction == 'down')
      {
          if(_key_after == null || _key_middle == null)
              return
  
              key_1 = _key_after;
              key_2 = _key_middle;
      }
  
      
      _tempValue_1 = _local_list[key_1];
      _tempValue_2 = _local_list[key_2]; 
      
      _local_list[key_2] = _tempValue_1;
      _local_list[key_1] = _tempValue_2;
  
      _setter(_local_list);
  }

  

  const SetTimedMessage = (_message, _setter, _timer = 3000) => {
   var timer = null;
   _setter(_message);
   
   if(_timer > 0){
       const changeValue = () => {
           // Löschen Sie den vorherigen Timeout, falls vorhanden
           if(timer) {
               //console.log('clearTimeout');
               clearTimeout(timer);
           }
           else{
               //console.log('clearTimeout');
           }
           // Erstellen Sie einen neuen Timeout und speichern Sie ihn in der Variablen
           timer = setTimeout(() => {
               //timer = null
               _setter('');
           }, _timer);
       };
       changeValue();
   }
}

   export {
      CloneObj,
      IsWhitespace,
      IsNullOrEmpty,
      ConvertStringToFileNameFormat,
      GetElementById,
      ParseToInteger,
      ParseToFloat,
      ParseToBool,
      TryParse,
      RollSingleDice,
      GetListParameterByID,
      SetListParameterByID,
      AddElementToList,
      RemoveElementFromList,
      MoveElementInList,
      FixDecimalPlaces,
      CheckIfKeyIsContained,
      AdjustObjToRef,
      SetTimedMessage
   }