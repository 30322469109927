import {
    BACKEND_set_base_stats, 
    BACKEND_get_base_stats, 
    BACKEND_set_free_attribute_points, 
    BACKEND_get_free_attribute_points, 
    BACKEND_set_free_focus_points, 
    BACKEND_get_free_focus_points,
    BACKEND_set_ancestry_saldo,
    BACKEND_get_ancestry_saldo,
    BACKEND_set_free_skill_points,
    BACKEND_get_free_skill_points,
    BACKEND_set_attribute_groups,
    BACKEND_get_attribute_groups,
    BACKEND_set_attributes,
    BACKEND_get_attributes,
    BACKEND_set_character_stats,
    BACKEND_get_character_stats,
    //BACKEND_set_secondary_attributes,
    //BACKEND_get_secondary_attributes,
    BACKEND_set_skills,
    BACKEND_get_skills,
    BACKEND_set_comments,
    BACKEND_get_comments,
    BACKEND_get_negative_attribute_restriction,
    BACKEND_get_negative_skill_restriction,
    BACKEND_get_negative_focus_restriction,
    BACKEND_get_attribute_ref
} from '../Parameters/CharacterParameters'
import {
    INIT_get_base_stats, 
    INIT_get_free_attribute_points, 
    INIT_get_free_focus_points,
    INIT_get_attribute_groups,
    INIT_get_attributes,
    INIT_get_character_stats,
    //INIT_get_secondary_attributes,
    INIT_get_negative_attribute_restriction,
    INIT_get_negative_focus_restriction
} from '../ParametersInit/AttributesInit'
import { 
    INIT_get_skills,
    INIT_get_free_skill_points
 } from "../ParametersInit/SkillParametersInit"
import {
    CalculateAttribute, 
    GetHitpoints,
    GetLoadCapacity,
    GetEndurance,
    GetKhoTal,
    //GetInitiative,
    //GetDodgeValue,
    GetFreeAttributePoints, 
    GetFreeFocusPoints,
    GetFreeSkillPoints,
    GetSkillPointsAttribute,
    GetFocusCost,
    GetMaxAttributeIncrease,
    GetMaxSkillIncrease,
    CalculateSkill,
    ConvertAttributes, 
    ConvertAttributesBack
} from './CharacterFunctions'
import {
    RecalcCharacterStats,
    //RecalcSecondaryAttributes,
    RecalcFreeAttributePoints,
    RecalcFreeFocusPoints,
    RecalcFreeSkillPoints,
    RecalcAttributes,
    RecalcSkills
} from './RecalcFunctions'
import {
    BACKEND_get_character_file_name,
    BACKEND_set_upload_state,
    BACKEND_get_upload_state
} from '../Parameters/GameData'
//import {
//    LoadCharacter
//} from './LoadCharacter'
import {
    BACKEND_get_tool_data, 
    BACKEND_set_tool_data,
    BACKEND_get_dice_log,
    BACKEND_set_dice_log,
    BACKEND_set_dice_log_value,
    BACKEND_get_language_list
} from '../Parameters/ToolData'
import {
    Translate
} from '../Lang/Translator'
import {
    GetDiceList,
    GetDiceListString,
    GetFoundryDiceListString
} from '../DiceRoller'
import {
    ParseToNumber,
    GetElementById,
    RollSingleDice
} from '../../API/Functions/GeneralFunctions'
import{
    BACKEND_get_attribute_by_id,
    BACKEND_get_attribute_group_by_id,
    BACKEND_get_max_magic_circle
} from '../BackendRouter'

//All Backend Functions should only be accessed via UI_Controller or from functions inside Backend Folder


const IncrementLevel = (_increment) => 
{
    var _base_stats = BACKEND_get_base_stats();
    var _free_attribute_points = BACKEND_get_free_attribute_points();
    var _free_skill_points = BACKEND_get_free_skill_points();

    if(_base_stats.level + _increment <= 0)
        return;

    _base_stats.level += _increment;
    _free_attribute_points.total = GetFreeAttributePoints(_base_stats.level);
    _free_skill_points.total = GetFreeSkillPoints(_base_stats.level);

    BACKEND_set_base_stats(_base_stats);
    BACKEND_set_free_attribute_points(_free_attribute_points);
    BACKEND_set_free_skill_points(_free_skill_points);
    //BACKEND_calculate_max_attributes();
    RecalcAttributes();
    RecalcCharacterStats();
}

const CheckAttributeMaxValue = (_increment, _attribute) => 
{
    if(_increment <= 0)
        return true;

        
    let _attribute_group = BACKEND_get_attribute_group_by_id(_attribute.group);

    const _newAttributeValue = _attribute.increase + _increment;
    return (_newAttributeValue <= _attribute_group.max_training)
}

const CheckAttributeFreePoints = (_increment, _attribute) => 
{
    var _free_attribute_points = BACKEND_get_free_attribute_points();
    const _usedPoints = _free_attribute_points.used + _increment*_attribute.cost;
    return (_increment < 0 ) || (_usedPoints <= BACKEND_get_free_attribute_points().total);
}

const CheckAttributeNegativePoints = (_increment, _attribute) => 
{
    const _newAttributeValue = _attribute.increase + _increment;
    return (_newAttributeValue >= BACKEND_get_negative_attribute_restriction().single)
}

const CheckAttributeNegativeTotal = (_increment, _attribute) =>
{
    var _total_minus = 0;

    var _attributeList = BACKEND_get_attributes();
    for(var key in _attributeList)
    {
        if (typeof _attributeList[key] !== 'undefined') {
            if(_attribute.id == _attributeList[key].id)
            {
                if(_attributeList[key].increase +_increment < 0){
                    _total_minus += _attributeList[key].increase +_increment;
                }
            }
            else
            {
                if(_attributeList[key].increase < 0){
                    _total_minus += _attributeList[key].increase;
                }
            }
            
        }
    }
    return (_total_minus >= BACKEND_get_negative_attribute_restriction().total)
}

const IncrementAttribute = (_attributeID, _increment, _type, _recalc) => 
{
    var _attributes = BACKEND_get_attributes();
    var _free_attribute_points = BACKEND_get_free_attribute_points();
    var _ancestry_saldo = BACKEND_get_ancestry_saldo();
    for(var key in _attributes)
    {
        if (typeof _attributes[key] !== 'undefined') {
            if(_attributes[key].id == _attributeID){
                if(_type == 'increase')
                {
                    if((CheckAttributeFreePoints(_increment, _attributes[key])) && (CheckAttributeNegativePoints(_increment, _attributes[key])) && (CheckAttributeNegativeTotal(_increment, _attributes[key]) && (CheckAttributeMaxValue(_increment, _attributes[key]))))
                    {
                        _attributes[key].increase +=_increment;
                        _free_attribute_points.used += _increment*_attributes[key].cost;
                    }
                }
                else if(_type == 'other')
                {
                    _attributes[key].other_bonus +=_increment;
                }
                else if(_type == 'ancestry')
                {
                    _attributes[key].ancestry_bonus +=_increment;
                    _ancestry_saldo.points += _increment;
                    _ancestry_saldo.cost += _increment*_attributes[key].cost;
                }
            }
        }
    }
    BACKEND_set_attributes(_attributes);
    BACKEND_set_free_attribute_points(_free_attribute_points);
    BACKEND_set_ancestry_saldo(_ancestry_saldo);

    if(_recalc)
        RecalcAttributes();
}

/*const IncrementAncestryBonus = (_attributeID, _increment, _recalc) => {
    var _attributes = BACKEND_get_attributes();
    for(var key in _attributes)
    {
        if (typeof _attributes[key] !== 'undefined') {
            if(_attributes[key].id == _attributeID){
                _attributes[key].ancestry_bonus +=_increment;
                //_attributes[key].value = CalculateAttribute(_attributes[key]);
                //_attributes[key].dice = GetDiceList(_attributes[key].value);
            }
        }
    }
    BACKEND_set_attributes(_attributes);

    if(_recalc)
        RecalcAttributes();
}

const IncrementOtherAttributeBonus = (_attributeID, _increment, _recalc) => {
    var _attributes = BACKEND_get_attributes();
    for(var key in _attributes)
    {
        if (typeof _attributes[key] !== 'undefined') {
            if(_attributes[key].id == _attributeID){
                _attributes[key].other_bonus +=_increment;
                //_attributes[key].value = CalculateAttribute(_attributes[key]);
                //_attributes[key].dice = GetDiceList(_attributes[key].value);
            }
        }
    }
    BACKEND_set_attributes(_attributes);

    if(_recalc)
        RecalcAttributes();
}*/


const CheckSkillMaxValue = (_increment, _group_id, _skill) => 
{
    if(_increment <= 0)
        return true;
    
    //console.log('CheckSkillMaxValue');
    //console.log(_group_id);
    
    let _attribute = BACKEND_get_attribute_by_id(_group_id);
    //console.log(_attribute);
    let _attribute_group = BACKEND_get_attribute_group_by_id(_attribute.group);
    //console.log(_attribute_group);

    const _newSkillValue = _skill.increase + _increment;

    let _max_value = _attribute_group.max_specialization;
    if(_group_id == BACKEND_get_attribute_ref().arcana)
        _max_value = BACKEND_get_max_magic_circle();

    return (_newSkillValue <= _max_value)
}

const CheckSkillFreePoints = (_increment, _skill) => 
{
    var _free_skill_points = BACKEND_get_free_skill_points();
    const _usedPoints = _free_skill_points.used + _increment*_skill.cost;
    return (_increment < 0 ) || (_usedPoints <= BACKEND_get_free_skill_points().total);
}

const CheckSkillNegativePoints = (_increment, _skill) => 
{
    const _newSkillValue = _skill.increase + _increment;
    return (_newSkillValue >= BACKEND_get_negative_skill_restriction().single)
}

const CheckMagicSkillNegativePoints = (_increment, _skill) => 
{
    const _newSkillValue = _skill.increase + _increment;
    return (_newSkillValue >= 0)
}

const CheckSkillNegativeTotal = (_increment, _skill_group, _skill) =>
{
    if(_increment >= 0)
        return true;

    let _total_minus = 0;

    let _skills = BACKEND_get_skills();
    for(var key1 in _skills)
    {
        for(var key2 in _skills[key1].skills)
        {
            if((_skills[key1].skill_group_id == _skill_group.skill_group_id) && (_skills[key1].skills[key2].id == _skill.id))
            {
                if(_skills[key1].skills[key2].increase +_increment < 0){
                    _total_minus += _skills[key1].skills[key2].increase +_increment;
                }
            }
            else
            {
                if(_skills[key1].skills[key2].increase < 0){
                    _total_minus += _skills[key1].skills[key2].increase;
                }
            }
        }
    }
    return (_total_minus >= BACKEND_get_negative_skill_restriction().total)
}
            
const IncrementSkill = (_groupID, _skillID, _increment, _type, _recalc) => 
{
    if(_groupID != 18)
    {
        IncrementStandardSkill (_groupID, _skillID, _increment, _type, _recalc);
    }
    else if(_groupID == 18){
        IncrementMagicSkill (_groupID, _skillID, _increment, _type, _recalc);
    }
}

const IncrementStandardSkill = (_groupID, _skillID, _increment, _type, _recalc) => {
    let _skills = BACKEND_get_skills();
    let _free_skill_points = BACKEND_get_free_skill_points();
    for(var key1 in _skills)
    {
        if(_skills[key1].skill_group_id == _groupID)
        {
            for(var key2 in _skills[key1].skills)
            {
                if(_skills[key1].skills[key2].id == _skillID)
                {
                    if(_type == 'increase')
                    {
                        if((CheckSkillFreePoints(_increment, _skills[key1].skills[key2])) && (CheckSkillNegativePoints(_increment, _skills[key1].skills[key2])) && (CheckSkillNegativeTotal(_increment, _skills[key1], _skills[key1].skills[key2]) && (CheckSkillMaxValue(_increment, _groupID, _skills[key1].skills[key2]))))
                        {
                            _skills[key1].skills[key2].increase +=_increment;
                            _free_skill_points.used += _increment*_skills[key1].skills[key2].cost;
                                //_attributes[key].value = CalculateAttribute(_attributes[key]);
                                //_attributes[key].dice = GetDiceList(_attributes[key].value);
                        }
                    }
                    else if(_type == 'other')
                    {
                        _skills[key1].skills[key2].other_bonus +=_increment;
                    }
                    else if(_type == 'ancestry')
                    {
                        _skills[key1].skills[key2].ancestry_mod +=_increment;
                    }
                }
            }
        }
    }

    BACKEND_set_skills(_skills);
    BACKEND_set_free_skill_points(_free_skill_points);

    if(_recalc)
        RecalcSkills();
}

const IncrementMagicSkill = (_groupID, _skillID, _increment, _type, _recalc) => {
    let _skills = BACKEND_get_skills();
    let _free_skill_points = BACKEND_get_free_skill_points();
    for(var key1 in _skills)
    {
        if(_skills[key1].skill_group_id == _groupID)
        {
            for(var key2 in _skills[key1].skills)
            {
                if(_skills[key1].skills[key2].id == _skillID)
                {
                    if(_type == 'increase')
                    {
                        if((CheckSkillFreePoints(_increment, _skills[key1].skills[key2])) && (CheckMagicSkillNegativePoints(_increment, _skills[key1].skills[key2])) && (CheckSkillMaxValue(_increment, _skills[key1].skill_group_id, _skills[key1].skills[key2])))
                        {

                            if(_increment > 0)
                            {
                                _skills[key1].skills[key2].increase +=_increment;
                                _free_skill_points.used += _increment*_skills[key1].skills[key2].cost;

                                if(_skills[key1].skills[key2].increase < 7 && _skills[key1].skills[key2].increase >= 0)
                                    _skills[key1].skills[key2].cost  += _increment;
                            }
                            else if(_increment < 0)
                            {
                                if(_skills[key1].skills[key2].increase < 7 && _skills[key1].skills[key2].increase >= 0)
                                    _skills[key1].skills[key2].cost  += _increment;

                                _skills[key1].skills[key2].increase +=_increment;
                                _free_skill_points.used += _increment*_skills[key1].skills[key2].cost;
                            }
                        }
                    }
                    else if(_type == 'other')
                    {
                        //_skills[key1].skills[key2].other_bonus +=_increment;
                    }
                }
            }
        }
    }

    BACKEND_set_skills(_skills);
    BACKEND_set_free_skill_points(_free_skill_points);

    if(_recalc)
        RecalcSkills();
}

/*const IncrementOtherSkillBonus = (_groupID, _skillID, _increment, _recalc) => {
    var _skills = BACKEND_get_skills();
    for(var key1 in _skills)
    {
        if(_skills[key1].skill_group_id == _groupID)
        {
            for(var key2 in _skills[key1].skills)
            {
                if(_skills[key1].skills[key2].id == _skillID)
                {
                    _skills[key1].skills[key2].other_bonus +=_increment;
                }
            }
        }
    }
    BACKEND_set_skills(_skills);

    if(_recalc)
        RecalcSkills();
}*/

const CheckFocusId = (_id1, _id2) => {
    return (_id1 == _id2);
}

const CheckFocusFreePoints = (_focus, _increment) => {
    if(_increment != 1 && _increment != -1)
        return false;

    if(_increment == -1)
        return true;

    var _free_focus_points = BACKEND_get_free_focus_points();
    var _cost = GetFocusCost(_focus);

    if((_free_focus_points.used + _increment*_cost > _free_focus_points.total))
        return false;

    return true;
}

const CheckFocusMaxMin = (_focus, _increment) => {
    var _restriction = BACKEND_get_negative_focus_restriction();
    if(_focus +  _increment > _restriction.max)
        return false;

    if(_focus +  _increment < _restriction.min)
        return false;

    return true;
}

const CheckFocusNegativeTotal = (_attributeGroupID, _increment) =>
{
    var _total_minus = 0;

    var _restriction = BACKEND_get_negative_focus_restriction();
    var _attributeGroups = BACKEND_get_attribute_groups();
    for(var key in _attributeGroups)
    {
        if (typeof _attributeGroups[key] !== 'undefined') {
            if(_attributeGroupID == _attributeGroups[key].id)
            {
                if(_attributeGroups[key].focus +_increment < 0){
                    _total_minus += _attributeGroups[key].focus +_increment;
                }
            }
            else
            {
                if(_attributeGroups[key].focus < 0){
                    _total_minus += _attributeGroups[key].focus;
                }
            }
            
        }
    }
    return (_total_minus >= _restriction.total)
}

const IncrementFocus = (_attributeGroupID, _increment) => {
    var _attribute_groups = BACKEND_get_attribute_groups();
    var _free_focus_points = BACKEND_get_free_focus_points();
    for(var key in _attribute_groups)
    {
        if (typeof _attribute_groups[key] !== 'undefined') {
            if((CheckFocusId(_attribute_groups[key].id, _attributeGroupID)) && (CheckFocusFreePoints(_attribute_groups[key].focus, _increment)) && (CheckFocusMaxMin(_attribute_groups[key].focus, _increment)) && (CheckFocusNegativeTotal(_attributeGroupID, _increment))){
                if(_increment == 1)
                {
                    _free_focus_points.used += GetFocusCost(_attribute_groups[key].focus);
                }
                else
                {
                    _free_focus_points.used -= GetFocusCost(_attribute_groups[key].focus-1);
                }
                _attribute_groups[key].focus += _increment;
            }
        }
    }
    BACKEND_set_attribute_groups(_attribute_groups);
    BACKEND_set_free_focus_points(_free_focus_points);
    //BACKEND_calculate_max_attributes();
    RecalcAttributes();
}

const IncrementKhoTal = (_increment) => {
    var _character_stats = BACKEND_get_character_stats();

    if(_increment > 0 && _character_stats.kho_tal.value - (_character_stats.kho_tal.lost+_increment)<0)
        return;

    if(_increment < 0 && _character_stats.kho_tal.lost+_increment < 0)
        return;

    _character_stats.kho_tal.lost += _increment;
    BACKEND_set_character_stats(_character_stats)
}

const IncrementFreeFocusPointsBonus = (_increment) => {
    var _free_focus_points = BACKEND_get_free_focus_points();
    _free_focus_points.bonus += _increment;
    BACKEND_set_free_focus_points(_free_focus_points);
    RecalcFreeFocusPoints();
}

const IncrementFreeAttributePointsBonus = (_increment) => {
    var _free_attribute_points = BACKEND_get_free_attribute_points();
    _free_attribute_points.bonus += _increment;
    BACKEND_set_free_attribute_points(_free_attribute_points);
    RecalcFreeAttributePoints();
}

const IncrementFreeSkillPointsBonus = (_increment) => {
    var _free_skill_points = BACKEND_get_free_skill_points();
    _free_skill_points.bonus += _increment;
    BACKEND_set_free_skill_points(_free_skill_points);
    RecalcFreeSkillPoints();
}


export {
    IncrementLevel,
    IncrementAttribute,
    IncrementSkill,
    IncrementFreeFocusPointsBonus, 
    IncrementFreeAttributePointsBonus,
    IncrementFreeSkillPointsBonus,
    IncrementKhoTal,
    IncrementFocus
};