import { useState, createContext} from 'react'
import {
    BACKEND_get_campaign_name,
    BACKEND_set_campaign_name,
    BACKEND_get_campaign_id,
    BACKEND_set_campaign_id,
    BACKEND_get_subscribed_user,
    BACKEND_set_subscribed_user,
    BACKEND_get_monster_list,
    BACKEND_set_monster_list,
    BACKEND_get_monster_template
} from '../Backend/Parameters/CampaignParameters'

import{
    GetElementById,
    SetTimedMessage
} from './Functions/GeneralFunctions'

const CampaignContext = createContext();

const CampaignContextProvider = ({children}) => {

    const [campaignID, setCampaignID] = useState(BACKEND_get_campaign_id());
    const [campaignName, setCampaignName] = useState(BACKEND_get_campaign_name());
    const [subscribedUser, setSubscribedUser] = useState({...BACKEND_get_subscribed_user()});
    const [monsterList, setMonsterList] = useState({...BACKEND_get_monster_list()});
    const [remoteCampaignMessage, setRemoteCampaignMessage] = useState('');
    const [remoteSubscribedUserMessage, setRemoteSubscribedUserMessage] = useState('');

    
    const API_get_campaign_id = () => {return campaignID;}
    const API_set_campaign_id = () => {setCampaignID(BACKEND_get_campaign_id);}
    const API_get_campaign_name = () => {return campaignName;}
    const API_set_campaign_name = () => {setCampaignName(BACKEND_get_campaign_name);}
    const API_get_subscribed_user = () => {return subscribedUser;}
    const API_set_subscribed_user = () => {setSubscribedUser({...BACKEND_get_subscribed_user()});}
    const API_get_monster_list = () => {return monsterList;}
    const API_set_monster_list = () => {setMonsterList({...BACKEND_get_monster_list()});}
    
    const API_get_remote_campaign_message = () => {return remoteCampaignMessage;}
    const API_set_remote_campaign_message = (_message, _timer = 3000) => {SetTimedMessage(_message, setRemoteCampaignMessage, _timer);}
    const API_get_remote_subscribed_user_message = () => {return remoteSubscribedUserMessage;}
    const API_set_remote_subscribed_user_message = (_message, _timer = 3000) => {SetTimedMessage(_message, setRemoteSubscribedUserMessage, _timer);}
    
    const API_get_monster_by_id = (_id) => {
        return GetElementById(_id, monsterList);
    }

    return(
        <CampaignContext.Provider value={{
            API_get_campaign_id,
            API_set_campaign_id,
            API_get_campaign_name,
            API_set_campaign_name,
            API_get_subscribed_user,
            API_set_subscribed_user,
            API_get_monster_list,
            API_set_monster_list,
            API_get_monster_by_id,
            API_get_remote_campaign_message,
            API_set_remote_campaign_message,
            API_get_remote_subscribed_user_message,
            API_set_remote_subscribed_user_message
        }}>
            {children}
        </CampaignContext.Provider>
    )
}

export {CampaignContext, CampaignContextProvider}