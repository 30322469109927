import { useState, useContext } from 'react'
import {AttributeContext, AttributeContextProvider} from '../../API/AttributeContext'
import {SkillContext, SkillContextProvider} from '../../API/SkillContext'
import {TextDataContext, TextDataContextProvider} from '../../API/TextDataContext'
import {BaseStatContext, BaseStatContextProvider} from '../../API/BaseStatContext'
import {APIContext, APIContextProvider} from '../../API/API'
import btn from '../../Styles/btn.module.css'
import table from '../../Styles/table.module.css'
import field from '../../Styles/field.module.css'
import container from '../../Styles/container.module.css'

const SkillGroupHeaderPage = (props) => {
    const {
        API_full_GUI_update,
        API_increment_level,
        API_update_base_stat,
        API_increment_attribute,
        API_increment_skill,
        API_increment_other_skill_bonus,
        API_increment_ancestry_bonus,
        API_increment_other_bonus,
        API_increment_focus,
        API_increment_kho_tal,
        API_update_comments,
        API_get_dice_list_string,
        API_roll_dice,
        API_modify_character_stat_bonus,
        //API_modify_secondary_attribute_bonus,
        API_change_hitpoints,
        API_increment_free_attribute_points_bonus,
        API_increment_free_focus_points_bonus,
        API_get_skill_attribute_mod
    } = useContext(APIContext);

    const {
        API_get_attributes,
        API_set_attributes,
        API_set_comments,
        API_get_comments,
        API_set_character_stats,
        API_get_character_stats,
        //API_set_secondary_attributes,
        //API_get_secondary_attributes,
        API_get_attribute_group_by_id,
        API_get_attribute_by_id
    } = useContext(AttributeContext);
    
    const {
        API_get_base_stat_labels, 
        API_set_base_stat_labels,
        API_get_std_labels,
        API_set_std_labels,
        API_get_std_header_labels,
        API_set_std_header_labels,
        API_get_tab_view_labels,
        API_set_tab_view_labels,
        API_get_character_stats_labels,
        API_set_character_stats_labels,
        API_get_table_header_labels,
        API_set_table_header_labels
    } = useContext(TextDataContext);
    
    const {
        API_get_base_stats,
        API_get_free_attribute_points,
        API_get_free_focus_points,
        API_get_edit_character_visibility,
        API_set_edit_character_visibility
    } = useContext(BaseStatContext);
    
    const {
        API_get_skill_group_visibility,
        //API_set_skill_group_visibility,
        API_toggle_skill_group_visibility,
        API_get_skills,
        API_get_edit_skill_visibility,
        API_set_edit_skill_visibility,
        API_get_skill_selector,
        API_set_skill_selector
    } = useContext(SkillContext);

    const incrementFocus = (_increment) => {
        API_increment_focus (props.id, _increment);
    }

    //const skillGroupVisibility = API_get_skill_group_visibility();
    

    const toggleGroupVisibility = () => {
        API_toggle_skill_group_visibility(props.internal_groups);
        
    }

    return ( 
        <div>
            <table className = {table.table_base_stats} style={{marginLeft: 0, marginRight: 0}}>
                <tr >
                    <td className={table.cell_skill_header} style={{width: 83, minWidth: 83, textAlign: 'left', borderLeft: '0px solid black', borderTop: '0px solid black'}}>{API_get_std_header_labels().talent_in}</td>
                    <td className={table.cell_label} style={{width: 173, minWidth: 173, textAlign: 'left', fontWeight: 'bold', borderLeft: '0px solid black', borderTop: '0px solid black', borderRight: '0px solid black'}}>{API_get_attribute_group_by_id(props.id).group}</td>
                    <td className={table.cell_label} style={{width: 60, minWidth: 60, borderTop: '0px solid black', borderLeft: '0px solid black'}}>
                        {API_get_edit_character_visibility() &&
                            <div>
                                <button className={btn.button_icon} onClick={() => incrementFocus(1)} style={{width: 22, height: 22, margin: '0px 2px 0px 5px'}}>+</button>
                                <button className={btn.button_icon} onClick={() => incrementFocus(-1)} style={{width: 22, height: 22, margin: '0px 5px 0px 0px'}}>-</button>
                            </div>
                        }
                    </td>
                    {/*<td className={table.cell_table_separator} style={{width: 10, minWidth: 10, borderTop: '0px solid black'}}></td>
                    <td className={table.cell_label} style={{width: 60, minWidth: 60, fontWeight: 'bold', borderTop: '0px solid black'}}>Focus</td>
                    <td className={table.cell_table_separator} style={{width: 10, minWidth: 10, borderTop: '0px solid black'}}></td>*/}
                    <td style={{width: 60, minWidth: 60, borderTop: '0px solid black'}}>{API_get_attribute_group_by_id(props.id).focus}</td>
                    <td className={table.cell_skill_header} style={{width: 20, minWidth: 20, borderTop: '0px solid black'}}>+</td>
                    <td className={table.cell_label} style={{width: 50, minWidth: 50, borderTop: '0px solid black'}}>{API_get_base_stat_labels().level}</td>
                    <td style={{width: 50, minWidth: 50, fontWeight: 'bold', borderTop: '0px solid black'}}>{API_get_base_stats().level}</td>
                    <td className={table.cell_skill_header} style={{width: 40, minWidth: 40, borderTop: '0px solid black'}}>{'\u2794'}</td>
                    <td className={table.cell_label} style={{width: 180, minWidth: 180, borderTop: '0px solid black'}}>{API_get_std_header_labels().max_training}</td>
                    <td style={{width: 50, minWidth: 50, fontWeight: 'bold', borderTop: '0px solid black'}}>{API_get_attribute_group_by_id(props.id).max_training}</td>
                    <td className={table.cell_skill_header} style={{width: 30, minWidth: 30, borderTop: '0px solid black'}}></td>
                    <td className={table.cell_label} style={{width: 180, minWidth: 180, borderTop: '0px solid black'}}>{API_get_std_header_labels().max_specialization}</td>
                    <td style={{width: 50, minWidth: 50, fontWeight: 'bold', borderTop: '0px solid black'}}>{API_get_attribute_group_by_id(props.id).max_specialization}</td>
                    <td className={table.cell_skill_header} style={{width:309, minWidth: 309, borderTop: '0px solid black', borderRight: '0px solid black'}}></td>
                    {/*<td className={table.cell_skill_header} style={{width: 100, minWidth: 100, borderTop: '0px solid black', borderRight: '0px solid black', textAlign: 'right'}}>
                        <button className={btn.button_icon} onClick={() => toggleGroupVisibility(1)} style={{width: 22, height: 22, margin: '0px 2px 0px 5px'}}>+</button>
                    </td>*/}
                </tr>
            </table>
        </div>
    );
}
 
export {
    SkillGroupHeaderPage
};