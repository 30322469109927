import { useState, useContext } from 'react'
import {APIContext, APIContextProvider} from '../../API/API'
import {CampaignContext, CampaignContextProvider} from '../../API/CampaignContext'
import {TextDataContext, TextDataContextProvider} from '../../API/TextDataContext'
import { 
    ParseToFloat, 
    ParseToInteger,
    GetElementById,
    GetListParameterByID,
    SetListParameterByID,
    TryParse
} from '../../API/Functions/GeneralFunctions'
import btn from '../../Styles/btn.module.css'
import table from '../../Styles/table.module.css'
import field from '../../Styles/field.module.css'
import container from '../../Styles/container.module.css'

const MonsterTablePage = () => {
const {
    API_full_GUI_update,
    API_increment_level,
    API_update_base_stat,
    API_increment_attribute,
    API_increment_skill,
    API_increment_other_skill_bonus,
    API_increment_ancestry_bonus,
    API_increment_other_bonus,
    API_increment_focus,
    API_increment_kho_tal,
    API_update_comments,
    API_get_dice_list_string,
    API_roll_dice,
    API_modify_character_stat_bonus,
    //API_modify_secondary_attribute_bonus,
    API_change_hitpoints,
    API_increment_free_attribute_points_bonus,
    API_increment_free_focus_points_bonus,
    API_get_skill_attribute_mod,
    API_modify_magic_spell_name,
    API_modify_magic_spell_might,
    API_modify_magic_spell_description,
    API_switch_magic_element_type,
    API_switch_magic_circle_type,
    API_add_new_magic_spell,
    API_remove_magic_spell,
    API_move_magic_spell,
    API_modify_monster_name,
    API_modify_monster_initiative,
    API_modify_monster_damage,
    API_modify_monster_hitpoints,
    API_modify_monster_reflexes,
    API_modify_monster_attack,
    API_modify_monster_defense,
    API_modify_monster_comment,
    API_add_monster_element,
    API_remove_monster_element,
    API_move_monster_element
} = useContext(APIContext);
    
    const {
        API_get_monster_list,
        API_set_monster_list,
        API_get_monster_by_id
    } = useContext(CampaignContext);

    const {
        API_get_base_stat_labels, 
        API_set_base_stat_labels,
        API_get_std_labels,
        API_set_std_labels,
        API_get_std_header_labels,
        API_set_std_header_labels,
        API_get_stat_overview_labels,
        API_set_stat_overview_labels,
        API_get_money_header_labels,
        API_set_money_header_labels,
        API_get_tab_view_labels,
        API_set_tab_view_labels,
        API_get_character_stats_labels,
        API_set_character_stats_labels,
        API_get_table_header_labels,
        API_set_table_header_labels,
        API_get_equipment_header_labels,
        API_set_equipment_header_labels
    } = useContext(TextDataContext);

    const [editList, setEditList] = useState(null);
    const [editName, setEditName] = useState(null);
    const [editInitiative, setEditInitiative] = useState(null);
    const [editDamage, setEditDamage] = useState(null);
    const [editHitpoints, setEditHitpoints] = useState(null);
    const [editReflexes, setEditReflexes] = useState(null);
    const [editAttack, setEditAttack] = useState(null);
    const [editDefense, setEditDefense] = useState(null);
    const [editComment, setEditComment] = useState(null);

    const clickEditElementHandler = (_value) => {
        let _list = API_get_monster_list();
        let _local_edit_list = [];
        let _local_edit_name = [];
        let _local_edit_initiative = [];
        let _local_edit_damage = [];
        let _local_edit_hitpoints = [];
        let _local_edit_reflexes = [];
        let _local_edit_attack = [];
        let _local_edit_defense = [];
        let _local_edit_comment = [];

        for(let key in _list){
            _local_edit_list.push({id: _list[key].id, value: false});
            _local_edit_name.push({id: _list[key].id, value: _list[key].display_name});
            _local_edit_initiative.push({id: _list[key].id, value: _list[key].initiative});
            _local_edit_damage.push({id: _list[key].id, value: _list[key].damage});
            _local_edit_hitpoints.push({id: _list[key].id, value: _list[key].hitpoints});
            _local_edit_reflexes.push({id: _list[key].id, value: _list[key].reflexes});
            _local_edit_attack.push({id: _list[key].id, value: _list[key].attack});
            _local_edit_defense.push({id: _list[key].id, value: _list[key].defense});
            _local_edit_comment.push({id: _list[key].id, value: _list[key].comment});
        }

        if(editList == null)
        {
            for(let key in _local_edit_list){
                if(_local_edit_list[key].id == _value.id)
                    _local_edit_list[key].value = true;
            }

            setEditName(_local_edit_name);
            setEditInitiative(_local_edit_initiative);
            setEditDamage(_local_edit_damage);
            setEditHitpoints(_local_edit_hitpoints);
            setEditReflexes(_local_edit_reflexes);
            setEditAttack(_local_edit_attack);
            setEditDefense(_local_edit_defense);
            setEditComment(_local_edit_comment);
            setEditList(_local_edit_list);

            return;
        }


        for(let key1 in _local_edit_list){
            for(let key2 in editList){
                if(_local_edit_list[key1].id == editList[key2].id){
                    _local_edit_list[key1].value = editList[key2].value;
                }
            }
        }

        for(let key in _local_edit_list){
            if(_local_edit_list[key].id == _value.id)
            _local_edit_list[key].value = !_local_edit_list[key].value;
        }

        setEditName(_local_edit_name);
        setEditInitiative(_local_edit_initiative);
        setEditDamage(_local_edit_damage);
        setEditHitpoints(_local_edit_hitpoints);
        setEditReflexes(_local_edit_reflexes);
        setEditAttack(_local_edit_attack);
        setEditDefense(_local_edit_defense);
        setEditComment(_local_edit_comment);
        setEditList(_local_edit_list);
    }

    const getValueByID = (_id, _list, _type) => {
        return GetListParameterByID(_id, _list, _type);
    }

    const setValueByID = (_id, _list, _setter, _newValue, _type) => {
        SetListParameterByID(_id, _list, _setter, _newValue, _type);
    }

    const modifyName = (_id, _newValue) => {
        API_modify_monster_name(_id, _newValue);
    }

    const tryModifyInitiative = (_id, _newValue) => {
        if(TryParse(_newValue, 'integer'))
            modifyInitiative(_id, _newValue);

        SetListParameterByID(_id, editInitiative, setEditInitiative, _newValue, 'integer');
    }

    const modifyInitiative = (_id, _newValue) => {
        API_modify_monster_initiative(_id, _newValue);
    }

    const tryModifyHitpoints = (_id, _newValue) => {
        if(TryParse(_newValue, 'integer'))
            modifyHitpoints(_id, _newValue);

        SetListParameterByID(_id, editHitpoints, setEditHitpoints, _newValue, 'integer');
    }

    const modifyHitpoints = (_id, _newValue) => {
        API_modify_monster_hitpoints(_id, _newValue);
    }

    const tryModifyReflexes = (_id, _newValue) => {
        if(TryParse(_newValue, 'integer'))
            modifyReflexes(_id, _newValue);

        SetListParameterByID(_id, editReflexes, setEditReflexes, _newValue, 'integer');
    }

    const modifyReflexes = (_id, _newValue) => {
        API_modify_monster_reflexes(_id, _newValue);
    }

    const tryModifyAttack = (_id, _newValue) => {
        if(TryParse(_newValue, 'integer'))
            modifyAttack(_id, _newValue);

        SetListParameterByID(_id, editAttack, setEditAttack, _newValue, 'integer');
    }

    const modifyAttack = (_id, _newValue) => {
        API_modify_monster_attack(_id, _newValue);
    }

    const tryModifyDefense = (_id, _newValue) => {
        if(TryParse(_newValue, 'integer'))
            modifyDefense(_id, _newValue);

        SetListParameterByID(_id, editDefense, setEditDefense, _newValue, 'integer');
    }

    const modifyDefense = (_id, _newValue) => {
        API_modify_monster_defense(_id, _newValue);
    }

    const tryModifyDamage = (_id, _newValue) => {
        if(TryParse(_newValue, 'float'))
            modifyDamage(_id, _newValue);
  
        SetListParameterByID(_id, editDamage, setEditDamage, _newValue, 'float');
    }

    const modifyDamage = (_id, _newValue) => {
        API_modify_monster_damage(_id, _newValue);
    }

    const modifyComment = (_id, _newValue) => {
        API_modify_monster_comment(_id, _newValue);
    }
    
    const addNewElement = () => {
        API_add_monster_element();
    }

    const removeElement = (_element) => {
        API_remove_monster_element(_element);
    }

    const moveElement = (_element, _direction) => {
        API_move_monster_element(_element, _direction);
    }

    const click_roll_attack_dice = (_dice, _damage) => {
        //var _skill = API_get_skill_by_id(_skill_group.skill_group_id, _skillID);
        API_roll_dice('_skill.display_name', _dice, true, 'attack', _damage);
    }

    const click_roll_defense_dice = (_dice) => {
        //var _skill = API_get_skill_by_id(_skill_group.skill_group_id, _skillID);
        API_roll_dice('_skill.display_name', _dice, true, 'defense', '');
    }

    const click_roll_attribute_dice = (_dice, _name) => {
        //var _skill = API_get_skill_by_id(_skill_group.skill_group_id, _skillID);
        API_roll_dice('_skill.display_name', _dice, true, 'attribute', _name);
    }

    
    return ( 
        <div>
            <table className={table.table_editable} style={{background: 'transparent'}}>
                <tr>
                    <td className={table.cell_header} style={{width: 220, minWidth: 220, textAlign: 'left', paddingLeft: 5}}>{API_get_std_header_labels().monster}</td>
                    <td className={table.cell_header} style={{width: 120, minWidth: 120}}>{API_get_equipment_header_labels().initiative_dice}</td>
                    <td className={table.cell_header} style={{width: 68, minWidth: 68}}>{API_get_equipment_header_labels().initiative}</td>
                    <td className={table.cell_header} style={{width: 68, minWidth: 68}}>{API_get_character_stats_labels().hitpoints}</td>
                    <td className={table.cell_header} style={{width: 68, minWidth: 68}}>{API_get_character_stats_labels().reflexes}</td>
                    <td className={table.cell_header} style={{width: 68, minWidth: 68}}>{API_get_equipment_header_labels().attack}</td>
                    <td className={table.cell_header} style={{width: 68, minWidth: 68}}>{API_get_equipment_header_labels().defense}</td>
                    <td className={table.cell_header} style={{width: 120, minWidth: 120}}>{API_get_equipment_header_labels().attack_dice}</td>
                    <td className={table.cell_header} style={{width: 120, minWidth: 120}}>{API_get_equipment_header_labels().defense_dice}</td>
                    <td className={table.cell_header} style={{width: 68, minWidth: 68}}>{API_get_equipment_header_labels().damage}</td>
                    <td className={table.cell_header} style={{width: 420, minWidth: 420, textAlign: 'left', paddingLeft: 5}}>{API_get_std_header_labels().comment}</td>
                    <td className={table.cell_header} style={{width: 120, minWidth: 120}} colspan={5}></td>
                </tr>

                {Object.entries(API_get_monster_list()).map(([key, value], index) => (
                    <tr>
                        <td style={{textAlign: 'left'}}>
                            {(!getValueByID(value.id, editList, 'bool')) && 
                                <p style={{paddingLeft: 5}}>{value.display_name}</p>
                            }
                            {(getValueByID(value.id, editList, 'bool')) && 
                                <input className={field.input_field_text} style={{width: 200}} value={getValueByID(value.id, editName, 'text')} onChange={(e) => setValueByID(value.id, editName, setEditName, e.target.value, 'text')} onBlur={(e) => modifyName(value.id, e.target.value)} />
                            }
                        </td>
                        <td>
                            <button className={btn.dice_btn} onClick={() => click_roll_attribute_dice(value.initiative_dice, 'Initiative')}>{API_get_dice_list_string(value.initiative_dice)}</button>
                        </td>
                        <td>
                            {(!getValueByID(value.id, editList, 'bool')) && 
                                value.initiative
                            }
                            {(getValueByID(value.id, editList, 'bool')) && 
                                <input type='number' className={field.input_field_number} style={{width: 50}} value={getValueByID(value.id, editInitiative, 'integer')} onChange={(e) => tryModifyInitiative(value.id, e.target.value)} onBlur={(e) => modifyInitiative(value.id, e.target.value)} />
                             }
                        </td>
                        <td>
                            {(!getValueByID(value.id, editList, 'bool')) && 
                                value.hitpoints
                            }
                            {(getValueByID(value.id, editList, 'bool')) && 
                                <input type='number' className={field.input_field_number} style={{width: 50}} value={getValueByID(value.id, editHitpoints, 'integer')} onChange={(e) => tryModifyHitpoints(value.id, e.target.value)} onBlur={(e) => modifyHitpoints(value.id, e.target.value)} />
                             }
                        </td>
                        <td>
                            {(!getValueByID(value.id, editList, 'bool')) && 
                                value.reflexes
                            }
                            {(getValueByID(value.id, editList, 'bool')) && 
                                <input type='number' className={field.input_field_number} style={{width: 50}} value={getValueByID(value.id, editReflexes, 'integer')} onChange={(e) => tryModifyReflexes(value.id, e.target.value)} onBlur={(e) => modifyReflexes(value.id, e.target.value)} />
                             }
                        </td>
                        <td>
                            {(!getValueByID(value.id, editList, 'bool')) && 
                                value.attack
                            }
                            {(getValueByID(value.id, editList, 'bool')) && 
                                <input type='number' className={field.input_field_number} style={{width: 50}} value={getValueByID(value.id, editAttack, 'integer')} onChange={(e) => tryModifyAttack(value.id, e.target.value)} onBlur={(e) => modifyAttack(value.id, e.target.value)} />
                             }
                        </td>
                        <td>
                            {(!getValueByID(value.id, editList, 'bool')) && 
                                value.defense
                            }
                            {(getValueByID(value.id, editList, 'bool')) && 
                                <input type='number' className={field.input_field_number} style={{width: 50}} value={getValueByID(value.id, editDefense, 'integer')} onChange={(e) => tryModifyDefense(value.id, e.target.value)} onBlur={(e) => modifyDefense(value.id, e.target.value)} />
                             }
                        </td>
                        <td>
                            <button className={btn.dice_btn} onClick={() => click_roll_attack_dice(value.attack_dice)}>{API_get_dice_list_string(value.attack_dice, value.damage)}</button>
                        </td>
                        <td>
                            <button className={btn.dice_btn} onClick={() => click_roll_defense_dice(value.attack_dice)}>{API_get_dice_list_string(value.defense_dice)}</button>
                        </td>
                        <td>
                            {(!getValueByID(value.id, editList, 'bool')) && 
                                'x' + value.damage
                            }
                            {(getValueByID(value.id, editList, 'bool')) && 
                                <input type='number' className={field.input_field_number} style={{width: 50}} value={getValueByID(value.id, editDamage, 'float')} onChange={(e) => tryModifyDamage(value.id, e.target.value)} onBlur={(e) => modifyDamage(value.id, e.target.value)} />
                            }
                        </td>
                        <td style={{textAlign: 'left'}}>
                            {(!getValueByID(value.id, editList, 'bool')) && 
                                <p style={{paddingLeft: 5}}>{value.comment}</p>
                            }
                            {(getValueByID(value.id, editList, 'bool')) && 
                                <input className={field.input_field_text} style={{width: 400}} value={getValueByID(value.id, editComment, 'text')} onChange={(e) => setValueByID(value.id, editComment, setEditComment, e.target.value, 'text')} onBlur={(e) => modifyComment(value.id, e.target.value)} />
                            }
                        </td>
                        <td style={{width: 24, minWidth: 24, borderRight: '0px solid black'}}>
                            <button className={btn.button_icon} onClick={() => clickEditElementHandler(value)}>E</button>
                        </td>
                        <td style={{width: 24, minWidth: 24, borderLeft: '0px solid black', borderRight: '0px solid black'}}>
                            <button className={btn.button_icon} onClick={() => moveElement(value, 'up')}>+</button>
                        </td>
                        <td style={{width: 24, minWidth: 24, borderLeft: '0px solid black', borderRight: '0px solid black'}}>
                            <button className={btn.button_icon} onClick={() => moveElement(value, 'down')}>-</button>
                        </td>
                        <td style={{width: 24, minWidth: 24, borderLeft: '0px solid black', borderRight: '0px solid black'}}>
                        </td>
                        <td style={{width: 24, minWidth: 24, borderLeft: '0px solid black'}}>
                            <button className={btn.button_icon} onClick={() => removeElement(value)}>x</button>
                        </td>
                    </tr>
                ))}
                <tr>
                    <td style={{textAlign: 'right', paddingLeft: 5}}>
                        <button className={btn.button_icon} onClick={() => addNewElement()}>+</button>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan={5}></td>
                </tr>
            </table>
        </div>
    );
}

export {
    MonsterTablePage
}
                