import { useState, createContext} from 'react'
import {
    ConvertAttributes
} from '../Backend/Functions/CharacterFunctions'
import {
    BACKEND_get_attribute_groups,
    BACKEND_get_attributes,
    BACKEND_get_comments,
    BACKEND_get_character_stats,
    //BACKEND_get_secondary_attributes,
    BACKEND_get_lead_attribute_tags,
    BACKEND_get_lead_attribute_matching,
    BACKEND_get_ancestry_saldo,
    BACKEND_set_ancestry_saldo
} from '../Backend/Parameters/CharacterParameters'


const AttributeContext = createContext();

const AttributeContextProvider = ({children}) => {

    const API_set_attributes = () => {setAttributes({...API_convert_attributes()});}
    const API_get_attributes = () => {return attributes;}
    const API_convert_attributes = () => {return ConvertAttributes(BACKEND_get_attribute_groups(), BACKEND_get_attributes());}
    const API_set_comments = () => {setComments(BACKEND_get_comments());}
    const API_get_comments = () => {return comments;}
    const API_get_character_stats = () => {return characterStats;}
    const API_set_character_stats = () => {setCharacterStats({...BACKEND_get_character_stats()});}
    //const API_get_secondary_attributes = () => {return secondaryAttributes;}
    //const API_set_secondary_attributes = () => {setSecondaryAttributes({...BACKEND_get_secondary_attributes()});}
    const API_get_lead_attribute_tags = () => {return leadAttributeTags;}
    const API_set_lead_attribute_tags = () => {setLeadAttributeTags({...BACKEND_get_lead_attribute_tags()});}

    const [attributes, setAttributes] = useState({...API_convert_attributes()});
    const [comments, setComments] = useState('');
    const [characterStats, setCharacterStats] = useState({...BACKEND_get_character_stats()});
    //const [secondaryAttributes, setSecondaryAttributes] = useState({...BACKEND_get_secondary_attributes()});
    const [leadAttributeTags, setLeadAttributeTags] = useState({...BACKEND_get_lead_attribute_tags()});

    const API_get_attribute_group_by_id = (_id) => {
        for(let key in attributes){
            if(attributes[key].group_id == _id)
            {
                return attributes[key];
            }
        }
        return null;
    }
    
    const API_get_attribute_by_id = (_id) => {
        for(let key1 in attributes){
            for(let key2 in attributes[key1].attributes){
                if(attributes[key1].attributes[key2].id == _id)
                {
                    return attributes[key1].attributes[key2];
                }
            }
        }
        return null;
    }

    return(
        <AttributeContext.Provider value={{
            API_get_attributes,
            API_set_attributes,
            API_set_comments,
            API_get_comments,
            API_set_character_stats,
            API_get_character_stats,
            //API_set_secondary_attributes,
            //API_get_secondary_attributes,
            API_get_attribute_group_by_id,
            API_get_attribute_by_id,
            API_get_lead_attribute_tags,
            API_set_lead_attribute_tags
        }}>
            {children}
        </AttributeContext.Provider>
    )
}

export {AttributeContext, AttributeContextProvider}