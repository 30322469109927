import { useState, createContext} from 'react'
import 
{BACKEND_get_base_stat_labels, 
    BACKEND_set_base_stat_labels, 
    BACKEND_get_std_labels, 
    BACKEND_set_std_labels, 
    BACKEND_get_tab_view_labels,  
    BACKEND_set_tab_view_labels,  
    BACKEND_get_std_header_labels,
    BACKEND_set_std_header_labels,
    BACKEND_set_stat_overview_labels,
    BACKEND_get_stat_overview_labels,
    BACKEND_set_money_header_labels,
    BACKEND_get_money_header_labels,
    BACKEND_get_message_box_content_labels,
    BACKEND_set_message_box_content_labels,
    BACKEND_get_character_stats_labels, 
    BACKEND_set_character_stats_labels, 
    BACKEND_get_attribute_header_labels, 
    BACKEND_set_attribute_header_labels,
    BACKEND_get_equipment_header_labels,
    BACKEND_set_equipment_header_labels,
    BACKEND_get_user_labels,
    BACKEND_set_user_labels
} from '../Backend/Lang/TextData'

const TextDataContext = createContext();

const TextDataContextProvider = ({children}) => {

    const [baseStatLabels, setBaseStatLabels] = useState({...BACKEND_get_base_stat_labels()});
    const [stdLabels, setStdLabels] = useState({...BACKEND_get_std_labels()});
    const [stdHeader, setStdHeader] = useState({...BACKEND_get_std_header_labels()});
    const [statOverviewLabels, setStatOverviewLabels] = useState({...BACKEND_get_stat_overview_labels()});
    const [moneyHeaderLabels, setMoneyHeaderLabels] = useState({...BACKEND_get_money_header_labels()});
    const [messageBoxLabels, setMessageBoxLabels] = useState({...BACKEND_get_message_box_content_labels()});
    const [tabViewLabels, setTabViewLabels] = useState({...BACKEND_get_tab_view_labels()});
    const [characterStatLabels, setCharacterStatLabels] = useState({...BACKEND_get_character_stats_labels()});
    const [tableHeaderLabels, setTableHeaderLabels] = useState({...BACKEND_get_attribute_header_labels()});
    const [equipmentHeaderLabels, setEquipmentHeaderLabels] = useState({...BACKEND_get_equipment_header_labels()});
    const [userLabels, setUserLabels] = useState({...BACKEND_get_user_labels()});

    const API_get_base_stat_labels = () => {return baseStatLabels;}
    const API_set_base_stat_labels = () => {setBaseStatLabels({...BACKEND_get_base_stat_labels()});}

    const API_get_std_labels = () => {return stdLabels;}
    const API_set_std_labels = () => {setStdLabels({...BACKEND_get_std_labels()});}

    const API_get_std_header_labels = () => {return stdHeader;}
    const API_set_std_header_labels = () => {setStdHeader({...BACKEND_get_std_header_labels()});}

    const API_get_stat_overview_labels = () => {return statOverviewLabels;}
    const API_set_stat_overview_labels = () => {setStatOverviewLabels({...BACKEND_get_stat_overview_labels()});}

    const API_get_money_header_labels = () => {return moneyHeaderLabels;}
    const API_set_money_header_labels = () => {setMoneyHeaderLabels({...BACKEND_get_money_header_labels()});}

    const API_get_message_box_content_labels = () => {return messageBoxLabels;}
    const API_set_message_box_content_labels = () => {setMessageBoxLabels({...BACKEND_get_message_box_content_labels()});}

    const API_get_tab_view_labels = () => {return tabViewLabels;}
    const API_set_tab_view_labels = () => {setTabViewLabels({...BACKEND_get_tab_view_labels()});}

    const API_get_character_stats_labels = () => {return characterStatLabels;}
    const API_set_character_stats_labels = () => {setCharacterStatLabels({...BACKEND_get_character_stats_labels()});}

    const API_get_table_header_labels = () => {return tableHeaderLabels;}
    const API_set_table_header_labels = () => {setTableHeaderLabels({...BACKEND_get_attribute_header_labels()});}

    const API_get_equipment_header_labels = () => {return equipmentHeaderLabels;}
    const API_set_equipment_header_labels = () => {setEquipmentHeaderLabels({...BACKEND_get_equipment_header_labels()});}

    const API_get_user_labels = () => {return userLabels;}
    const API_set_user_labels = () => {setUserLabels({...BACKEND_get_user_labels()});}
    
    return(
        <TextDataContext.Provider value={{
            API_get_base_stat_labels, 
            API_set_base_stat_labels,
            API_get_std_labels,
            API_set_std_labels,
            API_get_std_header_labels,
            API_set_std_header_labels,
            API_get_stat_overview_labels,
            API_set_stat_overview_labels,
            API_get_money_header_labels,
            API_set_money_header_labels,
            API_get_message_box_content_labels,
            API_set_message_box_content_labels,
            API_get_tab_view_labels,
            API_set_tab_view_labels,
            API_get_character_stats_labels,
            API_set_character_stats_labels,
            API_get_table_header_labels,
            API_set_table_header_labels,
            API_get_equipment_header_labels,
            API_set_equipment_header_labels,
            API_get_user_labels,
            API_set_user_labels
        }}>
            {children}
        </TextDataContext.Provider>
    )
}

export {TextDataContext, TextDataContextProvider}