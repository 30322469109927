import { useState, useContext } from 'react'
import {EquipmentContext, EquipmentContextProvider} from '../../API/EquipmentContext'
import {TextDataContext, TextDataContextProvider} from '../../API/TextDataContext'
import {AttributeContext, AttributeContextProvider} from '../../API/AttributeContext'
import {APIContext, APIContextProvider} from '../../API/API'
import btn from '../../Styles/btn.module.css'
import table from '../../Styles/table.module.css'
import field from '../../Styles/field.module.css'
import container from '../../Styles/container.module.css'
import { 
    ParseToFloat, 
    ParseToInteger,
    GetElementById,
    GetListParameterByID,
    SetListParameterByID,
    TryParse
} from '../../API/Functions/GeneralFunctions'

const WeaponTablePage = (props) => {
    const {
        API_full_GUI_update,
        API_increment_level,
        API_update_base_stat,
        API_increment_attribute,
        API_increment_skill,
        API_increment_other_skill_bonus,
        API_increment_ancestry_bonus,
        API_increment_other_attribute_bonus,
        API_increment_focus,
        API_increment_kho_tal,
        API_update_comments,
        API_get_dice_list_string,
        API_roll_dice,
        API_modify_character_stat_bonus,
        //API_modify_secondary_attribute_bonus,
        API_change_hitpoints,
        API_increment_free_attribute_points_bonus,
        API_increment_free_focus_points_bonus,
        API_get_skill_attribute_mod,
        API_switch_weapon_type,
        API_switch_hand_type,
        API_modify_weapon_weight,
        API_modify_melee_weapon_attack_mod,
        API_modify_melee_weapon_defense_mod,
        API_modify_weapon_name,
        API_switch_lead_attribute,
        API_add_new_weapon,
        API_remove_weapon,
        API_move_weapon,
        API_modify_ammo_name,
        API_modify_ammo,
        API_modify_ammo_weight,
        API_modify_damage
    } = useContext(APIContext);

    const {
        API_get_melee_fighting_types,
        API_get_ranged_fighting_types,
        //API_get_hand_types,
        API_get_unarmed_weapons,
        API_set_unarmed_weapons,
        API_get_melee_weapons,
        API_set_melee_weapons,
        API_get_ranged_weapons,
        API_set_ranged_weapons,
        API_get_melee_fighting_type_by_id,
        API_get_ranged_fighting_type_by_id,
        //API_get_hand_type_by_id
    } = useContext(EquipmentContext);
    
    const {
        API_get_base_stat_labels, 
        API_set_base_stat_labels,
        API_get_std_labels,
        API_set_std_labels,
        API_get_std_header_labels,
        API_set_std_header_labels,
        API_get_tab_view_labels,
        API_set_tab_view_labels,
        API_get_character_stats_labels,
        API_set_character_stats_labels,
        API_get_table_header_labels,
        API_set_table_header_labels,
        API_get_equipment_header_labels,
        API_set_equipment_header_labels
    } = useContext(TextDataContext);

    const {
        API_get_attributes,
        API_set_attributes,
        API_set_comments,
        API_get_comments,
        API_set_character_stats,
        API_get_character_stats,
        //API_set_secondary_attributes,
        //API_get_secondary_attributes,
        API_get_attribute_group_by_id,
        API_get_attribute_by_id,
        API_get_lead_attribute_tags,
        API_set_lead_attribute_tags
    } = useContext(AttributeContext);

    const [editWeaponList, setEditWeaponList] = useState(null);
    const [editName, setEditName] = useState(null);
    const [editWeight, setEditWeight] = useState(null);
    const [editAttackMod, setEditAttackMod] = useState(null);
    const [editDefenseMod, setEditDefenseMod] = useState(null);
    const [editAmmoName, setEditAmmoName] = useState(null);
    const [editAmmo, setEditAmmo] = useState(null);
    const [editAmmoWeight, setEditAmmoWeight] = useState(null);
    const [editDamage, setEditDamage] = useState(null);
    //const localEditWeaponList = null;

    const click_roll_attack_dice = (_dice, _damage) => {
        //var _skill = API_get_skill_by_id(_skill_group.skill_group_id, _skillID);
        API_roll_dice('_skill.display_name', _dice, true, 'attack', _damage);
    }

    const click_roll_defense_dice = (_dice) => {
        //var _skill = API_get_skill_by_id(_skill_group.skill_group_id, _skillID);
        API_roll_dice('_skill.display_name', _dice, true, 'defense', '');
    }

    const get_weapon_list = () => {
        if(props.list_type == 'unarmed')
        {
            return API_get_unarmed_weapons();
        }
        else if(props.list_type == 'melee')
        {
            return API_get_melee_weapons();
        }
        else if(props.list_type == 'ranged')
        {
            return API_get_ranged_weapons();
        }
        return 'unknown'
    }

    const get_weapon_header = () => {
        if(props.list_type == 'unarmed')
        {
            return API_get_equipment_header_labels().unarmed_weapon;
        }
        else if(props.list_type == 'melee')
        {
            return API_get_equipment_header_labels().melee_weapon;
        }
        else if(props.list_type == 'ranged')
        {
            return API_get_equipment_header_labels().ranged_weapon;
        }
        return 'unknown'
    }

    const clickEditWeaponsHandler = (_value) => {
        let _weapon_list = get_weapon_list();
        let _local_edit_weapon_list = [];
        let _local_edit_name = [];
        let _local_edit_weight = [];
        let _local_edit_attack_mod = [];
        let _local_edit_defense_mod = [];
        let _local_edit_ammo_name = [];
        let _local_edit_ammo = [];
        let _local_edit_ammo_weight = [];
        let _local_edit_damage = [];

        for(let key in _weapon_list){
            _local_edit_weapon_list.push({id: _weapon_list[key].id, value: false});
            _local_edit_name.push({id: _weapon_list[key].id, value: _weapon_list[key].display_name});
            _local_edit_weight.push({id: _weapon_list[key].id, value: _weapon_list[key].weight});
            _local_edit_damage.push({id: _weapon_list[key].id, value: _weapon_list[key].damage});
            if(props.list_type != 'ranged'){
                _local_edit_attack_mod.push({id: _weapon_list[key].id, value: _weapon_list[key].attack_mod});
                _local_edit_defense_mod.push({id: _weapon_list[key].id, value: _weapon_list[key].defense_mod});
            }
            else if(props.list_type == 'ranged'){
                _local_edit_attack_mod.push({id: _weapon_list[key].id, value: _weapon_list[key].precision_mod});
                _local_edit_ammo_name.push({id: _weapon_list[key].id, value: _weapon_list[key].ammo_name});
                _local_edit_ammo.push({id: _weapon_list[key].id, value: _weapon_list[key].ammo});
                _local_edit_ammo_weight.push({id: _weapon_list[key].id, value: _weapon_list[key].ammo_weight});
            }
        }

        if(editWeaponList == null)
        {
            for(let key in _local_edit_weapon_list){
                if(_local_edit_weapon_list[key].id == _value.id)
                    _local_edit_weapon_list[key].value = true;
            }

            setEditName(_local_edit_name);
            setEditWeight(_local_edit_weight);
            setEditAttackMod(_local_edit_attack_mod);
            setEditDamage(_local_edit_damage);
            if(props.list_type != 'ranged'){
                setEditDefenseMod(_local_edit_defense_mod);
            }
            else if(props.list_type == 'ranged'){
                setEditAmmoName(_local_edit_ammo_name);
                setEditAmmo(_local_edit_ammo);
                setEditAmmoWeight(_local_edit_ammo_weight);
            }
            setEditWeaponList(_local_edit_weapon_list);

            return;
        }


        for(let key1 in _local_edit_weapon_list){
            for(let key2 in editWeaponList){
                if(_local_edit_weapon_list[key1].id == editWeaponList[key2].id){
                    _local_edit_weapon_list[key1].value =editWeaponList[key2].value;
                }
            }
        }

        for(let key in _local_edit_weapon_list){
            if(_local_edit_weapon_list[key].id == _value.id)
                _local_edit_weapon_list[key].value = !_local_edit_weapon_list[key].value;
        }
            
        setEditName(_local_edit_name);
        setEditWeight(_local_edit_weight);
        setEditAttackMod(_local_edit_attack_mod);
        setEditDamage(_local_edit_damage);
        if(props.list_type != 'ranged'){
            setEditDefenseMod(_local_edit_defense_mod);
        }
        else if(props.list_type == 'ranged'){
            setEditAmmoName(_local_edit_ammo_name);
            setEditAmmo(_local_edit_ammo);
            setEditAmmoWeight(_local_edit_ammo_weight);
        }
        setEditWeaponList(_local_edit_weapon_list);
    }

    const getValueByID = (_weaponID, _list, _type) => {
        
        return GetListParameterByID(_weaponID, _list, _type);
    }

    const setValueByID = (_weaponID, _list, _setter, _newValue, _type) => {
        SetListParameterByID(_weaponID, _list, _setter, _newValue, _type);
    }


    const modifyName = (_weaponID, _newValue) => {
        API_modify_weapon_name(_weaponID, _newValue, props.list_type);
    }

    const tryModifyWeight = (_weaponID, _newValue) => {
        if(TryParse(_newValue, 'float'))
            modifyWeight(_weaponID, _newValue);

        SetListParameterByID(_weaponID, editWeight, setEditWeight, _newValue, 'float');
    }

    const modifyWeight = (_weaponID, _newValue) => {
        API_modify_weapon_weight(_weaponID, _newValue, props.list_type);
    }

    const tryModifyAttackMod = (_weaponID, _newValue) => {
        if(TryParse(_newValue, 'integer'))
            modifyAttackMod(_weaponID, _newValue);

        SetListParameterByID(_weaponID, editAttackMod, setEditAttackMod, _newValue, 'integer')
    }

    const modifyAttackMod = (_weaponID, _newValue) => {
        API_modify_melee_weapon_attack_mod(_weaponID, _newValue, props.list_type);
    }

    const tryModifyDefenseMod = (_weaponID, _newValue) => {
        if(TryParse(_newValue, 'integer'))
            modifyDefenseMod(_weaponID, _newValue);

        SetListParameterByID(_weaponID, editDefenseMod, setEditDefenseMod, _newValue, 'integer')
    }

    const modifyDefenseMod = (_weaponID, _newValue) => {
        API_modify_melee_weapon_defense_mod(_weaponID, _newValue, props.list_type);
    }
    
    const modifyAmmoName = (_weaponID, _newValue) => {
        API_modify_ammo_name(_weaponID, _newValue, props.list_type);
    }

    const tryModifyAmmo = (_weaponID, _newValue) => {
        if(TryParse(_newValue, 'integer'))
            modifyAmmo(_weaponID, _newValue);

        SetListParameterByID(_weaponID, editAmmo, setEditAmmo, _newValue, 'integer')
    }

    const modifyAmmo = (_weaponID, _newValue) => {
        API_modify_ammo(_weaponID, _newValue, props.list_type);
    }

    const tryModifyAmmoWeight = (_weaponID, _newValue) => {
        if(TryParse(_newValue, 'float'))
            modifyAmmoWeight(_weaponID, _newValue);

        SetListParameterByID(_weaponID, editAmmoWeight, setEditAmmoWeight, _newValue, 'float')
    }

    const modifyAmmoWeight = (_weaponID, _newValue) => {
        API_modify_ammo_weight(_weaponID, _newValue, props.list_type);
    }

    const tryModifyDamage = (_weaponID, _newValue) => {
        if(TryParse(_newValue, 'float'))
            modifyDamage(_weaponID, _newValue);

        SetListParameterByID(_weaponID, editDamage, setEditDamage, _newValue, 'float')
    }

    const modifyDamage = (_weaponID, _newValue) => {
        API_modify_damage(_weaponID, _newValue, props.list_type);
    }

    const getWeaponTypes = () => {
        let _weaponTypes = null;
        if(props.list_type == 'melee')
        {
            _weaponTypes = API_get_melee_fighting_types();
        }
        else if(props.list_type == 'ranged')
        {
            _weaponTypes = API_get_ranged_fighting_types();
        }
        return _weaponTypes;
    }

    /*const getHandTypes = () => {
        let _handTypes = null;
        if(props.list_type == 'melee')
        {
            _handTypes = API_get_hand_types();
        }
        return _handTypes;
    }*/
    
    const getFightingTypeByID = (_fighting_type_id) => {
        let _weaponType = null;

        if(props.list_type != 'ranged')
        {
            _weaponType = API_get_melee_fighting_type_by_id(_fighting_type_id);
        }
        else if(props.list_type == 'ranged')
        {
            _weaponType = API_get_ranged_fighting_type_by_id(_fighting_type_id);
        }
        else
        {
            //console.log('none_type');
        }
        return _weaponType;
    }

    const handleFightingTypeSelected = (event, _weaponID) => {
        let _weapon_type = JSON.parse(event.target.value);
        if(props.list_type != 'unarmed')
        {
            API_switch_weapon_type(_weapon_type.id, _weaponID, props.list_type);
        }
    }

    const handleWeaponLeadAttrSelected = (event, _weaponID) => {
        let _lead_attribute = JSON.parse(event.target.value);
        API_switch_lead_attribute(_lead_attribute, _weaponID, props.list_type);
    }

    const addNewWeapon = () => {
        API_add_new_weapon(props.list_type);
    }

    const clickRemoveWeaponHandler = (_weapon) => {
        API_remove_weapon(_weapon, props.list_type);
    }

    const clickMoveWeaponHandler = (_weapon, _direction) => {
        API_move_weapon(_weapon, _direction, props.list_type);
    }

    const getCellWidth = (_val, _add_val, _type) => {
        if(props.list_type != _type){
            return _val;
        }
        else if(props.list_type == _type){
            return _add_val+_val;
        }
    }

    return ( 
        <div>
            <table className={table.table_editable} style={{marginLeft: 0, marginRight: 0}}>
                <tr style={{background: 'transparent'}}>
                    <td className={table.cell_header} style={{width: 346, minWidth: 346, textAlign: 'left', paddingLeft: 5}}>{get_weapon_header()}</td>
                    {/* props.list_type == 'melee' &&
                        <td className={table.cell_header} style={{width: 90, minWidth:90}}>
                            {API_get_equipment_header_labels().hand}
                        </td>
                    */}
                    <td className={table.cell_header} style={{width: 140, minWidth: 140}}>{API_get_std_header_labels().type}</td>
                    <td className={table.cell_header} colspan={2}>{API_get_std_header_labels().lead_attribute}</td>
                    <td className={table.cell_header} style={{width: 80, minWidth:80}}>{API_get_std_header_labels().weight}</td>
                    { props.list_type == 'ranged' &&
                        <td className={table.cell_header} style={{width: 76, minWidth: 76, textAlign: 'left', paddingLeft: 5}}>{API_get_equipment_header_labels().ammo_name}</td>
                    }
                    { props.list_type == 'ranged' &&
                        <td className={table.cell_header} style={{width: 80, minWidth:80}}>{API_get_equipment_header_labels().ammo}</td>
                    }
                    { props.list_type == 'ranged' &&
                        <td className={table.cell_header} style={{width: 80, minWidth:80}}>{API_get_equipment_header_labels().ammo_weight}</td>
                    }
                    <td className={table.cell_header} style={{width: 80, minWidth: 80}}>
                        { props.list_type != 'ranged' &&
                            API_get_equipment_header_labels().attack_mod
                        }
                        { props.list_type == 'ranged' &&
                            API_get_equipment_header_labels().precision_mod
                        }
                    </td>
                    { props.list_type != 'ranged' &&
                        <td className={table.cell_header} style={{width: 80, minWidth:80}}>{API_get_equipment_header_labels().defense_mod}</td>
                     }
                    <td className={table.cell_header} style={{width: getCellWidth(80, 40, 'ranged'), minWidth: getCellWidth(80, 40, 'ranged')}}>
                        { props.list_type != 'ranged' &&
                            API_get_equipment_header_labels().attack
                        }
                        { props.list_type == 'ranged' &&
                            API_get_equipment_header_labels().precision
                        }
                    </td>
                    { props.list_type != 'ranged' &&
                        <td className={table.cell_header} style={{width: 80, minWidth:80}}>{API_get_equipment_header_labels().defense}</td>
                    }
                    <td className={table.cell_header} style={{width: 120, minWidth: 120}}>
                        { props.list_type != 'ranged' &&
                            API_get_equipment_header_labels().attack_dice
                        }
                        { props.list_type == 'ranged' &&
                            API_get_equipment_header_labels().precision_dice
                        }
                    </td>
                    { props.list_type != 'ranged' &&
                        <td className={table.cell_header} style={{width: 120, minWidth:120}}>{API_get_equipment_header_labels().defense_dice}</td>
                    }
                    <td  className={table.cell_header} style={{width: 80, minWidth: 80}}>{API_get_equipment_header_labels().damage}</td>
                    <td className={table.cell_header} style={{width: 120, minWidth: 120}} colspan={5}></td>
                </tr>
                {Object.entries(get_weapon_list()).map(([key, value], index) => (
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: 5}}>
                            {(index == 0 && props.list_type == 'unarmed') && 
                                API_get_equipment_header_labels().fist_fight
                            }
                            {((index > 0 || props.list_type != 'unarmed') && (!getValueByID(value.id, editWeaponList, 'bool'))) && 
                                value.display_name
                            }
                            {((index > 0 || props.list_type != 'unarmed') && (getValueByID(value.id, editWeaponList, 'bool'))) && 
                                <input className={field.input_field_text} style={{marginLeft: 0, width: 265, minWidth: 265}} value={getValueByID(value.id, editName, 'text')} onChange={(e) => setValueByID(value.id, editName, setEditName, e.target.value, 'text')} onBlur={(e) => modifyName(value.id, e.target.value)} />
                            }
                        </td>
                        {/* props.list_type == 'melee' &&
                            <td className={table.cell_greyed_out}>
                                {(!getValueByID(value.id, editWeaponList, 'bool') || props.list_type == 'unarmed') && 
                                    getHandTypeByID(value.hand_type_id).display_name
                                }
                                {(getValueByID(value.id, editWeaponList, 'bool') && props.list_type != 'unarmed') && 
                                    <select value={JSON.stringify(getHandTypeByID(value.hand_type_id))} onChange={(e) => handleHandTypeSelected(e, value.id)}>
                                        {Object.entries(getHandTypes()).map(([key, value]) => (
                                            <option key={value.id} value={JSON.stringify(value)}>
                                                {value.display_name}
                                            </option>
                                        ))}
                                    </select>
                                }
                            </td>
                        */}
                        <td className={table.cell_greyed_out}>
                            {(!getValueByID(value.id, editWeaponList, 'bool') || props.list_type == 'unarmed') && 
                                getFightingTypeByID(value.fighting_type_id).display_name
                            }
                            {(getValueByID(value.id, editWeaponList, 'bool') && props.list_type != 'unarmed') && 
                                <select value={JSON.stringify(getFightingTypeByID(value.fighting_type_id))} onChange={(e) => handleFightingTypeSelected(e, value.id)}>
                                    {Object.entries(getWeaponTypes()).slice(1).map(([key, value]) => (
                                        <option key={value.id} value={JSON.stringify(value)}>
                                            {value.display_name}
                                        </option>
                                    ))}
                                </select>
                            }
                        </td>
                        <td className={table.cell_greyed_out} style={{width: 62, minWidth: 62}}>
                            {(!getValueByID(value.id, editWeaponList, 'bool')) && 
                                API_get_attribute_by_id(value.lead_attr_id).abbreviation
                            }
                            {(getValueByID(value.id, editWeaponList, 'bool')) && 
                                <select value={JSON.stringify(GetElementById(value.lead_attr_id, API_get_lead_attribute_tags(), 'attribute_id'))} onChange={(e) => handleWeaponLeadAttrSelected(e, value.id)}>
                                    {Object.entries(API_get_lead_attribute_tags()).map(([key, value]) => (
                                        <option key={value.id} value={JSON.stringify(value)}>
                                            {value.display_name}
                                        </option>
                                    ))}
                                </select>
                            }
                        </td>
                        <td style={{width: 40, minWidth: 40}}>{API_get_attribute_by_id(value.lead_attr_id).value}</td>
                        <td className={table.cell_greyed_out}>
                            {(!getValueByID(value.id, editWeaponList, 'bool') || props.list_type == 'unarmed') && 
                                value.weight
                            }
                            {(getValueByID(value.id, editWeaponList, 'bool') && props.list_type != 'unarmed') && 
                                <input type='number' className={field.input_field_number} style={{width: 60}} value={getValueByID(value.id, editWeight, 'float')} onChange={(e) => tryModifyWeight(value.id, e.target.value)} onBlur={(e) => modifyWeight(value.id, e.target.value)} />
                            }
                        </td>
                        { props.list_type == 'ranged' &&
                            <td style={{textAlign: 'left'}}>
                                {(!getValueByID(value.id, editWeaponList, 'bool')) && 
                                    <p style={{paddingLeft: 5}}>{value.ammo_name}</p>
                                }
                                {(getValueByID(value.id, editWeaponList, 'bool')) && 
                                    <input className={field.input_field_text} style={{width: 60}} value={getValueByID(value.id, editAmmoName, 'text')} onChange={(e) => setValueByID(value.id, editAmmoName, setEditAmmoName, e.target.value, 'text')} onBlur={(e) => modifyAmmoName(value.id, e.target.value)} />
                                }
                            </td>
                        }
                        { props.list_type == 'ranged' &&
                            <td>
                                {(!getValueByID(value.id, editWeaponList, 'bool')) && 
                                    value.ammo
                                }
                                {(getValueByID(value.id, editWeaponList, 'bool')) && 
                                    <input type='number' className={field.input_field_number} style={{width: 60}} value={getValueByID(value.id, editAmmo, 'integer')} onChange={(e) => tryModifyAmmo(value.id, e.target.value)} onBlur={(e) => modifyAmmo(value.id, e.target.value)} />
                                }
                            </td>
                        }
                        { props.list_type == 'ranged' &&
                            <td>
                                {(!getValueByID(value.id, editWeaponList, 'bool')) && 
                                    value.ammo_weight
                                }
                                {(getValueByID(value.id, editWeaponList, 'bool')) && 
                                    <input type='number' className={field.input_field_number} style={{width: 60}} value={getValueByID(value.id, editAmmoWeight, 'float')} onChange={(e) => tryModifyAmmoWeight(value.id, e.target.value)} onBlur={(e) => modifyAmmoWeight(value.id, e.target.value)} />
                                }
                            </td>
                        }
                        <td>
                            {(!getValueByID(value.id, editWeaponList, 'bool') && props.list_type != 'ranged') && 
                                value.attack_mod
                            }
                            {(!getValueByID(value.id, editWeaponList, 'bool') && props.list_type == 'ranged') && 
                                value.precision_mod
                            }
                            {(getValueByID(value.id, editWeaponList, 'bool')) && 
                                <input type='number' className={field.input_field_number} style={{width: 60}} value={getValueByID(value.id, editAttackMod, 'integer')} onChange={(e) => tryModifyAttackMod(value.id, e.target.value)} onBlur={(e) => modifyAttackMod(value.id, e.target.value)} />
                            }
                        </td>
                        { props.list_type != 'ranged' &&
                            <td>
                                {(!getValueByID(value.id, editWeaponList, 'bool')) && 
                                    value.defense_mod
                                }
                                {(getValueByID(value.id, editWeaponList, 'bool')) && 
                                    <input type='number' className={field.input_field_number} style={{width: 60}} value={getValueByID(value.id, editDefenseMod, 'integer')} onChange={(e) => tryModifyDefenseMod(value.id, e.target.value)} onBlur={(e) => modifyDefenseMod(value.id, e.target.value)} />
                                }
                            </td>
                        }
                        <td>
                            { props.list_type != 'ranged' &&
                                value.attack
                            }
                            { props.list_type == 'ranged' &&
                                value.precision
                            }
                        </td>
                            { props.list_type != 'ranged' &&
                                <td>{value.defense}</td>
                            }
                        <td>
                            { props.list_type != 'ranged' &&
                                <button className={btn.dice_btn} onClick={() => click_roll_attack_dice(value.attack_dice, value.damage)}>{API_get_dice_list_string(value.attack_dice)}</button>
                            }
                            { props.list_type == 'ranged' &&
                                <button className={btn.dice_btn} onClick={() => click_roll_attack_dice(value.precision_dice, value.damage)}>{API_get_dice_list_string(value.precision_dice)}</button>
                            }
                        </td>
                        { props.list_type != 'ranged' &&
                            <td>
                                <button className={btn.dice_btn} onClick={() => click_roll_defense_dice(value.defense_dice)}>{API_get_dice_list_string(value.defense_dice)}</button>
                            </td>
                        }
                        <td>
                            {(!getValueByID(value.id, editWeaponList, 'bool')) && 
                                'x' + value.damage
                            }
                            {(getValueByID(value.id, editWeaponList, 'bool')) && 
                                <input type='number' className={field.input_field_number} style={{width: 60}} value={getValueByID(value.id, editDamage, 'float')} onChange={(e) => tryModifyDamage(value.id, e.target.value)} onBlur={(e) => modifyDamage(value.id, e.target.value)} />
                            }
                        </td>
                        <td style={{width: 24, minWidth: 24, borderRight: '0px solid black'}}>
                            {(index > 0 || props.list_type != 'unarmed') && 
                                <button className={btn.button_icon} onClick={() => clickEditWeaponsHandler(value)}>E</button>
                            }
                        </td>
                        <td style={{width: 24, minWidth: 24, borderLeft: '0px solid black', borderRight: '0px solid black'}}>
                            {(index > 0 || props.list_type != 'unarmed') && 
                                <button className={btn.button_icon} onClick={() => clickMoveWeaponHandler(value, 'up')}>+</button>
                            }
                        </td>
                        <td style={{width: 24, minWidth: 24, borderLeft: '0px solid black', borderRight: '0px solid black'}}>
                            {(index > 0 || props.list_type != 'unarmed') && 
                                <button className={btn.button_icon} onClick={() => clickMoveWeaponHandler(value, 'down')}>-</button>
                            }
                        </td>
                        <td style={{width: 24, minWidth: 24, borderLeft: '0px solid black', borderRight: '0px solid black'}}>
                        </td>
                        <td style={{width: 24, minWidth: 24, borderLeft: '0px solid black'}}>
                            {(index > 0 || props.list_type != 'unarmed') && 
                                <button className={btn.button_icon} onClick={() => clickRemoveWeaponHandler(value)}>x</button>
                            }
                        </td>
                    </tr>
                ))}
                <tr>
                    <td style={{textAlign: 'right', paddingLeft: 5}}>
                        <button className={btn.button_icon} onClick={() => addNewWeapon()}>+</button>
                    </td>
                    {/* props.list_type == 'melee' &&
                        <td className={table.cell_greyed_out}></td>
                    */}
                    <td className={table.cell_greyed_out}></td>
                    <td className={table.cell_greyed_out} style={{width: 62, minWidth: 62}}></td>
                    <td style={{width: 40, minWidth: 40}}></td>
                    <td className={table.cell_greyed_out}></td>
                    { props.list_type == 'ranged' &&
                        <td></td>
                    }
                    { props.list_type == 'ranged' &&
                        <td></td>
                    }
                    { props.list_type == 'ranged' &&
                        <td></td>
                    }
                    <td></td>
                    <td></td>
                    { props.list_type != 'ranged' &&
                        <td></td>
                    }
                    <td></td>
                    { props.list_type != 'ranged' &&
                        <td></td>
                    }
                    <td></td>
                    { props.list_type != 'ranged' &&
                        <td></td>
                    }
                    <td colspan={5}></td>
                </tr>
            </table>
     </div>
    );
}
 
export {
    WeaponTablePage
};