import { useState, useContext, createContext, useEffect } from 'react'
import {APIContext, APIContextProvider} from '../API/API'
import {UserDataContext, UserDataContextProvider} from '../API/UserDataContext'
import {CampaignContext, CampaignContextProvider} from '../API/CampaignContext'
import {ToolDataContext, ToolDataContextProvider} from '../API/ToolDataContext'

import {
    REST_get_server_url
} from './ComParamters'
import{
    BACKEND_set_user,
    BACKEND_get_user,
    BACKEND_set_character_id,
    BACKEND_get_character_id
} from '../Backend/Parameters/UserData'
import{
    BACKEND_get_tool_data,
    BACKEND_set_tool_data,
    BACKEND_get_tool_settings,
    BACKEND_set_tool_settings
} from '../Backend/Parameters/ToolData'
import{
    BACKEND_set_campaign_id,
    BACKEND_get_campaign_id,
    BACKEND_set_subscribed_user,
    BACKEND_get_subscribed_user
} from '../Backend/Parameters/CampaignParameters'
import{
    INIT_get_campaign_id
} from '../Backend/ParametersInit/CampaignInit'
import{
    INIT_get_character_id
} from '../Backend/ParametersInit/UserDataInit'
import{
    PrepareCharacterForDownload,
    LoadCharacter,
    PrepareCampaignForDownload,
    LoadCampaign
} from '../Backend/Functions/IOFunctions'
import { INIT_get_guest_user } from '../Backend/ParametersInit/UserDataInit'
import {IsNullOrEmpty} from '../API/Functions/GeneralFunctions'

const RestUserContext = createContext();

const RestUserContextProvider = ({children}) => {
    
    const {
        API_user_login,
        API_full_GUI_update,
        API_switch_language
    } = useContext(APIContext);

    const {
        API_set_user,
        API_set_character_id,
        API_get_remote_connect_message,
        API_set_remote_connect_message
    } = useContext(UserDataContext);

    const {
        API_set_campaign_id,
        API_set_remote_campaign_message,
        API_get_remote_campaign_message,
        API_set_remote_subscribed_user_message,
        API_get_remote_subscribed_user_message,
        API_set_subscribed_user,
        API_get_subscribed_user
    } = useContext(CampaignContext);

    const {
        API_set_tool_data,
        API_set_tool_settings
    } = useContext(ToolDataContext);

    
    

    const request_post = 'POST';
    const content_json = 'application/json';
    const timeout = 4000;

    const triggerTimeOutMessage = (_message_setter) => {
        _message_setter('[timeout]: No connection to backend');
    }

    const try_parse_json = (_jsonString, _message_setter) => {
        var _data = null;
        try {
            _data = JSON.parse(_jsonString);
        } catch (error) {
            _message_setter('Response bad JSON format');
            return {success: false, data: {}}
        }
        return {success: true, data: _data}
    }

    const set_xhr = (route, content_type, _message_setter) => {
        const xhr = new XMLHttpRequest();

        xhr.open(request_post, REST_get_server_url() + route);
        xhr.setRequestHeader('Content-Type', content_type);
        xhr.timeout = timeout;
        xhr.ontimeout = function() {triggerTimeOutMessage(_message_setter);};
        return xhr;
    }

    const std_response_handler = (_status, _resp, _message_setter, _success_callback = null) => {
        console.log('std_response_handler')
        console.log(_status)
        console.log(_resp)
        console.log(_message_setter)
        console.log(_success_callback)
        if(_status == 200)
        {
            if(_resp.success)
            {
                if(_success_callback != null)
                {
                    console.log('_success_callback')
                    console.log(_success_callback)
                    _success_callback();
                }
            }

            if(_message_setter != null)
                _message_setter(_resp.message);
        }
        else{
            if(_message_setter != null)
            {
                _message_setter('Bad Status: ' + _status);
                //API_set_remote_connect_message('Bad Status: ' + _status);
            }
        }
    }

    const user_login = (_resp, _role, _response_status, _message_setter) => {
        let _user = {name: '', role: _role}
        if(!_resp.hasOwnProperty('validation')){bad_response_message(_message_setter); return;}
        if(!_resp.hasOwnProperty('user')){bad_response_message(_message_setter); return;}
        if(!_resp.user.hasOwnProperty('name')){bad_response_message(_message_setter); return;}

        if(_resp.validation)
            _user = { name: _resp.user.name, role: _role, characters: [], campaigns: []}

        API_user_login(_user, _resp.validation, _response_status);

        if(_resp.validation)
        {
            if(_role == 'player')
            {
                REST_apply_config();
                REST_load_characters();
            }
            else if(_role == 'game_master')
            {
                //REST_apply_config(_message_setter);
                REST_apply_config();
                REST_load_campaigns()
            }
        }
    }

    const load_characters = (_characters) => {
        BACKEND_set_user({...BACKEND_get_user(), characters: {..._characters}});
        API_set_user();
    }

    const load_campaigns = (_campaigns) => {
        BACKEND_set_user({...BACKEND_get_user(), campaigns: {..._campaigns}});
        API_set_user();
    }

    const load_subscribed_user = (_subscribed_user) => {
        BACKEND_set_subscribed_user(_subscribed_user);
        API_set_subscribed_user();
    }

    const load_character = (_character) => {
        LoadCharacter(_character);
        API_full_GUI_update();
    }

    const load_campaign = (_campaign) => {
        LoadCampaign(_campaign);
        API_full_GUI_update();
        REST_load_subscribed_users(_campaign.id);
        REST_load_campaign_characters(_campaign.id);
    }

    const apply_config = (_config, _role, _message_setter) => {
        if(!_config.hasOwnProperty('last_character_id')){bad_response_message(_message_setter); return;}
        if(!_config.hasOwnProperty('last_campaign_id')){bad_response_message(_message_setter); return;}
        if(_role == 'player')
        {
            BACKEND_set_character_id(_config.last_character_id);
            BACKEND_set_campaign_id(_config.last_campaign_id);
            API_set_character_id();
            API_set_campaign_id();
            REST_load_assigned_campaigns();
        }
        else if(_role == 'game_master')
        {
            BACKEND_set_character_id(_config.last_character_id);
            BACKEND_set_campaign_id(_config.last_campaign_id);
            API_set_character_id();
            API_set_campaign_id();
            REST_load_subscribed_users(_config.last_campaign_id);
            REST_load_campaign_characters(_config.last_campaign_id);
            //REST_load_campaign_character(_config.last_campaign_id);
            //REST_load_character(_config.last_campaign_id, () => apply_config(_config.last_campaign_id, _message_setter))
        }
        if(!_config.hasOwnProperty('tool_settings')){bad_response_message(_message_setter); return;}
        if(!_config.tool_settings.hasOwnProperty('foundry_url')){bad_response_message(_message_setter); return;}
        BACKEND_set_tool_settings({...BACKEND_get_tool_settings(), foundry_url: _config.tool_settings.foundry_url});
        API_set_tool_settings();
        
        if(!_config.hasOwnProperty('tool_data')){bad_response_message(_message_setter); return;}
        if(!_config.tool_data.hasOwnProperty('language')){bad_response_message(_message_setter); return;}
        API_switch_language(_config.tool_data.language);
    }

    const load_config = (_config, _role, _message_setter) => {
        if(!_config.hasOwnProperty('last_character_id')){bad_response_message(_message_setter); return;}
        if(!_config.hasOwnProperty('last_campaign_id')){bad_response_message(_message_setter); return;}
        
        if(_role == 'player')
        {
            REST_load_character(_config.last_character_id, () => apply_config(_config, _role, _message_setter))
        }
        else if(_role == 'game_master')
        {
            //apply_config(_config, _role, _message_setter) //For test purposes
            REST_load_campaign(_config.last_campaign_id, () => apply_config(_config, _role, _message_setter))
        }
    }

    const save_character = (_character_id) => {
        BACKEND_set_character_id(_character_id)
        API_set_character_id()
        REST_load_characters();
    }

    const save_campaign = (_campaign_id) => {
        BACKEND_set_campaign_id(_campaign_id)
        API_set_campaign_id()
        REST_load_campaigns();
    }

    const bad_response_message = (_message_setter) => {
        _message_setter("Bad response from backend")
        //API_set_remote_connect_message("Bad response from backend");
    }

    const block_guest_user = (_message_setter) => {
        let _guest_user_check = BACKEND_get_user()
        if(_guest_user_check != null)
        {
            if(_guest_user_check.name == INIT_get_guest_user().name){
                _message_setter("No remote functionality as guest")
                return false;
            }
        }
        return true;
    }

    const empty_character_name_message = () => {
        API_set_remote_connect_message("Character name is empty");
    }

    const empty_campaign_name_message = () => {
        API_set_remote_campaign_message("Campaign name is empty");
    }

    const send_post_request = (xhr, data, _message_setter) => {
        if(!block_guest_user(_message_setter)){return};
        xhr.send(data);
    }

    const REST_login_user = (_user, _password, _role) => {
        let _message_setter = API_set_remote_connect_message;
        const route = '/login';
        const data = JSON.stringify({name: _user, password: _password});
        const xhr = set_xhr(route, content_json, _message_setter)
        xhr.onload = function() {
            let _message_setter = API_set_remote_connect_message;
            let _json_parse_result = try_parse_json(xhr.responseText);
            if(!_json_parse_result.success){return;}
            if(!_json_parse_result.hasOwnProperty('data')){return;}
            let _resp = _json_parse_result.data;
            std_response_handler(xhr.status, _resp, _message_setter, () => user_login(_resp, _role, xhr.status, _message_setter))
        };
        send_post_request(xhr, data, _message_setter);
        //xhr.send(data);
    }

    const REST_create_new_user = (_user, _password) => {
        let _message_setter = API_set_remote_connect_message
        const route = '/create_user';
        const data = JSON.stringify({name: _user, password: _password});
        const xhr = set_xhr(route, content_json, _message_setter)
        xhr.onload = function() {
            let _message_setter = API_set_remote_connect_message
            let _json_parse_result = try_parse_json(xhr.responseText);
            if(!_json_parse_result.success){return;}
            if(!_json_parse_result.hasOwnProperty('data')){bad_response_message(_message_setter); return;}
            let _resp = _json_parse_result.data;
            std_response_handler(xhr.status, _resp, _message_setter)
        };

        send_post_request(xhr, data, _message_setter);
        //xhr.send(data);
    }

    const REST_load_characters = () => {
        let _message_setter = API_set_remote_connect_message
        let _user = BACKEND_get_user()
        const route = '/get_characters';
        const data = JSON.stringify({user: _user});
        const xhr = set_xhr(route, content_json, _message_setter)
        xhr.onload = function() {
            let _message_setter = API_set_remote_connect_message
            let _json_parse_result = try_parse_json(xhr.responseText);
            if(!_json_parse_result.success){return;}
            if(!_json_parse_result.hasOwnProperty('data')){bad_response_message(_message_setter); return;}
            let _resp = _json_parse_result.data;
            if(!_resp.hasOwnProperty('list')){bad_response_message(_message_setter); return;}
            std_response_handler(xhr.status, _resp, null, () => load_characters(_resp.list));
        };

        send_post_request(xhr, data, _message_setter);
        //xhr.send(data);
    }

    const REST_save_character = () => {
        let _message_setter = API_set_remote_connect_message
        let _user_name = BACKEND_get_user().name
        let _character_id = BACKEND_get_character_id()
        let _character = PrepareCharacterForDownload()
        const route = '/save_character';
        const data = JSON.stringify({user_name: _user_name, character_id: _character_id, character: _character});
        const base_stats = _character.base_stats;
        const character_name = base_stats.name;
        if(IsNullOrEmpty(character_name)){empty_character_name_message(); return;}


        const xhr = set_xhr(route, content_json, _message_setter)
        xhr.onload = function() {
            let _message_setter = API_set_remote_connect_message
            let _json_parse_result = try_parse_json(xhr.responseText);
            if(!_json_parse_result.success) {return;}
            if(!_json_parse_result.hasOwnProperty('data')){bad_response_message(_message_setter); return;}
            let _resp = _json_parse_result.data;
            if(!_resp.hasOwnProperty('created_id')){bad_response_message(_message_setter); return;}
            std_response_handler(xhr.status, _resp, _message_setter, () => save_character(_resp.created_id));
        };

        send_post_request(xhr, data, _message_setter);
        //xhr.send(data);
    }

    const REST_load_character = (_character_id, _success_callback = null) => {
        let _message_setter = API_set_remote_connect_message
        let _user_name = BACKEND_get_user().name
        const route = '/get_character_by_id'
        console.log(_character_id)
        const data = JSON.stringify({user_name: _user_name, character_id: _character_id});
        const xhr = set_xhr(route, content_json, _message_setter)
        xhr.onload = function() {
            let _message_setter = API_set_remote_connect_message
            let _json_parse_result = try_parse_json(xhr.responseText);
            if(!_json_parse_result.success){return;}
            if(!_json_parse_result.hasOwnProperty('data')){bad_response_message(_message_setter); return;}
            let _resp = _json_parse_result.data;

            if(!_resp.hasOwnProperty('element')){bad_response_message(_message_setter); return;}

            //let _json_parse_character_result = try_parse_json(_resp.character);
            
            std_response_handler(xhr.status, _resp, _message_setter, () => load_character(_resp.element));

            console.log("REST_load_character --- - -")
            console.log(_character_id)
            console.log(BACKEND_get_character_id())
            if(/*_resp.success &&*/ _success_callback != null)
                _success_callback();
        };

        send_post_request(xhr, data, _message_setter);
        //xhr.send(data);
    }

    const REST_load_campaign_character = (_character_owner, _character_id, _success_callback = null) => {
        //console.log("nkl REST_load_character lkm")
        let _message_setter = API_set_remote_connect_message
        //let _user_name = BACKEND_get_user().name
        const route = '/get_character_by_id'
        //console.log(_character_id)
        const data = JSON.stringify({user_name: _character_owner, character_id: _character_id});
        const xhr = set_xhr(route, content_json, _message_setter)
        xhr.onload = function() {
            let _message_setter = API_set_remote_connect_message
            let _json_parse_result = try_parse_json(xhr.responseText);
            if(!_json_parse_result.success){return;}
            if(!_json_parse_result.hasOwnProperty('data')){bad_response_message(_message_setter); return;}
            let _resp = _json_parse_result.data;

            if(!_resp.hasOwnProperty('element')){bad_response_message(_message_setter); return;}

            //let _json_parse_character_result = try_parse_json(_resp.character);
            
            std_response_handler(xhr.status, _resp, _message_setter, () => load_character(_resp.element));

            console.log("REST_load_character --- - -")
            console.log(_character_id)
            console.log(BACKEND_get_character_id())
            if(/*_resp.success &&*/ _success_callback != null)
                _success_callback();
        };

        send_post_request(xhr, data, _message_setter);
        //xhr.send(data);
    }

    const REST_delete_character = (_character_id) => {
        let _message_setter = API_set_remote_connect_message
        let _user_name = BACKEND_get_user().name
        const route = '/delete_character_by_id';
        const data = JSON.stringify({user_name: _user_name, character_id: _character_id});
        const xhr = set_xhr(route, content_json, _message_setter)
        xhr.onload = function() {
            let _message_setter = API_set_remote_connect_message
            let _json_parse_result = try_parse_json(xhr.responseText);
            if(!_json_parse_result.success){return;}
            if(!_json_parse_result.hasOwnProperty('data')){bad_response_message(_message_setter); return;}
            let _resp = _json_parse_result.data;

            std_response_handler(xhr.status, _resp, _message_setter, () => REST_load_characters());
        };

        send_post_request(xhr, data, _message_setter);
        //xhr.send(data);
    }

    const REST_apply_config = () => {
        let _message_setter = API_set_remote_connect_message
        let _user_name = BACKEND_get_user().name
        let _user_role = BACKEND_get_user().role
        const route = '/get_config';
        const data = JSON.stringify({user_name: _user_name});
        const xhr = set_xhr(route, content_json, _message_setter)

        xhr.onload = function() {
            let _message_setter = API_set_remote_connect_message
            let _json_parse_result = try_parse_json(xhr.responseText);
            if(!_json_parse_result.success) {return;}
            if(!_json_parse_result.hasOwnProperty('data')){bad_response_message(_message_setter); return;}
            let _resp = _json_parse_result.data;
            if(!_resp.hasOwnProperty('config')){bad_response_message(_message_setter); return;}
            
            std_response_handler(xhr.status, _resp, null, () => load_config(_resp.config, _user_role, _message_setter));
        };

        send_post_request(xhr, data, _message_setter);
    }

    const REST_set_config = (_user_name) => {
        let _message_setter = API_set_remote_connect_message
        let _tool_data = BACKEND_get_tool_data();
        let _tool_settings = BACKEND_get_tool_settings();
        const route = '/set_config';
        const data = JSON.stringify({user_name: _user_name, tool_data: _tool_data, tool_settings: _tool_settings});
        const xhr = set_xhr(route, content_json, _message_setter)

        xhr.onload = function() {
            let _message_setter = API_set_remote_connect_message
            let _json_parse_result = try_parse_json(xhr.responseText);
            if(!_json_parse_result.success) {return;}
            if(!_json_parse_result.hasOwnProperty('data')){bad_response_message(_message_setter); return;}
            let _resp = _json_parse_result.data;
            
            std_response_handler(xhr.status, _resp, _message_setter);
        };

        send_post_request(xhr, data, _message_setter);
    }

    const REST_load_campaign_characters = (_campaignID) => {
        let _message_setter = API_set_remote_connect_message
        let _user_name = BACKEND_get_user().name
        console.log("load_campaign_characters")
        const route = '/load_campaign_characters';
        const data = JSON.stringify({campaign_owner: _user_name, campaign_id: _campaignID});
        const xhr = set_xhr(route, content_json, _message_setter)
        xhr.onload = function() {
            let _message_setter = API_set_remote_connect_message
            let _json_parse_result = try_parse_json(xhr.responseText);
            if(!_json_parse_result.success){return;}
            if(!_json_parse_result.hasOwnProperty('data')){return;}
            let _resp = _json_parse_result.data;
            if(!_resp.hasOwnProperty('list')){bad_response_message(_message_setter); return;}
            console.log("Load it")
            console.log(_resp.list)
            std_response_handler(xhr.status, _resp, _message_setter, () => load_characters(_resp.list))
        };
        send_post_request(xhr, data, _message_setter);
        //xhr.send(data);
    }

    /*const REST_unsubscribe_character_from_campaign = (_characterOwner, _characterID, _campaignOwner, _campaignID) => {
        let _message_setter = API_set_remote_campaign_message
        let _user_name = BACKEND_get_user().name
        const route = '/unsubscribe_character_from_campaign';
        const data = JSON.stringify({character_owner: _characterOwner, character_id: _characterID, campaignID: _campaignID});
        const xhr = set_xhr(route, content_json, _message_setter)
        xhr.onload = function() {
            let _message_setter = API_set_remote_campaign_message
            let _json_parse_result = try_parse_json(xhr.responseText);
            if(!_json_parse_result.success){return;}
            if(!_json_parse_result.hasOwnProperty('data')){return;}
            let _resp = _json_parse_result.data;
            if(!_resp.hasOwnProperty('list')){bad_response_message(_message_setter); return;}
            std_response_handler(xhr.status, _resp, _message_setter, () => load_characters(_resp.list))
        };
        send_post_request(xhr, data, _message_setter);
        //xhr.send(data);
    }*/

    const REST_load_campaigns = () => {
        let _message_setter = API_set_remote_campaign_message
        let _user = BACKEND_get_user()
        const route = '/get_campaigns';
        const data = JSON.stringify({user: _user});
        const xhr = set_xhr(route, content_json, _message_setter)
        xhr.onload = function() {
            let _message_setter = API_set_remote_campaign_message
            let _json_parse_result = try_parse_json(xhr.responseText);
            if(!_json_parse_result.success){return;}
            if(!_json_parse_result.hasOwnProperty('data')){bad_response_message(_message_setter); return;}
            let _resp = _json_parse_result.data;
            if(!_resp.hasOwnProperty('list')){bad_response_message(_message_setter); return;}
            std_response_handler(xhr.status, _resp, null, () => load_campaigns(_resp.list));
        };

        send_post_request(xhr, data, _message_setter);
        //xhr.send(data);
    }

    const REST_save_campaign = () => {
        let _message_setter = API_set_remote_campaign_message
        let _user_name = BACKEND_get_user().name
        let _campaign_id = BACKEND_get_campaign_id()
        let _campaign = PrepareCampaignForDownload()
        const route = '/save_campaign';

        const data = JSON.stringify({user_name: _user_name, campaign_id: _campaign_id, campaign: _campaign});
        const campaign_name = _campaign.name;
        if(IsNullOrEmpty(campaign_name)){empty_campaign_name_message(); return;}

        const xhr = set_xhr(route, content_json, _message_setter)
        xhr.onload = function() {
            let _message_setter = API_set_remote_campaign_message
            let _json_parse_result = try_parse_json(xhr.responseText);
            if(!_json_parse_result.success) {return;}
            if(!_json_parse_result.hasOwnProperty('data')){bad_response_message(_message_setter); return;}
            let _resp = _json_parse_result.data;
            if(!_resp.hasOwnProperty('created_id')){bad_response_message(_message_setter); return;}

            std_response_handler(xhr.status, _resp, _message_setter, () => save_campaign(_resp.created_id));
        };

        send_post_request(xhr, data, _message_setter);
        //xhr.send(data);
    }

    const REST_load_campaign = (_campaign_id, _success_callback = null) => {
        let _message_setter = API_set_remote_campaign_message
        let _user_name = BACKEND_get_user().name
        const route = '/get_campaign_by_id'
        const data = JSON.stringify({user_name: _user_name, campaign_id: _campaign_id});
        const xhr = set_xhr(route, content_json, _message_setter)
        xhr.onload = function() {
            let _message_setter = API_set_remote_campaign_message
            let _json_parse_result = try_parse_json(xhr.responseText);
            if(!_json_parse_result.success){return;}
            if(!_json_parse_result.hasOwnProperty('data')){bad_response_message(_message_setter); return;}
            let _resp = _json_parse_result.data;

            if(!_resp.hasOwnProperty('element')){bad_response_message(_message_setter); return;}

            //let _json_parse_character_result = try_parse_json(_resp.campaign);
            
            if(_resp.success && _success_callback != null)
                _success_callback();
            
            std_response_handler(xhr.status, _resp, _message_setter, () => load_campaign(_resp.element));
        };

        send_post_request(xhr, data, _message_setter);
        //xhr.send(data);
    }

    const REST_delete_campaign = (_campaign_id) => {

        let _message_setter = API_set_remote_campaign_message
        let _user_name = BACKEND_get_user().name
        const route = '/delete_campaign_by_id';
        const data = JSON.stringify({user_name: _user_name, campaign_id: _campaign_id});
        const xhr = set_xhr(route, content_json, _message_setter)
        xhr.onload = function() {

            let _message_setter = API_set_remote_campaign_message
            let _json_parse_result = try_parse_json(xhr.responseText);

            if(!_json_parse_result.success){return;}
            if(!_json_parse_result.hasOwnProperty('data')){bad_response_message(_message_setter); return;}
            let _resp = _json_parse_result.data;

            std_response_handler(xhr.status, _resp, _message_setter, () => REST_load_campaigns());
        };
        send_post_request(xhr, data, _message_setter);
        //xhr.send(data);
    }

    const REST_subscribe_character_to_campaign = (_character_owner, _character_id, _campaign_owner, _campaign_id, _subscribe_state) => {
        let _message_setter = API_set_remote_campaign_message
        //let _user_name = BACKEND_get_user().name
        //let _characterID = BACKEND_get_character_id()
        if(_character_id == INIT_get_character_id())
        {
            _message_setter("Save character first");
            return;
        }
        if(IsNullOrEmpty(_character_owner) || IsNullOrEmpty(_campaign_owner))
        {
            _message_setter("User name empty");
            return;
        }
        const route = '/subscribe_character_to_campaign';
        const data = JSON.stringify({character_owner: _character_owner, character_id: _character_id, campaign_owner: _campaign_owner, campaign_id: _campaign_id, subscription_state: _subscribe_state});
        const xhr = set_xhr(route, content_json, _message_setter)
        xhr.onload = function() {
            let _message_setter = API_set_remote_campaign_message
            let _json_parse_result = try_parse_json(xhr.responseText);
            console.log("response")
            console.log(_json_parse_result)
            if(!_json_parse_result.success){return;}
            if(!_json_parse_result.hasOwnProperty('data')){return;}
            let _resp = _json_parse_result.data;
            let _user_role = BACKEND_get_user().role;
            if(_user_role == 'player')
            {
                std_response_handler(xhr.status, _resp, _message_setter, () => REST_load_assigned_campaigns())
            }
            else if(_user_role == 'game_master')
            {
                std_response_handler(xhr.status, _resp, _message_setter, () => REST_load_campaign_characters(_campaign_id))
            }
        };
        send_post_request(xhr, data, _message_setter);
        //xhr.send(data);
    }

    const REST_load_assigned_campaigns = () => {
        console.log("go for REST_load_assigned_campaigns")
        let _message_setter = API_set_remote_campaign_message;
        let _user_name = BACKEND_get_user().name;
        let _character_id = BACKEND_get_character_id();
        console.log(_user_name)
        console.log(_character_id)
        /*if(_character_id == INIT_get_character_id())
        {
            _message_setter("Save character first")
            return;
        }*/
        const route = '/load_assigned_campaigns';
        const data = JSON.stringify({user_name: _user_name, character_id: _character_id});
        const xhr = set_xhr(route, content_json, _message_setter)
        xhr.onload = function() {
            let _message_setter = API_set_remote_campaign_message
            let _json_parse_result = try_parse_json(xhr.responseText);
            if(!_json_parse_result.success){return;}
            if(!_json_parse_result.hasOwnProperty('data')){return;}
            let _resp = _json_parse_result.data;
            if(!_resp.hasOwnProperty('list')){bad_response_message(_message_setter); return;}
            std_response_handler(xhr.status, _resp, _message_setter, () => load_campaigns(_resp.list))
        };
        send_post_request(xhr, data, _message_setter);
        //xhr.send(data);
    }

    const REST_subscribe_user_to_campaign = (_subscribe_user_name, _campaign_id, _subscription_state) => {
        let _message_setter = API_set_remote_subscribed_user_message;
        if(_campaign_id == INIT_get_campaign_id())
        {
            _message_setter("Save campaign to remote before subscribing characters")
            return;
        }

        const route = '/subscribe_user_to_campaign';
        let _user_name = ''
        let _campaign_owner = ''
        let _user_role = BACKEND_get_user().role;
        if(_user_role == 'player')
        {
            _user_name = BACKEND_get_user().name
            _campaign_owner = _subscribe_user_name
        }
        else if(_user_role == 'game_master')
        {
            _user_name = _subscribe_user_name
            _campaign_owner = BACKEND_get_user().name
        }

        console.log('---REST_subscribe_user_to_campaign---');
        console.log(_user_name);
        console.log(_campaign_owner);
        console.log(_campaign_id);
        console.log(_subscription_state);
        const data = JSON.stringify({user_name: _user_name, campaign_owner: _campaign_owner, campaign_id: _campaign_id, subscription_state: _subscription_state});
        const xhr = set_xhr(route, content_json, _message_setter)
        xhr.onload = function() {

            let _message_setter = API_set_remote_subscribed_user_message
            let _json_parse_result = try_parse_json(xhr.responseText);
            console.log('REST_subscribe_user_to_campaign')
            console.log(_json_parse_result)
            if(!_json_parse_result.success){return;}
            if(!_json_parse_result.hasOwnProperty('data')){return;}
            let _resp = _json_parse_result.data;
            let _user_role = BACKEND_get_user().role
            if(_user_role == 'player')
            {
                console.log(_user_role)
                std_response_handler(xhr.status, _resp, _message_setter, () => REST_load_assigned_campaigns())
            }
            else if(_user_role == 'game_master')
            {
                std_response_handler(xhr.status, _resp, _message_setter, () => REST_load_subscribed_users(_campaign_id))
            }
        };
        send_post_request(xhr, data, _message_setter);
    }
    
    const REST_load_subscribed_users = (_campaign_id) => {

        console.log('REST_load_subscribed_users in')
        let _message_setter = API_set_remote_subscribed_user_message
        let _user_name = BACKEND_get_user().name
        const route = '/load_subscribed_users';
        const data = JSON.stringify({campaign_owner: _user_name, campaign_id: _campaign_id});
        const xhr = set_xhr(route, content_json, _message_setter)
        console.log(_user_name)
        console.log(_campaign_id)
        xhr.onload = function() {

            let _message_setter = API_set_remote_subscribed_user_message
            let _json_parse_result = try_parse_json(xhr.responseText);

            if(!_json_parse_result.success){return;}
            if(!_json_parse_result.hasOwnProperty('data')){return;}
            let _resp = _json_parse_result.data;
            if(!_resp.hasOwnProperty('list')){bad_response_message(_message_setter); return;}
            std_response_handler(xhr.status, _resp, _message_setter, () => load_subscribed_user(_resp.list))
        };
        send_post_request(xhr, data, _message_setter);
        //xhr.send(data);
    }
        
    return(
        <RestUserContext.Provider value={{
            REST_login_user,
            REST_create_new_user,
            REST_load_characters,
            REST_save_character,
            REST_load_character,
            REST_load_campaign_character,
            REST_delete_character,
            REST_apply_config,
            REST_set_config,
            REST_load_campaign_characters,
            //REST_unsubscribe_character_from_campaign,
            REST_load_campaigns,
            REST_save_campaign,
            REST_load_campaign,
            REST_delete_campaign,
            REST_subscribe_character_to_campaign,
            REST_load_assigned_campaigns,
            REST_subscribe_user_to_campaign,
            REST_load_subscribed_users
        }}>
            {children}
        </RestUserContext.Provider>
    )
}

export {RestUserContext, RestUserContextProvider}