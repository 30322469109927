import { 
    ParseToFloat, 
    ParseToInteger,
    GetListParameterByID,
    SetListParameterByID,
    AddElementToList,
    RemoveElementFromList,
    MoveElementInList,
    FixDecimalPlaces
} from '../../API/Functions/GeneralFunctions'

import {
    BACKEND_get_armor,
    BACKEND_set_armor,
    BACKEND_get_gear,
    BACKEND_set_gear,
    BACKEND_get_feat_lv_2,
    BACKEND_set_feat_lv_2,
    BACKEND_get_feat_lv_3,
    BACKEND_set_feat_lv_3,
    BACKEND_get_magic_spells,
    BACKEND_set_magic_spells,
    BACKEND_get_armor_template,
    BACKEND_get_gear_template,
    BACKEND_get_feat_lv_2_template,
    BACKEND_get_feat_lv_3_template,
    BACKEND_get_magic_spells_template,
    BACKEND_set_magic_element_types,
    BACKEND_get_magic_element_types,
    BACKEND_set_magic_circle_types,
    BACKEND_get_magic_circle_types
} from '../Parameters/CharacterParameters'

import {
    RecalcCharacterStats,
    //RecalcSecondaryAttributes,
    RecalcFreeAttributePoints,
    RecalcFreeFocusPoints,
    RecalcFreeSkillPoints,
    RecalcAttributes,
    RecalcSkills,
    RecalcWeapons,
    RecalcWeight,
    RecalcArmor
} from './RecalcFunctions'


const ModifyArmorName = (_id, _new_value) => {
    SetListParameterByID(_id, BACKEND_get_armor(), BACKEND_set_armor, _new_value, 'text', 'id', 'display_name');
}

const ToggleWearArmor = (_id) => {
    let _list = BACKEND_get_armor();

    for(let key in _list){
        if(_list[key].id == _id)
        {
            _list[key].is_put_on = !_list[key].is_put_on;
        }
    }
    BACKEND_set_armor(_list);
    RecalcArmor();
    RecalcWeapons();
}

const ModifyArmorWeight = (_id, _new_value) => {
    SetListParameterByID(_id, BACKEND_get_armor(), BACKEND_set_armor, _new_value, 'float', 'id', 'weight');
    RecalcWeight();
}

const ModifyArmorClass = (_id, _new_value) => {
    SetListParameterByID(_id, BACKEND_get_armor(), BACKEND_set_armor, _new_value, 'float', 'id', 'armor_class');
    RecalcArmor();
    RecalcWeapons();
}

const ModifyArmorRestriction = (_id, _new_value) => {
    SetListParameterByID(_id, BACKEND_get_armor(), BACKEND_set_armor, _new_value, 'float', 'id', 'armor_restriction');
    RecalcArmor();
    RecalcWeapons();
}

const AddNewArmorElement = () => {
    AddElementToList(BACKEND_get_armor(), BACKEND_get_armor_template(), BACKEND_set_armor);
    RecalcWeight();
    RecalcArmor();
    RecalcWeapons();
}

const RemoveArmorElement = (_element) => {
    RemoveElementFromList(_element.id, BACKEND_get_armor(), BACKEND_set_armor);
    RecalcWeight();
    RecalcArmor();
    RecalcWeapons();
}

const MoveArmorElement = (_element, _direction) => {
    MoveElementInList(_element.id, BACKEND_get_armor(), BACKEND_set_armor, _direction);
}

export {
    ModifyArmorName,
    ToggleWearArmor,
    ModifyArmorWeight,
    ModifyArmorClass,
    ModifyArmorRestriction,
    AddNewArmorElement,
    RemoveArmorElement,
    MoveArmorElement
}