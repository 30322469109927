import { useState, useContext } from 'react'
import {APIContext, APIContextProvider} from '../API/API'
import {SkillCheckTable} from './SubPages/SkillCheckTable'
import btn from '../Styles/btn.module.css'
import table from '../Styles/table.module.css'
import field from '../Styles/field.module.css'
import container from '../Styles/container.module.css'

const RulesPage = () => {

    return ( 
        <div style={{height: '100%', background: 'lightgray', marginLeft: 15, marginTop: 15}}>
            <div style={{display: 'flex'}}>
                <div style={{display: 'inline-block'}}>
                    <SkillCheckTable type={'meaning'}></SkillCheckTable>
                </div>
                <div style={{display: 'inline-block', marginLeft: 15}}>
                    <SkillCheckTable type={'difficulty'}></SkillCheckTable>
                </div>
                <div style={{display: 'inline-block', marginLeft: 15}}>
                    <SkillCheckTable type={'quality'}></SkillCheckTable>
                </div>
            </div>
        </div>
     );
}
 
export default RulesPage;