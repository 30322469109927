import { createRef, useContext } from 'react'
import {APIContext} from '../../../API/API'
import {TextDataContext} from '../../../API/TextDataContext'
import {UserDataContext} from '../../../API/UserDataContext'
import btn_style from '../../../StylesStylish/btn.module.css'
import exportIcon from '../../../images/icons/export_cloud.png';
import { Tooltip } from 'react-tooltip'

const RemoteDownloadButton = (props) => {
    const { width = '48px', height = '24px' } = props;

    const {
        API_get_character_file_name,
        API_get_character_file,
        API_get_campaign_file_name,
        API_get_campaign_file
    } = useContext(APIContext);

    const {
        API_get_user
    } = useContext(UserDataContext);

    const {
        API_get_std_labels
    } = useContext(TextDataContext);


    const downloadRef = createRef();
    const _user_role = API_get_user().role;

    function handleDownload() {
        downloadRef.current.click();
      }

    const getDownloadFileName = () => {
        if(_user_role == 'player'){
            return API_get_character_file_name();
        }
        else if(_user_role == 'game_master'){
            return API_get_campaign_file_name();
        }
    }
    const getDownloadFile = () => {
        if(_user_role == 'player'){
            return API_get_character_file();
        }
        else if(_user_role == 'game_master'){
            return API_get_campaign_file();
        }
    }

    return ( 
        <div>
            <a ref={downloadRef} download={getDownloadFileName()} target="_blank" rel="noreferrer" href={URL.createObjectURL(getDownloadFile())} style={{textDecoration: "inherit", color: "inherit", display: 'none'}}></a>
            <button className={btn_style.std_button} data-tooltip-id="btn-tooltip" data-tooltip-place="bottom" data-tooltip-content={API_get_std_labels().save} onClick={handleDownload} style={{width: width, minWidth: width, height: height}}>
                <img src={exportIcon} alt="Bild" style={{ float: 'left', width: '22px' }} />
            </button>
            <Tooltip id="btn-tooltip" />
        </div>
     );
}
 
export default RemoteDownloadButton;