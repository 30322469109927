import {
    BACKEND_get_base_stats
} from './CharacterParameters'

import {
    BACKEND_get_campaign_name
} from './CampaignParameters'

import {
    BACKEND_get_std_labels
} from '../Lang/TextData'

import {
    ConvertStringToFileNameFormat,
    IsWhitespace
} from '../../API/Functions/GeneralFunctions'

//All Backend Functions should only be accessed via UI_Controller or from functions inside Backend Folder
//var character_file_name = 'test.json';

const BACKEND_get_character_file_name = () => 
{
    let _default_name = ConvertStringToFileNameFormat(BACKEND_get_std_labels().character);
    let _character_name = BACKEND_get_base_stats().name;

    if(_character_name == '' || _character_name == null)
        return _default_name + '.json'

    if(IsWhitespace(_character_name))
        return _default_name + '.json'

    _character_name = ConvertStringToFileNameFormat(_character_name);

    let _file_name =  _character_name + '.json'
    return _file_name;
}

const BACKEND_get_campaign_file_name = () => 
{
    let _default_name = ConvertStringToFileNameFormat(BACKEND_get_std_labels().new_campaign);
    let _campaign_name = BACKEND_get_campaign_name();

    if(_campaign_name == '' || _campaign_name == null)
        return _default_name + '.json'

    if(IsWhitespace(_campaign_name))
        return _default_name + '.json'
    
    _campaign_name = ConvertStringToFileNameFormat(BACKEND_get_campaign_name());

    let _file_name =  _campaign_name + '.json'
    return _file_name;
}

export 
{
    BACKEND_get_character_file_name,
    BACKEND_get_campaign_file_name
};