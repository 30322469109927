import { useState, useContext } from 'react'
import {SkillContext, SkillContextProvider} from '../API/SkillContext'
import {TextDataContext, TextDataContextProvider} from '../API/TextDataContext'
import {BaseStatContext, BaseStatContextProvider} from '../API/BaseStatContext'
import {APIContext, APIContextProvider} from '../API/API'
import {SkillTablePage} from './SubPages/SkillTablePage'
import {SkillGroupHeaderPage} from './SubPages/SkillGroupHeaderPage'
import {FreePointsTablePage} from './SubPages/FreePointsTablePage'
import {IncreaseSelectorPage} from './SubPages/IncreaseSelectorPage'
import btn from '../Styles/btn.module.css'
import table from '../Styles/table.module.css'
import field from '../Styles/field.module.css'
import container from '../Styles/container.module.css'

const SkillPage = () => {
    const {
        API_full_GUI_update,
        API_increment_level,
        API_update_base_stat,
        API_increment_attribute,
        API_increment_ancestry_bonus,
        API_increment_other_bonus,
        API_increment_focus,
        API_increment_kho_tal,
        API_update_comments,
        API_get_dice_list_string,
        API_roll_dice,
        API_modify_character_stat_bonus,
        //API_modify_secondary_attribute_bonus,
        API_change_hitpoints,
        API_increment_free_attribute_points_bonus,
        API_increment_free_focus_points_bonus,
        API_increment_free_skill_points_bonus
    } = useContext(APIContext);

    const {
        API_get_base_stat_labels, 
        API_set_base_stat_labels,
        API_get_std_labels,
        API_set_std_labels,
        API_get_std_header_labels,
        API_set_std_header_labels,
        API_get_tab_view_labels,
        API_set_tab_view_labels,
        API_get_character_stats_labels,
        API_set_character_stats_labels,
        API_get_table_header_labels,
        API_set_table_header_labels
    } = useContext(TextDataContext);

    const {
        API_get_base_stats,
        API_get_free_attribute_points,
        API_get_free_focus_points,
        API_get_edit_character_visibility,
        API_set_edit_character_visibility
    } = useContext(BaseStatContext);

    const {
        API_get_skills,
        API_get_free_skill_points,
        API_get_edit_skill_visibility,
        API_set_edit_skill_visibility,
        API_get_skill_selector,
        API_set_skill_selector
    } = useContext(SkillContext);

    return ( 
        <div style={{height: '100%', background: 'lightgray'}}>
            {/*<div style={{display: 'inline-block'}}>
                    <div style={{margin: '0px 0px 12px 0px'}}><SkillGroupHeaderPage id={0} internal_groups={[0, 1]}></SkillGroupHeaderPage></div>
                    <div style={{margin: '0px 0px 12px 12px', display: 'flex', verticalAlign: 'top'}}>
                        <div style={{display: 'inline-block', marginRight: 15}}><SkillTablePage skill_group_id={0}></SkillTablePage></div>
                        <div style={{display: 'inline-block'}}><SkillTablePage skill_group_id={1}></SkillTablePage></div>
                    </div>
                    <div style={{margin: '0px 0px 12px 0px'}}><SkillGroupHeaderPage id={1} internal_groups={[2, 3, 4, 5]}></SkillGroupHeaderPage></div>
                    <div style={{margin: '0px 0px 12px 12px', display: 'flex', verticalAlign: 'top'}}>
                        <div style={{display: 'inline-block', marginRight: 15}}><SkillTablePage skill_group_id={2}></SkillTablePage></div>
                        <div style={{display: 'inline-block'}}><SkillTablePage skill_group_id={5}></SkillTablePage></div>
                    </div>
                    <div style={{margin: '0px 0px 12px 12px', display: 'flex', verticalAlign: 'top'}}>
                        <div style={{display: 'inline-block', marginRight: 15}}><SkillTablePage skill_group_id={3}></SkillTablePage></div>
                        <div style={{display: 'inline-block'}}><SkillTablePage skill_group_id={4}></SkillTablePage></div>
                    </div>
                    <div style={{margin: '0px 0px 12px 0px'}}><SkillGroupHeaderPage id={2} internal_groups={[6, 7, 8, 9]}></SkillGroupHeaderPage></div>
                    <div style={{margin: '0px 0px 12px 12px', display: 'flex', verticalAlign: 'top'}}>
                        <div style={{display: 'inline-block', marginRight: 15}}><SkillTablePage skill_group_id={6}></SkillTablePage></div>
                        <div style={{display: 'inline-block'}}><SkillTablePage skill_group_id={7}></SkillTablePage></div>
                    </div>
                    <div style={{margin: '0px 0px 12px 12px', display: 'flex', verticalAlign: 'top'}}>
                        <div style={{display: 'inline-block', marginRight: 15}}><SkillTablePage skill_group_id={9}></SkillTablePage></div>
                        <div style={{display: 'inline-block'}}><SkillTablePage skill_group_id={8}></SkillTablePage></div>
                    </div>
                    <div style={{margin: '0px 0px 12px 0px'}}><SkillGroupHeaderPage id={3} internal_groups={[10, 11]}></SkillGroupHeaderPage></div>
                    <div style={{margin: '0px 0px 12px 12px', display: 'flex', verticalAlign: 'top'}}>
                        <div style={{display: 'inline-block', marginRight: 15}}><SkillTablePage skill_group_id={11}></SkillTablePage></div>
                        <div style={{display: 'inline-block'}}><SkillTablePage skill_group_id={10}></SkillTablePage></div>
                    </div>
                    <div style={{margin: '0px 0px 12px 0px'}}><SkillGroupHeaderPage id={4} internal_groups={[12, 13, 14, 15]}></SkillGroupHeaderPage></div>
                    <div style={{margin: '0px 0px 12px 12px', display: 'flex', verticalAlign: 'top'}}>
                        <div style={{display: 'inline-block', marginRight: 15}}><SkillTablePage skill_group_id={12}></SkillTablePage></div>
                        <div style={{display: 'inline-block'}}><SkillTablePage skill_group_id={13}></SkillTablePage></div>
                    </div>
                    <div style={{margin: '0px 0px 12px 12px', display: 'flex', verticalAlign: 'top'}}>
                        <div style={{display: 'inline-block', marginRight: 15}}><SkillTablePage skill_group_id={15}></SkillTablePage></div>
                        <div style={{display: 'inline-block'}}><SkillTablePage skill_group_id={14}></SkillTablePage></div>
                    </div>
            </div>*/}

            <div style={{display: 'inline-block'}}>
                <div className={container.skill_table_container}>
                    <div style={{margin: '0px 0px 12px 0px'}}><SkillGroupHeaderPage id={0} internal_groups={[0, 1]}></SkillGroupHeaderPage></div>
                    <div style={{margin: '0px 0px 12px 12px', display: 'flex', verticalAlign: 'top'}}>
                        <div style={{display: 'inline-block', marginRight: 15}}><SkillTablePage skill_group_id={0}></SkillTablePage></div>
                        <div style={{display: 'inline-block'}}><SkillTablePage skill_group_id={1}></SkillTablePage></div>
                    </div>
                </div>
                <div className={container.skill_table_container}>
                    <div style={{margin: '0px 0px 12px 0px'}}><SkillGroupHeaderPage id={1} internal_groups={[2, 3, 4, 5]}></SkillGroupHeaderPage></div>
                    <div style={{margin: '0px 0px 12px 12px', display: 'flex', verticalAlign: 'top'}}>
                        <div style={{display: 'inline-block', marginRight: 15}}><SkillTablePage skill_group_id={2}></SkillTablePage></div>
                        <div style={{display: 'inline-block'}}><SkillTablePage skill_group_id={5}></SkillTablePage></div>
                    </div>
                    <div style={{margin: '0px 0px 12px 12px', display: 'flex', verticalAlign: 'top'}}>
                        <div style={{display: 'inline-block', marginRight: 15}}><SkillTablePage skill_group_id={3}></SkillTablePage></div>
                        <div style={{display: 'inline-block'}}><SkillTablePage skill_group_id={4}></SkillTablePage></div>
                    </div>
                </div>
                <div className={container.skill_table_container}>
                    <div style={{margin: '0px 0px 12px 0px'}}><SkillGroupHeaderPage id={2} internal_groups={[6, 7, 8, 9]}></SkillGroupHeaderPage></div>
                    <div style={{margin: '0px 0px 12px 12px', display: 'flex', verticalAlign: 'top'}}>
                        <div style={{display: 'inline-block', marginRight: 15}}><SkillTablePage skill_group_id={6}></SkillTablePage></div>
                        <div style={{display: 'inline-block'}}><SkillTablePage skill_group_id={7}></SkillTablePage></div>
                    </div>
                    <div style={{margin: '0px 0px 12px 12px', display: 'flex', verticalAlign: 'top'}}>
                        <div style={{display: 'inline-block', marginRight: 15}}><SkillTablePage skill_group_id={9}></SkillTablePage></div>
                        <div style={{display: 'inline-block'}}><SkillTablePage skill_group_id={8}></SkillTablePage></div>
                    </div>
                </div>
                <div className={container.skill_table_container}>
                    <div style={{margin: '0px 0px 12px 0px'}}><SkillGroupHeaderPage id={3} internal_groups={[10, 11]}></SkillGroupHeaderPage></div>
                    <div style={{margin: '0px 0px 12px 12px', display: 'flex', verticalAlign: 'top'}}>
                        <div style={{display: 'inline-block', marginRight: 15}}><SkillTablePage skill_group_id={11}></SkillTablePage></div>
                        <div style={{display: 'inline-block'}}><SkillTablePage skill_group_id={10}></SkillTablePage></div>
                    </div>
                </div>
                <div className={container.skill_table_container}>
                    <div style={{margin: '0px 0px 12px 0px'}}><SkillGroupHeaderPage id={4} internal_groups={[12, 13, 14, 15]}></SkillGroupHeaderPage></div>
                    <div style={{margin: '0px 0px 12px 12px', display: 'flex', verticalAlign: 'top'}}>
                        <div style={{display: 'inline-block', marginRight: 15}}><SkillTablePage skill_group_id={12}></SkillTablePage></div>
                        <div style={{display: 'inline-block'}}><SkillTablePage skill_group_id={13}></SkillTablePage></div>
                    </div>
                    <div style={{margin: '0px 0px 12px 12px', display: 'flex', verticalAlign: 'top'}}>
                        <div style={{display: 'inline-block', marginRight: 15}}><SkillTablePage skill_group_id={15}></SkillTablePage></div>
                        <div style={{display: 'inline-block'}}><SkillTablePage skill_group_id={14}></SkillTablePage></div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default SkillPage;