import { useState, useContext } from 'react'
import {SkillContext, SkillContextProvider} from '../../API/SkillContext'
import {TextDataContext, TextDataContextProvider} from '../../API/TextDataContext'
import {BaseStatContext, BaseStatContextProvider} from '../../API/BaseStatContext'
import {APIContext, APIContextProvider} from '../../API/API'
import {SkillTablePage} from './SkillTablePage'
import {SkillGroupHeaderPage} from './SkillGroupHeaderPage'
import btn from '../../Styles/btn.module.css'
import table from '../../Styles/table.module.css'
import field from '../../Styles/field.module.css'
import container from '../../Styles/container.module.css'

const IncreaseSelectorPage = (props) => {

    const {
        API_get_base_stat_labels, 
        API_set_base_stat_labels,
        API_get_std_labels,
        API_set_std_labels,
        API_get_std_header_labels,
        API_set_std_header_labels,
        API_get_tab_view_labels,
        API_set_tab_view_labels,
        API_get_character_stats_labels,
        API_set_character_stats_labels,
        API_get_table_header_labels,
        API_set_table_header_labels
    } = useContext(TextDataContext);

    const {
        API_get_skills,
        API_get_increase_selector,
        API_set_increase_selector,
        API_get_edit_skill_visibility
    } = useContext(SkillContext);

    const {
        API_get_free_attribute_points,
        API_set_free_attribute_points,
        API_get_ancestry_saldo,
        API_set_ancestry_saldo
    } = useContext(BaseStatContext);

    const selectIncreaseSelector = (e) => {
        API_set_increase_selector(e.currentTarget.value);
    }

    return ( 
        <div>
            {true &&
                <table >
                    <tr style={{height: 20}}>
                        <td style={{verticalAlign: 'middle'}}><input onChange={selectIncreaseSelector} type="radio" name="attributeIncreaseSelector" value="increase" checked={API_get_increase_selector() == 'increase'}/></td>
                        <td style={{verticalAlign: 'middle'}}><label>{API_get_std_header_labels().increase}</label></td>
                    </tr>
                    <tr style={{height: 20}}>
                        <td style={{verticalAlign: 'middle'}}><input onChange={selectIncreaseSelector} type="radio" name="attributeIncreaseSelector" value="other" checked={API_get_increase_selector() == 'other'}/></td>
                        <td style={{verticalAlign: 'middle'}}><label>{API_get_table_header_labels().other_bonus}</label></td>
                    </tr>
                    <tr style={{height: 20}}>
                        <td style={{verticalAlign: 'middle'}}><input onChange={selectIncreaseSelector} type="radio" name="attributeIncreaseSelector" value="ancestry" checked={API_get_increase_selector() == 'ancestry'}/></td>
                        <td style={{verticalAlign: 'middle'}}><label>{API_get_table_header_labels().ancestry_bonus} ({API_get_free_attribute_points().bonus+API_get_ancestry_saldo().cost-4})</label></td>
                    </tr>
                </table>
            }
        </div>
     );
}
 
export {IncreaseSelectorPage};

