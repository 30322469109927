import {
    BACKEND_get_campaign_id,
    BACKEND_set_campaign_id,
    BACKEND_get_subscribed_user,
    BACKEND_set_subscribed_user,
    BACKEND_get_campaign_name,
    BACKEND_set_campaign_name,
    BACKEND_get_monster_list,
    BACKEND_set_monster_list,
    BACKEND_get_monster_template
} from '../Parameters/CampaignParameters'

import {
    Translate
} from '../Lang/Translator'

import { 
    INIT_get_campaign_id,
    INIT_get_subscribed_user,
    INIT_get_campaign_name,
    INIT_get_monster_list
} 
from '../ParametersInit/CampaignInit';

import {
    RecalcMonsterList
} from './RecalcFunctions'
import {
    BACKEND_get_user,
    BACKEND_set_user,
    BACKEND_get_user_campaigns,
    BACKEND_set_user_campaigns,
    BACKEND_get_user_characters,
    BACKEND_set_user_characters
} from '../Parameters/UserData'
import { 
    MoveElementInList
} from '../../API/Functions/GeneralFunctions'

const InitCampaign = (_language) => {
    BACKEND_set_campaign_id(INIT_get_campaign_id());
    BACKEND_set_campaign_name(INIT_get_campaign_name());
    BACKEND_set_subscribed_user(INIT_get_subscribed_user());
    BACKEND_set_monster_list(INIT_get_monster_list());
    Translate(_language);
    RecalcMonsterList();
}

const MoveCampaignElement = (_campaign_id, _direction) => { 
    MoveElementInList(_campaign_id, BACKEND_get_user().campaigns, BACKEND_set_user_campaigns, _direction);
}

const MoveSubscribedUserElement = (_user_name, _direction) => { 
    MoveElementInList(_user_name, BACKEND_get_subscribed_user(), BACKEND_set_subscribed_user, _direction, 'name', false);
}

export {
    InitCampaign,
    MoveCampaignElement,
    MoveSubscribedUserElement
}