import { useState, useContext } from 'react'
import {CheckIfKeyIsContained, IsWhitespace, ParseToInteger, ParseToFloat, TryParse} from '../../API/Functions/GeneralFunctions'
import field_style from '../../StylesStylish/field.module.css'
import label_style from '../../StylesStylish/label.module.css'

const InputField = (props) => {
    const { widthLabel = '120px', widthValue = '120px', heightHeader = '48px', heightStd = '24px', label = '-', labelVisibility = true } = props;
    const [currentInput, setCurrentInput] = useState(props.content)

    const updateInput = (_input, _use_setter = true) => {
        if(_use_setter)
            props.setter(_input);
        setCurrentInput(_input);
    }

    const validateInput = (_input) => {
        let _validateValue = _input;
        let _use_setter = false;
        console.log('_validateValue')
        console.log(_validateValue)
        if(props.type=='integer')
        {
            _validateValue = ParseToInteger(_validateValue);
            if(Number.isInteger(_validateValue))
                _use_setter = true;
        }
        else if(props.type=='float')
        {
            _validateValue = _validateValue.replace(',', '.')
            let _numberOfDots = _validateValue.split('.').length - 1;
            let _lastLetter = _input.charAt(_input.length - 1);
            if(_lastLetter == '.' && _numberOfDots == 1 )
            {
                let _slicedInput = _input.slice(0, _input.length - 1);
                if(Number.isInteger(_slicedInput))
                    _use_setter = false;
            }
            else
            {
                _validateValue = ParseToFloat(_validateValue);
                _use_setter = true;
            }
            console.log(_validateValue)
        }
        //props.setter(_validateValue);
        updateInput(_validateValue, _use_setter);
    }

    const getContent = () => {
        if(props.type=='integer')
        {
            if(Number.isInteger(currentInput))
            {
                return props.content
            }
            else
            {
                return currentInput
            }
        }
        else if(props.type=='float')
        {
            let _validateValue = currentInput;
            let _numberOfDots = 0;
            let _lastLetter = null;
            if(typeof _validateValue === 'string')
            {
                _validateValue = _validateValue.replace(',', '.');
                _numberOfDots = _validateValue.split('.').length - 1;
                _lastLetter = _validateValue.charAt(_validateValue.length - 1);
            }
            if(_lastLetter == '.' && _numberOfDots == 1 )
            {
                return currentInput;
            }
            else
            {
                if(TryParse(currentInput, 'float')){
                    return props.content;
                }
                else
                {
                    return currentInput;
                }
            }
        }
        else
        {
            return props.content;
        }
    }

    const getType = () => {
        return props.type
    }

    return ( 
        <div>
            {props.type == 'header' &&
                <input type='text' className={field_style.input_field_header} style={{width: widthValue, minWidth: widthValue, height: heightHeader}} value={getContent()} onChange={(e) => updateInput(e.target.value)} />
            }
            {props.type != 'header' && labelVisibility &&
                <label className={label_style.std_label} style={{width: widthLabel, minWidth: widthLabel, height: heightStd, display: 'inline-block'}}>{props.label}</label>
            }
            {props.type == 'text' &&
                <input className={field_style.input_field} style={{width: widthValue, minWidth: widthValue, height: heightStd}} value={getContent()} onChange={(e) => updateInput(e.target.value)}></input>
            }
            {props.type == 'integer' &&
                <input type='text' className={field_style.input_field} style={{width: widthValue, minWidth: widthValue, height: heightStd, textAlign: 'center'}} value={getContent()} onChange={(e) => validateInput(e.target.value)}></input>
            }
            {props.type == 'float' &&
                <input type='text' className={field_style.input_field} style={{width: widthValue, minWidth: widthValue, height: heightStd, textAlign: 'center'}} value={getContent()} onChange={(e) => validateInput(e.target.value)}></input>
            }
        </div>
    );
}
 
export {
    InputField
}