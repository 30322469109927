import { useState, useContext } from 'react'
import {APIContext, APIContextProvider} from '../../API/API'
import {TextDataContext, TextDataContextProvider} from '../../API/TextDataContext'
import {BaseStatContext, BaseStatContextProvider} from '../../API/BaseStatContext'
import {
    ParseToInteger,
    ParseToFloat, 
    GetElementById,
    TryParse
} from '../../API/Functions/GeneralFunctions'
import btn from '../../Styles/btn.module.css'
import table from '../../Styles/table.module.css'
import field from '../../Styles/field.module.css'
import container from '../../Styles/container.module.css'
const MoneyTablePage = () => {
    const {
        API_full_GUI_update,
        API_increment_level,
        API_update_base_stat,
        API_increment_attribute,
        API_increment_skill,
        API_increment_other_skill_bonus,
        API_increment_ancestry_bonus,
        API_increment_other_bonus,
        API_increment_focus,
        API_increment_kho_tal,
        API_update_comments,
        API_get_dice_list_string,
        API_roll_dice,
        API_modify_character_stat_bonus,
        //API_modify_secondary_attribute_bonus,
        API_change_hitpoints,
        API_increment_free_attribute_points_bonus,
        API_increment_free_focus_points_bonus,
        API_get_skill_attribute_mod,
        API_modify_money_list
    } = useContext(APIContext);

    const {
        API_get_base_stat_labels, 
        API_set_base_stat_labels,
        API_get_std_labels,
        API_set_std_labels,
        API_get_std_header_labels,
        API_set_std_header_labels,
        API_get_tab_view_labels,
        API_set_tab_view_labels,
        API_get_character_stats_labels,
        API_set_character_stats_labels,
        API_get_table_header_labels,
        API_set_table_header_labels,
        API_get_stat_overview_labels,
        API_set_stat_overview_labels,
        API_get_money_header_labels,
        API_set_money_header_labels
    } = useContext(TextDataContext);

    const {
        API_get_money_list,
        API_set_money_list
    } = useContext(BaseStatContext);

    const [editMoneyList, setEditMoneyList] = useState(false);
    const [moneyListInput, setMoneyListInput] = useState(null);

    const toggleEditMoneyList = () => {
        setMoneyListInput(getMoneyList());
        setEditMoneyList(!editMoneyList);
    }

    const modifyMoneyList = (_index, _value) => {
        API_modify_money_list(_index, _value);
    }

    const tryModifyMoneyListInput = (_index, _newValue) => {
        if(TryParse(_newValue, 'integer'))
            modifyMoneyList(_index, _newValue);


        modifyMoneyListInput(_index, _newValue);
    }

    const modifyMoneyListInput = (_index, _value) => {
        let _moneyListInput = {...moneyListInput};
        _moneyListInput[_index] = ParseToInteger(_value);
        setMoneyListInput(_moneyListInput);
    }
    
    const getMoneyList = () => {
        return [API_get_money_list()[0].amount, API_get_money_list()[1].amount, API_get_money_list()[2].amount, API_get_money_list()[3].amount];
    }

    return ( 
        <div>
            <table className = {table.table_base_stats}>
                <tr>
                    <td className={table.cell_header} style={{textAlign: 'left', paddingLeft: 5}} colspan={2}>{API_get_money_header_labels().money}</td>
                    <td className={table.cell_header} style={{width: 80}}>{API_get_money_header_labels().worth}</td>
                    <td className={table.cell_header} style={{width: 80}}>{API_get_std_header_labels().weight}</td>
                    <td className={table.cell_header} style={{width: 80}}>{API_get_std_header_labels().amount}</td>
                </tr>
                <tr>
                    <td className={table.cell_label} style={{textAlign: 'left', width: 80, borderRight: '0px solid black'}}>{API_get_money_list()[0].display_name}</td>
                    <td className={table.cell_label} style={{width: 20}}>[{API_get_money_list()[0].abbreviation}]</td>
                    <td className={table.cell_input}>{API_get_money_list()[0].worth}G</td>
                    <td className={table.cell_input}>{1000*API_get_money_list()[0].weight}g</td>
                    <td className={table.cell_input}>
                        {!editMoneyList && 
                            API_get_money_list()[0].amount
                        }
                        {editMoneyList && 
                            <input type='number' className={field.input_field_number} style={{width: 60}} value={moneyListInput[0]} onChange={(e) => tryModifyMoneyListInput(0, e.target.value)} onBlur={(e) => modifyMoneyList(0, e.target.value)} />
                        }
                    </td>
                </tr>
                <tr>
                    <td className={table.cell_label} style={{textAlign: 'left', borderRight: '0px solid black'}}>{API_get_money_list()[1].display_name}</td>
                    <td className={table.cell_label}>[{API_get_money_list()[1].abbreviation}]</td>
                    <td className={table.cell_input}>{API_get_money_list()[1].worth}G</td>
                    <td className={table.cell_input}>{1000*API_get_money_list()[1].weight}g</td>
                    <td className={table.cell_input}>
                        {!editMoneyList && 
                            API_get_money_list()[1].amount
                        }
                        {editMoneyList && 
                            <input type='number' className={field.input_field_number} style={{width: 60}} value={moneyListInput[1]} onChange={(e) => tryModifyMoneyListInput(1, e.target.value)} onBlur={(e) => modifyMoneyList(1, e.target.value)} />
                        }
                    </td>
                </tr>
                <tr>
                    <td className={table.cell_label} style={{textAlign: 'left', borderRight: '0px solid black'}}>{API_get_money_list()[2].display_name}</td>
                    <td className={table.cell_label}>[{API_get_money_list()[2].abbreviation}]</td>
                    <td className={table.cell_input}>{API_get_money_list()[2].worth}G</td>
                    <td className={table.cell_input}>{1000*API_get_money_list()[2].weight}g</td>
                    <td className={table.cell_input}>
                        {!editMoneyList && 
                            API_get_money_list()[2].amount
                        }
                        {editMoneyList && 
                            <input type='number' className={field.input_field_number} style={{width: 60}} value={moneyListInput[2]} onChange={(e) => tryModifyMoneyListInput(2, e.target.value)} onBlur={(e) => modifyMoneyList(2, e.target.value)} />
                        }
                    </td>
                </tr>
                <tr>
                    <td className={table.cell_label} style={{textAlign: 'left', borderRight: '0px solid black'}}>{API_get_money_list()[3].display_name}</td>
                    <td className={table.cell_label}>[{API_get_money_list()[3].abbreviation}]</td>
                    <td className={table.cell_input}>{API_get_money_list()[3].worth}G</td>
                    <td className={table.cell_input}>{1000*API_get_money_list()[3].weight}g</td>
                    <td className={table.cell_input}>
                        {!editMoneyList && 
                            API_get_money_list()[3].amount
                        }
                        {editMoneyList && 
                            <input type='number' className={field.input_field_number} style={{width: 60}} value={moneyListInput[3]} onChange={(e) => tryModifyMoneyListInput(3, e.target.value)} onBlur={(e) => modifyMoneyList(3, e.target.value)} />
                        }
                    </td>
                </tr>
            </table>
            <div style={{marginTop: 5}}>
                <button className={btn.button_std} style={{width: 80, minWidth: 80}} onClick={toggleEditMoneyList}>{API_get_std_labels().edit}</button>
            </div>
        </div>
    );
}
 
export {
    MoneyTablePage
}