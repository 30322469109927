import { 
    INIT_get_campaign_id,
    INIT_get_campaign_name,
    INIT_get_subscribed_user,
    INIT_get_monster_list,
    INIT_get_monster_template
 } from "../ParametersInit/CampaignInit";

 var campaign_id = INIT_get_campaign_id();
 var campaign_name = INIT_get_campaign_name();
 var subscribed_user = INIT_get_subscribed_user();
 var monster_list = INIT_get_monster_list();
 var monster_template = INIT_get_monster_template();

 const BACKEND_get_campaign_id = () => 
 {
     return campaign_id;
 }
 
 const BACKEND_set_campaign_id = (_campaign_id) => 
 {
     return campaign_id = _campaign_id;
 }

 const BACKEND_get_campaign_name = () => 
 {
     return campaign_name;
 }
 
 const BACKEND_set_campaign_name = (_campaign_name) => 
 {
     return campaign_name = _campaign_name;
 }

 const BACKEND_get_subscribed_user = () => 
 {
     return subscribed_user;
 }
 
 const BACKEND_set_subscribed_user = (_subscribed_user) => 
 {
     return subscribed_user = _subscribed_user;
 }

 const BACKEND_get_monster_list = () => 
 {
     return monster_list;
 }
 
 const BACKEND_set_monster_list = (_monster_list) => 
 {
    monster_list = _monster_list;
 }
 
 const BACKEND_get_monster_template = () => 
 {
     return monster_template;
 }

 export 
{
    BACKEND_get_campaign_id,
    BACKEND_set_campaign_id,
    BACKEND_get_campaign_name,
    BACKEND_set_campaign_name,
    BACKEND_get_subscribed_user,
    BACKEND_set_subscribed_user,
    BACKEND_get_monster_list,
    BACKEND_set_monster_list,
    BACKEND_get_monster_template
};