import {
    BACKEND_set_base_stats, 
    BACKEND_get_base_stats, 
    BACKEND_set_free_attribute_points, 
    BACKEND_get_free_attribute_points, 
    BACKEND_set_free_focus_points, 
    BACKEND_get_free_focus_points,
    BACKEND_set_free_skill_points,
    BACKEND_get_free_skill_points,
    BACKEND_set_attribute_groups,
    BACKEND_get_attribute_groups,
    BACKEND_set_attributes,
    BACKEND_get_attributes,
    BACKEND_set_character_stats,
    BACKEND_get_character_stats,
    //BACKEND_set_secondary_attributes,
    //BACKEND_get_secondary_attributes,
    BACKEND_set_skills,
    BACKEND_get_skills,
    BACKEND_set_comments,
    BACKEND_get_comments,
    BACKEND_get_negative_attribute_restriction,
    BACKEND_get_negative_skill_restriction,
    BACKEND_get_negative_focus_restriction,
    BACKEND_get_unarmed_weapons,
    BACKEND_get_melee_weapons,
    BACKEND_get_ranged_weapons
} from './Parameters/CharacterParameters'
import {
    INIT_get_base_stats, 
    INIT_get_free_attribute_points, 
    INIT_get_free_focus_points,
    INIT_get_attribute_groups,
    INIT_get_attributes,
    INIT_get_character_stats,
    //INIT_get_secondary_attributes,
    INIT_get_negative_attribute_restriction,
    INIT_get_negative_focus_restriction
} from './ParametersInit/AttributesInit'
import { 
    INIT_get_skills,
    INIT_get_free_skill_points
 } from "./ParametersInit/SkillParametersInit";
import {
    CalculateAttribute, 
    GetHitpoints,
    GetLoadCapacity,
    GetEndurance,
    GetKhoTal,
    //GetInitiative,
    //GetDodgeValue,
    GetFreeAttributePoints, 
    GetFreeFocusPoints,
    GetFreeSkillPoints,
    GetSkillPointsAttribute,
    GetFocusCost,
    GetMaxAttributeIncrease,
    GetMaxSkillIncrease,
    CalculateSkill,
    ConvertAttributes, 
    ConvertAttributesBack,
    InitCharacter,
    ModifyMoneyList,
    ModifyFeatName,
    ModifyFeatDescription,
    AddNewFeatElement,
    RemoveFeatElement,
    MoveFeatElement,
    GetMagicSkillIncrease
} from './Functions/CharacterFunctions'
import{
    InitCampaign,
    MoveCampaignElement,
    MoveSubscribedUserElement
} from './Functions/CampaignFunctions'
import{
    BACKEND_set_campaign_name
} from './Parameters/CampaignParameters'
import {
    PrepareCharacterForDownload,
    GetCharacterFile,
    GetCampaignFile,
    UploadCharacter,
    UploadCampaign
} from './Functions/IOFunctions'
import {
    IncrementLevel,
    IncrementAttribute,
    IncrementSkill,
    IncrementFreeFocusPointsBonus, 
    IncrementFreeAttributePointsBonus,
    IncrementFreeSkillPointsBonus,
    IncrementKhoTal,
    IncrementFocus
} from './Functions/IncrementFunctions'
import {
    BACKEND_get_character_file_name,
    BACKEND_set_upload_state,
    BACKEND_get_upload_state
} from './Parameters/GameData'
import {
    BACKEND_get_tool_data, 
    BACKEND_set_tool_data,
    BACKEND_get_dice_log,
    BACKEND_set_dice_log,
    BACKEND_set_dice_log_value,
    BACKEND_get_language_list
} from './Parameters/ToolData'
import {
    Translate
} from './Lang/Translator'
import {
    GetDiceList,
    GetDiceListString,
    GetFoundryDiceListString
} from './DiceRoller'
import {
    ParseToInteger,
    ParseToFloat,
    GetElementById,
    RollSingleDice
} from '../API/Functions/GeneralFunctions'
import {
    SwitchWeaponType,
    //SwitchRangedWeaponType,
    //SwitchHandType,
    ModifyWeaponName,
    ModifyWeaponWeight,
    ModifyMeleeWeaponAttackMod,
    ModifyMeleeWeaponDefenseMod,
    ModifyRangedWeaponPrecisionMod,
    SwitchLeadAttribute,
    AddNewWeapon,
    RemoveWeapon,
    MoveWeapon,
    ModifyAmmoName,
    ModifyAmmo,
    ModifyAmmoWeight,
    ModifyDamage
} from './Functions/WeaponFunctions'

import{
    ModifyArmorName,
    ToggleWearArmor,
    ModifyArmorWeight,
    ModifyArmorClass,
    ModifyArmorRestriction,
    AddNewArmorElement,
    RemoveArmorElement,
    MoveArmorElement
} from './Functions/ArmorFunctions'

import{
    ModifyGearName,
    ModifyGearAmount,
    ModifyGearWeight,
    ModifyGearDescription,
    AddNewGearElement,
    RemoveGearElement,
    MoveGearElement
} from './Functions/GearFunctions'

import{
    ModifyMagicSpellName,
    ModifyMagicSpellMight,
    ModifyMagicSpellDescription,
    SwitchMagicElementType,
    SwitchMagicCircleType,
    AddNewMagicSpell,
    RemoveMagicSpell,
    MoveMagicSpell
} from './Functions/MagicFunctions'
    
import{
    ModifyMonsterName,
    ModifyMonsterInitiative,
    ModifyMonsterDamage,
    ModifyMonsterHitpoints,
    ModifyMonsterReflexes,
    ModifyMonsterAttack,
    ModifyMonsterDefense,
    ModifyMonsterComment,
    AddMonsterElement,
    RemoveMonsterElement,
    MoveMonsterElement
} from './Functions/ManageCombatFunctions'   
import {
    UserLogin,
    MoveCharacterElement
} from '../Backend/Functions/UserFunctions'

//All Backend Functions should only be accessed via UI_Controller or from functions inside Backend Folder
const BACKEND_init_character = (_language) => {
    InitCharacter(_language);
}

const BACKEND_init_campaign = (_language) => {
    InitCampaign(_language);
}

const BACKEND_increment_level = (_increment) => 
{
    IncrementLevel(_increment);
    /*var _base_stats = BACKEND_get_base_stats();
    var _attribute_points = BACKEND_get_attribute_points();
    var _free_skill_points = BACKEND_get_free_skill_points();

    if(_base_stats.level + _increment <= 0)
        return;

    _base_stats.level += _increment;
    _attribute_points.total = GetFreeAttributePoints(_base_stats.level);
    _free_skill_points.total = GetFreeSkillPoints(_base_stats.level);

    BACKEND_set_base_stats(_base_stats);
    BACKEND_set_attribute_points(_attribute_points);
    BACKEND_set_free_skill_points(_free_skill_points);
    //BACKEND_calculate_max_attributes();
    BACKEND_recalc_attributes();
    BACKEND_recalc_character_stats();*/
}

const BACKEND_update_base_stat = (_base_stats) => 
{
    BACKEND_set_base_stats(_base_stats);
}

const BACKEND_update_campaign_name = (_name) => {
    BACKEND_set_campaign_name(_name)
}

/*const internal_check_attribute_max_value = (_increment, _attribute) => 
{
    if(_increment <= 0)
        return true;

    const _newAttributeValue = _attribute.increase + _increment;
    return (_newAttributeValue <= _attribute.max)
}

const internal_check_attribute_free_points = (_increment, _attribute) => 
{
    var _attribute_points = BACKEND_get_attribute_points();
    const _usedPoints = _attribute_points.used + _increment*_attribute.cost;
    return (_increment < 0 ) || (_usedPoints <= BACKEND_get_attribute_points().total);
}

const internal_check_attribute_negative_points = (_increment, _attribute) => 
{
    const _newAttributeValue = _attribute.increase + _increment;
    return (_newAttributeValue >= BACKEND_get_negative_attribute_restriction().single)
}

const internal_check_attribute_negative_total = (_increment, _attribute) =>
{
    var _total_minus = 0;

    var _attributeList = BACKEND_get_attributes();
    for(var key in _attributeList)
    {
        if (typeof _attributeList[key] !== 'undefined') {
            if(_attribute.id == _attributeList[key].id)
            {
                if(_attributeList[key].increase +_increment < 0){
                    _total_minus += _attributeList[key].increase +_increment;
                }
            }
            else
            {
                if(_attributeList[key].increase < 0){
                    _total_minus += _attributeList[key].increase;
                }
            }
            
        }
    }
    return (_total_minus >= BACKEND_get_negative_attribute_restriction().total)
}*/

const BACKEND_recalc_character_stats = () => {
    var _character_stats = BACKEND_get_character_stats();
    _character_stats.hitpoints.value = GetHitpoints();
    _character_stats.load_capacity.value = GetLoadCapacity();
    _character_stats.endurance.value = GetEndurance();
    _character_stats.kho_tal.value = GetKhoTal();
    BACKEND_set_character_stats(_character_stats);

}

/*const BACKEND_recalc_secondary_attributes = () => {
    var _secondary_attributes = BACKEND_get_secondary_attributes();
    _secondary_attributes.initiative.value = GetInitiative();
    _secondary_attributes.dodge.value = GetDodgeValue();
    _secondary_attributes.initiative.dice = GetDiceList(_secondary_attributes.initiative.value);
    _secondary_attributes.dodge.dice = GetDiceList(_secondary_attributes.dodge.value);
    BACKEND_set_secondary_attributes(_secondary_attributes);

}*/

const BACKEND_recalc_free_attribute_points = () => {
    var _free_attribute_points = BACKEND_get_free_attribute_points();
    var _base_stats = BACKEND_get_base_stats();
    
    _free_attribute_points.total = GetFreeAttributePoints(_base_stats.level);
    BACKEND_set_free_attribute_points(_free_attribute_points);
}

const BACKEND_recalc_free_focus_points = () => {
    var _free_focus_points = BACKEND_get_free_focus_points();
    var _base_stats = BACKEND_get_base_stats();

    _free_focus_points.total = GetFreeFocusPoints(_base_stats.level);
    BACKEND_set_free_focus_points(_free_focus_points);
}

const BACKEND_recalc_free_skill_points = () => {
    var _free_skill_points = BACKEND_get_free_skill_points();
    var _base_stats = BACKEND_get_base_stats();

    _free_skill_points.total = GetFreeSkillPoints(_base_stats.level);
    BACKEND_set_free_skill_points(_free_skill_points);
}

const BACKEND_recalc_attributes = () => {
    let _attributes = BACKEND_get_attributes();
    let _base_stats = BACKEND_get_base_stats();

    for(var key in _attributes)
    {
        if (typeof _attributes[key] !== 'undefined') {
            let _attribute_group = BACKEND_get_attribute_group_by_id(_attributes[key].group);
            _attributes[key].max = GetMaxAttributeIncrease(_attribute_group.focus, _base_stats.level);
            _attributes[key].value = CalculateAttribute(_attributes[key]);
            _attributes[key].dice = GetDiceList(_attributes[key].value);
        }
    }

    BACKEND_recalc_character_stats();
    //BACKEND_recalc_secondary_attributes();
    BACKEND_recalc_skills();
}

const BACKEND_recalc_skills = () => {
    var _skills = BACKEND_get_skills();
    for(var key1 in _skills)
    {
        //let _attribute = BACKEND_get_attribute_by_id(_skills[key1].skill_group_id);
        //_skills[key1].skill_points.total = GetSkillPointsAttribute(_attribute.value);
        for(var key2 in _skills[key1].skills)
        {
            if (typeof _skills[key1].skills[key2] !== 'undefined') {
                _skills[key1].skills[key2].max = GetMaxSkillIncrease(_skills[key1].skill_group_id, _skills[key1].skills[key2]);
                _skills[key1].skills[key2].value = CalculateSkill(_skills[key1].skill_group_id, _skills[key1].skills[key2]);
                _skills[key1].skills[key2].dice = GetDiceList(_skills[key1].skills[key2].value);
            }
        }
    }
    BACKEND_set_skills(_skills);

}      

const BACKEND_increment_attribute = (_attributeID, _increment, _type, _recalc) => 
{
    IncrementAttribute(_attributeID, _increment, _type, _recalc);
    
    /*var _attributes = BACKEND_get_attributes();
    var _attribute_points = BACKEND_get_attribute_points();
    for(var key in _attributes)
    {
        if (typeof _attributes[key] !== 'undefined') {
            if(_attributes[key].id == _attributeID){
                if((internal_check_attribute_free_points(_increment, _attributes[key])) && (internal_check_attribute_negative_points(_increment, _attributes[key])) && (internal_check_attribute_negative_total(_increment, _attributes[key]) && (internal_check_attribute_max_value(_increment, _attributes[key]))))
                {
                    _attributes[key].increase +=_increment;
                    _attribute_points.used += _increment*_attributes[key].cost;
                    //_attributes[key].value = CalculateAttribute(_attributes[key]);
                    //_attributes[key].dice = GetDiceList(_attributes[key].value);
                }
            }
        }
    }
    BACKEND_set_attributes(_attributes);
    BACKEND_set_attribute_points(_attribute_points);

    if(_recalc)
        BACKEND_recalc_attributes();*/
}



/*const internal_check_skill_max_value = (_increment, _skill) => 
{
    if(_increment <= 0)
        return true;
    
    const _newSkillValue = _skill.increase + _increment;
    return (_newSkillValue <= _skill.max)
}

const internal_check_skill_free_points = (_increment, _skill) => 
{
    var _skill_points = BACKEND_get_skill_points();
    const _usedPoints = _skill_points.used + _increment*_skill.cost;
    return (_increment < 0 ) || (_usedPoints <= BACKEND_get_skill_points().total);
}

const internal_check_skill_negative_points = (_increment, _skill) => 
{
    const _newSkillValue = _skill.increase + _increment;
    return (_newSkillValue >= BACKEND_get_negative_skill_restriction().single)
}

const internal_check_skill_negative_total = (_increment, _skill_group, _skill) =>
{
    var _total_minus = 0;

    var _skillList = BACKEND_get_skills();
    for(var key1 in _skillList)
    {
        for(var key2 in _skillList[key1].skills)
        {
            
        }
            
    }
    return true; //(_total_minus >= BACKEND_get_negative_skill_restriction().total)
}*/

const BACKEND_increment_skill = (_attributeID, _skillID, _increment, _type, _recalc) => 
{
    IncrementSkill(_attributeID, _skillID, _increment, _type, _recalc);
    
    /*var _skills = BACKEND_get_skills();
    var _skill_points = BACKEND_get_skill_points();
    for(var key1 in _skills)
    {
        if(_skills[key1].skill_group_id == _groupID){
            for(var key2 in _skills[key1].skills)
            {
                if(_skills[key1].skills[key2].id == _skillID){
                    if((internal_check_skill_free_points(_increment, _skills[key1].skills[key2])) && (internal_check_skill_negative_points(_increment, _skills[key1].skills[key2])) && (internal_check_skill_negative_total(_increment, _skills[key1], _skills[key1].skills[key2]) && (internal_check_skill_max_value(_increment, _skills[key1].skills[key2]))))
                    {
                        _skills[key1].skills[key2].increase +=_increment;
                        _skill_points.used += _increment*_skills[key1].skills[key2].cost;
                        //_attributes[key].value = CalculateAttribute(_attributes[key]);
                        //_attributes[key].dice = GetDiceList(_attributes[key].value);
                    }
                }
            }
        }
    }
    BACKEND_set_skills(_skills);
    BACKEND_set_skill_points(_skill_points);

    if(_recalc)
        BACKEND_recalc_skills();*/
}

/*const BACKEND_increment_ancestry_bonus = (_attributeID, _increment, _recalc) => {
    var _attributes = BACKEND_get_attributes();
    for(var key in _attributes)
    {
        if (typeof _attributes[key] !== 'undefined') {
            if(_attributes[key].id == _attributeID){
                _attributes[key].ancestry_bonus +=_increment;
                //_attributes[key].value = CalculateAttribute(_attributes[key]);
                //_attributes[key].dice = GetDiceList(_attributes[key].value);
            }
        }
    }
    BACKEND_set_attributes(_attributes);

    if(_recalc)
        BACKEND_recalc_attributes();
}

const BACKEND_increment_other_attribute_bonus = (_attributeID, _increment, _recalc) => {
    var _attributes = BACKEND_get_attributes();
    for(var key in _attributes)
    {
        if (typeof _attributes[key] !== 'undefined') {
            if(_attributes[key].id == _attributeID){
                _attributes[key].other_bonus +=_increment;
                //_attributes[key].value = CalculateAttribute(_attributes[key]);
                //_attributes[key].dice = GetDiceList(_attributes[key].value);
            }
        }
    }
    BACKEND_set_attributes(_attributes);

    if(_recalc)
        BACKEND_recalc_attributes();
}

const BACKEND_increment_other_skill_bonus = (_groupID, _skillID, _increment, _recalc) => {
    var _skills = BACKEND_get_skills();
    for(var key1 in _skills)
    {
        if(_skills[key1].skill_group_id == _groupID)
        {
            for(var key2 in _skills[key1].skills)
            {
                if(_skills[key1].skills[key2].id == _skillID)
                {
                    _skills[key1].skills[key2].other_bonus +=_increment;
                }
            }
        }
    }
    BACKEND_set_skills(_skills);

    if(_recalc)
        BACKEND_recalc_skills();
}

const internal_check_focus_id = (_id1, _id2) => {
    return (_id1 == _id2);
}

const internal_check_focus_free_points = (_focus, _increment) => {
    if(_increment != 1 && _increment != -1)
        return false;

    if(_increment == -1)
        return true;

    var _focus_points = BACKEND_get_focus_points();
    var _cost = GetFocusCost(_focus);

    if((_focus_points.used + _increment*_cost > _focus_points.total))
        return false;

    return true;
}

const internal_check_focus_max_min = (_focus, _increment) => {
    var _restriction = BACKEND_get_negative_focus_restriction();
    if(_focus +  _increment > _restriction.max)
        return false;

    if(_focus +  _increment < _restriction.min)
        return false;

    return true;
}

const internal_check_focus_negative_total = (_attributeGroupID, _increment) =>
{
    var _total_minus = 0;

    var _restriction = BACKEND_get_negative_focus_restriction();
    var _attributeGroups = BACKEND_get_attribute_groups();
    for(var key in _attributeGroups)
    {
        if (typeof _attributeGroups[key] !== 'undefined') {
            if(_attributeGroupID == _attributeGroups[key].id)
            {
                if(_attributeGroups[key].focus +_increment < 0){
                    _total_minus += _attributeGroups[key].focus +_increment;
                }
            }
            else
            {
                if(_attributeGroups[key].focus < 0){
                    _total_minus += _attributeGroups[key].focus;
                }
            }
            
        }
    }
    return (_total_minus >= _restriction.total)
}*/

const BACKEND_increment_focus = (_attributeGroupID, _increment) => {
    IncrementFocus(_attributeGroupID, _increment);
    
    
    /*var _attribute_groups = BACKEND_get_attribute_groups();
    var _focus_points = BACKEND_get_focus_points();
    for(var key in _attribute_groups)
    {
        if (typeof _attribute_groups[key] !== 'undefined') {
            if((internal_check_focus_id(_attribute_groups[key].id, _attributeGroupID)) && (internal_check_focus_free_points(_attribute_groups[key].focus, _increment)) && (internal_check_focus_max_min(_attribute_groups[key].focus, _increment)) && (internal_check_focus_negative_total(_attributeGroupID, _increment))){
                if(_increment == 1)
                {
                    _focus_points.used += GetFocusCost(_attribute_groups[key].focus);
                }
                else
                {
                    _focus_points.used -= GetFocusCost(_attribute_groups[key].focus-1);
                }
                _attribute_groups[key].focus += _increment;
            }
        }
    }
    BACKEND_set_attribute_groups(_attribute_groups);
    BACKEND_set_focus_points(_focus_points);
    //BACKEND_calculate_max_attributes();
    BACKEND_recalc_attributes();*/
}

const BACKEND_increment_kho_tal = (_increment) => {
    IncrementKhoTal(_increment);
    
    
    /*var _character_stats = BACKEND_get_character_stats();

    if(_increment > 0 && _character_stats.kho_tal.value - (_character_stats.kho_tal.lost+_increment)<0)
        return;

    if(_increment < 0 && _character_stats.kho_tal.lost+_increment < 0)
        return;

    _character_stats.kho_tal.lost += _increment;
    BACKEND_set_character_stats(_character_stats)*/
}

const BACKEND_increment_free_attribute_points_bonus = (_increment) => {
    IncrementFreeAttributePointsBonus(_increment);
    
    /*var _attribute_points = BACKEND_get_attribute_points();
    _attribute_points.bonus += _increment;
    BACKEND_set_attribute_points(_attribute_points);
    BACKEND_recalc_attribute_points();*/
}

const BACKEND_increment_free_skill_points_bonus = (_increment) => {
    IncrementFreeSkillPointsBonus(_increment);
    
    /*var _skill_points = BACKEND_get_skill_points();
    _skill_points.bonus += _increment;
    BACKEND_set_skill_points(_skill_points);
    BACKEND_recalc_skill_points();*/
}

const BACKEND_increment_free_focus_points_bonus = (_increment) => {
    IncrementFreeFocusPointsBonus(_increment);
    
    /*var _focus_points = BACKEND_get_focus_points();
    _focus_points.bonus += _increment;
    BACKEND_set_focus_points(_focus_points);
    BACKEND_recalc_focus_points();*/
}

/*const internal_get_attribute_group_by_id = (_group_id) =>
{
    var _attribute_groups = BACKEND_get_attribute_groups();
    return GetElementById(_group_id, _attribute_groups);
    for(var key in _attribute_groups)
    {
        if (typeof _attribute_groups[key] !== 'undefined') {
            if(_group_id == _attribute_groups[key].id)
            {
                return _attribute_groups[key];
            }
        }
    }
    return null;
}*/

const BACKEND_change_hitpoints = (_val) => {
    var _character_stats = BACKEND_get_character_stats();
    var _valInt = ParseToInteger(_val);
    if(_character_stats.hitpoints.lost - _valInt >= 0)
    {
        _character_stats.hitpoints.lost -= _valInt;
    }
    else
    {
        _character_stats.hitpoints.lost = 0;
    }
}

const BACKEND_get_attribute_group_by_id = (_id) => {
    var _attributes_groups = BACKEND_get_attribute_groups();
    return GetElementById(_id, _attributes_groups);
    /*for(var key in _attributes)
    {
        if(_attributes[key].id == _id)
        {
            return _attributes[key];
        }
    }
    return null;*/
}

const BACKEND_get_attribute_by_id = (_id) => {
    var _attributes = BACKEND_get_attributes();
    return GetElementById(_id, _attributes);
}

const BACKEND_get_skill_group_by_id = (_id) => {
    var _skills = BACKEND_get_skills();
    return GetElementById(_id, _skills, 'skill_group_id');
}

const BACKEND_get_skill_by_id = (_group_id, _skill_id) => {
    var _skill_group = BACKEND_get_skill_group_by_id(_group_id);
    return GetElementById(_skill_id, _skill_group.skills);
}

/*const BACKEND_get_weapon_by_id = (_weapon_id, _type) => {
    let _weapon_list = null;
    if(_type == 'unarmed')
    {
        _weapon_list = BACKEND_get_unarmed_weapons();
    }
    else if(_type == 'melee')
    {
        _weapon_list = BACKEND_get_melee_weapons();
    }
    else if(_type == 'ranged')
    {
        _weapon_list = BACKEND_get_ranged_weapons();
    }
    return GetElementById(_weapon_id, _weapon_list);
}*/


const BACKEND_modify_character_stat_bonus = (_id, _value) => {
    var _character_stats = BACKEND_get_character_stats();

    for(var key in _character_stats)
    {
        if(_character_stats[key].id == _id)
        {
            _character_stats[key].bonus = ParseToInteger(_value);
        }
    }
    BACKEND_set_character_stats({..._character_stats});
    BACKEND_recalc_character_stats();
}

/*const BACKEND_modify_secondary_attribute_bonus = (_id, _value) => {
    var _secondary_attributes = BACKEND_get_secondary_attributes();
    for(var key in _secondary_attributes)
    {
        if(_secondary_attributes[key].id == _id)
        {
            _secondary_attributes[key].bonus = ParseToInteger(_value);
        }
    }
    BACKEND_set_secondary_attributes({..._secondary_attributes});
    BACKEND_recalc_secondary_attributes();
}*/

const BACKEND_switch_weapon_type = (_weapon_type_id, _weapon_id, _type) => {
    SwitchWeaponType(_weapon_type_id, _weapon_id, _type);
}

/*const BACKEND_switch_ranged_weapon_type = (_weapon_type_id, _weapon_id) => {
    SwitchRangedWeaponType(_weapon_type_id, _weapon_id);
}*/

/*const BACKEND_switch_hand_type = (_hand_type_id, _weapon_id, _type) => {
    SwitchHandType(_hand_type_id, _weapon_id, _type);
}*/

const BACKEND_modify_weapon_name = (_weapon_id, _new_value, _type) => {
    ModifyWeaponName(_weapon_id, _new_value, _type);
}

const BACKEND_modify_weapon_weight = (_weapon_id, _new_value, _type) => {
    ModifyWeaponWeight(_weapon_id, _new_value, _type);
}

const BACKEND_modify_melee_weapon_attack_mod = (_weapon_id, _new_value, _type) => {
    ModifyMeleeWeaponAttackMod(_weapon_id, _new_value, _type);
}

const BACKEND_modify_melee_weapon_defense_mod = (_weapon_id, _new_value, _type) => {
    ModifyMeleeWeaponDefenseMod(_weapon_id, _new_value, _type);
}

const BACKEND_modify_ranged_weapon_precision_mod = (_weapon_id, _new_value) => {
    ModifyRangedWeaponPrecisionMod(_weapon_id, _new_value);
}

const BACKEND_modify_ammo_name = (_weapon_id, _new_value, _type) => {
    ModifyAmmoName(_weapon_id, _new_value, _type);
}

const BACKEND_modify_ammo = (_weapon_id, _new_value, _type) => {
    ModifyAmmo(_weapon_id, _new_value, _type);
}

const BACKEND_modify_ammo_weight = (_weapon_id, _new_value, _type) => {
    ModifyAmmoWeight(_weapon_id, _new_value, _type);
}

const BACKEND_modify_damage = (_weapon_id, _new_value, _type) => {
    ModifyDamage(_weapon_id, _new_value, _type);
}

const BACKEND_switch_lead_attribute = (_lead_attribute, _weapon_id, _type) => {
    SwitchLeadAttribute(_lead_attribute, _weapon_id, _type);
}

const BACKEND_add_new_weapon = (_type) => {
    AddNewWeapon(_type);
}

const BACKEND_remove_weapon = (_weapon, _type) => {
    RemoveWeapon(_weapon, _type);
}

const BACKEND_move_weapon = (_weapon, _direction, _type) => {
    MoveWeapon(_weapon, _direction, _type);
}

/*const BACKEND_calculate_max_attributes = () =>
{
    var _attributes = BACKEND_get_attributes();
    var _base_stats = BACKEND_get_base_stats();
    for(var key in _attributes)
    {
        if (typeof _attributes[key] !== 'undefined') {
            var _group = GetElementById(_attributes[key].group, BACKEND_get_attribute_groups()); //internal_get_attribute_group_by_id(_attributes[key].group);

            if(_group != null)
            {
                _attributes[key].max = GetMaxAttributeIncrease(_group.focus, _base_stats.level);
            }
        }
    }
    BACKEND_set_attributes(_attributes);
}*/



const BACKEND_modify_money_list = (_index, _value) => {
    ModifyMoneyList(_index, _value);
}

const BACKEND_switch_language = (_language) => {
    //let _tool_data = BACKEND_get_tool_data();

    Translate(_language);
    //_tool_data.language = _language;
    //BACKEND_set_tool_data(_tool_data);
}

/*const BACKEND_get_character = () => 
{
    const _character = {
        tool_data: BACKEND_get_tool_data(),
        attribute_points_bonus: BACKEND_get_attribute_points().bonus,
        focus_points_bonus: BACKEND_get_focus_points().bonus,
        skill_points_bonus: BACKEND_get_skill_points().bonus,
        base_stats: BACKEND_get_base_stats(),
        comments: BACKEND_get_comments(),
        attribute_groups: BACKEND_get_attribute_groups(), 
        attributes: BACKEND_get_attributes(),
        character_stats: BACKEND_get_character_stats(),
        secondary_attributes: BACKEND_get_secondary_attributes()
    }

    return _character;
}*/

const BACKEND_get_character_file = () => 
{
    return GetCharacterFile(); //new Blob([JSON.stringify(BACKEND_get_character(), null, 2)], {type: 'text/plain'});
}

const BACKEND_get_campaign_file = () => {
    return GetCampaignFile();
}

const BACKEND_upload_character = (e, callback) => {
    UploadCharacter(e, callback);
}

const BACKEND_upload_campaign = (e, callback) => {
    UploadCampaign(e, callback);
}

const BACKEND_roll_dice = (_name, _dice, _good_roll, _roll_type = 'default', _parameter = '') => {

    var _dice_log = BACKEND_get_dice_log(); 
    var _temp_roll = 0;
    var _result = 0;
    var _foundry_dice_string = GetFoundryDiceListString(_dice, _roll_type, _parameter);

    _dice_log.dice = _dice;
    //_dice_log.log_message = BACKEND_get_base_stats().name + " " + _dice_log.rolled_label  + " " +  _name + '\n';
    _dice_log.log_message = _dice_log.rolled_label + '\n';
    _dice_log.log_message += _foundry_dice_string;
    /*for(var key in _dice.dice){
        _temp_roll = RollSingleDice(_dice.dice[key]);
        _dice_log.log_message += '\t' + GetDiceListString({dice: [_dice.dice[key]], offset: 0}) + "=" + _temp_roll + '\n';
        _result += _temp_roll
        if(_good_roll)
        {
            if(_temp_roll == _dice.dice[key])
            {
                _temp_roll = RollSingleDice(_dice.dice[key]);
                _dice_log.log_message += '\t\t' + GetDiceListString({dice: [_dice.dice[key]], offset: 0}) + "=" + _temp_roll + '\n';
                _result += _temp_roll
            }
        }
    }
    _result += _dice.offset;*/
    _dice_log.value = _result;
    //_dice_log.log_message += _dice_log.result_label + ': ' + _result;
    navigator.clipboard.writeText(_foundry_dice_string);
    BACKEND_set_dice_log(_dice_log);
}

const BACKEND_modify_armor_name = (_id, _new_value) => {
    ModifyArmorName(_id, _new_value);
}

const BACKEND_toggle_wear_armor = (_id) => {
    ToggleWearArmor(_id);
}

const BACKEND_modify_armor_weight = (_id, _new_value) => {
    ModifyArmorWeight(_id, _new_value);
}

const BACKEND_modify_armor_class = (_id, _new_value) => {
    ModifyArmorClass(_id, _new_value);
}

const BACKEND_modify_armor_restriction = (_id, _new_value) => {
    ModifyArmorRestriction(_id, _new_value);
}

const BACKEND_add_new_armor_element = () => {
    AddNewArmorElement();
}

const BACKEND_remove_armor_element = (_element) => {
    RemoveArmorElement(_element);
}

const BACKEND_move_armor_element = (_element, _direction) => {
    MoveArmorElement(_element, _direction);
}

const BACKEND_modify_feat_name = (_id, _new_value, _feat_level) => {
    ModifyFeatName(_id, _new_value, _feat_level);
}

const BACKEND_modify_feat_description = (_id, _new_value, _feat_level) => {
    ModifyFeatDescription(_id, _new_value, _feat_level);
}

const BACKEND_add_new_feat_element = (_feat_level) => {
    AddNewFeatElement(_feat_level);
}

const BACKEND_remove_feat_element = (_element, _feat_level) => {
    RemoveFeatElement(_element, _feat_level);
}

const BACKEND_move_feat_element = (_element, _direction, _feat_level) => {
    MoveFeatElement(_element, _direction, _feat_level);
}


const BACKEND_modify_gear_name = (_id, _new_value) => {
    ModifyGearName(_id, _new_value);
}

const BACKEND_modify_gear_amount = (_id, _new_value) => {
    ModifyGearAmount(_id, _new_value);
}

const BACKEND_modify_gear_weight = (_id, _new_value) => {
    ModifyGearWeight(_id, _new_value);
}

const BACKEND_modify_gear_description = (_id, _new_value) => {
    ModifyGearDescription(_id, _new_value);
}

const BACKEND_add_new_gear_element = () => {
    AddNewGearElement();
}

const BACKEND_remove_gear_element = (_element) => {
    RemoveGearElement(_element);
}

const BACKEND_move_gear_element = (_element, _direction) => {
    MoveGearElement(_element, _direction);
}

const BACKEND_get_max_magic_circle = () => {
    return GetMagicSkillIncrease();
}


const BACKEND_modify_magic_spell_name = (_id, _new_value) => {
    ModifyMagicSpellName(_id, _new_value);
}

const BACKEND_modify_magic_spell_might = (_id, _new_value) => {
    ModifyMagicSpellMight(_id, _new_value);
}

const BACKEND_modify_magic_spell_description = (_id, _new_value) => {
    ModifyMagicSpellDescription(_id, _new_value);
}

const BACKEND_switch_magic_element_type = (_type_id, _list_element_id) => {
    SwitchMagicElementType(_type_id, _list_element_id);
}

const BACKEND_switch_magic_circle_type = (_type_id, _list_element_id) => {
    SwitchMagicCircleType(_type_id, _list_element_id);
}

const BACKEND_add_new_magic_spell = () => {
    AddNewMagicSpell();
}

const BACKEND_remove_magic_spell = (_element) => {
    RemoveMagicSpell(_element);
}

const BACKEND_move_magic_spell = (_element, _direction) => {
    MoveMagicSpell(_element, _direction);
}


const BACKEND_modify_monster_name = (_id, _new_value) => {
    ModifyMonsterName(_id, _new_value);
}

const BACKEND_modify_monster_initiative = (_id, _new_value) => {
    ModifyMonsterInitiative(_id, _new_value);
}

const BACKEND_modify_monster_damage = (_id, _new_value) => {
    ModifyMonsterDamage(_id, _new_value);
}

const BACKEND_modify_monster_hitpoints = (_id, _new_value) => {
    ModifyMonsterHitpoints(_id, _new_value);
}

const BACKEND_modify_monster_reflexes = (_id, _new_value) => {
    ModifyMonsterReflexes(_id, _new_value);
}

const BACKEND_modify_monster_attack = (_id, _new_value) => {
    ModifyMonsterAttack(_id, _new_value);
}

const BACKEND_modify_monster_defense = (_id, _new_value) => {
    ModifyMonsterDefense(_id, _new_value);
}

const BACKEND_modify_monster_comment = (_id, _new_value) => {
    ModifyMonsterComment(_id, _new_value);
}

const BACKEND_add_monster_element = () => {
    AddMonsterElement();
}

const BACKEND_remove_monster_element = (_element) => {
    RemoveMonsterElement(_element);
}

const BACKEND_move_monster_element = (_element, _direction) => {
    MoveMonsterElement(_element, _direction);
}

const BACKEND_user_login = (_user) => {
    UserLogin(_user);
}

const BACKEND_move_character_element = (_character_id, _direction) => {
    MoveCharacterElement(_character_id, _direction);
}

const BACKEND_move_campaign_element = (_campaign_id, _direction) => {
    MoveCampaignElement(_campaign_id, _direction);
}

const BACKEND_move_subscribed_user_element = (_user_name, _direction) => {
    MoveSubscribedUserElement(_user_name, _direction);
}

export {
    BACKEND_increment_level, 
    BACKEND_update_base_stat, 
    BACKEND_update_campaign_name,
    BACKEND_increment_attribute,
    BACKEND_increment_skill,
    BACKEND_increment_focus,
    BACKEND_increment_kho_tal,
    BACKEND_increment_free_attribute_points_bonus,
    BACKEND_increment_free_skill_points_bonus,
    BACKEND_increment_free_focus_points_bonus,
    //BACKEND_calculate_max_attributes,
    BACKEND_switch_language,
    //BACKEND_get_character,
    BACKEND_get_character_file,
    BACKEND_get_campaign_file,
    BACKEND_upload_character,
    BACKEND_upload_campaign,
    BACKEND_init_character,
    BACKEND_init_campaign,
    BACKEND_get_attribute_group_by_id,
    BACKEND_get_attribute_by_id,
    BACKEND_get_skill_group_by_id,
    BACKEND_get_skill_by_id,
    BACKEND_roll_dice,
    BACKEND_recalc_attributes,
    BACKEND_recalc_free_attribute_points,
    BACKEND_recalc_free_focus_points,
    BACKEND_recalc_free_skill_points,
    BACKEND_modify_character_stat_bonus,
    //BACKEND_modify_secondary_attribute_bonus,
    BACKEND_change_hitpoints,
    BACKEND_switch_weapon_type,
    //BACKEND_switch_ranged_weapon_type,
    //BACKEND_switch_hand_type,
    BACKEND_modify_weapon_name,
    BACKEND_modify_weapon_weight,
    BACKEND_modify_melee_weapon_attack_mod,
    BACKEND_modify_melee_weapon_defense_mod,
    BACKEND_modify_ranged_weapon_precision_mod,
    BACKEND_modify_ammo_name,
    BACKEND_modify_ammo,
    BACKEND_modify_ammo_weight,
    BACKEND_modify_damage,
    BACKEND_switch_lead_attribute,
    BACKEND_add_new_weapon,
    BACKEND_remove_weapon,
    BACKEND_move_weapon,
    BACKEND_modify_money_list,
    BACKEND_modify_armor_name,
    BACKEND_toggle_wear_armor,
    BACKEND_modify_armor_weight,
    BACKEND_modify_armor_class,
    BACKEND_modify_armor_restriction,
    BACKEND_add_new_armor_element,
    BACKEND_remove_armor_element,
    BACKEND_move_armor_element,
    BACKEND_modify_feat_name,
    BACKEND_modify_feat_description,
    BACKEND_add_new_feat_element,
    BACKEND_remove_feat_element,
    BACKEND_move_feat_element,
    BACKEND_modify_gear_name,
    BACKEND_modify_gear_amount,
    BACKEND_modify_gear_weight,
    BACKEND_modify_gear_description,
    BACKEND_add_new_gear_element,
    BACKEND_remove_gear_element,
    BACKEND_move_gear_element,
    BACKEND_get_max_magic_circle,
    BACKEND_modify_magic_spell_name,
    BACKEND_modify_magic_spell_might,
    BACKEND_modify_magic_spell_description,
    BACKEND_switch_magic_element_type,
    BACKEND_switch_magic_circle_type,
    BACKEND_add_new_magic_spell,
    BACKEND_remove_magic_spell,
    BACKEND_move_magic_spell,
    BACKEND_modify_monster_name,
    BACKEND_modify_monster_initiative,
    BACKEND_modify_monster_damage,
    BACKEND_modify_monster_hitpoints,
    BACKEND_modify_monster_reflexes,
    BACKEND_modify_monster_attack,
    BACKEND_modify_monster_defense,
    BACKEND_modify_monster_comment,
    BACKEND_add_monster_element,
    BACKEND_remove_monster_element,
    BACKEND_move_monster_element,
    BACKEND_move_character_element,
    BACKEND_move_campaign_element,
    BACKEND_move_subscribed_user_element,
    BACKEND_user_login
};