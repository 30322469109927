import { 
    ParseToFloat, 
    ParseToInteger ,
    GetListParameterByID,
    SetListParameterByID,
    AddElementToList,
    RemoveElementFromList,
    MoveElementInList
} from '../../API/Functions/GeneralFunctions'

import {
    BACKEND_get_armor,
    BACKEND_set_armor,
    BACKEND_get_gear,
    BACKEND_set_gear,
    BACKEND_get_feat_lv_2,
    BACKEND_set_feat_lv_2,
    BACKEND_get_feat_lv_3,
    BACKEND_set_feat_lv_3,
    BACKEND_get_magic_spells,
    BACKEND_set_magic_spells,
    BACKEND_get_armor_template,
    BACKEND_get_gear_template,
    BACKEND_get_feat_lv_2_template,
    BACKEND_get_feat_lv_3_template,
    BACKEND_get_magic_spells_template,
    BACKEND_set_magic_element_types,
    BACKEND_get_magic_element_types,
    BACKEND_set_magic_circle_types,
    BACKEND_get_magic_circle_types
} from '../Parameters/CharacterParameters'

import {
    RecalcCharacterStats,
    //RecalcSecondaryAttributes,
    RecalcFreeAttributePoints,
    RecalcFreeFocusPoints,
    RecalcFreeSkillPoints,
    RecalcAttributes,
    RecalcSkills,
    RecalcWeapons,
    RecalcWeight,
    RecalcArmor
} from './RecalcFunctions'


const ModifyGearName = (_id, _new_value) => {
    SetListParameterByID(_id, BACKEND_get_gear(), BACKEND_set_gear, _new_value, 'text', 'id', 'display_name');
}

const ModifyGearAmount = (_id, _new_value) => {
    SetListParameterByID(_id, BACKEND_get_gear(), BACKEND_set_gear, _new_value, 'integer', 'id', 'amount');
    RecalcWeight();
}

const ModifyGearWeight = (_id, _new_value) => {
    SetListParameterByID(_id, BACKEND_get_gear(), BACKEND_set_gear, _new_value, 'float', 'id', 'weight');
    RecalcWeight();
    
}

const ModifyGearDescription = (_id, _new_value) => {
    SetListParameterByID(_id, BACKEND_get_gear(), BACKEND_set_gear, _new_value, 'text', 'id', 'description');
}

const AddNewGearElement = () => {
    AddElementToList(BACKEND_get_gear(), BACKEND_get_gear_template(), BACKEND_set_gear);
    RecalcWeight();
}

const RemoveGearElement = (_element) => {
    RemoveElementFromList(_element.id, BACKEND_get_gear(), BACKEND_set_gear);
    RecalcWeight();
}

const MoveGearElement = (_element, _direction) => {
    MoveElementInList(_element.id, BACKEND_get_gear(), BACKEND_set_gear, _direction);
}

export {
    MoveGearElement,
    RemoveGearElement,
    AddNewGearElement,
    ModifyGearDescription,
    ModifyGearWeight,
    ModifyGearAmount,
    ModifyGearName
}