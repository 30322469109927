import { useState, useContext, useEffect } from 'react'
import {BaseStatContext, BaseStatContextProvider} from '../API/BaseStatContext'
import {AttributeContext, AttributeContextProvider} from '../API/AttributeContext'
import {ToolDataContext, ToolDataContextProvider} from '../API/ToolDataContext'
import {TextDataContext, TextDataContextProvider} from '../API/TextDataContext'
//import {FunctionContext, FunctionContextProvider} from '../DataConvertContext/FunctionContext'
import {CalculateFreeAttribute, GetFreeAttributePoints} from '../Backend/Functions/CharacterFunctions'
//import {IncrementLevel} from '../Backend/CharacterController'
import {APIContext, APIContextProvider} from '../API/API'
import {ParseToInteger} from '../API/Functions/GeneralFunctions'
import btn from '../Styles/btn.module.css'
import field from '../Styles/field.module.css'
import table from '../Styles/table.module.css'

const BaseStatsPage = () => {
    const {
        API_get_attributes
    } = useContext(AttributeContext);

    const {
        API_get_base_stats,
        API_get_free_attribute_points,
        API_get_free_focus_points,
        API_get_edit_character_visibility,
        API_set_edit_character_visibility
    } = useContext(BaseStatContext);

    const {
        API_full_GUI_update,
        API_increment_level,
        API_update_base_stat,
        API_increment_attribute,
        API_init_character
    } = useContext(APIContext);

    const {
        API_get_base_stat_labels, 
        API_set_base_stat_labels,
        API_get_std_labels,
        API_set_std_labels
    } = useContext(TextDataContext);

    const {
        API_get_tool_data,
        API_get_dice_log,
        API_get_dice_log_visibility,
        API_set_dice_log_visibility
    } = useContext(ToolDataContext);

    const init = () => {
        API_init_character(API_get_tool_data().language);
    }

    /*const toggleEdit = () => {
        API_set_edit_character_visibility(!API_get_edit_character_visibility());
    }*/

    const calculateFreePoints = (m_Level) => 
    {
        return GetFreeAttributePoints(m_Level);
    }

    const increaseLevel = () => 
    {
        API_increment_level(1);
    }
    
    const decreaseLevel = () => 
    {
        API_increment_level(-1);
    }

    const sendBaseStat = (_baseStat) => {
        API_update_base_stat(_baseStat);
    }  

    return ( 
        <div style={{display: 'block', marginLeft: '15px', paddingBottom: '8px', background: 'lightgray'}} >
            {/*<div style={{width: 250, minWidth: 250, display: 'inline-block'}}>
                <button onClick={toggleEdit} className={btn.toggle_btn} style={{width: 120, marginLeft: 15}}>{API_get_std_labels().edit_character}</button>
            </div>*/}
            <div>
                <div style={{display: 'inline-block'}}>
                    <table className = {table.table_base_stats} style={{padding: '8px'}}>
                        <tr>
                            <td className={table.cell_label} style={{height: 22, width: 80, minWidth: 80, textAlign: 'left'}}><p className={field.paragraph_std}>{API_get_base_stat_labels().name}</p></td>
                            <td className={table.cell_input} style={{textAlign: 'left'}} colspan={5}>
                                {!API_get_edit_character_visibility() && 
                                    <p className={field.paragraph_std}>{API_get_base_stats().name}</p>
                                }
                                {API_get_edit_character_visibility() && 
                                    <input className={field.input_field_text} style={{width: 385}} value={API_get_base_stats().name} onChange={(e) => sendBaseStat({...API_get_base_stats(), name: e.target.value})} />
                                }
                            </td>
                            <td className={table.cell_transparent}></td>
                            <td className={table.cell_label} style={{height: 22, width: 80, minWidth: 80, textAlign: 'left'}}><p className={field.paragraph_std}>{API_get_base_stat_labels().ancestry}</p></td>
                            <td className={table.cell_input} style={{textAlign: 'left', width: 180, minWidth: 180}}>
                                {!API_get_edit_character_visibility() && 
                                    <p className={field.paragraph_std}>{API_get_base_stats().ancestry}</p>
                                }
                                {API_get_edit_character_visibility() && 
                                    <input className={field.input_field_text} style={{width: 160}} value={API_get_base_stats().ancestry} onChange={(e) => sendBaseStat({...API_get_base_stats(), ancestry: e.target.value})} />
                                }
                            </td>
                            <td className={table.cell_transparent}></td>
                            <td className={table.cell_label} style={{width: 95, minWidth: 95, textAlign: 'left'}}><p className={field.paragraph_std}>{API_get_base_stat_labels().hair_color}</p></td>
                            <td className={table.cell_input} style={{width: 148, minWidth: 148, textAlign: 'left'}}>
                                {!API_get_edit_character_visibility() && 
                                    <p className={field.paragraph_std}>{API_get_base_stats().hair_color}</p>
                                }
                                {API_get_edit_character_visibility() && 
                                    <input className={field.input_field_text} style={{width: 130}} value={API_get_base_stats().hair_color} onChange={(e) => sendBaseStat({...API_get_base_stats(), hair_color: e.target.value})} />
                                }
                            </td>
                            <td className={table.cell_transparent}></td>
                            
                            <td className={table.cell_label} style={{textAlign: 'left', width:85, minWidth: 85}}><p className={field.paragraph_std}>{API_get_base_stat_labels().famous}</p></td>
                            <td className={table.cell_input} style={{width:80, minWidth: 80}}>
                                {!API_get_edit_character_visibility() && 
                                    <p className={field.paragraph_std}>{API_get_base_stats().famous}</p>
                                }
                                {API_get_edit_character_visibility() && 
                                    <input type='number' className={field.input_field_number} style={{width: 60}} value={API_get_base_stats().famous} onChange={(e) => sendBaseStat({...API_get_base_stats(), famous: ParseToInteger(e.target.value)})} />
                                }
                            </td>
                            <td className={table.cell_transparent}></td>
                            <td className={table.cell_label} style={{textAlign: 'left', width: 105, minWidth: 105}}><p className={field.paragraph_std}>{API_get_base_stat_labels().experience}</p></td>
                            <td className={table.cell_input} style={{width: 80, minWidth: 80}}>
                                {!API_get_edit_character_visibility() && 
                                    <p className={field.paragraph_std}>{API_get_base_stats().experience}</p>
                                }
                                {API_get_edit_character_visibility() && 
                                    <input type='number' className={field.input_field_number} style={{width: 60}} value={API_get_base_stats().experience} onChange={(e) => sendBaseStat({...API_get_base_stats(), experience: ParseToInteger(e.target.value)})} />
                                }
                            </td>
                        </tr>
                        <tr style={{height: 0}}></tr>
                        <tr>
                            <td className={table.cell_label} style={{textAlign: 'left'}}><p className={field.paragraph_std}>{API_get_base_stat_labels().age}</p></td>
                            <td className={table.cell_input} style={{width: 80, minWidth: 80}}>
                                {!API_get_edit_character_visibility() && 
                                    <p className={field.paragraph_std}>{API_get_base_stats().age}</p>
                                }
                                {API_get_edit_character_visibility() && 
                                    <input type='number' className={field.input_field_number} style={{width: 60}} value={API_get_base_stats().age} onChange={(e) => sendBaseStat({...API_get_base_stats(), age: ParseToInteger(e.target.value)})} />
                                }
                            </td>
                            <td className={table.cell_label} style={{width: 70, minWidth: 70, textAlign: 'center'}}><p className={field.paragraph_std}>{API_get_base_stat_labels().height}</p></td>
                            <td className={table.cell_input} style={{width: 80, minWidth: 80}}>
                                {!API_get_edit_character_visibility() && 
                                    <p className={field.paragraph_std}>{API_get_base_stats().height}cm</p>
                                }
                                {API_get_edit_character_visibility() && 
                                    <input type='number' className={field.input_field_number} style={{width: 60, minWidth: 60}} value={API_get_base_stats().height} onChange={(e) => sendBaseStat({...API_get_base_stats(), height: ParseToInteger(e.target.value)})} />
                                }
                            </td>
                            <td className={table.cell_label} style={{width: 70, minWidth: 70, textAlign: 'center'}}><p className={field.paragraph_std}>{API_get_base_stat_labels().weight}</p></td>
                            <td className={table.cell_input} style={{width: 80, minWidth: 80}}>
                                {!API_get_edit_character_visibility() && 
                                    <p className={field.paragraph_std}>{API_get_base_stats().weight}kg</p>
                                }
                                {API_get_edit_character_visibility() && 
                                    <input type='number' className={field.input_field_number} style={{width: 60, minWidth: 60}} value={API_get_base_stats().weight} onChange={(e) => sendBaseStat({...API_get_base_stats(), weight: ParseToInteger(e.target.value)})} />
                                }
                            </td>
                            <td className={table.cell_transparent}></td>
                            <td className={table.cell_label} style={{textAlign: 'left'}}><p className={field.paragraph_std}>{API_get_base_stat_labels().heritage}</p></td>
                            <td className={table.cell_input} style={{textAlign: 'left'}}>
                                {!API_get_edit_character_visibility() && 
                                    <p className={field.paragraph_std}>{API_get_base_stats().heritage}</p>
                                }
                                {API_get_edit_character_visibility() && 
                                    <input className={field.input_field_text} style={{width: 160}} value={API_get_base_stats().heritage} onChange={(e) => sendBaseStat({...API_get_base_stats(), heritage: e.target.value})} />
                                }
                            </td>
                            <td className={table.cell_transparent}></td>
                            <td className={table.cell_label} style={{textAlign: 'left'}}><p className={field.paragraph_std}>{API_get_base_stat_labels().eye_color}</p></td>
                            <td className={table.cell_input} style={{textAlign: 'left'}}>
                                {!API_get_edit_character_visibility() && 
                                    <p className={field.paragraph_std}>{API_get_base_stats().eye_color}</p>
                                }
                                {API_get_edit_character_visibility() && 
                                    <input className={field.input_field_text} style={{width: 130}} value={API_get_base_stats().eye_color} onChange={(e) => sendBaseStat({...API_get_base_stats(), eye_color: e.target.value})} />
                                }
                            </td>
                            <td className={table.cell_transparent}></td>     
                            <td className={table.cell_label} style={{textAlign: 'left'}}><p className={field.paragraph_std}>{API_get_base_stat_labels().notorious}</p></td>
                            <td className={table.cell_input}>
                                {!API_get_edit_character_visibility() && 
                                    <p className={field.paragraph_std}>{API_get_base_stats().notorious}</p>
                                }
                                {API_get_edit_character_visibility() && 
                                    <input type='number' className={field.input_field_number} style={{width: 60}} value={API_get_base_stats().notorious} onChange={(e) => sendBaseStat({...API_get_base_stats(), notorious: ParseToInteger(e.target.value)})} />
                                }
                            </td>
                            <td className={table.cell_transparent}></td>
                            <td className={table.cell_label} style={{textAlign: 'left'}}>
                                <div>
                                    <p className={field.paragraph_std} style={{float: 'left'}}>{API_get_base_stat_labels().level}</p>
                                    {API_get_edit_character_visibility() && 
                                        <div style={{float: 'right', margin: '0px 3px 0px 0px'}}>
                                            <button onClick={increaseLevel} className={btn.button_icon} style={{width: 20, height: 20, marginTop: '3px'}}>+</button>
                                            <button onClick={decreaseLevel} className={btn.button_icon} style={{width: 20, height: 20, marginTop: '3px'}}>-</button>
                                        </div>
                                    }
                                </div>
                            </td>
                            <td className={table.cell_input}>
                                <div>
                                    <p className={field.paragraph_std}>{API_get_base_stats().level}</p>
                                </div>
                            </td>
                        </tr>   
                    </table>
                </div>
            </div>
        </div>
     );
}
 
export default BaseStatsPage;