import { useState, createContext} from 'react'

const ScreenDataContext = createContext();

const ScreenDataContextProvider = ({children}) => {

    const [activeMainScreen, setActiveMainScreen] = useState('character');
    const [activeScreen, setActiveScreen] = useState('skills');

    const API_get_active_main_screen = () => {return activeMainScreen;}
    const API_set_active_main_screen = (_value) => {setActiveMainScreen(_value)}

    const API_get_active_screen = () => {return activeScreen;}
    const API_set_active_screen = (_value) => {setActiveScreen(_value);}

    return(
        <ScreenDataContext.Provider value={{
            API_get_active_main_screen,
            API_set_active_main_screen,
            API_get_active_screen,
            API_set_active_screen
        }}>
            {children}
        </ScreenDataContext.Provider>
    )
}

export {ScreenDataContext, ScreenDataContextProvider}