import { useState, useContext } from 'react'
import {BaseStatContext, BaseStatContextProvider} from '../API/BaseStatContext'
import {TextDataContext, TextDataContextProvider} from '../API/TextDataContext'
import {FreePointsTablePage} from '../Components/SubPages/FreePointsTablePage'
import {IncreaseSelectorPage} from '../Components/SubPages/IncreaseSelectorPage'
import btn from '../Styles/btn.module.css'
import table from '../Styles/table.module.css'
import field from '../Styles/field.module.css'
import container from '../Styles/container.module.css'

const EditCharacterHeader = () => {
    const {
        API_get_base_stats,
        API_get_free_attribute_points,
        API_get_free_focus_points,
        API_get_edit_character_visibility,
        API_set_edit_character_visibility
    } = useContext(BaseStatContext);

    const {
        API_get_base_stat_labels, 
        API_set_base_stat_labels,
        API_get_std_labels,
        API_set_std_labels
    } = useContext(TextDataContext);

    const toggleEdit = () => {
        API_set_edit_character_visibility(!API_get_edit_character_visibility());
    }

    return ( 
        <div style={{height: '100%', marginLeft: 45, marginTop: 0, width: 620, minWidth: 620, background: 'transparet'}}>
            <div style={{marginLeft: 15, marginBottom: 0, verticalAlign: 'bottom', display: 'inline-block', background: 'transparet'}}>
                <button className={(API_get_edit_character_visibility()) ? btn.toggle_btn_active : btn.toggle_btn} onClick={toggleEdit}style={{width: 120}}>{API_get_std_labels().edit_character}</button>
            </div>
            <div style={{display: 'inline-block', height: 120, minHeight: 120, verticalAlign: 'bottom', background: 'transparet'}}>
                { API_get_edit_character_visibility() && 
                    <div style={{marginTop: 15}}>
                        <div><FreePointsTablePage id={'focus'}></FreePointsTablePage></div>
                        <div style={{marginTop:5}}><FreePointsTablePage id={'attribute'}></FreePointsTablePage></div>
                        <div style={{marginTop:5}}><FreePointsTablePage id={'skill'}></FreePointsTablePage></div>
                    </div>
                }
            </div>
            <div style={{display: 'inline-block', marginLeft: 15}}>
                { API_get_edit_character_visibility() && 
                    <IncreaseSelectorPage></IncreaseSelectorPage>
                }
            </div>
        </div>
     );
}
 
        {/*<div style={{height: 200, background: 'lightgray'}}>
            <div style={{margin: '0px 0px 12px 0px'}}>
                <div ><FreePointsTablePage id={'focus'}></FreePointsTablePage></div>
                <div ><FreePointsTablePage id={'attribute'}></FreePointsTablePage></div>
                <div ><FreePointsTablePage id={'skill'}></FreePointsTablePage></div>
                <div style={{width: 250, minWidth: 250, display: 'inline-block'}}>
                    <button onClick={toggleEdit} className={btn.toggle_btn} style={{width: 120, marginLeft: 15}}>{API_get_std_labels().edit_character}</button>
                </div>
            </div>
    </div>*/}
export default EditCharacterHeader;