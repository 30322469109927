import { useState, useContext, useEffect } from 'react'
//import DownloadHandler from '../Components/DownloadHandler';
import {APIContext, APIContextProvider} from '../API/API'
import {ToolDataContext, ToolDataContextProvider} from '../API/ToolDataContext'
import {ScreenDataContext, ScreenDataContextProvider} from '../API/ScreenDataContext'
import {RestUserContext, RestUserContextProvider} from '../ServerConnection/RestUserContext'
import {UserDataContext, UserDataContextProvider} from '../API/UserDataContext'
import {RemoteCharactersTable} from './SubPages/RemoteCharactersTable'
import {RemoteCampaignsTable} from './SubPages/RemoteCampaignsTable'
import {RemoteSubscribedUsersTable} from './SubPages/RemoteSubscribedUsersTable'
/*import {
    REST_log_in,
    REST_create_new_user
} from '../ServerConnection/RestCalls'*/
import btn from '../Styles/btn.module.css'
import container from '../Styles/container.module.css'




const RemoteUserPage = () => {

    const {
        API_get_user, 
        API_set_user,
        API_get_remote_connect_message,
        API_set_remote_connect_message
    } = useContext(UserDataContext);

    const getUserRole = () => {
        //console.log('getCharacter');
        let user = API_get_user();
        //console.log(user);
    
        return user.role;
    }
    
    return ( 
        <div>
            <div style={{display: "inline-block", verticalAlign: 'top'}}>
                <RemoteCharactersTable />
            </div>
            <div style={{display: "inline-block", verticalAlign: 'top'}}>
                <div>
                    <RemoteCampaignsTable />
                </div>
                { getUserRole() == 'game_master' && 
                    <div style={{marginTop: 15}}>
                        <RemoteSubscribedUsersTable />
                    </div>
                }
            </div>
        </div>
    );
}

export default RemoteUserPage;