import { GetFreeAttributePoints, GetFreeFocusPoints, GetMaxAttributeIncrease, CalculateAttribute } from "../Functions/CharacterFunctions";
import { CloneObj } from "../../API/Functions/GeneralFunctions";
import { GetDiceList } from "../DiceRoller";

/*var init_melee_weapon_types = [
    {id: 0, group_id: 16, skill_id: 0, display_name: 'Unarmed'},
    {id: 1, group_id: 16, skill_id: 9, display_name: 'Melee'},
    {id: 2, group_id: 16, skill_id: 9, display_name: 'Shield'}
    //{id: 1, group_id: 16, skill_id: 2, display_name: 'Light'},
    //{id: 2, group_id: 16, skill_id: 3, display_name: 'Medium'},
    //{id: 3, group_id: 16, skill_id: 4, display_name: 'Heavy'},
    //{id: 4, group_id: 16, skill_id: 5, display_name: 'Pole'},
    //{id: 5, group_id: 16, skill_id: 9, display_name: 'Shield'}
]*/

/*var init_melee_type_skill_matching ={
    group_id: 16, 
    skill_matching: [
        {id: 0, skill_id: 0},
        {id: 1, skill_id: 2},
        {id: 2, skill_id: 3},
        {id: 3, skill_id: 4},
        {id: 4, skill_id: 5},
        {id: 5, skill_id: 8}
    ]
}*/

var init_ranged_fighting_types = [
    {id: 0, group_id: 17, skill_id: 0, display_name: 'None'},
    {id: 1, group_id: 17, skill_id: 0, display_name: 'Shooting weapon'},
    {id: 2, group_id: 17, skill_id: 1, display_name: 'Throwing weapom'}
    /*{id: 1, group_id: 17, skill_id: 0, display_name: '(Cross-)bow'},
    {id: 2, group_id: 17, skill_id: 1, display_name: 'Light throw'},
    {id: 3, group_id: 17, skill_id: 2, display_name: 'Heavy throw'},
    {id: 4, group_id: 17, skill_id: 3, display_name: 'Slings'},
    {id: 5, group_id: 17, skill_id: 4, display_name: 'Blowpipes'}*/
]

var init_melee_fighting_types = [
    {id: 0, group_id: 16, skill_id: 0, display_name: 'Unarmed'},
    {id: 1, group_id: 16, skill_id: 1, display_name: 'One hand'},
    {id: 2, group_id: 16, skill_id: 2, display_name: 'Main'},
    {id: 3, group_id: 16, skill_id: 2, display_name: 'Offhand'},
    {id: 4, group_id: 16, skill_id: 3, display_name: 'Both'},
    {id: 5, group_id: 16, skill_id: 4, display_name: 'Shield'}
]

/*var init_ranged_type_skill_matching ={
    group_id: 17, 
    skill_matching: [
        {id: 0, skill_id: 0},
        {id: 1, skill_id: 0},
        {id: 2, skill_id: 1},
        {id: 3, skill_id: 2},
        {id: 4, skill_id: 3},
        {id: 5, skill_id: 4}
    ]
}*/

var init_unarmed_weapons  = [
    {
        id: 0,
        display_name: 'Fistfight',
        //hand_type_id: 0,
        fighting_type_id: 0,
        lead_attr_id: 1,
        weight: 0,
        attack_mod: 0,
        defense_mod: 0,
        attack: 0,
        defense: 0,
        attack_dice: null,
        defense_dice: null,
        damage: 1.0
    }
]

var init_melee_weapons  = []

var init_ranged_weapons  = []

var init_melee_weapon_template = 
{
    id: 0,
    display_name: '-',
    //hand_type_id: 0,
    fighting_type_id: 1,
    lead_attr_id: 1,
    weight: 0,
    attack_mod: 0,
    defense_mod: 0,
    attack: 0,
    defense: 0,
    attack_dice: null,
    defense_dice: null,
    damage: 0.0
}

var init_ranged_weapon_template = 
{
    id: 0,
    display_name: '-',
    fighting_type_id: 1,
    lead_attr_id: 10,
    weight: 0,
    precision_mod: 0,
    precision: 0,
    precision_dice: null,
    ammo_name: '',
    ammo: 0,
    ammo_weight: 0.0,
    damage: 0.0
}


var init_armor = []

var init_armor_template = 
{
    id: 0,
    is_put_on: false,
    display_name: '-',
    weight: 0,
    armor_class: 0,
    armor_restriction: 0
}

var init_gear  = []

var init_gear_template = 
{
    id: 0,
    display_name: '-',
    amount: 0,
    weight: 0,
    description: '-'
}

const INIT_get_melee_weapon_template = () => 
{
    return CloneObj(init_melee_weapon_template, true);
}

const INIT_get_ranged_weapon_template = () => 
{
    return CloneObj(init_ranged_weapon_template, true);
}

const INIT_get_armor_template = () => 
{
    return CloneObj(init_armor_template, true);
}

const INIT_get_gear_template = () => 
{
    return CloneObj(init_gear_template, true);
}


/*const INIT_get_melee_type_skill_matching = () => 
 {
     return CloneObj(init_melee_type_skill_matching, true);
 }

const INIT_get_ranged_type_skill_matching = () => 
{
      return CloneObj(init_ranged_type_skill_matching, true);
}*/

const INIT_get_melee_fighting_types = () => 
{
    return CloneObj(init_melee_fighting_types, true);
}

const INIT_get_ranged_fighting_types = () => 
{
    return CloneObj(init_ranged_fighting_types, true);
}

/*const INIT_get_hand_types = () => 
{
    return CloneObj(init_hand_types, true);
}*/

const INIT_get_unarmed_weapons = () => 
{
    return CloneObj(init_unarmed_weapons, true);
}

const INIT_get_melee_weapons = () => 
{
    return CloneObj(init_melee_weapons, true);
}

const INIT_get_ranged_weapons = () => 
{
    return CloneObj(init_ranged_weapons, true);
}

const INIT_get_armor = () => 
{
    return CloneObj(init_armor, true);
}

const INIT_get_gear = () => 
{
    return CloneObj(init_gear, true);
}

export 
{
    INIT_get_melee_weapon_template,
    INIT_get_ranged_weapon_template,
    INIT_get_armor_template,
    INIT_get_gear_template,
    //INIT_get_melee_type_skill_matching,
    //INIT_get_ranged_type_skill_matching,
    INIT_get_melee_fighting_types,
    INIT_get_ranged_fighting_types,
    //INIT_get_hand_types,
    INIT_get_unarmed_weapons,
    INIT_get_melee_weapons,
    INIT_get_ranged_weapons,
    INIT_get_armor,
    INIT_get_gear
};