import { useState, createContext} from 'react'
import {
    BACKEND_get_confirm_function, 
    BACKEND_set_confirm_function
} from './Parameters/MessageBoxParameters'

const MessageBoxContext = createContext();

const MessageBoxContextProvider = ({children}) => {

    const [yesNoMessageBoxVisibility, setYesNoMessageBoxVisibility] = useState(false);
    const [messageBoxContent, setMessageBoxContent] = useState('-');

    const API_get_yes_no_message_box_visibility = () => {return yesNoMessageBoxVisibility}
    const API_set_yes_no_message_box_visibility = (_value) => {setYesNoMessageBoxVisibility(_value)}

    const API_get_message_box_content = () => {return messageBoxContent}
    const API_set_message_box_content = (_value) => {setMessageBoxContent(_value)}

    const API_get_confirm_function = () => {return BACKEND_get_confirm_function()}
    const API_set_confirm_function = (_value) => {BACKEND_set_confirm_function(_value)}

    const API_open_yes_no_message_box = (_content, _function) => {
        console.log('API_open_yes_no_message_box')
        console.log(_content)
        API_set_message_box_content(_content);
        API_set_confirm_function(_function);
        API_set_yes_no_message_box_visibility(true);
    }
    
    const API_close_yes_no_message_box = () => {
        API_set_message_box_content('-');
        API_set_confirm_function(null);
        API_set_yes_no_message_box_visibility(false);
    }

    return(
        <MessageBoxContext.Provider value={{
            API_get_yes_no_message_box_visibility,
            API_get_message_box_content,
            API_get_confirm_function,
            API_open_yes_no_message_box,
            API_close_yes_no_message_box
        }}>
            {children}
        </MessageBoxContext.Provider>
    )
}

export {MessageBoxContext, MessageBoxContextProvider}