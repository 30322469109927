import { GetFreeAttributePoints, GetFreeFocusPoints, GetMaxAttributeIncrease, CalculateAttribute } from "../../Backend/Functions/CharacterFunctions";
import { CloneObj } from "../Functions/GeneralFunctions";
import { GetDiceList } from "../../Backend/DiceRoller";

//All Backend Functions should only be accessed via UI_Controller or from functions inside Backend Folder
//initialize all display_names with default language

/*var translator = [
    {id: 0, display_name: {en: 'Name', de: 'Name'}},
    {id: 1, display_name: {en: 'Ancestry', de: 'Volk'}}
]*/

var skill_meanings = [
    {id: 0, value_min: -100, value_max: 0, display_name: 'Inferior'},
    {id: 1, value_min: 1, value_max: 2, display_name: 'Very bad'},
    {id: 2, value_min: 3, value_max: 3, display_name: 'Bad'},
    {id: 3, value_min: 4, value_max: 4, display_name: 'Below average'},
    {id: 4, value_min: 5, value_max: 6, display_name: 'Average'},
    {id: 5, value_min: 7, value_max: 9, display_name: 'Above average'},
    {id: 6, value_min: 10, value_max: 19, display_name: 'Good'},
    {id: 7, value_min: 20, value_max: 29, display_name: 'Very good'},
    {id: 8, value_min: 30, value_max: 39, display_name: 'Outstanding'},
    {id: 9, value_min: 40, value_max: 49, display_name: 'Superb'},
    {id: 10, value_min: 50, value_max: 59, display_name: 'Legendary'},
    {id: 11, value_min: 60, value_max: 100, display_name: 'Otherworldly'}
]

var check_difficulties = [
    {id: 0, value_min: -100, value_max: 0, display_name: 'Inferior'},
    {id: 1, value_min: 1, value_max: 1, display_name: 'Very bad'},
    {id: 2, value_min: 2, value_max: 2, display_name: 'Bad'},
    {id: 3, value_min: 3, value_max: 3, display_name: 'Below average'},
    {id: 4, value_min: 4, value_max: 6, display_name: 'Average'},
    {id: 5, value_min: 7, value_max: 9, display_name: 'Above average'},
    {id: 6, value_min: 10, value_max: 14, display_name: 'Good'},
    {id: 7, value_min: 15, value_max: 19, display_name: 'Very good'},
    {id: 8, value_min: 20, value_max: 24, display_name: 'Outstanding'},
    {id: 9, value_min: 25, value_max: 29, display_name: 'Superb'},
    {id: 10, value_min: 30, value_max: 49, display_name: 'Legendary'},
    {id: 11, value_min: 50, value_max: 100, display_name: 'Otherworldly'}
]

var check_qualities = [
    {id: 0, value_min: -100, value_max: 0, display_name: 'Inferior'},
    {id: 1, value_min: 1, value_max: 1, display_name: 'Very bad'},
    {id: 2, value_min: 2, value_max: 2, display_name: 'Bad'},
    {id: 3, value_min: 3, value_max: 3, display_name: 'Below average'},
    {id: 4, value_min: 4, value_max: 6, display_name: 'Average'},
    {id: 5, value_min: 7, value_max: 9, display_name: 'Above average'},
    {id: 6, value_min: 10, value_max: 14, display_name: 'Good'},
    {id: 7, value_min: 15, value_max: 19, display_name: 'Very good'},
    {id: 8, value_min: 20, value_max: 24, display_name: 'Outstanding'},
    {id: 9, value_min: 25, value_max: 29, display_name: 'Superb'},
    {id: 10, value_min: 30, value_max: 49, display_name: 'Legendary'},
    {id: 11, value_min: 50, value_max: 100, display_name: 'Otherworldly'}
]

const skill_meanings_translations = [
    {id: 0, en: 'Inferior', de: 'Unterirdisch'},
    {id: 1, en: 'Very bad', de: 'Sehr schlecht'},
    {id: 2, en: 'Bad', de: 'Schlecht'},
    {id: 3, en: 'Below average', de: 'Unterdurchschn.'},
    {id: 4, en: 'Average', de: 'Durchschnittlich'},
    {id: 5, en: 'Above average', de: 'Überdurchschn.'},
    {id: 6, en: 'Good', de: 'Gut'},
    {id: 7, en: 'Very good', de: 'Sehr gut'},
    {id: 8, en: 'Outstanding', de: 'Herausragend'},
    {id: 9, en: 'Superb', de: 'Grandios'},
    {id: 10, en: 'Legendary', de: 'Legend\u00E4r'},
    {id: 11, en: 'Otherworldly', de: 'Jenseitig'},
]

const check_difficulty_translations = [
    {id: 0, en: 'Child\u0027s play', de: 'Kinderspiel'},
    {id: 1, en: 'Very easy', de: 'Sehr leicht'},
    {id: 2, en: 'Easy', de: 'Leicht'},
    {id: 3, en: 'Below average', de: 'Unterdurchschn.'},
    {id: 4, en: 'Average', de: 'Durchschnittlich'},
    {id: 5, en: 'Challenging', de: 'Fordernd'},
    {id: 6, en: 'Hard', de: 'Schwer'},
    {id: 7, en: 'Very hard', de: 'Sehr schwer'},
    {id: 8, en: 'Insane', de: 'Verr\u00FCckt'},
    {id: 9, en: 'Brutal', de: 'Brutal'},
    {id: 10, en: 'Legendary', de: 'Legend\u00E4r'},
    {id: 11, en: 'Impossible', de: 'Unm\u00F6glich'},
]

const check_quality_translations = [
    {id: 0, en: 'Inferior', de: 'Unterirdisch'},
    {id: 1, en: 'Very bad', de: 'Sehr schlecht'},
    {id: 2, en: 'Bad', de: 'Schlecht'},
    {id: 3, en: 'Below average', de: 'Unterdurchschn.'},
    {id: 4, en: 'Average', de: 'Durchschnittlich'},
    {id: 5, en: 'Above average', de: 'Überdurchschn.'},
    {id: 6, en: 'Good', de: 'Gut'},
    {id: 7, en: 'Very good', de: 'Sehr gut'},
    {id: 8, en: 'Outstanding', de: 'Herausragend'},
    {id: 9, en: 'Superb', de: 'Grandios'},
    {id: 10, en: 'Legendary', de: 'Legend\u00E4r'},
    {id: 11, en: 'Otherworldly', de: 'Jenseitig'},
]


const API_get_skill_meanings = () => {
    return skill_meanings;
}


const API_set_skill_meanings = (_skill_meanings) => {
    skill_meanings = _skill_meanings;
}

const API_get_check_difficulties = () => {
    return check_difficulties;
}

const API_set_check_difficulties = (_check_difficulties) => {
    check_difficulties = _check_difficulties;
}


const API_get_check_qualities = () => {
    return check_qualities;
}

const API_set_check_qualities = (_check_qualities) => {
    check_qualities = _check_qualities;
}

const API_get_skill_meanings_translations = () => {
    return skill_meanings_translations;
}

const API_get_check_difficulty_translations = () => {
    return check_difficulty_translations;
}

const API_get_check_quality_translations = () => {
    return check_quality_translations;
}


export 
{
    API_get_skill_meanings,
    API_set_skill_meanings,
    API_get_check_difficulties,
    API_set_check_difficulties,
    API_get_check_qualities,
    API_set_check_qualities,
    API_get_skill_meanings_translations,
    API_get_check_difficulty_translations,
    API_get_check_quality_translations
};