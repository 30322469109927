const server_mode = false;
const port = 5000;

var server_url = 'http://127.0.0.1';
if(server_mode)
    server_url = 'https://85.215.60.106';

const REST_get_server_url = () => {
    return server_url + ":" + port;
}

const REST_get_server_mode = () => {
    return server_mode
}


export{
    REST_get_server_url,
    REST_get_server_mode
}