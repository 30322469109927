import { useContext } from 'react'
import {APIContext} from '../../API/API'
import {ToolDataContext} from '../../API/ToolDataContext'
import field_style from '../../StylesStylish/field.module.css'

const LanguageSelector = () => {
    const {
        API_switch_language,
        API_get_language_list
    } = useContext(APIContext);

    const {
        API_get_tool_data
    } = useContext(ToolDataContext);

    const switchLanguage = (_language) => {
        API_switch_language(_language);
    }

    return ( 
        <div>
            <select className={field_style.drop_down_field} value={API_get_tool_data().language} name='language' id='language' style={{width: 48, minWidth: 48}} onChange={(e) => switchLanguage(e.target.value)}>
                <option value={API_get_language_list().english}>{API_get_language_list().english}</option>
                <option value={API_get_language_list().german}>{API_get_language_list().german}</option>
            </select>
        </div>
    );
}

export default LanguageSelector;