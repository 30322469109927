import { GetFreeAttributePoints, GetFreeFocusPoints, GetMaxAttributeIncrease, CalculateAttribute } from "../Functions/CharacterFunctions";
import { CloneObj } from "../../API/Functions/GeneralFunctions";

var init_magic_spells  = []

var init_magic_spell_template = 
{
    id: 0,
    display_name: '-',
    element_id: 0,
    circle_id: 0,
    might: 10,
    description: '-'
}

var init_magic_element_types = [
    {id: 0, group_id: 18, skill_id: 0, display_name: 'Light'},
    {id: 1, group_id: 18, skill_id: 1, display_name: 'Heat'},
    {id: 2, group_id: 18, skill_id: 2, display_name: 'Fire'},
    {id: 3, group_id: 18, skill_id: 3, display_name: 'Earth/ore/rock'},
    {id: 4, group_id: 18, skill_id: 4, display_name: 'Nature'},
    {id: 5, group_id: 18, skill_id: 5, display_name: 'Life'},
    {id: 6, group_id: 18, skill_id: 6, display_name: 'Body'},
    {id: 7, group_id: 18, skill_id: 7, display_name: 'Darkness'},
    {id: 8, group_id: 18, skill_id: 8, display_name: 'Cold'},
    {id: 9, group_id: 18, skill_id: 9, display_name: 'Water'},
    {id: 10, group_id: 18, skill_id: 10, display_name: 'Air'},
    {id: 11, group_id: 18, skill_id: 11, display_name: 'Construct'},
    {id: 12, group_id: 18, skill_id: 12, display_name: 'Death'},
    {id: 13, group_id: 18, skill_id: 13, display_name: 'Spirit'},
    {id: 14, group_id: 18, skill_id: 14, display_name: 'Kho\u0027Tal'},
    {id: 15, group_id: 18, skill_id: 15, display_name: 'Arkana'},
    {id: 16, group_id: 18, skill_id: 16, display_name: 'Daemonica'},
    {id: 17, group_id: 18, skill_id: 17, display_name: 'Holiness'}
]

var init_magic_circle_types = [
    {id: 0, display_name: '1', circle: 1},
    {id: 1, display_name: '2', circle: 2},
    {id: 2,  display_name: '3', circle: 3},
    {id: 3,  display_name: '4', circle: 4},
    {id: 4,  display_name: '5', circle: 5},
    {id: 5,  display_name: '6', circle: 6},
    {id: 6,  display_name: '7', circle: 7}
]

const INIT_get_magic_spells = () => 
{
    return CloneObj(init_magic_spells, true);
}

const INIT_get_magic_spell_template = () => 
{
    return CloneObj(init_magic_spell_template, true);
}

const INIT_get_magic_element_types = () => 
{
    return CloneObj(init_magic_element_types, true);
}

const INIT_get_magic_circle_types = () => 
{
    return CloneObj(init_magic_circle_types, true);
}

export 
{
    INIT_get_magic_spells,
    INIT_get_magic_spell_template,
    INIT_get_magic_element_types,
    INIT_get_magic_circle_types
};