import { useState, useContext, useEffect } from 'react'
import {APIContext, APIContextProvider} from '../../API/API'
import {BaseStatContext} from '../../API/BaseStatContext'
import {TextDataContext} from '../../API/TextDataContext'
import {UserDataContext} from '../../API/UserDataContext'
import {CampaignContext} from '../../API/CampaignContext'
import {InputField} from '../ComponentsStylish/InputField';
import {MultiLineInput} from '../ComponentsStylish/MultiLineInput';
import RemoteDownloadButton from '../ComponentsStylish/Buttons/RemoteDownloadButton';
import NewCharacterButton from '../ComponentsStylish/Buttons/NewCharacterButton';
/*const logo = require('./logo.jpeg'); // with require

then plug it in...

<img src={logo} />*/


const CampaignSheetHeader = () => {

    const {
        API_full_GUI_update,
        API_increment_level,
        API_update_base_stat,
        API_increment_attribute,
        API_init_character,
        API_set_base_stats_name,
        API_set_base_stats_heritage,
        API_set_base_stats_ancestry,
        API_set_base_stats_gender,
        API_set_base_stats_hair_color,
        API_set_base_stats_eye_color,
        API_set_base_stats_skin_color,
        API_set_base_stats_famous,
        API_set_base_stats_notorious,
        API_set_base_stats_height,
        API_set_base_stats_weight,
        API_set_base_stats_age,
        API_set_base_stats_experience,
        API_set_base_stats_level,
        API_update_campaign_name
    } = useContext(APIContext);

    const {
        API_get_user
    } = useContext(UserDataContext);
    const {
        API_get_base_stats,
        API_get_free_attribute_points,
        API_get_free_focus_points,
        API_get_edit_character_visibility,
        API_set_edit_character_visibility
    } = useContext(BaseStatContext);

    const {
        API_get_base_stat_labels, 
        API_set_base_stat_labels,
        API_get_std_labels,
        API_set_std_labels,
        API_get_tab_view_labels,
        API_set_tab_view_labels,
        API_get_character_stats_labels,
        API_set_character_stats_labels,
        API_get_table_header_labels,
        API_set_table_header_labels,
        API_get_user_labels,
        API_set_user_labels
    } = useContext(TextDataContext);

    const {
        API_get_campaign_name,
        API_get_campaign_id
    } = useContext(CampaignContext);

    const marginBottom = 4;
    const _user_role = API_get_user().role;
    return ( 
        <div>
            <div style={{marginBottom: 12, display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                <div style={{display: 'inline-block', alignContent: 'left'}}>
                    <InputField type='header' widthValue='776px' content={API_get_campaign_name()} setter={API_update_campaign_name} />
                </div>
                { _user_role == 'game_master' &&
                    <div style={{display: 'inline-block', verticalAlign: 'middle', marginLeft: 15}}>
                        <RemoteDownloadButton height={'36px'} />
                    </div>
                }
                { _user_role == 'game_master' &&
                    <div style={{display: 'inline-block', verticalAlign: 'middle', marginLeft: 10}}>
                        <NewCharacterButton height={'36px'}/>
                    </div>
                }
            </div>
            <div style={{marginLeft: 5}}>
                <MultiLineInput />
            </div>
        </div>
    );
}

export default CampaignSheetHeader;