import { useState, createContext} from 'react'
import {
    BACKEND_get_magic_spells,
    BACKEND_set_magic_spells,
    BACKEND_set_magic_element_types,
    BACKEND_get_magic_element_types,
    BACKEND_set_magic_circle_types,
    BACKEND_get_magic_circle_types
} from '../Backend/Parameters/CharacterParameters'

import{
    GetElementById
} from './Functions/GeneralFunctions'
import {
    BACKEND_get_max_magic_circle
} from '../Backend/BackendRouter'

const MagicContext = createContext();

const MagicContextProvider = ({children}) => {

    const [magicSpells, setMagicSpells] = useState({...BACKEND_get_magic_spells()});
    const [maxMagicCircle, setMaxMagicCircle] = useState(BACKEND_get_max_magic_circle());
    const [magicElementTypes, setMagicElementTypes] = useState({...BACKEND_get_magic_element_types()});
    const [magicCircleTypes, setMagicCircleTypes] = useState({...BACKEND_get_magic_circle_types()});

    const API_get_magic_spells = () => {return magicSpells;}
    const API_set_magic_spells = () => {setMagicSpells({...BACKEND_get_magic_spells()});}

    const API_get_max_magic_circle = () => {return maxMagicCircle;}
    const API_set_max_magic_circle = () => {setMaxMagicCircle(BACKEND_get_max_magic_circle());}

    const API_get_magic_element_types = () => {return magicElementTypes;}
    const API_set_magic_element_types = () => {setMagicElementTypes({...BACKEND_get_magic_element_types()});}

    const API_get_magic_circle_types = () => {return magicCircleTypes;}
    const API_set_magic_circle_types = () => {setMagicCircleTypes({...BACKEND_get_magic_circle_types()});}


    const API_get_magic_spell_by_id = (_id) => {
        return GetElementById(_id, magicSpells);
    }

    const API_get_magic_element_type_by_id = (_id) => {
        return GetElementById(_id, magicElementTypes);
    }

    const API_get_magic_circle_type_by_id = (_id) => {
        return GetElementById(_id, magicCircleTypes);
    }
    

    return(
        <MagicContext.Provider value={{
            API_get_magic_spells,
            API_set_magic_spells,
            API_get_max_magic_circle,
            API_set_max_magic_circle,
            API_get_magic_element_types,
            API_set_magic_element_types,
            API_get_magic_circle_types,
            API_set_magic_circle_types,
            API_get_magic_spell_by_id,
            API_get_magic_element_type_by_id,
            API_get_magic_circle_type_by_id
        }}>
            {children}
        </MagicContext.Provider>
    )
}

export {MagicContext, MagicContextProvider}