import {
    BACKEND_set_base_stats, 
    BACKEND_get_base_stats, 
    BACKEND_set_free_attribute_points, 
    BACKEND_get_free_attribute_points, 
    BACKEND_set_free_focus_points, 
    BACKEND_get_free_focus_points,
    BACKEND_set_free_skill_points,
    BACKEND_get_free_skill_points,
    BACKEND_set_attribute_groups,
    BACKEND_get_attribute_groups,
    BACKEND_set_attributes,
    BACKEND_get_attributes,
    BACKEND_set_character_stats,
    BACKEND_get_character_stats,
    //BACKEND_set_secondary_attributes,
    //BACKEND_get_secondary_attributes,
    BACKEND_set_skills,
    BACKEND_get_skills,
    BACKEND_set_comments,
    BACKEND_get_comments,
    BACKEND_get_negative_attribute_restriction,
    BACKEND_get_negative_skill_restriction,
    BACKEND_get_negative_focus_restriction,
    BACKEND_get_unarmed_weapons,
    BACKEND_set_unarmed_weapons,
    BACKEND_get_melee_weapons,
    BACKEND_set_melee_weapons,
    BACKEND_get_ranged_weapons,
    BACKEND_set_ranged_weapons,
    BACKEND_get_money_list,
    BACKEND_set_money_list,
    BACKEND_get_armor,
    BACKEND_set_armor,
    BACKEND_get_gear,
    BACKEND_set_gear,
    BACKEND_get_feat_lv_2,
    BACKEND_set_feat_lv_2,
    BACKEND_get_feat_lv_3,
    BACKEND_set_feat_lv_3,
    BACKEND_get_magic_spells,
    BACKEND_set_magic_spells,
    BACKEND_get_melee_weapon_template,
    BACKEND_get_ranged_weapon_template
} from '../Parameters/CharacterParameters'
import {
    BACKEND_get_monster_list, BACKEND_set_monster_list
} from '../Parameters/CampaignParameters'
import {
    INIT_get_base_stats, 
    INIT_get_free_attribute_points, 
    INIT_get_free_focus_points,
    INIT_get_attribute_groups,
    INIT_get_attributes,
    INIT_get_character_stats,
    //INIT_get_secondary_attributes,
    INIT_get_negative_attribute_restriction,
    INIT_get_negative_focus_restriction
} from '../ParametersInit/AttributesInit'
import { 
    INIT_get_skills,
    INIT_get_free_skill_points
 } from "../ParametersInit/SkillParametersInit";
import {
    CalculateAttribute, 
    GetHitpoints,
    GetLoadCapacity,
    GetEndurance,
    GetKhoTal,
    //GetInitiative,
    //GetDodgeValue,
    GetFreeAttributePoints, 
    GetFreeFocusPoints,
    GetFreeSkillPoints,
    GetSkillPointsAttribute,
    GetFocusCost,
    GetMaxAttributeIncrease,
    GetMaxSkillIncrease,
    CalculateSkill,
    ConvertAttributes, 
    ConvertAttributesBack
} from './CharacterFunctions'
import {
    BACKEND_get_character_file_name,
    BACKEND_set_upload_state,
    BACKEND_get_upload_state
} from '../Parameters/GameData'
/*import {
    LoadCharacter
} from './LoadCharacter'*/
import {
    BACKEND_get_tool_data, 
    BACKEND_set_tool_data,
    BACKEND_get_dice_log,
    BACKEND_set_dice_log,
    BACKEND_set_dice_log_value,
    BACKEND_get_language_list
} from '../Parameters/ToolData'
import {
    Translate
} from '../Lang/Translator'
import {
    GetDiceList,
    GetDiceListString,
    GetFoundryDiceListString
} from '../DiceRoller'
import {
    ParseToNumber,
    GetElementById,
    RollSingleDice,
    AdjustObjToRef,
    CloneObj
} from '../../API/Functions/GeneralFunctions'
import {
    RecalcCharacterStats,
    //RecalcSecondaryAttributes,
    RecalcFreeAttributePoints,
    RecalcFreeFocusPoints,
    RecalcFreeSkillPoints,
    RecalcAttributes,
    RecalcSkills,
    RecalcWeight,
    RecalcWeapons,
    RecalcArmor,
    RecalcMonsterList
} from './RecalcFunctions'
import {
    BACKEND_increment_level, 
    BACKEND_update_base_stat, 
    BACKEND_increment_attribute,
    BACKEND_increment_skill,
    //BACKEND_increment_ancestry_bonus,
    //BACKEND_increment_other_attribute_bonus,
    BACKEND_increment_focus,
    BACKEND_get_character_file,
    BACKEND_upload_character,
    BACKEND_init_character,
    BACKEND_init_campaign,
    BACKEND_switch_language,
    BACKEND_recalc_attribute_points,
    BACKEND_recalc_focus_points,
    BACKEND_recalc_skill_points,
    BACKEND_recalc_attributes,
    BACKEND_change_hitpoints,
    BACKEND_increment_kho_tal,
    BACKEND_modify_character_stat_bonus,
    BACKEND_get_attribute_by_id,
    BACKEND_get_attribute_group_by_id,
    BACKEND_increment_free_focus_points_bonus,
    BACKEND_increment_free_skill_points_bonus,
    BACKEND_increment_free_attribute_points_bonus
} from '../BackendRouter'

const CheckCompatibility = (_character) => {
    let _version = _character.tool_data.version;
    if(_version.main == 0 && _version.sub == 5 && _version.rev == 0)
    {
        return CorrectRev_0_5_0(_character);
    }
    else if(_version.main == 0 && _version.sub == 6 && _version.rev == 0)
    {
        return CorrectRev_0_6_0(_character);
    }
    else
    {
        return _character;
    }

}

const SetSkillIDs = (_character, _skill_id_handler) => {
    for(let key1 in _character.skills){
        for(let key2 in _character.skills[key1].skills){
            for(let key3 in _skill_id_handler)
            {
                if(_character.skills[key1].skill_group_id == _skill_id_handler[key3].group_id && _character.skills[key1].skills[key2].id == _skill_id_handler[key3].skill_id_init)
                {
                    _character.skills[key1].skills[key2].id = _skill_id_handler[key3].skill_id_new;
                }
            }
        }
    }
    return _character;
}

const _skill_id_handler_0_5_0 = [
    {group_id: 1, skill_id_init: 1, skill_id_new: -1},
    {group_id: 1, skill_id_init: 2, skill_id_new: -1},
    {group_id: 1, skill_id_init: 3, skill_id_new: 1},
    {group_id: 1, skill_id_init: 4, skill_id_new: 2},
    {group_id: 1, skill_id_init: 5, skill_id_new: 3},
    {group_id: 2, skill_id_init: 3, skill_id_new: -1},
    {group_id: 2, skill_id_init: 4, skill_id_new: -1},
    {group_id: 2, skill_id_init: 5, skill_id_new: -1},
    {group_id: 2, skill_id_init: 6, skill_id_new: 3},
    {group_id: 9, skill_id_init: 4, skill_id_new: -1},
    {group_id: 9, skill_id_init: 5, skill_id_new: 4},
    {group_id: 9, skill_id_init: 6, skill_id_new: 5},
    {group_id: 10, skill_id_init: 3, skill_id_new: -1},
    {group_id: 10, skill_id_init: 4, skill_id_new: 3},
    {group_id: 10, skill_id_init: 5, skill_id_new: 4},
    {group_id: 16, skill_id_init: 1, skill_id_new: -1},
    {group_id: 16, skill_id_init: 2, skill_id_new: -1},
    {group_id: 16, skill_id_init: 3, skill_id_new: -1},
    {group_id: 16, skill_id_init: 4, skill_id_new: -1},
    {group_id: 16, skill_id_init: 5, skill_id_new: -1},
    {group_id: 16, skill_id_init: 6, skill_id_new: 1},
    {group_id: 16, skill_id_init: 7, skill_id_new: 2},
    {group_id: 16, skill_id_init: 8, skill_id_new: 3},
    {group_id: 16, skill_id_init: 9, skill_id_new: 4},
    {group_id: 16, skill_id_init: 10, skill_id_new: 5},
    {group_id: 17, skill_id_init: 2, skill_id_new: -1},
    {group_id: 17, skill_id_init: 3, skill_id_new: -1},
    {group_id: 17, skill_id_init: 4, skill_id_new: -1}
]

const _skill_id_handler_0_6_0 = [
    {group_id: 2, skill_id_init: 3, skill_id_new: -1},
    {group_id: 2, skill_id_init: 4, skill_id_new: 3}
]

const CorrectRev_0_5_0 = (_character) => {
    return SetSkillIDs(_character, _skill_id_handler_0_5_0);
}

const CorrectRev_0_6_0 = (_character) => {
    return SetSkillIDs(_character, _skill_id_handler_0_6_0);
}

export {
    CheckCompatibility
}