import { 
    ParseToFloat, 
    ParseToInteger ,
    GetListParameterByID,
    SetListParameterByID,
    AddElementToList,
    RemoveElementFromList,
    MoveElementInList
} from '../../API/Functions/GeneralFunctions'

import {
    BACKEND_get_armor,
    BACKEND_set_armor,
    BACKEND_get_gear,
    BACKEND_set_gear,
    BACKEND_get_feat_lv_2,
    BACKEND_set_feat_lv_2,
    BACKEND_get_feat_lv_3,
    BACKEND_set_feat_lv_3,
    BACKEND_get_magic_spells,
    BACKEND_set_magic_spells,
    BACKEND_get_armor_template,
    BACKEND_get_gear_template,
    BACKEND_get_feat_lv_2_template,
    BACKEND_get_feat_lv_3_template,
    BACKEND_get_magic_spells_template,
    BACKEND_set_magic_element_types,
    BACKEND_get_magic_element_types,
    BACKEND_set_magic_circle_types,
    BACKEND_get_magic_circle_types
} from '../Parameters/CharacterParameters'

import {
    BACKEND_get_monster_list,
    BACKEND_set_monster_list,
    BACKEND_get_monster_template
} from '../Parameters/CampaignParameters'

import {
    RecalcCharacterStats,
    //RecalcSecondaryAttributes,
    RecalcFreeAttributePoints,
    RecalcFreeFocusPoints,
    RecalcFreeSkillPoints,
    RecalcAttributes,
    RecalcSkills,
    RecalcWeapons,
    RecalcWeight,
    RecalcArmor,
    RecalcMonsterList
} from './RecalcFunctions'

const ModifyMonsterName = (_id, _new_value) => {
    SetListParameterByID(_id, BACKEND_get_monster_list(), BACKEND_set_monster_list, _new_value, 'text', 'id', 'display_name');
}

const ModifyMonsterInitiative = (_id, _new_value) => {
    SetListParameterByID(_id, BACKEND_get_monster_list(), BACKEND_set_monster_list, _new_value, 'integer', 'id', 'initiative');
    RecalcMonsterList();
}

const ModifyMonsterHitpoints = (_id, _new_value) => {
    SetListParameterByID(_id, BACKEND_get_monster_list(), BACKEND_set_monster_list, _new_value, 'integer', 'id', 'hitpoints');
    RecalcMonsterList();
}

const ModifyMonsterReflexes = (_id, _new_value) => {
    SetListParameterByID(_id, BACKEND_get_monster_list(), BACKEND_set_monster_list, _new_value, 'integer', 'id', 'reflexes');
    RecalcMonsterList();
}

const ModifyMonsterAttack = (_id, _new_value) => {
    SetListParameterByID(_id, BACKEND_get_monster_list(), BACKEND_set_monster_list, _new_value, 'integer', 'id', 'attack');
    RecalcMonsterList();
}

const ModifyMonsterDefense = (_id, _new_value) => {
    SetListParameterByID(_id, BACKEND_get_monster_list(), BACKEND_set_monster_list, _new_value, 'integer', 'id', 'defense');
    RecalcMonsterList();
}

const ModifyMonsterDamage = (_id, _new_value) => {
    SetListParameterByID(_id, BACKEND_get_monster_list(), BACKEND_set_monster_list, _new_value, 'float', 'id', 'damage');
    RecalcMonsterList();
}

const ModifyMonsterComment = (_id, _new_value) => {
    SetListParameterByID(_id, BACKEND_get_monster_list(), BACKEND_set_monster_list, _new_value, 'text', 'id', 'comment');
}

const AddMonsterElement = () => {
    AddElementToList(BACKEND_get_monster_list(), BACKEND_get_monster_template(), BACKEND_set_monster_list);
    RecalcMonsterList();
}

const RemoveMonsterElement = (_element) => {
    RemoveElementFromList(_element.id, BACKEND_get_monster_list(), BACKEND_set_monster_list);
}

const MoveMonsterElement = (_element, _direction) => { 
    MoveElementInList(_element.id, BACKEND_get_monster_list(), BACKEND_set_monster_list, _direction);
}

export {
    MoveMonsterElement,
    RemoveMonsterElement,
    AddMonsterElement,
    ModifyMonsterComment,
    ModifyMonsterDefense,
    ModifyMonsterAttack,
    ModifyMonsterReflexes,
    ModifyMonsterHitpoints,
    ModifyMonsterDamage,
    ModifyMonsterInitiative,
    ModifyMonsterName
}