import { useState, useContext } from 'react'
import {SkillContext, SkillContextProvider} from '../API/SkillContext'
import {TextDataContext, TextDataContextProvider} from '../API/TextDataContext'
import {BaseStatContext, BaseStatContextProvider} from '../API/BaseStatContext'
import {APIContext, APIContextProvider} from '../API/API'
import {SkillTablePage} from './SubPages/SkillTablePage'
import {SkillGroupHeaderPage} from './SubPages/SkillGroupHeaderPage'
import {FreePointsTablePage} from './SubPages/FreePointsTablePage'
import {IncreaseSelectorPage} from './SubPages/IncreaseSelectorPage'
import {WeaponTablePage} from './SubPages/WeaponTablePage'
import {ArmorTablePage} from './SubPages/ArmorTablePage'
import {InventoryTablePage} from './SubPages/InventoryTablePage'
import btn from '../Styles/btn.module.css'
import table from '../Styles/table.module.css'
import field from '../Styles/field.module.css'
import container from '../Styles/container.module.css'

const EquipmentPage = () => {
    

    return ( 
        <div style={{height: '100%', background: 'lightgray'}}>
            <div style={{display: 'inline-block'}}>
                <div className={container.skill_table_container}>
                    <div style={{margin: '0px 0px 12px 0px'}}><SkillGroupHeaderPage id={5} internal_groups={[16, 17]}></SkillGroupHeaderPage></div>
                    <div style={{margin: '0px 0px 12px 12px', display: 'flex', verticalAlign: 'top'}}>
                        <div style={{display: 'inline-block', marginRight: 15}}><SkillTablePage skill_group_id={16}></SkillTablePage></div>
                        <div style={{display: 'inline-block'}}><SkillTablePage skill_group_id={17}></SkillTablePage></div>
                    </div>
                </div>
                <div>
                    <div style={{marginLeft: 15, marginBottom: 15}}>
                        <WeaponTablePage list_type={'unarmed'}></WeaponTablePage>
                    </div>
                    <div style={{marginLeft: 15, marginBottom: 15}}>
                        <WeaponTablePage list_type={'melee'}></WeaponTablePage>
                    </div>
                    <div style={{marginLeft: 15, marginBottom: 15}}>
                        <WeaponTablePage list_type={'ranged'}></WeaponTablePage>
                    </div>
                </div>
                <div style={{display: 'flex'}}>
                    <div style={{display: 'inline-block', marginLeft: 15, marginBottom: 15}}>
                        <ArmorTablePage></ArmorTablePage>
                    </div>
                    <div style={{display: 'inline-block', marginLeft: 15, marginBottom: 15}}>
                        <InventoryTablePage></InventoryTablePage>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default EquipmentPage;