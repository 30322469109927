import {
    BACKEND_get_user,
    BACKEND_set_user,
    BACKEND_get_user_campaigns,
    BACKEND_set_user_campaigns,
    BACKEND_get_user_characters,
    BACKEND_set_user_characters
} from '../Parameters/UserData'
import {
    BACKEND_get_tool_data
} from '../Parameters/ToolData'
import {
    InitCharacter
} from './CharacterFunctions'
import { 
    MoveElementInList
} from '../../API/Functions/GeneralFunctions'

const UserLogin = (_user) => {
    InitCharacter(BACKEND_get_tool_data().language);
    BACKEND_set_user(_user);
}

const MoveCharacterElement = (_campaign_id, _direction) => { 
    MoveElementInList(_campaign_id, BACKEND_get_user().characters, BACKEND_set_user_characters, _direction);
}

export{
    UserLogin,
    MoveCharacterElement
}