import { GetFreeAttributePoints, GetFreeFocusPoints, GetMaxAttributeIncrease, CalculateAttribute } from "../Functions/CharacterFunctions";
import { CloneObj } from "../../API/Functions/GeneralFunctions";
import { GetDiceList } from "../DiceRoller";

//All Backend Functions should only be accessed via UI_Controller or from functions inside Backend Folder
//initialize all display_names with default language

/*var translator = [
    {id: 0, display_name: {en: 'Name', de: 'Name'}},
    {id: 1, display_name: {en: 'Ancestry', de: 'Volk'}}
]*/

var init_free_attribute_points = {
    used: 0,
    bonus: 0,
    total: 0
}

var init_free_focus_points = {
    used: 0,
    bonus: 0,
    total: 0
}

var init_ancestry_saldo = {
    points: 0,
    cost: 0
}

var init_negative_attribute_restriction = {
    single: -2,
    total: -5
}

var init_negative_focus_restriction = {
    max: 3,
    min: -1,
    total: -2
}

var init_attribute_groups = [
    {
        id: 0, 
        display_name: 'Robustness', 
        focus: 0, 
        max_training: 0, 
        max_specialization: 0
    },
    {
        id: 1, 
        display_name: 'Agility', 
        focus: 0, 
        max_training: 0, 
        max_specialization: 0
    },
    {
        id: 2, 
        display_name: 'Social Skills', 
        focus: 0, 
        max_training: 0, 
        max_specialization: 0
    },
    {
        id: 3, 
        display_name: 'Intuition', 
        focus: 0, 
        max_training: 0, 
        max_specialization: 0
    },
    {
        id: 4, 
        display_name: 'Knowledge', 
        focus: 0, 
        max_training: 0, 
        max_specialization: 0
    },
    {
        id: 5, 
        display_name: 'Combat', 
        focus: 0, 
        max_training: 0, 
        max_specialization: 0
    },
    {
        id: 6, 
        display_name: 'Magic', 
        focus: -1, 
        max_training: 0, 
        max_specialization: 0
    }
]

var init_attribute_group_ref = {
    robustness: 0,
    agility: 1,
    social_skills: 2,
    intuition: 3,
    knowledge: 4,
    combat: 5,
    magic: 6
}

var init_attributes  = [
    {
        id: 0, 
        group: 0, 
        display_name: 'Constitution', 
        abbreviation: 'Co', 
        cost: 2, 
        //max: 0, //GetMaxAttributeIncrease(0, init_base_stats.level), 
        ancestry_bonus: 0, 
        other_bonus: 0, 
        base: 3, 
        temporary_mod: 0, 
        increase: 0, 
        value: 0, //CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}), 
        dice: null //GetDiceList(CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}))
    },
    {
        id: 1, 
        group: 0, 
        display_name: 'Strength', 
        abbreviation: 'St', 
        cost: 2, 
        //max: 0, //GetMaxAttributeIncrease(0, init_base_stats.level), 
        ancestry_bonus: 0, 
        other_bonus: 0, 
        base: 3, 
        temporary_mod: 0, 
        increase: 0, 
        value: 0, //CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}), 
        dice: null //GetDiceList(CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}))
    },
    {
        id: 2, 
        group: 1, 
        display_name: 'Dexterity', 
        abbreviation: 'Dx', 
        cost: 2, 
        //max: 0, //GetMaxAttributeIncrease(0, init_base_stats.level), 
        ancestry_bonus: 0, 
        other_bonus: 0, 
        base: 3, 
        temporary_mod: 0, 
        increase: 0, 
        value: 0, //CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}), 
        dice: null //GetDiceList(CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}))
    },
    {
        id: 3, 
        group: 1, 
        display_name: 'Coordination', 
        abbreviation: 'Cd', 
        cost: 1, 
        //max: 0, //GetMaxAttributeIncrease(0, init_base_stats.level), 
        ancestry_bonus: 0, 
        other_bonus: 0, 
        base: 3,
        temporary_mod: 0, 
        increase: 0, 
        value: 0, //CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}), 
        dice: null //GetDiceList(CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}))
    },
    {
        id: 4, 
        group: 1, 
        display_name: 'Stealth', 
        abbreviation: 'Sh', 
        cost: 1, 
        //max: 0, //GetMaxAttributeIncrease(0, init_base_stats.level), 
        ancestry_bonus: 0, 
        other_bonus: 0, 
        base: 3, 
        temporary_mod: 0, 
        increase: 0, 
        value: 0, //CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}), 
        dice: null //GetDiceList(CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}))},
    },
    {
        id: 5, 
        group: 1, 
        display_name: 'Thievery', 
        abbreviation: 'Th', 
        cost: 1, 
        //max: 0, //GetMaxAttributeIncrease(0, init_base_stats.level), 
        ancestry_bonus: 0, 
        other_bonus: 0, 
        base: 3, 
        temporary_mod: 0, 
        increase: 0, 
        value: 0, //CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}), 
        dice: null //GetDiceList(CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}))
    },
    {
        id: 6, 
        group: 2, 
        display_name: 'Society', 
        abbreviation: 'Sc', 
        cost: 1, 
        //max: 0, //GetMaxAttributeIncrease(0, init_base_stats.level), 
        ancestry_bonus: 0, 
        other_bonus: 0, 
        base: 3, 
        temporary_mod: 0, 
        increase: 0, 
        value: 0, //CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}), 
        dice: null //GetDiceList(CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}))
    },
    {
        id: 7, 
        group: 2, 
        display_name: 'Eloquence', 
        abbreviation: 'Eq', 
        cost: 1, 
        //max: 0, //GetMaxAttributeIncrease(0, init_base_stats.level), 
        ancestry_bonus: 0, 
        other_bonus: 0, 
        base: 3, 
        temporary_mod: 0, 
        increase: 0, 
        value: 0, //CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}), 
        dice: null //GetDiceList(CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}))
    },
    {
        id: 8, 
        group: 2, 
        display_name: 'Deceive', 
        abbreviation: 'Dc', 
        cost: 1, 
        //max: 0, //GetMaxAttributeIncrease(0, init_base_stats.level), 
        ancestry_bonus: 0, 
        other_bonus: 0, 
        base: 3, 
        temporary_mod: 0, 
        increase: 0, 
        value: 0, //CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}), 
        dice: null //GetDiceList(CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}))
    },
    {
        id: 9, 
        group: 2, 
        display_name: 'Bardic art', 
        abbreviation: 'Ba', 
        cost: 1, 
        //max: 0, //GetMaxAttributeIncrease(0, init_base_stats.level), 
        ancestry_bonus: 0, 
        other_bonus: 0, 
        base: 3, 
        temporary_mod: 0, 
        increase: 0, 
        value: 0, //CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}), 
        dice: null //GetDiceList(CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}))
    },
    {
        id: 10, 
        group: 3, 
        display_name: 'Perception', 
        abbreviation: 'Pr', 
        cost: 1, 
        //max: 0, //GetMaxAttributeIncrease(0, init_base_stats.level), 
        ancestry_bonus: 0, 
        other_bonus: 0, 
        base: 3, 
        temporary_mod: 0, 
        increase: 0, 
        value: 0, //CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}), 
        dice: null //GetDiceList(CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}))
    },
    {
        id: 11, 
        group: 3, 
        display_name: 'Nature', 
        abbreviation: 'Nt', 
        cost: 1, 
        //max: 0, //GetMaxAttributeIncrease(0, init_base_stats.level), 
        ancestry_bonus: 0, 
        other_bonus: 0, 
        base: 3, 
        temporary_mod: 0, 
        increase: 0, 
        value: 0, //CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}), 
        dice: null //GetDiceList(CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}))
    },
    {
        id: 12, 
        group: 4, 
        display_name: 'Healing', 
        abbreviation: 'He', 
        cost: 1, 
        //max: 0, //GetMaxAttributeIncrease(0, init_base_stats.level), 
        ancestry_bonus: 0, 
        other_bonus: 0, 
        base: 3, 
        temporary_mod: 0, 
        increase: 0, 
        value: 0, //CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}), 
        dice: null //GetDiceList(CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}))
    },
    {
        id: 13, 
        group: 4, 
        display_name: 'Common', 
        abbreviation: 'Co', 
        cost: 1, 
        //max: 0, //GetMaxAttributeIncrease(0, init_base_stats.level), 
        ancestry_bonus: 0, 
        other_bonus: 0, 
        base: 3, 
        temporary_mod: 0, 
        increase: 0, 
        value: 0, //CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}), 
        dice: null //GetDiceList(CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}))
    },
    {
        id: 14, 
        group: 4, 
        display_name: 'Mystic', 
        abbreviation: 'My', 
        cost: 1, 
        //max: 0, //GetMaxAttributeIncrease(0, init_base_stats.level), 
        ancestry_bonus: 0, 
        other_bonus: 0, 
        base: 3, 
        temporary_mod: 0, 
        increase: 0, 
        value: 0, //CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}), 
        dice: null //GetDiceList(CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}))
    },
    {
        id: 15, 
        group: 4, 
        display_name: 'Military', 
        abbreviation: 'Mi', 
        cost: 1, 
        //max: 0, //GetMaxAttributeIncrease(0, init_base_stats.level), 
        ancestry_bonus: 0, 
        other_bonus: 0, 
        base: 3, 
        temporary_mod: 0, 
        increase: 0, 
        value: 0, //CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}), 
        dice: null //GetDiceList(CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}))
    },
    {
        id: 16, 
        group: 5, 
        display_name: 'Melee Combat', 
        abbreviation: 'MC', 
        cost: 3, 
        //max: 0, //GetMaxAttributeIncrease(0, init_base_stats.level), 
        ancestry_bonus: 0, 
        other_bonus: 0, 
        base: 3, 
        temporary_mod: 0, 
        increase: 0, 
        value: 0, //CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}), 
        dice: null //GetDiceList(CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}))
    },
    {
        id: 17, 
        group: 5, 
        display_name: 'Ranged Combat', 
        abbreviation: 'RC', 
        cost: 3, 
        //max: 0, //GetMaxAttributeIncrease(0, init_base_stats.level), 
        ancestry_bonus: 0, 
        other_bonus: 0, 
        base: 3, 
        temporary_mod: 0, 
        increase: 0, 
        value: 0, //CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}), 
        dice: null //GetDiceList(CalculateAttribute({base: 3, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}))
    },
    {
        id: 18, 
        group: 6, 
        display_name: 'Arcana', 
        abbreviation: 'AC', 
        cost: 3, 
        //max: 0, //GetMaxAttributeIncrease(0, init_base_stats.level), 
        ancestry_bonus: 0, 
        other_bonus: 0, 
        base: 0, 
        temporary_mod: 0, 
        increase: 0, 
        value: 0, //CalculateAttribute({base: 0, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}), 
        dice: null //GetDiceList(CalculateAttribute({base: 0, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}))
    },
    {
        id: 19, 
        group: 6, 
        display_name: 'Magic Resistance', 
        abbreviation: 'MR', 
        cost: 2, 
        //max: 0, //GetMaxAttributeIncrease(0, init_base_stats.level), 
        ancestry_bonus: 0, 
        other_bonus: 0, 
        base: 3, 
        temporary_mod: 0, 
        increase: 0, 
        value: 0, //CalculateAttribute({base: 2, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}), 
        dice: null //GetDiceList(CalculateAttribute({base: 2, ancestry_bonus: 0, increase: 0, other_bonus: 0, temporary_mod: 0}))
    }
]

var init_attribute_ref = {
    constitution: 0,
    strength: 1,
    dexterity: 2,
    coordination: 3,
    stealth: 4,
    thievery: 5,
    society: 6,
    eloquence: 7,
    deceive: 8,
    bardic_art: 9,
    perception: 10,
    nature: 11,
    healing: 12,
    common: 13,
    mystic: 14,
    military: 15,
    melee_combat: 16,
    ranged_combat: 17,
    arcana: 18,
    magic_resistance: 19
}

/*var init_secondary_attributes = 
{
    initiative: {id: 0, temporary_mod: 0, bonus: 0, value: 20, dice: GetDiceList(4)},
    dodge: {id: 1, temporary_mod: 0, bonus: 0, value: 20, dice: GetDiceList(4)}
}*/

var init_lead_attribute_tags = [
    {attribute_id: 0, display_name: 'Co'},
    {attribute_id: 1, display_name: 'St'},
    {attribute_id: 2, display_name: 'Dx'},
    {attribute_id: 3, display_name: 'Cd'},
    {attribute_id: 10, display_name: 'Pr'}
]


/*var init_skills  = [
    {id: 0, group: 'Robustness', display_name: 'Strength', cost: 2, max: 5, ancestry_bonus: 0, other_bonus: 0, base: 3, increase: 0},
    {id: 1, group: 'Robustness', display_name: 'Robustness', cost: 2, max: 5, ancestry_bonus: 0, other_bonus: 0, base: 3, increase: 0},
    {id: 2, group: 'Robustness', display_name: 'Drinking_resistance', cost: 2, max: 5, ancestry_bonus: 0, other_bonus: 0, base: 3, increase: 0},
    {id: 3, group: 'Robustness', display_name: 'Poison_resistance', cost: 2, max: 5, ancestry_bonus: 0, other_bonus: 0, base: 3, increase: 0},
    {id: 4, group: 'Robustness', display_name: 'Endurance', cost: 2, max: 5, ancestry_bonus: 0, other_bonus: 0, base: 3, increase: 0},
    {id: 4, group: 'Athletics', display_name: 'Jump', cost: 2, max: 5, ancestry_bonus: 0, other_bonus: 0, base: 3, increase: 0},
    {id: 4, group: 'Society', display_name: 'Talk', cost: 2, max: 5, ancestry_bonus: 0, other_bonus: 0, base: 3, increase: 0},
    {id: 4, group: 'Society2', display_name: 'Talk2', cost: 2, max: 5, ancestry_bonus: 0, other_bonus: 0, base: 3, increase: 0},
    {id: 4, group: 'Eloquence', display_name: 'Behave', cost: 2, max: 5, ancestry_bonus: 0, other_bonus: 0, base: 3, increase: 0},
    {id: 4, group: 'Eloquence2', display_name: 'Behave2', cost: 2, max: 5, ancestry_bonus: 0, other_bonus: 0, base: 3, increase: 0}
]*/

const INIT_get_attribute_group_ref = () => 
{
    return CloneObj(init_attribute_group_ref, true);
}

const INIT_get_attribute_ref = () => 
{
    return CloneObj(init_attribute_ref, true);
}

const INIT_get_free_attribute_points = () => 
{
    return CloneObj(init_free_attribute_points, true);
}

const INIT_get_free_focus_points = () => 
{
    return CloneObj(init_free_focus_points, true);
}

const INIT_get_ancestry_saldo = () => 
{
    return CloneObj(init_ancestry_saldo, true);
}

const INIT_get_attribute_groups = () => 
{
    return CloneObj(init_attribute_groups, true);
}

const INIT_get_attributes = () => 
{
    return CloneObj(init_attributes, true);
}

/*const INIT_get_secondary_attributes = () => 
{
    return CloneObj(init_secondary_attributes, true);
}*/

/*const INIT_get_skills = () => 
{
    return CloneObj(init_skills, true);
}*/

const INIT_get_negative_attribute_restriction = () => 
{
    return CloneObj(init_negative_attribute_restriction, true);
}

const INIT_get_negative_focus_restriction = () => 
{
    return CloneObj(init_negative_focus_restriction, true);
}

const INIT_get_lead_attribute_tags = () => 
{
    return CloneObj(init_lead_attribute_tags, true);
}

export 
{
    INIT_get_free_attribute_points, 
    INIT_get_free_focus_points,
    INIT_get_ancestry_saldo,
    INIT_get_attribute_groups,
    INIT_get_attributes,
    //INIT_get_secondary_attributes,
    //INIT_get_skills,
    INIT_get_negative_attribute_restriction,
    INIT_get_negative_focus_restriction,
    INIT_get_lead_attribute_tags,
    INIT_get_attribute_group_ref,
    INIT_get_attribute_ref
};