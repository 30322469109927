import { useState, useContext, useEffect, useRef  } from 'react'
//import DownloadHandler from '../Components/DownloadHandler';
import IOPage from '../Components/IOPage';
import BaseStatsPage from '../Components/BaseStatsPage';
import AttributePage from '../Components/GeneralPage';
import SkillPage from '../Components/SkillPage';
import EquipmentPage from '../Components/EquipmentPage';
import MagicPage from '../Components/MagicPage';
import SettingsPage from '../Components/FeatPage';
import EditCharacterHeader from './EditCharacterHeader';
import {APIContext, APIContextProvider} from '../API/API'
import {ToolDataContext,ToolDataContextProvider} from '../API/ToolDataContext'
import {TextDataContext,TextDataContextProvider} from '../API/TextDataContext'
import {ScreenDataContext,ScreenDataContextProvider} from '../API/ScreenDataContext'
import {BaseStatContext, BaseStatContextProvider} from '../API/BaseStatContext'
import {UserDataContext, UserDataContextProvider} from '../API/UserDataContext'
import {CampaignContext, CampaignContextProvider} from '../API/CampaignContext'
import {CheckIfKeyIsContained, IsWhitespace} from '../API/Functions/GeneralFunctions'
import btn from '../Styles/btn.module.css'
import table from '../Styles/table.module.css'
import container from '../Styles/container.module.css'
import field from '../Styles/field.module.css'

const Navbar = (props) => {
    const {
        API_switch_language,
        API_get_language_list,
        API_init_character,
        API_update_base_stat,
        API_update_campaign_name
    } = useContext(APIContext);

    const {
        API_get_campaign_name,
        API_get_campaign_id
    } = useContext(CampaignContext);
    
    const {
        API_get_base_stats,
        API_get_free_attribute_points,
        API_get_free_focus_points,
        API_get_edit_character_visibility,
        API_set_edit_character_visibility
    } = useContext(BaseStatContext);

    const {
        API_get_active_screen,
        API_set_active_screen
    } = useContext(ScreenDataContext);

    const {
        API_get_tool_data,
        API_set_tool_data,
        API_get_dice_log,
        API_get_dice_log_visibility,
        API_set_dice_log_visibility
    } = useContext(ToolDataContext);

    const {
        API_get_base_stat_labels, 
        API_set_base_stat_labels,
        API_get_std_labels,
        API_set_std_labels,
        API_get_tab_view_labels,
        API_set_tab_view_labels,
        API_get_character_stats_labels,
        API_set_character_stats_labels,
        API_get_table_header_labels,
        API_set_table_header_labels,
        API_get_user_labels,
        API_set_user_labels
    } = useContext(TextDataContext);

    const {
        API_get_user, 
        API_set_user,
        API_get_character_id,
        API_set_character_id
        //API_get_user_mode,
        //API_set_user_mode
    } = useContext(UserDataContext);

    //const [activeScreen, setActiveScreen] = useState('attributes');
    //const inputElement = useRef();
    const inputRef = useRef();
    const [editHeaderNameActive, setEditHeaderNameActive] = useState(false);

    const selectScreen = (_pageName) => {
        API_set_active_screen(_pageName)
        //setActive(!active);
    }

    const switchLanguage = (_language) => {
        API_switch_language(_language);
    }

    const getUserName = () => {
        if(API_get_user() == null)
            return 'None'

        if(CheckIfKeyIsContained('name', API_get_user())){
            return API_get_user().name;
        }
        
        return 'None';
    }

    const getUserMode = () => {
        if(API_get_user() == null)
            return 'None'

        if(CheckIfKeyIsContained('role', API_get_user())){
            if(API_get_user().role == 'player')
            {
                return API_get_user_labels().player;
            }
            if(API_get_user().role == 'game_master')
            {
                return API_get_user_labels().game_master;
            }
            else
            {
                return 'None';
            }
        }
        
        return 'None';
    }

    const getHeaderName = (_editMode = false) => {
        if(props.game_mode == 'player'){
            if((API_get_base_stats().name == '' || IsWhitespace(API_get_base_stats().name)) && !_editMode){
                return API_get_std_labels().character
            }
            else
            {
                return API_get_base_stats().name
            }
        }
        else if(props.game_mode == 'game_master'){
            
            if((API_get_campaign_name() == '' || IsWhitespace(API_get_campaign_name()))  && !_editMode){
                return API_get_std_labels().new_campaign
            }
            else
            {
                return API_get_campaign_name()
            }
        }
        else
        {
            return 'Not found'
        }
    }

    useEffect(() => {
        if(editHeaderNameActive)
        {
            if(inputRef.current)
            {
                console.log("focus")
                resizeInput();
                inputRef.current.focus();
            }
        }
        else
        {
            console.log("defocus")
            if(!inputRef.current)
                console.log("null")
        }
      }, [editHeaderNameActive]);
      
    const editHeaderName = () => {
        if(editHeaderNameActive)
        {
            if(inputRef.current)
                inputRef.current.blur();
        }

        setEditHeaderNameActive(!editHeaderNameActive);
    }

    const activeEditMode = () => {
        setEditHeaderNameActive(true);
    }

    const closeEditMode = () => {
        if(inputRef.current)
            inputRef.current.blur();

        setEditHeaderNameActive(false);
    }
    

    const updateHeaderName = (_name) => {
        if(props.game_mode == 'player'){
            API_update_base_stat({...API_get_base_stats(), name: _name});
        }
        else if(props.game_mode == 'game_master'){
            return API_update_campaign_name(_name)
        }
    }

    const resizeInput = () => {
        let input = inputRef.current;
        if(inputRef.current)
        {
            const textWidth = input.scrollWidth;
            input.style.width = textWidth + "px";
            //input.style.display = "block";
            //input.style.display = "none";
        }
            //inputRef.current.style.width = inputRef.current.value.length + "ch";
    }

    const getInputMinWidth = () => {
        if(props.game_mode == 'player'){
            return 164
        }
        else if(props.game_mode == 'game_master'){
            return 2
        }
    }

    const get_id = () => {
        
        if(props.game_mode == 'player'){
            return API_get_character_id();
        }
        else if(props.game_mode == 'game_master'){
            return API_get_campaign_id();
        }
        
    }

    return ( 
        

        <div style={{height: '100%', borderBottom: '1px solid black', width: '100%', background: 'transparent'}}>
            <div style={{height: '100%', width: 1800, minWidth: 1800}}>
                <div style={{height: '100%', display: 'inline-block', verticalAlign: 'top'}}>
                    <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                        {/* (props.game_mode == 'player' && API_get_base_stats().name == '') && 
                            <h1>{API_get_std_labels().character}</h1>
                        */}
                        {/*(props.game_mode == 'player' &&  API_get_base_stats().name != '') && 
                            <h1>{API_get_base_stats().name}</h1>
                        */}
                        {/*(props.game_mode == 'game_master') && 
                            <h1>{API_get_campaign_name()}</h1>
                        */}
                        <div style={{display: 'block', margin: '15px 15px 10px 12px'}}>
                            { !editHeaderNameActive &&
                                <label style={{fontSize: 35, fontFamily: "Arial, sans-serif", marginRight: 5, fontWeight: 'bold'}} onClick={activeEditMode}>{getHeaderName()}</label>
                            }
                            { editHeaderNameActive &&
                                <input className={field.input_field_header} ref={inputRef} onInput={resizeInput} style={{minWidth: getInputMinWidth(), marginLeft: 0, marginTop: -5, padding: 0, marginBottom: -1}} value={getHeaderName(true)} onBlur={closeEditMode} onChange={(e) => updateHeaderName(e.target.value)} />
                            }
                            {/*<button className={btn.button_icon} onClick={editHeaderName}>E</button>*/}
                        </div>
                        <div style={{marginLeft: 15}}>
                            <table>
                                <tr>
                                    <td style={{width: 55, minWidth: 55}}>{API_get_user_labels().user}:</td>
                                    <td style={{width: 440, minWidth: 440}}>{getUserName()} ({get_id()})</td>
                                </tr>
                                <tr>
                                    <td>{API_get_user_labels().role}:</td>
                                    <td>{getUserMode()}</td>
                                </tr>
                            </table>
                        </div>
                        <div style={{width: 660, minWidth: 660, background: 'transparent', marginTop: 'auto'}}>
                            <button className={(API_get_active_screen() == 'general') ? btn.tab_component_active : btn.tab_component} style={{marginLeft: 15}} onClick={() => selectScreen('general')}>{API_get_tab_view_labels().general}</button>
                            <button className={(API_get_active_screen() == 'skills') ? btn.tab_component_active : btn.tab_component}  onClick={() => selectScreen('skills')}>{API_get_tab_view_labels().skills}</button>
                            <button className={(API_get_active_screen() == 'equipment') ? btn.tab_component_active : btn.tab_component}  onClick={() => selectScreen('equipment')}>{API_get_tab_view_labels().equipment}</button>
                            <button className={(API_get_active_screen() == 'feat') ? btn.tab_component_active : btn.tab_component}  onClick={() => selectScreen('feat')}>{API_get_tab_view_labels().feat}</button>
                            <button className={(API_get_active_screen() == 'magic') ? btn.tab_component_active : btn.tab_component}  onClick={() => selectScreen('magic')}>{API_get_tab_view_labels().magic}</button>
                            {(props.game_mode == 'game_master') &&
                                <button className={(API_get_active_screen() == 'combat') ? btn.tab_component_active : btn.tab_component} onClick={() => selectScreen('combat')}>{API_get_tab_view_labels().combat}</button>
                            }
                            <button className={(API_get_active_screen() == 'rules') ? btn.tab_component_active : btn.tab_component}  onClick={() => selectScreen('rules')}>{API_get_tab_view_labels().rules}</button>
                            <button className={(API_get_active_screen() == 'remote_user') ? btn.tab_component_active : btn.tab_component}  onClick={() => selectScreen('remote_user')}>{API_get_tab_view_labels().remote_user}</button>
                        </div>
                        
                        {/* (props.game_mode == 'game_master') &&
                            <div style={{width: 620, minWidth: 620, background: 'transparent', marginTop: 'auto'}}>
                                <button className={(API_get_active_screen() == 'combat') ? btn.tab_component_active : btn.tab_component} style={{marginLeft: 15}} onClick={() => selectScreen('combat')}>{API_get_tab_view_labels().combat}</button>
                                <button className={(API_get_active_screen() == 'rules') ? btn.tab_component_active : btn.tab_component}  onClick={() => selectScreen('rules')}>{API_get_tab_view_labels().rules}</button>
                            </div>
                    */}
                    </div>
                </div>
                <div style={{height: '100%', width: 570, minWidth: 570, display: 'inline-block', verticalAlign: 'top', background: 'transparent'}}>
                    { (props.game_mode == 'player') &&
                        <EditCharacterHeader></EditCharacterHeader>
                    }
                </div>
                <div style={{height: '100%', display: 'inline-block', verticalAlign: 'top', background: 'transparent', marginLeft:100}}>
                    <div style={{marginTop:20, verticalAlign: 'bottom'}}>
                        <div style={{verticalAlign: 'bottom'}}>
                            <IOPage game_mode={props.game_mode}></IOPage> 
                        </div>
                        <div style={{verticalAlign: 'bottom'}}>
                            <select value={API_get_tool_data().language} name='language' id='language' style={{width: 48, margin: '10px 0px 0px 5px'}} onChange={(e) => switchLanguage(e.target.value)}>
                                <option value={API_get_language_list().english}>{API_get_language_list().english}</option>
                                <option value={API_get_language_list().german}>{API_get_language_list().german}</option>
                            </select>
                            <lable style={{margin: '10px 0px 0px 60px', color: 'orange'}}>v{API_get_tool_data().version.main}.{API_get_tool_data().version.sub}.{API_get_tool_data().version.rev}</lable>
                        </div>
                    </div>
                </div>
                <div style={{display: 'inline-block', marginLeft: '20px', marginTop: '60px'}}>
                    { API_get_dice_log_visibility() && 
                        <div>
                            <div>
                                <div style={{ whiteSpace: 'pre-wrap' }}>
                                    <p style={{color: 'darkred', fontWeight: 'bold'}}>{API_get_dice_log().log_message}</p>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        {/*<div style={{background: 'violet', height: '100%', display: 'flex', flexDirection: 'column'}}>
    <div style={{background: 'brown', width: 200}}><h1>{API_get_std_labels().character}</h1></div>
    <div style={{display:'flex', height: '100%'}}>
        <div style={{alignSelf: 'flex-end', height: '100%', background: 'blue'}}>Buttons</div>
        <div style={{display: 'inline-block', marginLeft: 500, background: 'green'}}>
            <div> Element2</div>
        </div>
        <div style={{display: 'inline-block', background: 'red'}}>
            <div> Element3</div>
        </div>
    </div>
        <div className={container.base_container} style={{borderBottom: '1px solid black'}} >
            <div><h1>{API_get_std_labels().character}</h1></div>
            <div style={{display: 'inline-block', overflow: 'hidden', height: 70, minHeight: 70, display: 'flex', alignItems: 'flex-end', background: 'transparent'}}>
                <div style={{height: 70, minHeight: 70, width: 515, minWidth: 515, float: 'left', marginTop: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}  >
                    <button className={btn.tab_component} style={{marginLeft: 15}} onClick={() => selectScreen('attributes')}>{API_get_tab_view_labels().attributes}</button>
                    <button className={btn.tab_component} onClick={() => selectScreen('skills')}>{API_get_tab_view_labels().skills}</button>
                    <button className={btn.tab_component} onClick={() => selectScreen('equipment')}>{API_get_tab_view_labels().equipment}</button>
                    <button className={btn.tab_component} onClick={() => selectScreen('magic')}>{API_get_tab_view_labels().magic}</button>
                    <button className={btn.tab_component} onClick={() => selectScreen('settings')}>{API_get_tab_view_labels().settings}</button>
                </div>
            </div>
            <div style={{display: 'inline-block'}}>
                <EditCharacterHeader></EditCharacterHeader>
            </div>
            <div style={{display: 'inline-block', height: '70px', minHeight: 70}}>
                <div style={{margin: '-5px 0px 0px 555px', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', display: 'inline-block'}}>   
                    <UploadHandler></UploadHandler> 
                </div>
                <div>
                    <select value={API_get_tool_data().language} name='language' id='language' style={{width: 48, margin: '10px 0px 0px 560px'}} onChange={(e) => switchLanguage(e.target.value)}>
                        <option value={API_get_language_list().english}>{API_get_language_list().english}</option>
                        <option value={API_get_language_list().german}>{API_get_language_list().german}</option>
                    </select>
                    <lable style={{margin: '10px 0px 0px 60px'}}>v{API_get_tool_data().version.main}.{API_get_tool_data().version.sub}.{API_get_tool_data().version.rev}</lable>
                </div>
            </div>
    </div>*/}
        </div>
    );
}

/*
                    
                         
*/
/*

                    
*/
/*

*/

/*

*/
export default Navbar;