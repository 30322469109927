import { useState, useContext, useEffect } from 'react'
import {APIContext, APIContextProvider} from '../../API/API'
import {AttributeContext, AttributeContextProvider} from '../../API/AttributeContext'
import {BaseStatContext, BaseStatContextProvider} from '../../API/BaseStatContext'
import {ToolDataContext, ToolDataContextProvider} from '../../API/ToolDataContext'
import {TextDataContext, TextDataContextProvider} from '../../API/TextDataContext'
import {
    ParseToFloat, 
    ParseToInteger,
    GetElementById,
    GetListParameterByID,
    SetListParameterByID,
    TryParse
} from '../../API/Functions/GeneralFunctions'
import btn from '../../Styles/btn.module.css'
import table from '../../Styles/table.module.css'
import field from '../../Styles/field.module.css'
import container from '../../Styles/container.module.css'

const CharacterStatsPage = () => {
    const {
        API_full_GUI_update,
        API_increment_level,
        API_update_base_stat,
        API_increment_attribute,
        API_increment_skill,
        API_increment_other_skill_bonus,
        API_increment_ancestry_bonus,
        API_increment_other_bonus,
        API_increment_focus,
        API_increment_kho_tal,
        API_update_comments,
        API_get_dice_list_string,
        API_roll_dice,
        API_modify_character_stat_bonus,
        //API_modify_secondary_attribute_bonus,
        API_change_hitpoints,
        API_increment_free_attribute_points_bonus,
        API_increment_free_focus_points_bonus,
        API_get_skill_attribute_mod
    } = useContext(APIContext);

    const {
        API_get_base_stat_labels, 
        API_set_base_stat_labels,
        API_get_std_labels,
        API_set_std_labels,
        API_get_std_header_labels,
        API_set_std_header_labels,
        API_get_tab_view_labels,
        API_set_tab_view_labels,
        API_get_character_stats_labels,
        API_set_character_stats_labels,
        API_get_table_header_labels,
        API_set_table_header_labels
    } = useContext(TextDataContext);


    const {
        API_get_base_stats,
        API_get_free_attribute_points,
        API_get_free_focus_points,
        API_get_edit_character_visibility,
        API_set_edit_character_visibility
    } = useContext(BaseStatContext);

    const {
        API_get_attributes,
        API_get_comments,
        API_set_character_stats,
        API_get_character_stats,
        //API_get_secondary_attributes,
        API_get_attribute_by_id
    } = useContext(AttributeContext);

    const [dmgVal, setDmgVal] = useState(0);
    const [healVal, setHealVal] = useState(0);

    //API_set_character_stats();

    const [editHitpoints, setEditHitpoints] = useState(0);
    const [editLoadCapacity, setEditLoadCapacity] = useState(0);
    const [editEndurance, setEditEndurance] = useState(0);
    const [editKhoTal, setEditKhoTal] = useState(0);


    const modifyCharacterStat = (_id, _value) => {
        setEditHitpoints(ParseToInteger(editHitpoints));
        setEditLoadCapacity(ParseToInteger(editLoadCapacity));
        setEditEndurance(ParseToInteger(editEndurance));
        setEditKhoTal(ParseToInteger(editKhoTal));
        API_modify_character_stat_bonus(_id, _value);
    }


    const tryModifyHitpoints = (_newValue) => {
        if(TryParse(_newValue, 'integer'))
            API_modify_character_stat_bonus(API_get_character_stats().hitpoints.id, _newValue);

        setEditHitpoints(_newValue);
    }

    const tryModifyLoadCapacity = (_newValue) => {
        if(TryParse(_newValue, 'integer'))
            API_modify_character_stat_bonus(API_get_character_stats().load_capacity.id, _newValue);

        setEditLoadCapacity(_newValue);
    }

    const tryModifyEditEndurance = (_newValue) => {
        if(TryParse(_newValue, 'integer'))
            API_modify_character_stat_bonus(API_get_character_stats().endurance.id, _newValue);

        setEditEndurance(_newValue);
    }

    const tryModifyKhoTal = (_newValue) => {
        if(TryParse(_newValue, 'integer'))
            API_modify_character_stat_bonus(API_get_character_stats().kho_tal.id, _newValue);

        setEditKhoTal(_newValue);
    }

    const DealDamage = () => {
        var _val = parseInt(dmgVal);
        var _val = -_val;
        API_change_hitpoints(_val)
        setDmgVal(0);
    }

    const Heal = () => {
        API_change_hitpoints(healVal)
        setHealVal(0);
    }

    const incrementKhoTal = (_increment) => {
        API_increment_kho_tal(_increment);
    }

    return ( 
        <div>
            <p style={{fontWeight: 'bold', fontSize: '20px', marginBottom: '9px'}}>{API_get_character_stats_labels().character_stats}</p>
            <div style={{alignItems: 'left', marginTop: '5px'}}>
                <table className = {table.table_attributes} style={{marginLeft: 0}}>
                    <tr>
                        <td className={table.cell_header} style={{width: 130, minWidth: 130, textAlign: 'left', paddingLeft: '5px'}}>{API_get_std_header_labels().characteristics}</td>
                        <td className={table.cell_header} style={{width: 120, minWidth: 120}}>{API_get_std_header_labels().formula}</td>
                        <td className={table.cell_header} style={{width: 80, minWidth: 80}}>{API_get_std_header_labels().bonus}</td>
                        <td className={table.cell_header} style={{width: 80, minWidth: 80}}>{API_get_std_header_labels().temporary_mod}</td>
                        <td className={table.cell_header} style={{width: 80, minWidth: 80}}>{API_get_std_header_labels().remain}</td>
                        <td className={table.cell_header} style={{width: 80, minWidth: 80}}>{API_get_std_header_labels().value}</td>
                    </tr>
                    <tr>
                        <td style={{width: 10, minWidth: 10, textAlign: 'left', paddingLeft: '5px'}}>{API_get_character_stats_labels().hitpoints}</td>
                        <td>3x{API_get_character_stats_labels().formula_toughness}+20</td>
                        <td>
                            {!API_get_edit_character_visibility() && 
                                <p className={field.paragraph_std}>{API_get_character_stats().hitpoints.bonus}</p>
                            }
                            {API_get_edit_character_visibility() && 
                                <input type='number' className={field.input_field_number} style={{width: 60}} value={editHitpoints} onChange={(e) => tryModifyHitpoints(e.target.value)} onBlur={(e) => modifyCharacterStat(API_get_character_stats().hitpoints.id, e.target.value)} />
                            }
                        </td>
                        <td>{API_get_character_stats().hitpoints.temporary_mod}</td>
                        <td>{API_get_character_stats().hitpoints.value - API_get_character_stats().hitpoints.lost}</td>
                        <td>{API_get_character_stats().hitpoints.value}</td>
                    </tr>
                    <tr>
                        <td style={{width: 110, textAlign: 'left', paddingLeft: '5px'}}>{API_get_character_stats_labels().load_capacity}</td>
                        <td>3x{API_get_character_stats_labels().formula_load_capacity}+28</td>
                        <td>
                            {!API_get_edit_character_visibility() && 
                                <p className={field.paragraph_std}>{API_get_character_stats().load_capacity.bonus}</p>
                            }
                            {API_get_edit_character_visibility() && 
                                <input type='number' className={field.input_field_number} style={{width: 60}} value={editLoadCapacity} onChange={(e) => tryModifyLoadCapacity(e.target.value)} onBlur={(e) => modifyCharacterStat(API_get_character_stats().load_capacity.id, e.target.value)} />
                            }
                        </td>
                        <td>{API_get_character_stats().load_capacity.temporary_mod}</td>
                        <td>{API_get_character_stats().load_capacity.value - API_get_character_stats().load_capacity.lost}</td>
                        <td>{API_get_character_stats().load_capacity.value}</td>
                    </tr>
                    <tr>
                        <td style={{width: 110, textAlign: 'left', paddingLeft: '5px'}}>{API_get_character_stats_labels().endurance}</td>
                        <td>4x{API_get_character_stats_labels().formula_endurance}+16</td>
                        <td>
                            {!API_get_edit_character_visibility() && 
                                <p className={field.paragraph_std}>{API_get_character_stats().endurance.bonus}</p>
                            }
                            {API_get_edit_character_visibility() && 
                                <input type='number' className={field.input_field_number} style={{width: 60}} value={editEndurance} onChange={(e) => tryModifyEditEndurance(e.target.value)} onBlur={(e) => modifyCharacterStat(API_get_character_stats().endurance.id, e.target.value)} />
                            }
                        </td>
                        <td>{API_get_character_stats().endurance.temporary_mod}</td>
                        <td>{API_get_character_stats().endurance.value - API_get_character_stats().endurance.lost}</td>
                        <td>{API_get_character_stats().endurance.value}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: '5px'}}>
                            <div style={{width: 120, minWidth: 120}}>
                                <div style={{float: 'left', display: 'flex', alignItems: 'center'}}><p style={{marginTop: 3}}>Kho'Tal</p></div>
                                <div style={{margin: '0px 0px 0px 4px', float: 'right'}}>
                                    <button className={btn.button_icon} onClick={() => incrementKhoTal(-1)} style={{width: 22, height: 22, margin: '0px 2px 0px 0px'}}>+</button>
                                    <button className={btn.button_icon} onClick={() => incrementKhoTal(1)} style={{width: 22, height: 22, margin: '0px 5px 0px 0px'}}>-</button>
                                </div>
                            </div>
                        </td>
                        <td>{API_get_character_stats().kho_tal.formula}</td>
                        <td>
                            {!API_get_edit_character_visibility() && 
                                <p className={field.paragraph_std}>{API_get_character_stats().kho_tal.bonus}</p>
                            }
                            {API_get_edit_character_visibility() && 
                                <input type='number' className={field.input_field_number} style={{width: 60}} onChange={(e) => tryModifyKhoTal(e.target.value)} value={editKhoTal} onBlur={(e) => modifyCharacterStat(API_get_character_stats().kho_tal.id, e.target.value)} />
                            }
                        </td>
                        <td>{API_get_character_stats().kho_tal.temporary_mod}</td>
                        <td>{API_get_character_stats().kho_tal.value - API_get_character_stats().kho_tal.lost}</td>
                        <td>{API_get_character_stats().kho_tal.value}</td>
                    </tr>
                </table>
                
                <div style={{marginTop: 5}}>
                    <table>
                        <tr>
                            <td style={{width: 90, minWidth: 90}}><button className={btn.button_std} onClick={DealDamage} style={{margin: 0}}>{API_get_character_stats_labels().deal_dmg}</button></td>
                            <td style={{width: 70, minWidth: 70}}><input type='number' className={field.input_field_number} value={dmgVal} style={{width: 70, minWidth: 70}} onChange={(e) => setDmgVal(e.target.value)}></input></td>
                            <td style={{width: 255, minWidth: 255}}></td>
                            <td style={{width: 40, minWidth: 40}}><button className={btn.button_std} onClick={Heal}>{API_get_character_stats_labels().heal}</button></td>
                            <td style={{width: 70, minWidth: 70}}><input type='number' className={field.input_field_number} value={healVal} style={{width: 70, minWidth: 70}} onChange={(e) => setHealVal(e.target.value)}></input></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    );
}
 
export {
    CharacterStatsPage
}