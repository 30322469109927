import { 
    ParseToFloat, 
    ParseToInteger ,
    GetListParameterByID,
    SetListParameterByID,
    AddElementToList,
    RemoveElementFromList,
    MoveElementInList
} from '../../API/Functions/GeneralFunctions'

import {
    BACKEND_get_armor,
    BACKEND_set_armor,
    BACKEND_get_gear,
    BACKEND_set_gear,
    BACKEND_get_feat_lv_2,
    BACKEND_set_feat_lv_2,
    BACKEND_get_feat_lv_3,
    BACKEND_set_feat_lv_3,
    BACKEND_get_magic_spells,
    BACKEND_set_magic_spells,
    BACKEND_get_armor_template,
    BACKEND_get_gear_template,
    BACKEND_get_feat_lv_2_template,
    BACKEND_get_feat_lv_3_template,
    BACKEND_get_magic_spells_template,
    BACKEND_set_magic_element_types,
    BACKEND_get_magic_element_types,
    BACKEND_set_magic_circle_types,
    BACKEND_get_magic_circle_types
} from '../Parameters/CharacterParameters'

import {
    RecalcCharacterStats,
    //RecalcSecondaryAttributes,
    RecalcFreeAttributePoints,
    RecalcFreeFocusPoints,
    RecalcFreeSkillPoints,
    RecalcAttributes,
    RecalcSkills,
    RecalcWeapons,
    RecalcWeight,
    RecalcArmor
} from './RecalcFunctions'


const ModifyMagicSpellName = (_id, _new_value) => {
    SetListParameterByID(_id, BACKEND_get_magic_spells(), BACKEND_set_magic_spells, _new_value, 'text', 'id', 'display_name');
}

const ModifyMagicSpellMight = (_id, _new_value) => {
    SetListParameterByID(_id, BACKEND_get_magic_spells(), BACKEND_set_magic_spells, _new_value, 'integer', 'id', 'might');
}

const ModifyMagicSpellDescription = (_id, _new_value) => {
    SetListParameterByID(_id, BACKEND_get_magic_spells(), BACKEND_set_magic_spells, _new_value, 'text', 'id', 'description');
}

const SwitchMagicElementType = (_type_id, _list_element_id) => {
    SetListParameterByID(_list_element_id, BACKEND_get_magic_spells(), BACKEND_set_magic_spells, _type_id, 'integer', 'id', 'element_id');
}

const SwitchMagicCircleType = (_type_id, _list_element_id) => {
    SetListParameterByID(_list_element_id, BACKEND_get_magic_spells(), BACKEND_set_magic_spells, _type_id, 'integer', 'id', 'circle_id');
}

const AddNewMagicSpell = () => {
    AddElementToList(BACKEND_get_magic_spells(), BACKEND_get_magic_spells_template(), BACKEND_set_magic_spells);
}

const RemoveMagicSpell = (_element) => {
    RemoveElementFromList(_element.id, BACKEND_get_magic_spells(), BACKEND_set_magic_spells);
}

const MoveMagicSpell = (_element, _direction) => {
    MoveElementInList(_element.id, BACKEND_get_magic_spells(), BACKEND_set_magic_spells, _direction);
}

export {
    MoveMagicSpell,
    RemoveMagicSpell,
    AddNewMagicSpell,
    SwitchMagicCircleType,
    SwitchMagicElementType,
    ModifyMagicSpellDescription,
    ModifyMagicSpellMight,
    ModifyMagicSpellName
}