import { useState, useContext, useEffect } from 'react'
//import DownloadHandler from '../Components/DownloadHandler';
import UploadHandler from '../Components/IOPage';
import BaseStatsPage from '../Components/BaseStatsPage';
import GeneralPage from '../Components/GeneralPage';
import SkillPage from '../Components/SkillPage';
import EquipmentPage from '../Components/EquipmentPage';
import MagicPage from '../Components/MagicPage';
import FeatPage from '../Components/FeatPage';
import RulesPage from '../Components/RulesPage';
import RemoteUserPage from '../Components/RemoteUserPage';
import Navbar from './Navbar';
import EditCharacterHeader from './EditCharacterHeader';
import {APIContext, APIContextProvider} from '../API/API'
import {ToolDataContext,ToolDataContextProvider} from '../API/ToolDataContext'
import {TextDataContext,TextDataContextProvider} from '../API/TextDataContext'
import {ScreenDataContext,ScreenDataContextProvider} from '../API/ScreenDataContext'
import btn from '../Styles/btn.module.css'
import container from '../Styles/container.module.css'

const CharacterMainPage = () => {
    const {
        API_switch_language,
        API_get_language_list,
        API_init_character
    } = useContext(APIContext);

    const {
        API_get_active_screen,
        API_set_active_screen
    } = useContext(ScreenDataContext);

    const {
        API_get_tool_data,
        API_set_tool_data,
        API_get_user_mode
    } = useContext(ToolDataContext);

    const {
        API_get_base_stat_labels, 
        API_set_base_stat_labels,
        API_get_std_labels,
        API_set_std_labels,
        API_get_tab_view_labels,
        API_set_tab_view_labels,
        API_get_character_stats_labels,
        API_set_character_stats_labels,
        API_get_table_header_labels,
        API_set_table_header_labels
    } = useContext(TextDataContext);

    //const [activeScreen, setActiveScreen] = useState('attributes');

    /*const selectScreen = (_pageName) => {
        {
            API_set_active_screen(_pageName)
        }
    }*/

    /*const switchLanguage = (_language) => {
        API_switch_language(_language);
    }*/


    return ( 
        <div>
            <div className={container.base_container} style={{position: 'fixed', zIndex: 9, width: '100%', minWidth: '100%', height: '126px', minHeight: '126px', maxHeight: '126px', background: 'gray'}}>
                <Navbar game_mode={'player'}></Navbar>
            </div>
            <div style={{background: 'transparent', paddingTop: '146px'}}>
                <BaseStatsPage></BaseStatsPage>  
            </div>
                
            <div>
                { API_get_active_screen() == 'general' &&
                    <GeneralPage />
                }
                { API_get_active_screen() == 'skills' &&
                    <SkillPage />
                }
                { API_get_active_screen() == 'equipment' &&
                    <EquipmentPage />
                }
                { API_get_active_screen() == 'magic' &&
                    <MagicPage />
                }
                { API_get_active_screen() == 'feat' &&
                    <FeatPage />
                }
                { API_get_active_screen() == 'rules' &&
                    <RulesPage />
                }
                { API_get_active_screen() == 'remote_user' &&
                    <RemoteUserPage />
                }
            </div>
        </div>
    );
}

export {CharacterMainPage};