import { createRef, useState, useContext } from 'react'
//import {BaseStatContext, BaseStatContextProvider} from '../UI_Controller/BaseStatContext'
//import {LoadedAttributesContext, LoadedAttributesContextProvider} from '../UI_Controller/LoadedAttributesContext'
//import {AttributeContext, AttributeContextProvider} from '../UI_Controller/AttributeContext'
//import {ConvertAttributes} from '../Backend/CharacterController'
//import CharacterAttributes from './CharacterAttributes'
import {APIContext, APIContextProvider} from '../API/API'
import {ToolDataContext, ToolDataContextProvider} from '../API/ToolDataContext'
import {TextDataContext, TextDataContextProvider} from '../API/TextDataContext'
import btn from '../Styles/btn.module.css'

//set right id structure for later reference
/*const SetAttributeID = (m_Attributes) => {
    for(var i = 0; i < m_Attributes.length; i++)
    {
        m_Attributes[i].id = i;
        for(var j = 0; j < m_Attributes[i].attribute_list.length; j++)
        {
            m_Attributes[i].attribute_list[j].id = j;
            for(var k = 0; k < m_Attributes[i].attribute_list[j].skill_list.length; k++)
            {
                m_Attributes[i].attribute_list[j].skill_list[k].id = k;
            }
        }
    }
    return m_Attributes;
}*/

const IO_Handler = (props) => {
    const inputRef = createRef();

    const {
        API_full_GUI_update,
        API_increment_level,
        API_update_base_stat,
        API_increment_attribute,
        API_download_character,
        API_upload_character,
        API_upload_campaign,
        API_get_character_file_name,
        API_get_character_file,
        API_get_campaign_file_name,
        API_get_campaign_file,
        API_switch_language,
        API_init_character,
        API_init_campaign
    } = useContext(APIContext);

    const {
        API_get_tool_data,
        API_set_tool_data,
    } = useContext(ToolDataContext);

    const {
        API_get_std_labels,
        API_set_std_labels
    } = useContext(TextDataContext);
    /*const {
        baseStats,
        setBaseStats,
        attributePoints,
        setAttributePoints,
        focusPoints,
        setFocusPoints
    } = useContext(BaseStatContext);*/

    /*const {
        loadedAttributeGroups,
        setLoadedAttributeGroups,
        loadedAttributes,
        setLoadedAttributes,
        loadedSkills,
        setLoadedSkills
    } = useContext(LoadedAttributesContext);*/

    /*const {
        attributes,
        setAttributes
    } = useContext(AttributeContext);*/

    const openFileDialog = () => {
        inputRef.current?.click();
    }

    const handleFileChange = e => {
        if(props.game_mode == 'player'){
            API_upload_character(e);
        }
        else if(props.game_mode == 'game_master'){
            API_upload_campaign(e);
        }
        
    };

    const getDownloadFileName = () => {
        if(props.game_mode == 'player'){
            return API_get_character_file_name();
        }
        else if(props.game_mode == 'game_master'){
            return API_get_campaign_file_name();
        }
    }
    const getDownloadFile = () => {
        if(props.game_mode == 'player'){
            return API_get_character_file();
        }
        else if(props.game_mode == 'game_master'){
            return API_get_campaign_file();
        }
    }

    const newCharacterHandler = () => {
        let _toolData = API_get_tool_data();
        if(props.game_mode == 'player'){
            API_init_character(_toolData.language)
        }
        else if(props.game_mode == 'game_master'){
            API_init_campaign(_toolData.language)
        }
    }

    return ( 
        <div>
        <div style={{marginLeft: 5, marginBottom: 5}}>
            <button className={btn.button_std} onClick={newCharacterHandler}>{API_get_std_labels().new}</button>
        </div>
        <div style={{display: 'inline-block', width: 180, minWidth: 180}}>
            <button variant="outlined" className={btn.button_std} style={{marginLeft: '5px', marginRight: '5px'}}>
                <a download={getDownloadFileName()} target="_blank" rel="noreferrer" href={URL.createObjectURL(getDownloadFile())} style={{
                    textDecoration: "inherit",
                    color: "inherit",
                }}>{API_get_std_labels().save}</a>
            </button>
            <input ref={inputRef} style={{display: 'none'}} type="file" onChange={handleFileChange} />
            <button type="file" className={btn.button_std} onClick={openFileDialog} >{API_get_std_labels().load}</button>
        </div>
        </div>
     );
}
 
export default IO_Handler;