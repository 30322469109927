
import { useState, useContext } from 'react'
import {APIContext, APIContextProvider} from '../../API/API'
import {AttributeContext, AttributeContextProvider} from '../../API/AttributeContext'
import {BaseStatContext, BaseStatContextProvider} from '../../API/BaseStatContext'
import {ToolDataContext, ToolDataContextProvider} from '../../API/ToolDataContext'
import {TextDataContext, TextDataContextProvider} from '../../API/TextDataContext'
import {
    ParseToInteger,
    ParseToFloat} 
from '../../API/Functions/GeneralFunctions'
import btn from '../../Styles/btn.module.css'
import table from '../../Styles/table.module.css'
import field from '../../Styles/field.module.css'
import container from '../../Styles/container.module.css'

const StatOverviewPage = () => {
    const {
        API_full_GUI_update,
        API_increment_level,
        API_update_base_stat,
        API_increment_attribute,
        API_increment_skill,
        API_increment_other_skill_bonus,
        API_increment_ancestry_bonus,
        API_increment_other_bonus,
        API_increment_focus,
        API_increment_kho_tal,
        API_update_comments,
        API_get_dice_list_string,
        API_roll_dice,
        API_modify_character_stat_bonus,
        //API_modify_secondary_attribute_bonus,
        API_change_hitpoints,
        API_increment_free_attribute_points_bonus,
        API_increment_free_focus_points_bonus,
        API_get_skill_attribute_mod
    } = useContext(APIContext);

    const {
        API_get_base_stat_labels, 
        API_set_base_stat_labels,
        API_get_std_labels,
        API_set_std_labels,
        API_get_std_header_labels,
        API_set_std_header_labels,
        API_get_tab_view_labels,
        API_set_tab_view_labels,
        API_get_character_stats_labels,
        API_set_character_stats_labels,
        API_get_table_header_labels,
        API_set_table_header_labels,
        API_get_stat_overview_labels,
        API_set_stat_overview_labels,
        API_get_money_header_labels,
        API_set_money_header_labels
    } = useContext(TextDataContext);


    const {
        API_get_base_stats,
        API_get_free_attribute_points,
        API_get_free_focus_points,
        API_get_edit_character_visibility,
        API_set_edit_character_visibility,
        API_get_stat_overview,
        API_set_stat_overview
    } = useContext(BaseStatContext);

    const {
        API_get_attributes,
        API_get_comments,
        API_get_character_stats,
        //API_get_secondary_attributes,
        API_get_attribute_by_id
    } = useContext(AttributeContext);

    const [dmgVal, setDmgVal] = useState(0);
    const [healVal, setHealVal] = useState(0);

    const [editHitpoints, setEditHitpoints] = useState(0);
    const [editLoadCapacity, setEditLoadCapacity] = useState(0);
    const [editEndurance, setEditEndurance] = useState(0);
    const [editKhoTal, setEditKhoTal] = useState(0);

    const modifyCharacterStat = (_id, _value) => {
        setEditHitpoints(ParseToInteger(editHitpoints));
        setEditLoadCapacity(ParseToInteger(editLoadCapacity));
        setEditEndurance(ParseToInteger(editEndurance));
        setEditKhoTal(ParseToInteger(editKhoTal));
        API_modify_character_stat_bonus(_id, _value);
    }
    const DealDamage = () => {
        var _val = parseInt(dmgVal);
        var _val = -_val;
        API_change_hitpoints(_val)
        setDmgVal(0);
    }

    const Heal = () => {
        API_change_hitpoints(healVal)
        setHealVal(0);
    }

    const incrementKhoTal = (_increment) => {
        API_increment_kho_tal(_increment);
    }

    return ( 
        <div>
            <table className = {table.table_base_stats}>
                <tr>
                    <td className={table.cell_header} style={{textAlign: 'left', paddingLeft: 5}} colspan={2}>{API_get_stat_overview_labels().stat_overview}</td>
                </tr>
                <tr>
                    <td className={table.cell_label} style={{textAlign: 'left', width: 130}}>{API_get_stat_overview_labels().carried_weight}</td>
                    <td className={table.cell_input} style={{width: 80}}>{ParseToFloat(API_get_stat_overview().carried_weight)}kg</td>
                </tr>
                <tr>
                    <td className={table.cell_label} style={{textAlign: 'left'}}>{API_get_stat_overview_labels().damage_restriction}</td>
                    <td className={table.cell_input}>{API_get_stat_overview().damage_restriction}</td>
                </tr>
                <tr>
                    <td className={table.cell_label} style={{textAlign: 'left'}}>{API_get_stat_overview_labels().armor_restriction}</td>
                    <td className={table.cell_input}>{API_get_stat_overview().armor_restriction}</td>
                </tr>
                <tr>
                    <td className={table.cell_label} style={{textAlign: 'left'}}>{API_get_stat_overview_labels().other_restriction}</td>
                    <td className={table.cell_input}>{API_get_stat_overview().other_restriction}</td>
                </tr>
            </table>
        </div>
    );
}
 
export {
    StatOverviewPage
}
                