import { useState, useContext } from 'react'
import {APIContext, APIContextProvider} from '../API/API'
import {MonsterTablePage} from './SubPages/MonsterTablePage'
import btn from '../Styles/btn.module.css'
import table from '../Styles/table.module.css'
import field from '../Styles/field.module.css'
import container from '../Styles/container.module.css'

const MagicPage = () => {


    return ( 
        <div style={{height: '100%', background: 'lightgray', marginLeft: 15}}>
            <MonsterTablePage></MonsterTablePage>
        </div>
     );
}
 
export default MagicPage;