import { useState, useContext } from 'react'
import {FeatTablePage} from './SubPages/FeatTablePage'

const FeatPage = () => {


    return ( 
        <div>
            <div style={{marginLeft: 15, marginBottom: 15}}>
                <FeatTablePage level={2}></FeatTablePage>
            </div>
            <div style={{marginLeft: 15, marginBottom: 15}}>
                <FeatTablePage level={3}></FeatTablePage>
            </div>
        </div>
     );
}
 
export default FeatPage;