import { useContext, createContext } from 'react'
import {ToolDataContext, ToolDataContextProvider} from '../API/ToolDataContext'

import {
    BACKEND_get_foundry_connector_window,
    BACKEND_set_foundry_connector_window,
    BACKEND_get_foundry_connector_url
} from './FoundryConnectorParameters'

const RemoteConnectContext = createContext();

const RemoteConnectContextProvider = ({children}) => {

    const {
        API_get_tool_settings,
        API_set_tool_settings
    } = useContext(ToolDataContext);

    const BACKEND_open_foundry_connector_window = () => {
        if(BACKEND_get_foundry_connector_window() != null)
            BACKEND_get_foundry_connector_window().close();

        let newTab = window.open('about:blank', '_blank');
        newTab.document.write('<h1>Foundry connector ready!</h1><p>Keep this window open as long as you need remote connection to Foundry.</p><p>Open Foundry in the same browser.</p><p>You can pop out the foundry tab afterwards.</p>');
        BACKEND_set_foundry_connector_window(newTab);
        BACKEND_get_foundry_connector_window().document.title = 'PECS FondryVTT Connector';
    }

    const BACKEND_send_dice_roll = (_roll_string) => {
        let _connect_string = API_get_tool_settings().foundry_url + BACKEND_get_foundry_connector_url() + _roll_string;
        if(BACKEND_get_foundry_connector_window() == null)
        {
            console.log('null');
            BACKEND_open_foundry_connector_window();
            return;
        }
        if(BACKEND_get_foundry_connector_window().closed)
        {
            console.log('closed');
            BACKEND_open_foundry_connector_window();
            return;
        }

        BACKEND_get_foundry_connector_window().location.href = _connect_string;
    }
        
    return(
        <RemoteConnectContext.Provider value={{
            BACKEND_send_dice_roll,
            BACKEND_open_foundry_connector_window
        }}>
            {children}
        </RemoteConnectContext.Provider>
    )
}

export {RemoteConnectContext, RemoteConnectContextProvider}