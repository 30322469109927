import { useState, useContext } from 'react'
import {CheckIfKeyIsContained, IsWhitespace, ParseToInteger, ParseToFloat, TryParse} from '../../API/Functions/GeneralFunctions'
import field_style from '../../StylesStylish/field.module.css'
import label_style from '../../StylesStylish/label.module.css'

const MultiLineInput = (props) => {

    const updateComments = (_input) => {

    }

    return ( 
        <div>
            <textarea
                className={field_style.multiline_input}
                rows={21} 
                cols={124}
                placeholder={'...'/*API_get_character_stats_labels().add_comments*/}
                onChange={(e) => updateComments(e.target.value)}
                value={2/*API_get_comments()*/}
            />
        </div>
    );
}

export {MultiLineInput}