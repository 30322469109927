
import {
    GetDiceList
} from '../DiceRoller'

//All Backend Functions should only be accessed via UI_Controller or from functions inside Backend Folder
const language_list = {
    default: 'de',
    english: 'en',
    german: 'de'
}

var tool_data = {
    version: {main: 0, sub: 6, rev: 1},
    language: language_list.default
}

var dice_log = {
    header_label: 'Dice Roller:',
    rolled_label: 'Copied to clipboard',
    result_label: 'Result',
    log_message: '',
    dice: null,
    value: 0
}

var tool_settings = {
    foundry_url: 'https://the-council.forge-vtt.com'
}

const BACKEND_get_tool_data = () => 
{
    return tool_data;
}

const BACKEND_set_tool_data = (_tool_data) => 
{
    tool_data = _tool_data;
}

const BACKEND_get_tool_settings = () => 
{
    return tool_settings;
}

const BACKEND_set_tool_settings = (_tool_settings) => 
{
    tool_settings = _tool_settings;
}

const BACKEND_get_language_list = () => 
{
    return language_list;
}

const BACKEND_get_dice_log = () => 
{
    return dice_log;
}

const BACKEND_set_dice_log = (_dice_log) => 
{
    dice_log = _dice_log;
}

const BACKEND_set_dice_log_value = (_value) => 
{
    dice_log.value = _value;
}

export 
{
    BACKEND_get_tool_data, 
    BACKEND_set_tool_data,
    BACKEND_get_tool_settings,
    BACKEND_set_tool_settings,
    BACKEND_get_dice_log,
    BACKEND_set_dice_log,
    BACKEND_set_dice_log_value,
    BACKEND_get_language_list
};