import { useState, useContext, useEffect } from 'react'
import background from "../images/leather-1222379_1280.jpg";
import {ScreenDataContext} from '../API/ScreenDataContext'
import {MessageBoxContext} from '../API/MessageBoxContext'
import CharacterSheetStylish from './CharacterSheetStylish';
import CampaignSheetStylish from './CampaignSheetStylish';
import SideBarStylish from './SideBarStylish';
import SettingsPage from './SettingsPage';
import YesNoMessageBox from './ComponentsStylish/YesNoMessageBox';

const MainPageStylish = () => {
    const {
        API_get_active_main_screen,
        API_set_active_main_screen,
        API_get_active_screen,
        API_set_active_screen

    } = useContext(ScreenDataContext);

    const {
        API_get_yes_no_message_box_visibility,
        API_get_confirm_function,
        API_open_yes_no_message_box,
        API_close_yes_no_message_box
    } = useContext(MessageBoxContext);

    return ( 
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '100vh',
            backgroundRepeat: 'repeat',
            backgroundImage: `url(${background})`
          }}>
            { API_get_yes_no_message_box_visibility() &&
                <YesNoMessageBox />
            }
            <div style={{
              backgroundColor: 'rgba(0, 0, 0, 0.9)',
              width: '10%',
              height: '100%',
            }}>
                <SideBarStylish />
            </div>
            <div style={{
              display: 'flex', 
              flexDirection: 'row', 
              alignItems: 'flex-start',
              flex: 1,
              height: '100vh',
              boxSizing: 'border-box',
              padding: '15px'
            }}>
                { API_get_active_main_screen() == 'campaign' &&
                    <CampaignSheetStylish/>
                }
                { API_get_active_main_screen() == 'character' &&
                    <CharacterSheetStylish/>
                }
                { API_get_active_main_screen() == 'remote_campaigns' &&
                    'Kampagnen'
                }
                { API_get_active_main_screen() == 'remote_characters' &&
                    'Characters'
                }
                { API_get_active_main_screen() == 'rules' &&
                    'Regeln'
                }
                { API_get_active_main_screen() == 'settings' &&
                    <SettingsPage />
                }
            </div>
            <div style={{
              backgroundColor: 'rgba(0, 0, 0, 0.9)',
              width: '10%',
              height: '100%',
            }}></div>
        </div>
    );
}

export default MainPageStylish;