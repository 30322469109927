import { GetFreeAttributePoints, GetFreeFocusPoints, GetMaxAttributeIncrease, CalculateAttribute } from "../Functions/CharacterFunctions";
import { CloneObj } from "../../API/Functions/GeneralFunctions";

var init_feat_lvl_2  = []

var init_feat_lvl_2_template = 
{
    id: 0,
    display_name: '-',
    description: '-'
}

var init_feat_lvl_3  = []

var init_feat_lvl_3_template = 
{
    id: 0,
    display_name: '-',
    description: '-'
}

const INIT_get_feat_lvl_2 = () => 
{
    return CloneObj(init_feat_lvl_2, true);
}

const INIT_get_feat_lvl_2_template = () => 
{
    return CloneObj(init_feat_lvl_2_template, true);
}

const INIT_get_feat_lvl_3 = () => 
{
    return CloneObj(init_feat_lvl_3, true);
}

const INIT_get_feat_lvl_3_template = () => 
{
    return CloneObj(init_feat_lvl_3_template, true);
}

export 
{
    INIT_get_feat_lvl_2,
    INIT_get_feat_lvl_2_template,
    INIT_get_feat_lvl_3,
    INIT_get_feat_lvl_3_template
};