import { createRef, useState, useContext, useEffect } from 'react'
import {AttributeContext, AttributeContextProvider} from '../API/AttributeContext'
import {BaseStatContext, BaseStatContextProvider} from '../API/BaseStatContext'
import {ToolDataContext, ToolDataContextProvider} from '../API/ToolDataContext'
import {TextDataContext, TextDataContextProvider} from '../API/TextDataContext'
import {APIContext, APIContextProvider} from '../API/API'
import {GetDiceListString} from '../API/API'
import {CharacterStatsPage} from './SubPages/CharacterStatsPage'
import {CommentPage} from './SubPages/CommentPage'
import {MoneyTablePage} from './SubPages/MoneyTablePage'
import {StatOverviewPage} from './SubPages/StatOverviewPage'
import {RestTablePage} from './SubPages/RestTablePage'

import btn from '../Styles/btn.module.css'
import table from '../Styles/table.module.css'
import field from '../Styles/field.module.css'
import container from '../Styles/container.module.css'
import {
    ParseToInteger,
    ParseToFloat} from '../API/Functions/GeneralFunctions'
    
const GeneralPage = () => {
    const {
        API_full_GUI_update,
        API_increment_level,
        API_update_base_stat,
        API_increment_attribute,
        API_increment_ancestry_bonus,
        API_increment_other_bonus,
        API_increment_focus,
        API_increment_kho_tal,
        API_update_comments,
        API_get_dice_list_string,
        //API_roll_dice,
        API_modify_character_stat_bonus,
        //API_modify_secondary_attribute_bonus,
        API_change_hitpoints,
        API_increment_free_attribute_points_bonus,
        API_increment_free_focus_points_bonus
    } = useContext(APIContext);

    const {
        API_get_tool_data,
        API_get_dice_log,
        API_get_dice_log_visibility,
        API_set_dice_log_visibility
    } = useContext(ToolDataContext);

    const {
        API_get_base_stat_labels, 
        API_set_base_stat_labels,
        API_get_std_labels,
        API_set_std_labels,
        API_get_std_header_labels,
        API_set_std_header_labels,
        API_get_tab_view_labels,
        API_set_tab_view_labels,
        API_get_character_stats_labels,
        API_set_character_stats_labels,
        API_get_table_header_labels,
        API_set_table_header_labels
    } = useContext(TextDataContext);

    const {
        API_get_base_stats,
        API_get_free_attribute_points,
        API_get_free_focus_points,
        API_get_edit_character_visibility,
        API_set_edit_character_visibility
    } = useContext(BaseStatContext);

    const {
        API_get_attributes,
        API_get_comments,
        API_get_character_stats,
        //API_get_secondary_attributes,
        API_get_attribute_by_id
    } = useContext(AttributeContext);


    return ( 
        <div style={{background: 'lightgray'}}> 
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div>
                    <div style={{ marginLeft: 15, marginBottom: 37 }}>
                        <CharacterStatsPage></CharacterStatsPage>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'inline-block', marginLeft: 15 }}>
                            <StatOverviewPage></StatOverviewPage>
                        </div>
                        <div style={{ display: 'inline-block', marginLeft: 15 }}>
                            <MoneyTablePage></MoneyTablePage>
                        </div>
                    </div>
                </div>
                <div style={{ marginLeft: 15, marginBottom: 15, display: 'flex', flexDirection: 'column' }}>
                    <div>
                        <CommentPage></CommentPage>
                    </div>
                </div>
            </div>
            <div style={{ marginLeft: 15}}>
                <RestTablePage></RestTablePage>
            </div>
        </div>
    );
}

/*
Reminder accordion content
//const accordionContent = [];
<tr key={value2.id}   
    ref={ref => (accordionContent[value2.id] = ref)}>
*/

export default GeneralPage;