var foundry_connector_window = null;
var foundry_connector_url = '/modules/external-dice-roll-connector/roll.html?roll='

const BACKEND_get_foundry_connector_window = () => {
    return foundry_connector_window;
}

const BACKEND_set_foundry_connector_window = (_foundry_connector_window) => {
    foundry_connector_window = _foundry_connector_window;
}

const BACKEND_get_foundry_connector_url = () => {
    return foundry_connector_url;
}

export{
    BACKEND_get_foundry_connector_window,
    BACKEND_set_foundry_connector_window,
    BACKEND_get_foundry_connector_url
}