import { useState, useContext } from 'react'
import {AttributeContext, AttributeContextProvider} from '../../API/AttributeContext'
import {SkillContext, SkillContextProvider} from '../../API/SkillContext'
import {TextDataContext, TextDataContextProvider} from '../../API/TextDataContext'
import {BaseStatContext, BaseStatContextProvider} from '../../API/BaseStatContext'
import {MagicContext, MagicContextProvider} from '../../API/MagicContext'
import {APIContext, APIContextProvider} from '../../API/API'
import {
    ParseToNumber,
    GetElementById
} from '../../API/Functions/GeneralFunctions'
import btn from '../../Styles/btn.module.css'
import table from '../../Styles/table.module.css'
import field from '../../Styles/field.module.css'
import container from '../../Styles/container.module.css'

const SkillTablePage = (props) => {
    const {
        API_full_GUI_update,
        API_increment_level,
        API_update_base_stat,
        API_increment_attribute,
        API_increment_skill,
        API_increment_other_skill_bonus,
        API_increment_ancestry_bonus,
        API_increment_other_attribute_bonus,
        API_increment_focus,
        API_increment_kho_tal,
        API_update_comments,
        API_get_dice_list_string,
        API_roll_dice,
        API_modify_character_stat_bonus,
        //API_modify_secondary_attribute_bonus,
        API_change_hitpoints,
        API_increment_free_attribute_points_bonus,
        API_increment_free_focus_points_bonus,
        API_get_skill_attribute_mod,
        API_get_attribute_group_ref,
        API_get_attribute_ref
    } = useContext(APIContext);

    const {
        API_get_attributes,
        API_set_attributes,
        API_set_comments,
        API_get_comments,
        API_set_character_stats,
        API_get_character_stats,
        //API_set_secondary_attributes,
        //API_get_secondary_attributes,
        API_get_attribute_group_by_id,
        API_get_attribute_by_id
    } = useContext(AttributeContext);

    const {
        API_get_base_stat_labels, 
        API_set_base_stat_labels,
        API_get_std_labels,
        API_set_std_labels,
        API_get_std_header_labels,
        API_set_std_header_labels,
        API_get_tab_view_labels,
        API_set_tab_view_labels,
        API_get_character_stats_labels,
        API_set_character_stats_labels,
        API_get_table_header_labels,
        API_set_table_header_labels
    } = useContext(TextDataContext);

    const {
        API_get_base_stats,
        API_get_free_attribute_points,
        API_get_free_focus_points,
        API_get_edit_character_visibility,
        API_set_edit_character_visibility
    } = useContext(BaseStatContext);

    const {
        API_get_skill_group_visibility,
        API_set_skill_group_visibility,
        API_toggle_skill_group_visibility,
        API_get_skills,
        API_get_increase_selector,
        API_set_increase_selector,
        API_get_skill_selector,
        API_set_skill_selector,
        API_get_edit_skill_visibility,
        API_get_skill_by_id
    } = useContext(SkillContext);

    const {
        API_get_max_magic_circle
    } = useContext(MagicContext);
    
    //const _skill_group_id = ParseToNumber(props.skill_group_id);
    var _skill_group = GetElementById(props.skill_group_id, API_get_skills(), 'skill_group_id');
    var _attibute = API_get_attribute_by_id(props.skill_group_id);
    var _attribute_group = API_get_attribute_group_by_id(_attibute.group);

    const incrementAttribute = (_increment) => {
        API_increment_attribute (props.skill_group_id, API_get_increase_selector(), _increment);
        //if(accordionContent[selectedAttribute.attributeID].isHighlihted)
    }

    /*const incrementAncestryBonus = (_increment, _attributeID) => {
        //if(accordionContent[selectedAttribute.attributeID].isHighlihted)
        API_increment_attribute (_attributeID, 'ancestry', _increment);
    }

    const incrementAttributeBonus = (_increment, _attributeID) => {
        //if(accordionContent[selectedAttribute.attributeID].isHighlihted)
        API_increment_attribute (_attributeID, 'other', _increment);
    }*/

    /*const incrementAttributeAny  = (_increment) => {
        if(API_get_increase_selector() == 'increase')
        {
            incrementAttribute (_increment, 'increase', props.skill_group_id);
        }
        else if(API_get_increase_selector() == 'ancestry')
        {
            incrementAttribute (_increment, 'ancestry', props.skill_group_id);
        }
        else if(API_get_increase_selector() == 'other')
        {
            incrementAttribute (_increment, 'other', props.skill_group_id);
        }
    }*/

    const incrementSkill = (_skillID, _increment) => {
        API_increment_skill (props.skill_group_id, _skillID, API_get_increase_selector(), _increment);
    }

    const click_skill_dice = (_skillID) => {
        var _skill = API_get_skill_by_id(_skill_group.skill_group_id, _skillID);
        API_roll_dice(_skill.display_name, _skill.dice, true, 'attribute', _skill.display_name);
        //role_dice(_skill.display_name, _skill.dice)
    }

    const isArcana = () => {
        let _attribute = API_get_attribute_by_id(_skill_group.skill_group_id);
        let _attribute_group = API_get_attribute_group_by_id(_attribute.group);

        if(_skill_group.skill_group_id == API_get_attribute_ref().arcana)
            return true;

        return false;
    }

    const getVisibility = () => { 

        if(!isArcana())
            return true;

        if(isArcana())
            return _attribute_group.focus >= 0;

        return false;
    }

    /*const toggleExpanded = () => {
        API_toggle_skill_group_visibility(props.skill_group_id);
    }*/

    const getAttributeGroup= () => {
        let _attribute = API_get_attribute_by_id(props.skill_group_id);
        let _attributeGroup = API_get_attribute_group_by_id(_attribute.group);
        return _attributeGroup;
    }

    return ( 
        <div>
            { getVisibility() &&
                <table className={table.table_skills} style={{background: 'transparent', marginLeft: 0, marginRight: 0}}>
                    {/*<div style={{background: 'transparent', marginLeft: -1, marginRight: 0}}>*/}
                        {/*<tr style={{background: 'transparent'}}>
                            <td className={table.cell_transparent} style={{textAlign: 'left', paddingLeft: 5}} colspan={2}></td>
                            <td className={table.cell_header} style={{width: 90, minWidth:90}}>{API_get_std_header_labels().increase}</td>
                            <td className={table.cell_header} style={{width: 60, minWidth:60}}>{API_get_std_header_labels().cost}</td>
                            <td className={table.cell_header} style={{width: 60, minWidth:60}}>{API_get_std_header_labels().bonus}</td>
                            <td className={table.cell_header} style={{width: 61, minWidth:61}}>{API_get_table_header_labels().ancestry_mod}</td>
                            <td className={table.cell_header} style={{width: 60, minWidth:60}}>{API_get_std_header_labels().base}</td>
                            <td className={table.cell_header} style={{width: 121, minWidth:121}}>{API_get_std_header_labels().value}</td>
                        </tr>*/}
                        <tr style={{borderBottom: '1px solid black'}}>
                            <td className={table.cell_label} style={{width: 70, minWidth: 70, textAlign: 'left', paddingLeft: 5}}>{API_get_std_header_labels().training_in}</td>
                            <td className={table.cell_label} style={{width: 140, minWidth: 140, fontWeight: 'bold', textAlign: 'left', paddingLeft: 5, borderRight: '0px solid black'}} colspan={2}>{_attibute.display_name} ({_attibute.abbreviation})</td>
                            <td className={table.cell_label} style={{borderLeft: '0px solid black', padding: 0}} colspan={2}>
                                {(API_get_edit_character_visibility()) &&
                                    <div>
                                        <p style={{display: 'inline', fontSize: 11, marginRight: 4}}>{API_get_std_header_labels().cost_first_letter}: {_attibute.cost}</p>
                                        <button className={btn.button_icon} onClick={() => incrementAttribute(1)} style={{width: 20, height: 20, margin: '0px 2px 0px 0px'}}>+</button>
                                        <button className={btn.button_icon} onClick={() => incrementAttribute(-1)} style={{width: 20, height: 20, margin: '0px 0px 0px 0px'}}>-</button>
                                    </div>
                                }
                            </td>
                            <td style={{width: 46, minWidth:46}}>{_attibute.increase + _attibute.base}{/*_attibute.max*/}</td>
                            <td className={table.cell_header} style={{width: 22, minWidth:22}}>+</td>
                            <td className={table.cell_label} style={{width: 38, minWidth: 38}}>{API_get_table_header_labels().ancestry_mod}</td>
                            <td style={{width: 38, minWidth: 38}}>{_attibute.ancestry_bonus}</td>
                            {/*<td className={table.cell_greyed_out} style={{width: 60, minWidth:60}}>{_attibute.other_bonus}+{_attibute.temporary_mod}</td>*/}
                            {/*<td>{_attribute_group.focus}</td>*/}
                            {/*<td className={table.cell_greyed_out} style={{width: 60, minWidth:60}}>{_attibute.base}</td>*/}
                            <td className={table.cell_header} style={{width: 24, minWidth: 24}}>{'\u2794'}</td>
                            <td className={table.cell_highlighted} style={{width: 54, minWidth:54, border: '2px solid black'}}>
                                {_attibute.value}
                                {_attibute.other_bonus > 0 && <label style={{fontSize: 9, color: 'green'}}> (+{_attibute.other_bonus})</label>}
                                {_attibute.other_bonus < 0 && <label style={{fontSize: 9, color: 'red'}}> ({_attibute.other_bonus})</label>}
                                {_attibute.temporary_mod > 0 && <label style={{fontSize: 9, color: 'green'}}> (+{_attibute.temporary_mod})</label>}
                                {_attibute.temporary_mod < 0 && <label style={{fontSize: 9, color: 'red'}}> ({_attibute.temporary_mod})</label>}
                            </td>
                            <td className={table.cell_header} style={{width: 80, minWidth:80, borderRight: '0px solid black'}}>
                                { isArcana() &&
                                    'Circle'
                                }
                            </td>
                            { isArcana() &&
                                <td style={{width: 40, minWidth:40}}>
                                    {API_get_max_magic_circle()}
                                </td>
                            }
                            { !isArcana() &&
                                <td className={table.cell_header}  style={{width: 40, minWidth:40, borderLeft: '0px solid black'}}>
                                </td>
                            }
                            {/*<td className={table.cell_greyed_out} style={{width: 60, minWidth:60}}>{API_get_std_header_labels().cost}: {_attibute.cost}</td>*/}
                        </tr>
                    {/*</div>*/}
                    {/* API_get_skill_group_visibility(props.skill_group_id) && 
                        <div style={{background: 'transparent', marginLeft: -1, marginRight: 0}}>*/}
                            <tr className={table.cell_sub_header}>
                                <td style={{textAlign: 'left', paddingLeft: 5}} colspan={3}>{API_get_std_header_labels().skill}</td>
                                {/*<td colspan={2}>
                                    {_skill_group.skill_group_id != API_get_attribute_ref().arcana &&
                                        API_get_std_header_labels().increase
                                    }
                                    {_skill_group.skill_group_id == API_get_attribute_ref().arcana &&
                                        API_get_std_header_labels().circle
                                    }
                                </td>*/}
                                {/*<td style={{width: 40, minWidth:40}}>{API_get_std_header_labels().cost}</td>*/}
                                { !isArcana() &&
                                    <td>{API_get_std_header_labels().specialization}</td>
                                }
                                { isArcana() &&
                                    <td>{API_get_std_header_labels().circle}</td>
                                }
                                <td colspan={2}>{API_get_base_stat_labels().ancestry}</td>
                                <td style={{textAlign: 'left', paddingLeft: 5}} colspan={3}>{API_get_std_header_labels().influence}</td>
                                {/*<td style={{width: 60, minWidth:60}}>{API_get_std_header_labels().bonus}</td>*/}
                                <td colspan={2}>{API_get_std_header_labels().value}</td>
                                <td colspan={2}>{API_get_std_header_labels().dice}</td>
                            </tr>
                        {Object.entries(_skill_group.skills).map(([key, value], index) => (
                            <tr>
                                <td style={{width: 180, minWidth: 180, textAlign: 'left', paddingLeft: 5, borderRight: '0px solid black'}} colspan={2}>
                                    {value.display_name != 'empty' &&
                                        value.display_name
                                    }
                                </td>
                                <td style={{width: 79, minWidth:79, borderLeft: '0px solid black'}} >
                                    {(API_get_edit_character_visibility() && value.display_name != 'empty') &&
                                        <div>
                                            <p style={{display: 'inline', fontSize: 11, marginRight: 4}}>{API_get_std_header_labels().cost_first_letter}: {value.cost}</p>
                                            <button className={btn.button_icon} onClick={() => incrementSkill(_skill_group.skills[key].id, 1)} style={{width: 20, height: 20, margin: '0px 2px 0px 0px'}}>+</button>
                                            <button className={btn.button_icon} onClick={() => incrementSkill(_skill_group.skills[key].id, -1)} style={{width: 20, height: 20, margin: '0px 0px 0px 0px'}}>-</button>
                                        </div>
                                    }
                                </td>
                                {/* index == -1 &&
                                    <td className={table.cell_header} style={{width: 10, minWidth: 10}} rowspan={Object.keys(_skill_group.skills).length}></td>
                                */}
                                { !isArcana() &&
                                    <td style={{width: 54, minWidth: 54}} >
                                        {value.display_name != 'empty' &&
                                            value.increase + _attibute.value /*+ '/' + value.max*/
                                        }
                                    </td>
                                }
                                { isArcana() &&
                                    <td style={{width: 54, minWidth: 54}} >
                                        {value.display_name != 'empty' &&
                                            value.increase /*+ '/' + value.max*/
                                        }
                                    </td>
                                }
                               {/*} <td className={table.cell_greyed_out}>
                                    {value.display_name != 'empty' &&
                                        value.cost
                                    }
                                </td>*/}
                                { index == 0 &&
                                    <td className={table.cell_header} style={{width: 22, minWidth: 22}} rowspan={Object.keys(_skill_group.skills).length}>
                                        {value.display_name != 'empty' &&
                                            '+'
                                        }
                                    </td>
                                }
                                <td style={{width: 40, minWidth:40}}>
                                    {value.display_name != 'empty' &&
                                        value.ancestry_mod
                                    }
                                </td>
                                { index == 0 &&
                                    <td className={table.cell_header} rowspan={Object.keys(_skill_group.skills).length}>
                                        {value.display_name != 'empty' &&
                                            '+'
                                        }
                                    </td>
                                }
                                <td className={table.cell_greyed_out} style={{width: 29, minWidth:29}}>
                                    {value.display_name != 'empty' &&
                                        API_get_attribute_by_id(value.attr_mod_1_id).abbreviation + '/' + API_get_attribute_by_id(value.attr_mod_2_id).abbreviation
                                    }
                                </td>
                                {/*<td className={table.cell_greyed_out} style={{width: 29, minWidth:29}}>
                                    {value.display_name != 'empty' &&
                                        API_get_attribute_by_id(value.attr_mod_2_id).abbreviation
                                    }
                                </td>*/}
                                <td style={{width: 30, minWidth:30}}>
                                    {value.display_name != 'empty' &&
                                        API_get_skill_attribute_mod(_skill_group.skill_group_id, value)
                                    }
                                </td>
                                { index == 0 &&
                                    <td className={table.cell_header} rowspan={Object.keys(_skill_group.skills).length}>
                                        {value.display_name != 'empty' &&
                                            '\u2794'
                                        }
                                    </td>
                                }
                                {/*<td>
                                    {value.display_name != 'empty' &&
                                        value.other_bonus + '+' + value.temporary_mod}
                                </td>*/}
                                {index == 0 && //erster Zeile in jeder Gruppe
                                    <td className={table.cell_highlighted} style={{borderTop: '2px solid black', textAlign: 'center'}}>
                                        {value.display_name != 'empty' &&
                                            value.value
                                        }
                                        {value.other_bonus > 0 && value.display_name != 'empty' && <label style={{fontSize: 9, color: 'green'}}> (+{value.other_bonus})</label>}
                                        {value.other_bonus < 0 && value.display_name != 'empty' && <label style={{fontSize: 9, color: 'red'}}> ({value.other_bonus})</label>}
                                        {value.temporary_mod > 0 && value.display_name != 'empty' && <label style={{fontSize: 9, color: 'green'}}> (+{value.temporary_mod})</label>}
                                        {value.temporary_mod < 0 && value.display_name != 'empty' && <label style={{fontSize: 9, color: 'red'}}> ({value.temporary_mod})</label>}
                                    </td>
                                }
                                {(index < Object.keys(_skill_group.skills).length-1 && index > 0) && 
                                    <td className={table.cell_highlighted} style={{textAlign: 'center'}}>
                                        {value.display_name != 'empty' &&
                                            value.value
                                        }
                                        {value.other_bonus > 0 && value.display_name != 'empty' && <label style={{fontSize: 9, color: 'green'}}> (+{value.other_bonus})</label>}
                                        {value.other_bonus < 0 && value.display_name != 'empty' && <label style={{fontSize: 9, color: 'red'}}> ({value.other_bonus})</label>}
                                        {value.temporary_mod > 0 && value.display_name != 'empty' && <label style={{fontSize: 9, color: 'green'}}> (+{value.temporary_mod})</label>}
                                        {value.temporary_mod < 0 && value.display_name != 'empty' && <label style={{fontSize: 9, color: 'red'}}> ({value.temporary_mod})</label>}
                                    </td>
                                }
                                { (index == Object.keys(_skill_group.skills).length-1) && 
                                    <td className={table.cell_highlighted} style={{borderLeft: '2px solid black', borderRight: '2px solid black', borderBottom: '2px solid black', textAlign: 'center'}}>
                                        {value.display_name != 'empty' &&
                                            value.value
                                        }
                                        {value.other_bonus > 0 && value.display_name != 'empty' && <label style={{fontSize: 9, color: 'green'}}> (+{value.other_bonus})</label>}
                                        {value.other_bonus < 0 && value.display_name != 'empty' && <label style={{fontSize: 9, color: 'red'}}> ({value.other_bonus})</label>}
                                        {value.temporary_mod > 0 && value.display_name != 'empty' && <label style={{fontSize: 9, color: 'green'}}> (+{value.temporary_mod})</label>}
                                        {value.temporary_mod < 0 && value.display_name != 'empty' && <label style={{fontSize: 9, color: 'red'}}> ({value.temporary_mod})</label>}
                                    </td>
                                }
                                <td colspan={2}>
                                    {value.display_name != 'empty' &&
                                        <button className={btn.dice_btn} onClick={() => click_skill_dice(value.id)}>{API_get_dice_list_string(value.dice)}</button>
                                    }
                                </td>
                            </tr>
                        ))}
                    {/*</div>
                }*/}
                </table>
            }
        </div>
    );
}
 
export {
    SkillTablePage
};