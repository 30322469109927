import { createRef, useContext } from 'react'
import {APIContext} from '../../../API/API'
import {TextDataContext} from '../../../API/TextDataContext'
import {UserDataContext} from '../../../API/UserDataContext'
import btn_style from '../../../StylesStylish/btn.module.css'
import importIcon from '../../../images/icons/import.png';
import { Tooltip } from 'react-tooltip'

const UploadButton = (props) => {
    const inputRef = createRef();
    const { width = '48px', height = '24px' } = props;

    const {
        API_upload_character,
        API_upload_campaign
    } = useContext(APIContext);

    const {
        API_get_user
    } = useContext(UserDataContext);

    const {
        API_get_std_labels
    } = useContext(TextDataContext);

    const openFileDialog = () => {
        inputRef.current?.click();
    }

    const _user_role = API_get_user().role;

    const handleFileChange = e => {
        if(_user_role == 'player'){
            API_upload_character(e);
        }
        else if(_user_role == 'game_master'){
            API_upload_campaign(e);
        }
        
    };

    return ( 
        <div>
            <input ref={inputRef} style={{display: 'none'}} type="file" onChange={handleFileChange} />
            <button type="file" data-tooltip-id="btn-tooltip" data-tooltip-place="bottom" data-tooltip-content={API_get_std_labels().import_local} className={btn_style.std_button} onClick={openFileDialog} style={{width: width, minWidth: width, height: height}}>
                <img src={importIcon} alt="Bild" style={{ float: 'left', width: '16px' }} />
            </button>
            <Tooltip id="btn-tooltip" />
        </div>
     );
}
 
export default UploadButton;