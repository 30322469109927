
import { useState, useContext } from 'react'
import {APIContext, APIContextProvider} from '../../API/API'
import {AttributeContext, AttributeContextProvider} from '../../API/AttributeContext'
import {BaseStatContext, BaseStatContextProvider} from '../../API/BaseStatContext'
import {ToolDataContext, ToolDataContextProvider} from '../../API/ToolDataContext'
import {TextDataContext, TextDataContextProvider} from '../../API/TextDataContext'
import {RestUserContext, RestUserContextProvider} from '../../ServerConnection/RestUserContext'
import {UserDataContext, UserDataContextProvider} from '../../API/UserDataContext'
import {CampaignContext, CampaignContextProvider} from '../../API/CampaignContext'
import {
    ParseToInteger,
    ParseToFloat} 
from '../../API/Functions/GeneralFunctions'
import btn from '../../Styles/btn.module.css'
import table from '../../Styles/table.module.css'
import field from '../../Styles/field.module.css'
import container from '../../Styles/container.module.css'

const RemoteCharactersTable = () => {
    const {
        REST_login_user,
        REST_create_new_user,
        REST_load_characters,
        REST_save_character,
        REST_load_character,
        REST_load_campaign_character,
        REST_delete_character,
        REST_apply_config,
        REST_subscribe_character_to_campaign,
        REST_load_campaign_characters,
        REST_load_assigned_campaigns
    } = useContext(RestUserContext);

    const {
        API_get_user, 
        API_set_user,
        API_get_remote_connect_message,
        API_set_remote_connect_message
    } = useContext(UserDataContext);

    const {
        API_move_character_element
    } = useContext(APIContext)

    const {
        API_get_campaign_id
    } = useContext(CampaignContext)

    

    const getCharacter = () => {
        //console.log('getCharacter');
        let user = API_get_user();
        //console.log(user);

        return user.characters;
    }

    const getUserRole = () => {
        //console.log('getCharacter');
        let user = API_get_user();
        //console.log(user);

        return user.role;
    }
    
    const loadCharactersFromRemote = () => {
        //console.log("REST_load_characters")
        //console.log('loadFromRemote')
        //console.log(API_get_user());
        //API_set_remote_connect_message('Try');
        REST_load_characters();
    }
    
    const loadCampaignCharactersFromRemote = () => {
        console.log("REST_load_campaign_characters")
        let _campaignID = API_get_campaign_id();
        console.log(_campaignID)
        //console.log('loadFromRemote')
        //console.log(API_get_user());
        //API_set_remote_connect_message('Try');
        REST_load_campaign_characters(_campaignID);
    }

    const saveCharacterToRemote = () => {
        console.log("REST_save_character")
        //API_set_remote_connect_message('Save');
        REST_save_character();
    }

    const loadCharacterFromRemote = (_characterID) => {
        //API_set_remote_connect_message('Load');
        REST_load_character(_characterID, () => REST_load_assigned_campaigns());
    }

    const loadCampaignCharacterFromRemote = (_campaignOwner, _characterID) => {
        //API_set_remote_connect_message('Load');
        REST_load_campaign_character(_campaignOwner, _characterID, () => REST_load_assigned_campaigns());
    }

    const deleteCharacterAtRemote = (_characterID) => {
        console.log("REST_delete_character")
        console.log(_characterID)
        //API_set_remote_connect_message('Delete');
        REST_delete_character(_characterID);
    }

    const unsubscribeCharcter = (_characterOwner, _characterID) => {
        console.log("unsubscribeCharcter")
        console.log(_characterID)

        let _campaignID = API_get_campaign_id();
        let _campaignOwner = API_get_user().name
        console.log(_campaignOwner)
        console.log(_campaignID)
        //REST__campaigns
        REST_subscribe_character_to_campaign(_characterOwner, _characterID, _campaignOwner, _campaignID, false);
    }

    const moveElement = (_characterID, _direction) => {
        console.log("API_move_character_element")
        console.log(_characterID)
        console.log(_direction)
        API_move_character_element(_characterID, _direction);
    }

    const getConfigFromRemote = () => {
        //console.log("getConfigFromRemote");
        REST_apply_config();
    }

    
    //const clickEditElementHandler = (_value) => {
    //}

    //const removeElement = (_element) => {
        //API_remove_gear_element(_element);
    //}

    

    return ( 
        <div style={{marginLeft: 15}}>
            <table className={table.table_skills} style={{background: 'transparent', marginLeft: 0, marginRight: 0}}>
                <tr style={{borderBottom: '1px solid black'}}>
                    <td className={table.cell_header} style={{width: 400, minWidth: 400, textAlign: 'left', paddingLeft: 5}}>Character</td>
                    <td className={table.cell_header} style={{width: 280, minWidth: 280}}>ID</td>
                    { getUserRole() == 'game_master' &&
                        <td className={table.cell_header} style={{width: 200, minWidth: 200, textAlign: 'left', paddingLeft: 5}}>User</td>
                    }
                    {/*<td className={table.cell_header}>Load</td>*/}
                    <td className={table.cell_header} colspan={5}>Action</td>
                </tr>
                {Object.entries(getCharacter()).map(([key, value], index) => (
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: 5}}>{value.name}</td>
                        <td>{value.id}</td>
                        { getUserRole() == 'game_master' &&
                            <td style={{textAlign: 'left', paddingLeft: 5}}>{value.user}</td>
                        }
                        {/*<td style={{width: 64, minWidth: 64}}>
                            <button className={btn.button_std} style={{height: 20, width: 60, minWidth: 60}} onClick={() => loadCharacterFromRemote(value.id)}>Load</button>
                        </td>*/}
                        { getUserRole() == 'player' &&
                            <td style={{width: 24, minWidth: 24, borderRight: '0px solid black'}}>
                                <button className={btn.button_icon} onClick={() => loadCharacterFromRemote(value.id)}>L</button>
                            </td>
                        }
                        { getUserRole() == 'game_master' &&
                            <td style={{width: 24, minWidth: 24, borderRight: '0px solid black'}}>
                                <button className={btn.button_icon} onClick={() => loadCampaignCharacterFromRemote(value.user, value.id)}>L</button>
                            </td>
                        }
                        <td style={{width: 24, minWidth: 24, borderLeft: '0px solid black', borderRight: '0px solid black'}}>
                            <button className={btn.button_icon} onClick={() => moveElement(value.id, 'up')}>+</button>
                        </td>
                        <td style={{width: 24, minWidth: 24, borderLeft: '0px solid black', borderRight: '0px solid black'}}>
                            <button className={btn.button_icon} onClick={() => moveElement(value.id, 'down')}>-</button>
                        </td>
                        <td style={{width: 24, minWidth: 24, borderLeft: '0px solid black', borderRight: '0px solid black'}}>
                        </td>
                        { getUserRole() == 'player' &&
                            <td style={{width: 24, minWidth: 24, borderLeft: '0px solid black'}}>
                                <button className={btn.button_icon} onClick={() => deleteCharacterAtRemote(value.id)}>x</button>
                            </td>
                        }
                        { getUserRole() == 'game_master' &&
                            <td style={{width: 24, minWidth: 24, borderLeft: '0px solid black'}}>
                                <button className={btn.button_icon} onClick={() => unsubscribeCharcter(value.user, value.id)}>u</button>
                            </td>
                        }
                        {/*<td>
                            <button className={btn.button_std} style={{height: 20, width: 60, minWidth: 60}} onClick={() => loadCharacterFromRemote(value.id)}>Load</button>
                            { getUserRole() == 'player' &&
                                <button className={btn.button_std} style={{height: 20, width: 60, minWidth: 60}} onClick={() => deleteCharacterAtRemote(value.id)}>Delete</button>
                            }
                        </td>*/}
                    </tr>
                    
                ))}
            </table>
            { getUserRole() == 'player' &&
                <div>
                    <button onClick={saveCharacterToRemote} style={{margin: '0px 0px 0px 0px', width: 120, minWidth: 120}}>Save to Remote</button>
                    <button onClick={loadCharactersFromRemote} style={{margin: 5, width:120, minWidth: 120}}>Show Remote</button>
                </div>
            }
            { getUserRole() == 'game_master' &&
                <div>
                    <button onClick={loadCampaignCharactersFromRemote} style={{marginTop: 5, width:120, minWidth: 120}}>Show Remote</button>
                </div>
            }
            <button onClick={getConfigFromRemote}>Get Config</button>
            <p style={{color: 'red', height: 25, marginTop: 5}}>{API_get_remote_connect_message()}</p>
        </div>
    );
}
 
export {
    RemoteCharactersTable
}