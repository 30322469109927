import { createRef, useContext } from 'react'
import {APIContext} from '../../../API/API'
import {TextDataContext} from '../../../API/TextDataContext'
import {UserDataContext} from '../../../API/UserDataContext'
import {RestUserContext} from '../../../ServerConnection/RestUserContext'
import btn_style from '../../../StylesStylish/btn.module.css'
import exportIcon from '../../../images/icons/export_cloud.png';
import { Tooltip } from 'react-tooltip'

const SaveSettingsButton = (props) => {
    const { width = '48px', height = '24px' } = props;

    const {
        API_get_character_file_name,
        API_get_character_file,
        API_get_campaign_file_name,
        API_get_campaign_file
    } = useContext(APIContext);

    const {
        REST_set_config
    } = useContext(RestUserContext);

    const {
        API_get_user
    } = useContext(UserDataContext);

    const {
        API_get_std_labels
    } = useContext(TextDataContext);


    const _user_name = API_get_user().name;

    function handleClick() {
        REST_set_config(_user_name);
    }

    return ( 
        <div>
            <button className={btn_style.std_button} data-tooltip-id="btn-tooltip" data-tooltip-place="bottom" data-tooltip-content={API_get_std_labels().save} onClick={handleClick} style={{width: width, minWidth: width, height: height}}>
                <img src={exportIcon} alt="Bild" style={{ float: 'left', width: '22px' }} />
            </button>
            <Tooltip id="btn-tooltip" />
        </div>
     );
}
 
export default SaveSettingsButton;