import { useState, useContext } from 'react'
import {SkillContext, SkillContextProvider} from '../../API/SkillContext'
import {TextDataContext, TextDataContextProvider} from '../../API/TextDataContext'
import {BaseStatContext, BaseStatContextProvider} from '../../API/BaseStatContext'
import {APIContext, APIContextProvider} from '../../API/API'
import {SkillTablePage} from './SkillTablePage'
import {SkillGroupHeaderPage} from './SkillGroupHeaderPage'
import btn from '../../Styles/btn.module.css'
import table from '../../Styles/table.module.css'
import field from '../../Styles/field.module.css'
import container from '../../Styles/container.module.css'

const FreePointsTablePage = (props) => {
    const {
        API_full_GUI_update,
        API_increment_level,
        API_update_base_stat,
        API_increment_attribute,
        API_increment_ancestry_bonus,
        API_increment_other_bonus,
        API_increment_focus,
        API_increment_kho_tal,
        API_update_comments,
        API_get_dice_list_string,
        API_roll_dice,
        API_modify_character_stat_bonus,
        //API_modify_secondary_attribute_bonus,
        API_change_hitpoints,
        API_increment_free_attribute_points_bonus,
        API_increment_free_focus_points_bonus,
        API_increment_free_skill_points_bonus
    } = useContext(APIContext);

    const {
        API_get_base_stat_labels, 
        API_set_base_stat_labels,
        API_get_std_labels,
        API_set_std_labels,
        API_get_std_header_labels,
        API_set_std_header_labels,
        API_get_tab_view_labels,
        API_set_tab_view_labels,
        API_get_character_stats_labels,
        API_set_character_stats_labels,
        API_get_table_header_labels,
        API_set_table_header_labels
    } = useContext(TextDataContext);

    const {
        API_get_base_stats,
        API_get_free_attribute_points,
        API_get_free_focus_points,
        API_get_edit_character_visibility,
        API_set_edit_character_visibility
    } = useContext(BaseStatContext);

    const {
        API_get_skills,
        API_get_free_skill_points,
        API_get_edit_skill_visibility,
        API_set_edit_skill_visibility,
        API_get_skill_selector,
        API_set_skill_selector
    } = useContext(SkillContext);

    const incrementPointBonus = (_increment) => {
        if(props.id == 'attribute')
        {
            API_increment_free_attribute_points_bonus(_increment);
        }
        else if(props.id == 'focus')
        {
            API_increment_free_focus_points_bonus(_increment);
        }
        else if(props.id == 'skill')
        {
            API_increment_free_skill_points_bonus(_increment);
        }
    }

    const getHeaderName = () => {
        if(props.id == 'attribute')
        {
            return API_get_table_header_labels().free_attribute_points
        }
        else if(props.id == 'focus')
        {
            return API_get_table_header_labels().free_focus_points
        }
        else if(props.id == 'skill')
        {
            return API_get_table_header_labels().free_skill_points
        }
        return null;
    }

    const getPoints = () => {
        if(props.id == 'attribute')
        {
            return API_get_free_attribute_points();
        }
        else if(props.id == 'focus')
        {
            return API_get_free_focus_points();
        }
        else if(props.id == 'skill')
        {
            return API_get_free_skill_points();
        }
        return 'unknown'
    }

    return ( 
        <div style={{height: '100%', background: 'transparent'}}>
            <div style={{display: 'flex', verticalAlign: 'top'}}>
                <div style={{display: 'inline-block'}}>
                    <table className = {table.table_base_stats}>
                        <tr>
                            <td className={table.cell_label} style={{textAlign: 'left', borderRight: '0px solid black',width: 90, minWidth: 90}}>
                                {getHeaderName(props.id)}
                            </td>
                            <td className={table.cell_label} style={{borderLeft: '0px solid black', width: 48, minWidth: 48}}>
                                {API_get_edit_character_visibility() &&
                                    <div>
                                        <button className={btn.button_icon} onClick={() => incrementPointBonus(1)} style={{width: 22, height: 22, margin: '0px 2px 0px 0px'}}>+</button>
                                        <button className={btn.button_icon} onClick={() => incrementPointBonus(-1)} style={{width: 22, height: 22, margin: '0px 0px 0px 0px'}}>-</button>
                                    </div>
                                }
                            </td>
                            <td className={table.cell_table_separator} style={{width: 10, minWidth: 10}}></td>
                            <td className={table.cell_input} style={{width: 30, minWidth: 30}}>{getPoints().bonus}</td>
                            <td className={table.cell_table_separator} style={{width: 10, minWidth: 10}}></td>
                            <td className={table.cell_input} style={{width: 60, minWidth: 60}}>{getPoints().total - getPoints().used}/{getPoints().total}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
     );
}
 
export {FreePointsTablePage};