import { 
    //INIT_get_base_stats, 
    INIT_get_free_attribute_points, 
    INIT_get_free_focus_points,
    INIT_get_ancestry_saldo,
    INIT_get_attribute_groups,
    INIT_get_attributes,
    //INIT_get_character_stats,
    //INIT_get_secondary_attributes,
    INIT_get_negative_attribute_restriction,
    INIT_get_negative_focus_restriction,
    INIT_get_lead_attribute_tags,
    //INIT_get_lead_attribute_matching
    INIT_get_attribute_group_ref,
    INIT_get_attribute_ref
 } from "../ParametersInit/AttributesInit";

 import {
    INIT_get_base_stats, 
    INIT_get_character_stats,
    INIT_get_stat_overview,
    INIT_get_money_list
} from '../ParametersInit/CharacterStatInit'
 import { 
    INIT_get_formula_skill_ids,
    INIT_get_skills,
    INIT_get_free_skill_points,
    INIT_get_negative_skill_restriction
 } from "../ParametersInit/SkillParametersInit";

 import { 
    INIT_get_melee_weapon_template,
    INIT_get_ranged_weapon_template,
    INIT_get_armor_template,
    INIT_get_gear_template,
    INIT_get_melee_fighting_types,
    //INIT_get_hand_types,
    //INIT_get_melee_type_skill_matching,
    INIT_get_ranged_fighting_types,
    //INIT_get_ranged_type_skill_matching,
    INIT_get_unarmed_weapons,
    INIT_get_melee_weapons,
    INIT_get_ranged_weapons,
    INIT_get_armor,
    INIT_get_gear
 } from "../ParametersInit/EquipmentListInit";

 import { 
    INIT_get_feat_lvl_2,
    INIT_get_feat_lvl_2_template,
    INIT_get_feat_lvl_3,
    INIT_get_feat_lvl_3_template
 } from "../ParametersInit/FeatListInit";

 import { 
    INIT_get_magic_spells,
    INIT_get_magic_spell_template,
    INIT_get_magic_element_types,
    INIT_get_magic_circle_types
 } from "../ParametersInit/MagicSpellsInit";


//All Backend Functions should only be accessed via UI_Controller or from functions inside Backend Folder
var base_stats = INIT_get_base_stats();
var free_attribute_points = INIT_get_free_attribute_points();
var free_focus_points = INIT_get_free_focus_points();
var ancestry_saldo = INIT_get_ancestry_saldo();
var free_skill_points = INIT_get_free_skill_points();
var negative_attribute_restriction = INIT_get_negative_attribute_restriction();
var negative_skill_restriction = INIT_get_negative_skill_restriction();
var negative_focus_restriction = INIT_get_negative_focus_restriction();
var attribute_groups = INIT_get_attribute_groups();
var attributes  = INIT_get_attributes();
var character_stats  = INIT_get_character_stats();
var stat_overview  = INIT_get_stat_overview();
var money_list  = INIT_get_money_list();
var skills  = INIT_get_skills();
var melee_fighting_types  = INIT_get_melee_fighting_types();
var ranged_fighting_types  = INIT_get_ranged_fighting_types();
//var hand_types  = INIT_get_hand_types();
var unarmed_weapons  = INIT_get_unarmed_weapons();
var melee_weapons  = INIT_get_melee_weapons();
var ranged_weapons  = INIT_get_ranged_weapons();
var lead_attribute_tags  = INIT_get_lead_attribute_tags();
var melee_weapon_template  = INIT_get_melee_weapon_template();
var ranged_weapon_template  = INIT_get_ranged_weapon_template();
var armor = INIT_get_armor();
var gear = INIT_get_gear();
var armor_template = INIT_get_armor_template();
var gear_template = INIT_get_gear_template();
var feat_lv_2 = INIT_get_feat_lvl_2();
var feat_lv_2_template = INIT_get_feat_lvl_2_template();
var feat_lv_3 = INIT_get_feat_lvl_3();
var feat_lv_3_template = INIT_get_feat_lvl_3_template();
var magic_spells = INIT_get_magic_spells();
var magic_spells_template = INIT_get_magic_spell_template();
var magic_element_types  = INIT_get_magic_element_types();
var magic_circle_types  = INIT_get_magic_circle_types();
var formula_skill_ids = INIT_get_formula_skill_ids()
var attribute_group_ref  = INIT_get_attribute_group_ref();
var attribute_ref = INIT_get_attribute_ref();

var comments = '';

const BACKEND_get_attribute_group_ref = () => 
{
    return attribute_group_ref;
}

const BACKEND_get_attribute_ref = (_armor) => 
{
    return attribute_ref;
}

const BACKEND_get_armor = () => 
{
    return armor;
}

const BACKEND_set_armor = (_armor) => 
{
    armor = _armor;
}

const BACKEND_get_gear = () => 
{
    return gear;
}

const BACKEND_set_gear = (_gear) => 
{
    gear = _gear;
}

const BACKEND_get_feat_lv_2 = () => 
{
    return feat_lv_2;
}

const BACKEND_set_feat_lv_2 = (_feat_lv_2) => 
{
    feat_lv_2 = _feat_lv_2;
}

const BACKEND_get_feat_lv_3 = () => 
{
    return feat_lv_3;
}

const BACKEND_set_feat_lv_3 = (_feat_lv_3) => 
{
    feat_lv_3 = _feat_lv_3;
}

const BACKEND_get_magic_spells = () => 
{
    return magic_spells;
}

const BACKEND_set_magic_spells = (_magic_spells) => 
{
    magic_spells = _magic_spells;
}

const BACKEND_get_armor_template = () => 
{
    return armor_template;
}

const BACKEND_get_gear_template = () => 
{
    return gear_template;
}

const BACKEND_get_feat_lv_2_template = () => 
{
    return feat_lv_2_template;
}

const BACKEND_get_feat_lv_3_template = () => 
{
    return feat_lv_3_template;
}

const BACKEND_get_magic_spells_template = () => 
{
    return magic_spells_template;
}

const BACKEND_get_melee_weapon_template = () => 
{
    return melee_weapon_template;
}

const BACKEND_get_ranged_weapon_template = () => 
{
    return ranged_weapon_template;
}

const BACKEND_set_base_stats = (_baseStats) => 
{
    base_stats = _baseStats;
}

const BACKEND_get_base_stats = () => 
{
    return base_stats;
}

const BACKEND_set_free_attribute_points = (_freeAttributePoints) => 
{
    free_attribute_points = _freeAttributePoints;
}

const BACKEND_get_free_attribute_points = () => 
{
    return free_attribute_points;
}

const BACKEND_set_free_focus_points = (_freeFocusPoints) => 
{
    free_focus_points = _freeFocusPoints;
}

const BACKEND_get_free_focus_points = () => 
{
    return free_focus_points;
}

const BACKEND_set_ancestry_saldo = (_ancestry_saldo) => 
{
    ancestry_saldo = _ancestry_saldo;
}

const BACKEND_get_ancestry_saldo = () => 
{
    return ancestry_saldo;
}

const BACKEND_set_free_skill_points = (_freeSkillPoints) => 
{
    free_skill_points = _freeSkillPoints;
}

const BACKEND_get_free_skill_points = () => 
{
    return free_skill_points;
}

const BACKEND_set_attribute_groups = (_attributeGroups) => 
{
    attribute_groups = _attributeGroups;
}

const BACKEND_get_attribute_groups = () => 
{
    return attribute_groups;
}

const BACKEND_set_attributes = (_attributes) => 
{
    attributes = _attributes;
}

const BACKEND_get_attributes = () => 
{
    return attributes;
}

const BACKEND_set_character_stats = (_character_stats) => 
{
    character_stats = _character_stats;
}

const BACKEND_get_character_stats = () => 
{
    return character_stats;
}

const BACKEND_set_stat_overview = (_stat_overview) => 
{
    stat_overview = _stat_overview;
}

const BACKEND_get_stat_overview = () => 
{
    return stat_overview;
}

const BACKEND_set_money_list = (_money_list) => 
{
    money_list = _money_list;
}

const BACKEND_get_money_list = () => 
{
    return money_list;
}

const BACKEND_set_skills = (_skills) => 
{
    skills = _skills;
}

const BACKEND_get_skills = () => 
{
    return skills;
}

const BACKEND_set_comments = (_comments) => 
{
    comments = _comments;
}

const BACKEND_get_comments = () => 
{
    return comments;
}

const BACKEND_get_negative_attribute_restriction = () => 
{
    return negative_attribute_restriction;
}

const BACKEND_get_negative_skill_restriction = () => 
{
    return negative_skill_restriction;
}

const BACKEND_get_negative_focus_restriction = () => 
{
    return negative_focus_restriction;
}

const BACKEND_set_melee_fighting_types = (_melee_fighting_types) => 
{
    melee_fighting_types = _melee_fighting_types;
}

const BACKEND_get_melee_fighting_types = () => 
{
    return melee_fighting_types;
}

/*const BACKEND_set_melee_type_skill_matching = (_melee_type_skill_matching) => 
{
    melee_type_skill_matching = _melee_type_skill_matching;
}

const BACKEND_get_melee_type_skill_matching = () => 
{
    return melee_type_skill_matching;
}*/

const BACKEND_set_ranged_fighting_types = (_ranged_fighting_types) => 
{
    ranged_fighting_types = _ranged_fighting_types;
}

const BACKEND_get_ranged_fighting_types = () => 
{
    return ranged_fighting_types;
}

/*const BACKEND_set_hand_types = (_hand_types) => 
{
    hand_types = _hand_types;
}

const BACKEND_get_hand_types = () => 
{
    return hand_types;
}*/

/*const BACKEND_set_ranged_type_skill_matching = (_ranged_type_skill_matching) => 
{
    ranged_type_skill_matching = _ranged_type_skill_matching;
}

const BACKEND_get_ranged_type_skill_matching = () => 
{
    return ranged_type_skill_matching;
}*/

const BACKEND_set_unarmed_weapons = (_unarmed_weapons) => 
{
    unarmed_weapons = _unarmed_weapons;
}

const BACKEND_get_unarmed_weapons = () => 
{
    return unarmed_weapons;
}

const BACKEND_set_melee_weapons = (_melee_weapons) => 
{
    melee_weapons = _melee_weapons;
}

const BACKEND_get_melee_weapons = () => 
{
    return melee_weapons;
}

const BACKEND_set_ranged_weapons = (_ranged_weapons) => 
{
    ranged_weapons = _ranged_weapons;
}

const BACKEND_get_ranged_weapons = () => 
{
    return ranged_weapons;
}

const BACKEND_set_lead_attribute_tags = (_lead_attribute_tags) => 
{
    lead_attribute_tags = _lead_attribute_tags;
}

const BACKEND_get_lead_attribute_tags = () => 
{
    return lead_attribute_tags;
}

const BACKEND_set_magic_element_types = (_magic_element_types) => 
{
    magic_element_types = _magic_element_types;
}

const BACKEND_get_magic_element_types = () => 
{
    return magic_element_types;
}

const BACKEND_set_magic_circle_types = (_magic_circle_types) => 
{
    magic_circle_types = _magic_circle_types;
}

const BACKEND_get_magic_circle_types = () => 
{
    return magic_circle_types;
}

const BACKEND_get_formula_skill_ids = () => 
{
    return formula_skill_ids;
}

export 
{
    BACKEND_set_base_stats, 
    BACKEND_get_base_stats, 
    BACKEND_set_free_attribute_points, 
    BACKEND_get_free_attribute_points, 
    BACKEND_set_free_focus_points, 
    BACKEND_get_free_focus_points,
    BACKEND_set_free_skill_points,
    BACKEND_get_ancestry_saldo,
    BACKEND_set_ancestry_saldo,
    BACKEND_get_free_skill_points,
    BACKEND_set_attribute_groups,
    BACKEND_get_attribute_groups,
    BACKEND_set_attributes,
    BACKEND_get_attributes,
    BACKEND_set_character_stats,
    BACKEND_get_character_stats,
    BACKEND_set_stat_overview,
    BACKEND_get_stat_overview,
    BACKEND_set_money_list,
    BACKEND_get_money_list,
    //BACKEND_set_secondary_attributes,
    //BACKEND_get_secondary_attributes,
    BACKEND_set_skills,
    BACKEND_get_skills,
    BACKEND_set_comments,
    BACKEND_get_comments,
    BACKEND_get_negative_attribute_restriction,
    BACKEND_get_negative_skill_restriction,
    BACKEND_get_negative_focus_restriction,
    BACKEND_set_melee_fighting_types,
    BACKEND_get_melee_fighting_types,
    //BACKEND_set_melee_type_skill_matching,
    //BACKEND_get_melee_type_skill_matching,
    BACKEND_set_ranged_fighting_types,
    BACKEND_get_ranged_fighting_types,
    //BACKEND_set_ranged_type_skill_matching,
    //BACKEND_get_ranged_type_skill_matching,
    //BACKEND_set_hand_types,
    //BACKEND_get_hand_types,
    BACKEND_set_unarmed_weapons,
    BACKEND_get_unarmed_weapons,
    BACKEND_set_melee_weapons,
    BACKEND_get_melee_weapons,
    BACKEND_set_ranged_weapons,
    BACKEND_get_ranged_weapons,
    BACKEND_set_lead_attribute_tags,
    BACKEND_get_lead_attribute_tags,
    BACKEND_get_melee_weapon_template,
    BACKEND_get_ranged_weapon_template,
    BACKEND_get_armor,
    BACKEND_set_armor,
    BACKEND_get_gear,
    BACKEND_set_gear,
    BACKEND_get_feat_lv_2,
    BACKEND_set_feat_lv_2,
    BACKEND_get_feat_lv_3,
    BACKEND_set_feat_lv_3,
    BACKEND_get_magic_spells,
    BACKEND_set_magic_spells,
    BACKEND_get_armor_template,
    BACKEND_get_gear_template,
    BACKEND_get_feat_lv_2_template,
    BACKEND_get_feat_lv_3_template,
    BACKEND_get_magic_spells_template,
    BACKEND_set_magic_element_types,
    BACKEND_get_magic_element_types,
    BACKEND_set_magic_circle_types,
    BACKEND_get_magic_circle_types,
    BACKEND_get_formula_skill_ids,
    BACKEND_get_attribute_group_ref,
    BACKEND_get_attribute_ref
};