
import { useState, useContext } from 'react'
import {APIContext, APIContextProvider} from '../../API/API'
import {AttributeContext, AttributeContextProvider} from '../../API/AttributeContext'
import {BaseStatContext, BaseStatContextProvider} from '../../API/BaseStatContext'
import {ToolDataContext, ToolDataContextProvider} from '../../API/ToolDataContext'
import {TextDataContext, TextDataContextProvider} from '../../API/TextDataContext'
import {RestUserContext, RestUserContextProvider} from '../../ServerConnection/RestUserContext'
import {UserDataContext, UserDataContextProvider} from '../../API/UserDataContext'
import {CampaignContext, CampaignContextProvider} from '../../API/CampaignContext'
import {
    ParseToInteger,
    ParseToFloat} 
from '../../API/Functions/GeneralFunctions'
import btn from '../../Styles/btn.module.css'
import table from '../../Styles/table.module.css'
import field from '../../Styles/field.module.css'
import container from '../../Styles/container.module.css'

const RemoteCampaignsTable = () => {
    const {
        REST_login_user,
        REST_create_new_user,
        REST_load_characters,
        REST_save_character,
        REST_load_character,
        REST_delete_character,
        REST_apply_config,
        REST_load_campaign,
        REST_delete_campaign,
        REST_subscribe_character_to_campaign,
        REST_save_campaign,
        REST_load_assigned_campaigns,
        REST_load_campaigns,
        REST_subscribe_user_to_campaign
    } = useContext(RestUserContext);

    const {
        API_get_user, 
        API_set_user,
        API_get_remote_connect_message,
        API_set_remote_connect_message,
        API_get_character_id,
        API_set_character_id
    } = useContext(UserDataContext);

    const {
        API_move_campaign_element
    } = useContext(APIContext)

    const {
        API_get_campaign_id,
        API_get_remote_campaign_message,
        API_set_remote_campaign_message,
        API_get_remote_subscribed_user_message,
        API_set_remote_subscribed_user_message
    } = useContext(CampaignContext)
    

    const [campaignName, setCampaignName] = useState('');
    //const [subscribe, setSubscribe] = useState(false); // muss noch auf Multi Select umgestellt werden

    const getCampaigns = () => {
        //console.log('getCharacter');
        let user = API_get_user();
        //console.log(user);

        return user.campaigns;
    }

    const getUserRole = () => {
        //console.log('getCharacter');
        let user = API_get_user();
        //console.log(user);

        return user.role;
    }
    
    const loadCampaignsFromRemote = () => {
        console.log("REST_load_campaigns")
        REST_load_campaigns();
    }
    
    const loadAssignedCampaignsFromRemote = () => {
        console.log("REST_load_assigned_campaigns")
        REST_load_assigned_campaigns();
    }

    const saveCampaignToRemote = () => {
        console.log("REST_save_campaign")
        REST_save_campaign();
    }

    const loadCampaignFromRemote = (_campaignID) => {
        console.log("REST_load_campaign");
        console.log(_campaignID)
        REST_load_campaign(_campaignID);
    }

    const deleteCampaignAtRemote = (_campaignID) => {
        console.log("REST_delete_campaign")
        console.log(_campaignID)
        REST_delete_campaign(_campaignID);
    }

    const subscribeCharacter = (_campaignOwner, _campaignID) => {
        console.log("REST_subscribe_character_to_campaign")
        console.log(_campaignOwner)
        console.log(_campaignID)
        console.log(true)
        let _characterOwner = API_get_user().name;
        let _characterID = API_get_character_id();
        REST_subscribe_character_to_campaign(_characterOwner, _characterID, _campaignOwner, _campaignID, true);
    }

    const unsubscribeUser = (_campaignOwner, _campaignID) => {
        console.log("REST_subscribe_character_to_campaign")
        console.log(_campaignOwner)
        console.log(_campaignID)
        console.log(false)
        REST_subscribe_user_to_campaign(_campaignOwner, _campaignID, false);
    }

    const moveElement = (_characterID, _direction) => {
        console.log('moveElement')
        console.log(_characterID)
        console.log(_direction)
        API_move_campaign_element(_characterID, _direction);
        //API_move_gear_element(_element, _direction);
    }

    const getConfigFromRemote = () => {
        //console.log("getConfigFromRemote");
        //REST_apply_config();
    }

    /*const handleCampaignInputChange = (event) => {
        setCampaignName(event.target.value);
    };*/

    
    //const clickEditElementHandler = (_value) => {
    //}

    //const removeElement = (_element) => {
        //API_remove_gear_element(_element);
    //}

    return ( 
        <div style={{marginLeft: 15}}>
            <table className={table.table_skills} style={{background: 'transparent', marginLeft: 0, marginRight: 0}}>
                <tr style={{borderBottom: '1px solid black'}}>
                    <td className={table.cell_header} style={{width: 400, minWidth: 400, textAlign: 'left', paddingLeft: 5}}>Campaigns</td>
                    <td className={table.cell_header} style={{width: 280, minWidth: 280}}>ID</td>
                    { getUserRole() == 'player' &&
                        <td className={table.cell_header} style={{width: 200, minWidth: 200}}>User</td>
                    }
                    { getUserRole() == 'player' &&
                        <td className={table.cell_header} style={{width: 120, minWidth: 120}}>Status</td>
                    }
                    {/*<td className={table.cell_header} style={{width: 64, minWidth: 64}}>Load</td>*/}
                    <td className={table.cell_header} colspan={5}>Action</td>
                </tr>
                {Object.entries(getCampaigns()).map(([key, value], index) => (
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: 5}}>{value.name}</td>
                        <td>{value.id}</td>
                        { getUserRole() == 'player' &&
                            <td>{value.user}</td>
                        }
                        { getUserRole() == 'player' &&
                            <td>
                                {value.subscription_status == false &&
                                    'no'
                                }
                                {value.subscription_status == true &&
                                    'yes'
                                }
                            </td>
                        }
                        {/*<td>
                            { getUserRole() == 'player' &&
                                <button className={btn.button_std} style={{height: 20, width: 60, minWidth: 60}} onClick={() => loadCharacterFromRemote(value.id)}>Subscribe</button>
                            }
                            { getUserRole() == 'game_master' &&
                                <div>
                                    <button className={btn.button_std} style={{height: 20, width: 60, minWidth: 60}} onClick={() => loadCharacterFromRemote(value.id)}>Load</button>
                                </div>
                            }
                        </td>*/}
                        
                        { getUserRole() == 'player' &&
                            <td style={{width: 24, minWidth: 24, borderRight: '0px solid black'}}>
                                <button className={btn.button_icon} onClick={() => subscribeCharacter(value.user, value.id)}>  
                                    {value.subscription_status == false &&
                                        's'
                                    }
                                    {value.subscription_status == true &&
                                        'u'
                                    }
                                </button>
                            </td>
                        }   
                        { getUserRole() == 'game_master' &&
                            <td style={{width: 24, minWidth: 24, borderRight: '0px solid black'}}>
                                <button className={btn.button_icon} onClick={() => loadCampaignFromRemote(value.id)}>L</button>
                            </td>
                        }
                        <td style={{width: 24, minWidth: 24, borderLeft: '0px solid black', borderRight: '0px solid black'}}>
                            <button className={btn.button_icon} onClick={() => moveElement(value.id, 'up')}>+</button>
                        </td>
                        <td style={{width: 24, minWidth: 24, borderLeft: '0px solid black', borderRight: '0px solid black'}}>
                            <button className={btn.button_icon} onClick={() => moveElement(value.id, 'down')}>-</button>
                        </td>
                        <td style={{width: 24, minWidth: 24, borderLeft: '0px solid black', borderRight: '0px solid black'}}>
                        </td>
                        { getUserRole() == 'player' &&
                            <td style={{width: 24, minWidth: 24, borderLeft: '0px solid black'}}>
                                <button className={btn.button_icon} onClick={() => unsubscribeUser(value.user, value.id)}>x</button>
                            </td>
                        }   
                        { getUserRole() == 'game_master' &&
                            <td style={{width: 24, minWidth: 24, borderLeft: '0px solid black'}}>
                                <button className={btn.button_icon} onClick={() => deleteCampaignAtRemote(value.id)}>x</button>
                            </td>
                        }
                    </tr>
                    
                ))}
            </table>
            { getUserRole() == 'game_master' &&
                <div>
                    <button onClick={saveCampaignToRemote} style={{margin: '0px 0px 0px 0px', width: 120, minWidth: 120}}>Save to Remote</button>
                    <button onClick={loadCampaignsFromRemote} style={{margin: 5, width:120, minWidth: 120}}>Show Remote</button>
                </div>
            }
            { getUserRole() == 'player' &&
                <div style={{marginTop: 5}}>
                    <button onClick={loadAssignedCampaignsFromRemote} style={{margin: 0, width:120, minWidth: 120}}>Show Remote</button>
                </div>
            }
            <p style={{color: 'red', height: 25}}>{API_get_remote_campaign_message()}</p>
        </div>
    );
}
 
export {
    RemoteCampaignsTable
}