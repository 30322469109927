import { createRef, useState, useContext } from 'react'
import {APIContext, APIContextProvider} from '../../../API/API'
import {TextDataContext} from '../../../API/TextDataContext'
import {UserDataContext} from '../../../API/UserDataContext'
import {ToolDataContext} from '../../../API/ToolDataContext'
import {MessageBoxContext} from '../../../API/MessageBoxContext'
import btn_style from '../../../StylesStylish/btn.module.css'
import newCharacterIcon from '../../../images/icons/new_character.png';
import newCampaignIcon from '../../../images/icons/new_campaign.png';
import { Tooltip } from 'react-tooltip'

const NewCharacterButton = (props) => {
    const inputRef = createRef();
    const { width = '48px', height = '24px' } = props;

    const {
        API_init_character,
        API_init_campaign
    } = useContext(APIContext);

    const {
        API_get_std_labels,
        API_get_message_box_content_labels,
    } = useContext(TextDataContext);

    const {
        API_get_tool_data
    } = useContext(ToolDataContext);

    const {
        API_get_user
    } = useContext(UserDataContext);

    const {
        API_get_yes_no_message_box_visibility,
        API_get_confirm_function,
        API_open_yes_no_message_box,
        API_close_yes_no_message_box
    } = useContext(MessageBoxContext);

    const _user_role = API_get_user().role;

    const performNewCharacter = () => {
        let _toolData = API_get_tool_data();
        if(_user_role == 'player'){
            API_init_character(_toolData.language)
        }
        else if(_user_role == 'game_master'){
            API_init_campaign(_toolData.language)
        }
    }

    const getToolTip = () => {
        if(_user_role == 'player'){
            return API_get_std_labels().new_character;
        }
        else if(_user_role == 'game_master'){
            return API_get_std_labels().new_campaign;
        }
    }

    const newCharacterHandler = () => {
        let _content = '-';
        if(_user_role == 'player'){
            _content = API_get_message_box_content_labels().new_character;
        }
        else if(_user_role == 'game_master'){
            _content = API_get_message_box_content_labels().new_campaign;
        }

        API_open_yes_no_message_box(_content, performNewCharacter);
    }

    return ( 
        <div>
            <button className={btn_style.std_button} data-tooltip-id="btn-tooltip" data-tooltip-place="bottom" data-tooltip-content={getToolTip()} onClick={newCharacterHandler} style={{width: width, minWidth: width, height: height}}> 
                {_user_role == 'player' &&
                    <img src={newCharacterIcon} alt="Bild" style={{ float: 'left', width: '22px' }} />
                }
                {_user_role == 'game_master' &&
                    <img src={newCampaignIcon} alt="Bild" style={{ float: 'left', width: '26px' }} />
                }
            </button>
            <Tooltip id="btn-tooltip" />
        </div>
     );
}
 
export default NewCharacterButton;