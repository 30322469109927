import { useState, useContext, useRef } from 'react'
import {APIContext, APIContextProvider} from '../../API/API'
import {AttributeContext, AttributeContextProvider} from '../../API/AttributeContext'
import {BaseStatContext, BaseStatContextProvider} from '../../API/BaseStatContext'
import {ToolDataContext, ToolDataContextProvider} from '../../API/ToolDataContext'
import {TextDataContext, TextDataContextProvider} from '../../API/TextDataContext'
import {RestUserContext, RestUserContextProvider} from '../../ServerConnection/RestUserContext'
import {UserDataContext, UserDataContextProvider} from '../../API/UserDataContext'
import {CampaignContext, CampaignContextProvider} from '../../API/CampaignContext'
import {
    ParseToInteger,
    ParseToFloat} 
from '../../API/Functions/GeneralFunctions'
import btn from '../../Styles/btn.module.css'
import table from '../../Styles/table.module.css'
import field from '../../Styles/field.module.css'
import container from '../../Styles/container.module.css'


const RemoteSubscribedUsersTable = () => {
    const {
        REST_login_user,
        REST_create_new_user,
        REST_load_characters,
        REST_save_character,
        REST_load_character,
        REST_delete_character,
        REST_apply_config,
        REST_subscribe_user_to_campaign,
        REST_load_subscribed_users
    } = useContext(RestUserContext);

    const {
        API_get_user, 
        API_set_user,
        API_get_remote_connect_message,
        API_set_remote_connect_message
    } = useContext(UserDataContext);

    
    const {
        API_get_subscribed_user,
        API_get_campaign_name,
        API_get_campaign_id,
        API_get_remote_campaign_message,
        API_set_remote_campaign_message,
        API_get_remote_subscribed_user_message,
        API_set_remote_subscribed_user_message
    } = useContext(CampaignContext);

    const {
        API_move_subscribed_user_element
    } = useContext(APIContext)

    const inputRef = useRef();
    const [subscribedUser, setSubscribedUser] = useState('');
    const [subscribe, setSubscribe] = useState(false); // muss noch auf Multi Select umgestellt werden

    const getCampaigns = () => {
        //console.log('getCharacter');
        let _subscribedUser = API_get_subscribed_user();
        //console.log(user);

        return _subscribedUser;
    }

    const getUserRole = () => {
        //console.log('getCharacter');
        let user = API_get_user();
        //console.log(user);

        return user.role;
    }
    
    /*const loadFromRemote = () => {
        //console.log('loadFromRemote')
        //console.log(API_get_user());
        //API_set_remote_connect_message('Try');
        REST_load_characters();
    }

    const saveCharacterToRemote = () => {
        //API_set_remote_connect_message('Save');
        REST_save_character();
    }*/

    const loadSubscribedUsersFromRemote = () => {
        console.log("REST_load_subscribed_users")
        let _campaignID = API_get_campaign_id();
        console.log(_campaignID)
        //API_set_remote_connect_message('Load');
        REST_load_subscribed_users(_campaignID);
    }

    /*const deleteCharacterAtRemote = (_characterID) => {
        //API_set_remote_connect_message('Delete');
        REST_delete_character(_characterID);
    }

    const getConfigFromRemote = () => {
        //console.log("getConfigFromRemote");
        REST_apply_config();
    }*/

    const handleSubscribedUserInputChange = (event) => {
        setSubscribedUser(event.target.value);
    };


    const unsubscribeUser = (_userName) => {
        console.log("REST_subscribe_user_to_campaign")
        let _campaignID = API_get_campaign_id();
        console.log(_campaignID)
        console.log(_userName)
        console.log(false)
        REST_subscribe_user_to_campaign(_userName, _campaignID, false);
    }
    
    const subscribeUser = () => {
        let _campaignID = API_get_campaign_id();
        let _inputValue = inputRef.current.value;
        console.log("REST_subscribe_user_to_campaign")
        console.log(_campaignID)
        console.log(_inputValue)
        console.log(true)
        REST_subscribe_user_to_campaign(_inputValue, _campaignID, true);
    }

    /*const removeElement = (_element) => {
        //API_remove_gear_element(_element);
    }*/

    const moveElement = (_userName, _direction) => {
        console.log("moveElement")
        console.log(_userName)
        console.log(_direction)
        API_move_subscribed_user_element(_userName, _direction);
        //API_move_gear_element(_element, _direction);
    }

    return ( 
        <div style={{marginLeft: 15}}>
            <table className={table.table_skills} style={{background: 'transparent', marginLeft: 0, marginRight: 0}}>
                <tr style={{borderBottom: '1px solid black'}}>
                    <td className={table.cell_header} style={{width: 400, minWidth: 400, textAlign: 'left', paddingLeft: 5}}>Users</td>
                    {/*<td className={table.cell_header} style={{width: 100, minWidth: 100}}>Unsubscribe</td>*/}
                    <td className={table.cell_header} colspan={4}>Action</td>
                </tr>
                {Object.entries(getCampaigns()).map(([key, value], index) => (
                    <tr>
                        <td style={{textAlign: 'left', paddingLeft: 5}}>{value.name}</td>
                        {/*<td>
                            <button className={btn.button_std} style={{height: 20, width: 60, minWidth: 60}} onClick={() => deleteCharacterAtRemote(value.id)}>Unsubscribe</button>
                        </td>*/}
                        <td style={{width: 24, minWidth: 24, borderLeft: '0px solid black', borderRight: '0px solid black'}}>
                            <button className={btn.button_icon} onClick={() => moveElement(value.name, 'up')}>+</button>
                        </td>
                        <td style={{width: 24, minWidth: 24, borderLeft: '0px solid black', borderRight: '0px solid black'}}>
                            <button className={btn.button_icon} onClick={() => moveElement(value.name, 'down')}>-</button>
                        </td>
                        <td style={{width: 24, minWidth: 24, borderLeft: '0px solid black', borderRight: '0px solid black'}}>
                        </td>
                        <td style={{width: 24, minWidth: 24, borderLeft: '0px solid black'}}>
                            <button className={btn.button_icon} onClick={() => unsubscribeUser(value.name)}>u</button>
                        </td>
                    </tr>
                    
                ))}
            </table>
            { getUserRole() == 'game_master' &&
                <div style={{marginTop: 5}}>
                    <table>
                        <tr style={{height: 30}}>
                            <td style={{width: 110, minWidth: 110, verticalAlign: 'middle'}}>
                                Subscribe User:
                            </td>
                            <td style={{verticalAlign: 'middle'}}>
                                <input type="text" value={subscribedUser} onChange={handleSubscribedUserInputChange} ref={inputRef}/>
                            </td>
                            <td style={{width: 12, minWidht: 12}}></td>
                            <td style={{width: 124, minWidth: 124, verticalAlign: 'middle', textAlign: 'center'}}>
                                <button onClick={subscribeUser} style={{margin: '0px 0px 0px 0px', width: 120, minWidth: 120}}>Subscribe</button>
                            </td>
                            <td style={{width: 124, minWidth: 124, verticalAlign: 'middle', textAlign: 'center'}}>
                                <button onClick={loadSubscribedUsersFromRemote} style={{margin: 0, width:120, minWidth: 120}}>Show Remote</button>
                            </td>
                        </tr>
                    </table>
                </div>
            }
            {/*getUserRole() == 'player' &&
                <div style={{marginTop: 5}}>
                    <button onClick={loadFromRemote} style={{margin: 0, width:120, minWidth: 120}}>Show Remote</button>
                </div>
            */}
            <p style={{color: 'red', height: 25,}}>{API_get_remote_subscribed_user_message()}</p>
        </div>
    );
}
 
export {
    RemoteSubscribedUsersTable
}